/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Union = createSvgIcon(
    <path d="M11.3 21.9L27.5 5.6 29.8 8 11.3 26.4l-9-9L4.4 15z" clipRule="evenodd" fill="#fff" fillRule="evenodd" />,
    'Union'
)

export default Union
