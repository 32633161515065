import { useMemo } from 'react'

const useNavigation = (navigationData, name = 'main') => {
    const result = useMemo(() => {
        if (!navigationData) {
            return []
        }
        const { mapping, items, options = {} } = navigationData
        const checkMappingForValidNames = () =>
            (Array.isArray(name) ? name : [name]).some(n => !!navigationData?.mapping?.[n])
        if (!mapping || !checkMappingForValidNames() || !items) {
            return []
        }
        const checkNestedNavigation = n =>
            !!options?.createSubmenu &&
            !navigationData?.mapping?.[n]?.some(nav => options?.keepNavigationsFlat?.includes(nav))
        const mapNavigationItem = n =>
            checkNestedNavigation(n)
                ? mapping[n].map(menuName => items[menuName]).filter(Boolean)
                : mapping[n].reduce((acc, menuName) => [...acc, ...items[menuName]], []).filter(Boolean)

        return typeof name === 'string' ? mapNavigationItem(name) : name.map(mapNavigationItem)
    }, [navigationData, name])

    return result
}
export default useNavigation
