/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    '.stickyWrapper': {
        position: 'relative',
        '&_inner': {
            zIndex: (theme.zIndex.ad || 11) + 1,
            position: 'relative'
        }
    }
})

export default style
