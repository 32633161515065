const grid = {
    // 12 columns grid
    12: {
        // display: 'grid',
        // gridTemplateColumns: 'repeat(12, 1fr)',
        // gridColumnGap: toRems([20, 20, 20, 25, 25, 40], true),
        // gridRowGap: toRems([15, 15, 15, 25, 25, 40], true),
        // width: '100%',
        // maxWidth: '100%',
        // px: toRems([15, 15, 15, 20, 40, 40], true),

        // xs: 0,
        // sm: 360,
        // md: 768,
        // lg: 1024,
        // xl: 1280,
        // xxl: 1440,
        // xxxl: 1600

        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridColumnGap: '20px',
        gridRowGap: '15px',
        width: '100%',
        maxWidth: '100%',
        paddingRigth: '15px',
        paddingLeft: '15px',

        '@media (min-width: 768px)': {
            gridColumnGap: '25px',
            gridRowGap: '25px',
            paddingRight: '20px',
            paddingLeft: '20px'
        },

        '@media (min-width: 1024px)': {
            paddingRight: '40px',
            paddingLeft: '40px'
        },

        '@media (min-width: 1440px)': {
            gridColumnGap: '40px',
            gridRowGap: '40px'
        },

        col: {
            // 1 row
            '1x1': {
                gridColumn: 'span 1',
                gridRow: 'span 1'
            },
            '2x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 2', 'span 2', 'span 2'],
                gridColumn: 'span 12',
                gridRow: 'span 1',

                '@media (min-width: 768px)': {
                    gridColumn: 'span 2'
                }
            },
            '3x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 3', 'span 3', 'span 3'],
                gridColumn: 'span 12',
                gridRow: 'span 1',

                '@media (min-width: 768px)': {
                    gridColumn: 'span 3'
                }
            },
            '4x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                gridColumn: 'span 12',
                gridRow: 'span 1',

                '@media (min-width: 768px)': {
                    gridColumn: 'span 4'
                }
            },
            // refactor this code to use the same pattern as above
            '5x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 5'
                }
            },
            '6x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 6'
                }
            },
            '7x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 7'
                }
            },
            '8x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 8'
                }
            },
            '9x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 9', 'span 9', 'span 9'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 9'
                }
            },
            '10x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 10', 'span 10', 'span 10'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 10'
                }
            },
            '11x1': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 11', 'span 11', 'span 11'],
                gridColumn: 'span 12',
                gridRow: 'span 1',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 11'
                }
            },
            '12x1': {
                gridColumn: 'span 12',
                gridRow: 'span 1'
            },
            // 2 rows
            '1x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 1', 'span 1', 'span 1'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 1'
                }
            },
            '2x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 2', 'span 2', 'span 2'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 2'
                }
            },
            '3x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 3', 'span 3', 'span 3'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 3'
                }
            },
            '4x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 4'
                }
            },
            '5x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 5'
                }
            },
            '6x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 6'
                }
            },
            '7x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 7'
                }
            },
            '8x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 8'
                }
            },
            '9x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 9', 'span 9', 'span 9'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 9'
                }
            },
            '10x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 10', 'span 10', 'span 10'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 10'
                }
            },
            '11x2': {
                // gridColumn: ['span 12', 'span 12', 'span 12', 'span 11', 'span 11', 'span 11'],
                gridColumn: 'span 12',
                gridRow: 'span 2',
                '@media (min-width: 768px)': {
                    gridColumn: 'span 11'
                }
            },
            '12x2': {
                gridColumn: 'span 12',
                gridRow: 'span 2'
            }
        }
    }
}

export default {
    grid
}
