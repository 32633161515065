const organization = {
    '@type': 'Organization',
    name: process.env.NEXT_PUBLIC_APP_NAME_ZENA,
    url: process.env.NEXT_PUBLIC_APP_ROOT_ZENA,
    sameAs: [
        'https://www.facebook.com/www.zena.hr/',
        'https://www.instagram.com/zenahr/',
        'https://twitter.com/zenahr'
    ],
    logo: {
        '@type': 'ImageObject',
        url: '/images/amp/zenahr-publisher-600x60.png',
        width: 600,
        height: 60
    }
}
export default organization
