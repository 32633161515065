import { Children } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Columns.style'

const ColumnsStyled = styled.div(props => ({ ...styles(props) }))

function Columns({ className, children, columns, spacing, ...rest }) {
    if (!children?.length) {
        return null
    }

    return (
        <ColumnsStyled columns={columns} spacing={spacing} className={className} {...rest}>
            {Children.map(children, child => (
                <div className="columnsItem">{child}</div>
            ))}
        </ColumnsStyled>
    )
}

Columns.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.oneOf([1, 2, 3, 4]),
    spacing: PropTypes.number
}

Columns.defaultProps = {
    className: undefined,
    columns: 3,
    spacing: 32
}

export default withTheme(Columns)
