import { createContext, useContext } from 'react'

import { SsrPreloadedAdManifest } from '../../next/data/adsGetServerSideProps'
import { ProcessedAdList } from '../../types/ads/Ad.interface'

export type AdsLoading = { status: 'loading' }

export const AdManifestContext = createContext<SsrPreloadedAdManifest | AdsLoading>({ status: 'loading' })

export const useAdManifest = () => {
    return useContext(AdManifestContext)
}

export const useVideoAdList = () => {
    const adManifest = useAdManifest() as ProcessedAdList
    return adManifest?.videos
}
