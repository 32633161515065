import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Help.style'

const HelpStyled = styled.div(props => ({ ...styles(props) }))

function Help({ text, className, ...rest }) {
    const textHtmlProps = useInnerHtml(text)

    if (!text) {
        return null
    }

    return (
        <HelpStyled className={className} {...rest}>
            <div className="inputHelpInner" {...textHtmlProps} />
        </HelpStyled>
    )
}

Help.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string
}

Help.defaultProps = {
    text: undefined,
    className: undefined
}

export default Help
