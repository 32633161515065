import { useMemo } from 'react'
// eslint-disable-next-line
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'

const useAdPreview = () => {
    const router = useRouter()
    const { messageSource, messageType } = router.query

    return useMemo(() => messageSource === '@CMS' && messageType === 'preview', [messageSource, messageType])
}

export default useAdPreview
