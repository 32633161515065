import { GifImagePartial, isGif } from './image-gif'
import getImageOutput, { ImageOutput } from './image-output'

export interface OriginalImagePartial extends GifImagePartial {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    original_url: string
    // eslint-disable-next-line @typescript-eslint/naming-convention
    original_aspect_ratio: number
}

export enum ImageWant {
    ORIGINAL = 'original',
    GIF = 'gif',
    VARIATION = 'variation',
    NONE = 'none'
}

export const getImageWant = (original: boolean, image?: string | OriginalImagePartial): ImageWant => {
    if (typeof image === 'undefined') {
        return ImageWant.NONE
    }

    if (typeof image === 'string') {
        if (original) {
            return ImageWant.ORIGINAL
        }

        return ImageWant.VARIATION
    }

    if (isGif(image)) {
        return ImageWant.GIF
    }

    if (original) {
        return ImageWant.ORIGINAL
    }

    return ImageWant.VARIATION
}

export const wantsOriginalOrIsGif = (original: boolean, image?: string | OriginalImagePartial): boolean => {
    return [ImageWant.ORIGINAL, ImageWant.GIF].includes(getImageWant(original, image))
}

export const getOriginal = (
    image: string | OriginalImagePartial,
    initialWidth: number,
    initialHeight: number,
    initialRatio?: number
): ImageOutput => {
    if (typeof image === 'string') {
        return getImageOutput(
            initialRatio,
            initialWidth,
            initialHeight,
            `https://d19p4plxg0u3gz.cloudfront.net/${image}/original`
        )
    }

    return getImageOutput(image.original_aspect_ratio, initialWidth, initialHeight, image.original_url)
}
