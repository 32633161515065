import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Tab.style'

const TabStyled = styled.div(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Tab({ children, label, labelTagComponent, className, ...rest }) {
    return (
        <TabStyled className={className} {...rest}>
            {children}
        </TabStyled>
    )
}

Tab.propTypes = {
    label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    labelTagComponent: PropTypes.elementType,
    className: PropTypes.string
}

Tab.defaultProps = {
    label: undefined,
    labelTagComponent: 'div',
    className: undefined
}

// Needed so the parent component can access child defaultProps
const ThemedComponent = Tab
ThemedComponent.defaultProps = Tab.defaultProps

export default ThemedComponent
