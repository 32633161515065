import { useCallback, useEffect, useRef } from 'react'

const useOutsideClick = (ref, handler, when = true) => {
    const savedHandler = useRef(handler)

    const memoizedCallback = useCallback(
        event => {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                savedHandler.current(event)
            }
        },
        [ref]
    )

    useEffect(() => {
        savedHandler.current = handler
    })

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (when) {
            document.addEventListener('click', memoizedCallback)
            document.addEventListener('ontouchstart', memoizedCallback)

            return () => {
                document.removeEventListener('click', memoizedCallback)
                document.removeEventListener('ontouchstart', memoizedCallback)
            }
        }
    }, [ref, handler, when, memoizedCallback])
}

export default useOutsideClick
