import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { FormGroup, MenuItem, TextField, ThemeProvider, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml, useList, useTaxonList } from '@hmn/rtl-web-core/hooks'

import useUser from '../../../../hooks/useUser'
import { Button, buttonVariants } from '..'
import { Checkbox } from '../Input/components'
import styles from './TesterForm.style'

const TesterFormStyled = styled.div(props => ({ ...styles(props) }))

function TesterForm({ title, className, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)

    const theme = useTheme()

    const { profile } = useUser()

    const {
        data: [jobStatusFetched],
        action: jobStatusAction,
        isSuccess: jobStatusIsSuccess,
        mutationIsLoading: jobStatusMutationIsLoading
    } = useTaxonList(
        'job-status',
        {
            entity: 'users',
            entityId: profile?.id
        },
        {
            enabled: !!profile?.id
        }
    )

    const [jobStatusSelected, setJobStatusSelected] = useState(jobStatusFetched)

    useEffect(() => {
        if (jobStatusIsSuccess) {
            setJobStatusSelected(jobStatusFetched)
        }
    }, [jobStatusFetched, jobStatusIsSuccess])

    const {
        data: personalInterestsFetched,
        action: personalInterestsAction,
        isSuccess: personalInterestsIsSuccess,
        mutationIsLoading: personalInterestsMutationIsLoading
    } = useTaxonList(
        'personal-interests',
        {
            entity: 'users',
            entityId: profile?.id
        },
        {
            enabled: !!profile?.id
        }
    )

    const [personalInterestsSelected, setPersonalInterestsSelected] = useState(personalInterestsFetched)

    useEffect(() => {
        if (personalInterestsIsSuccess) {
            setPersonalInterestsSelected(personalInterestsFetched)
        }
    }, [personalInterestsFetched, personalInterestsIsSuccess])

    const getTaxonomyTaxonsEndpointPartial = taxonomyName => `taxonomies/${taxonomyName}/taxons`

    const getTaxonIdEndpointPartial = (taxonomyTaxonsEndpointPartial, taxonId) =>
        `/api/${taxonomyTaxonsEndpointPartial}/${taxonId}`

    const protectedTesterJobStatus = getTaxonomyTaxonsEndpointPartial('protected-tester-job-status')
    const { data: jobStatusList = [], isSuccess: jobStatusListIsSuccess } = useList(protectedTesterJobStatus)

    const protectedTesterPersonalInterests = getTaxonomyTaxonsEndpointPartial('protected-tester-personal-interests')
    const { data: personalInterestsList } = useList(protectedTesterPersonalInterests)

    const initialValues = {
        /**
         * NOTE: Wait for the jobStatusList to get all options before setting the value for select
         * in order to avoid the error in the console.
         */
        jobStatus: jobStatusListIsSuccess ? jobStatusSelected?.taxon?.id || '' : '',
        personalInterests: personalInterestsSelected.map(({ taxon }) => taxon.id)
    }

    const handleSubmit = values => {
        const { jobStatus, personalInterests = [] } = values

        const createJobStatus = (taxonomyEndpoint, taxonId, options = {}) =>
            jobStatusAction.create(
                {
                    id: getTaxonIdEndpointPartial(taxonomyEndpoint, taxonId)
                },
                options
            )

        if (jobStatus && jobStatus !== jobStatusSelected?.taxon?.id) {
            if (jobStatusSelected?.taxon?.id) {
                // Old job status must be removed before the new job status can be set.
                jobStatusAction.delete(
                    {
                        id: jobStatusSelected?.id
                    },
                    {
                        onSuccess: () => {
                            createJobStatus(protectedTesterJobStatus, jobStatus, {
                                onSuccess: setJobStatusSelected
                            })
                        }
                    }
                )
            } else {
                createJobStatus(protectedTesterJobStatus, jobStatus, {
                    onSuccess: setJobStatusSelected
                })
            }
        }

        const personalInterestsRemove = personalInterestsSelected
            .filter(personalInterestSelected => !personalInterests.includes(personalInterestSelected.taxon.id))
            .map(({ id }) => ({ id }))

        const personalInterestsAdd = personalInterests
            .filter(
                id =>
                    !personalInterestsSelected
                        .map(personalInterestSelected => personalInterestSelected.taxon.id)
                        .includes(id)
            )
            .map(id => ({
                id: getTaxonIdEndpointPartial(protectedTesterPersonalInterests, id)
            }))

        const setPersonalInterestsSelectedState = data => {
            setPersonalInterestsSelected([
                ...personalInterestsSelected.filter(({ taxon: { id } }) => personalInterests.includes(id)),
                ...data
            ])
        }

        personalInterestsAction.delete(personalInterestsRemove, {
            onSuccess: () => {
                personalInterestsAction.create(personalInterestsAdd, {
                    onSuccess: setPersonalInterestsSelectedState
                })
            }
        })
    }

    if (!title) {
        return null
    }

    return (
        <TesterFormStyled className={className} {...rest}>
            <div className="globalProfileTesterForm">
                <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues}>
                    {({ values, handleChange, handleBlur }) => (
                        <Form noValidate className="globalProfileTesterForm_form">
                            <ThemeProvider theme={theme}>
                                <TextField
                                    name="jobStatus"
                                    label="Status"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.jobStatus}
                                    className="textField_half"
                                    variant="standard"
                                    select>
                                    <MenuItem value="" disabled>
                                        Odaberi
                                    </MenuItem>
                                    {jobStatusList?.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </ThemeProvider>
                            {title && <div className="globalProfileTesterForm_title" {...titleHtmlProps} />}
                            <div className="globalProfileTesterForm_interests">
                                <FormGroup>
                                    {personalInterestsList?.map(item => (
                                        <Checkbox
                                            key={item.id}
                                            checked={values.personalInterests.includes(item.id)}
                                            onChange={handleChange}
                                            label={item.title}
                                            value={item.id}
                                            name="personalInterests"
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                            <Button
                                // Note(@mario.basic, testerica): This works but it could be better.
                                // Logic for creating after deleting taxons should be inside the mutation.
                                disabled={jobStatusMutationIsLoading || personalInterestsMutationIsLoading}
                                type="submit"
                                title="Ažuriraj"
                                variant={buttonVariants.ALPHA}>
                                Ažuriraj podatke
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </TesterFormStyled>
    )
}

TesterForm.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
}

TesterForm.defaultProps = {
    className: undefined,
    title: undefined
}

export default withErrorBoundary(TesterForm, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileTesterForm]: ', error, componentStack)
    }
})
