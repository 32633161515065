import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const { navigation: nav, header } = theme.global
    const barHeight = nav.panel?.header?.height ? nav.panel.header.height : header.height

    const config = {
        barHeight: {
            default: toRem(barHeight.default),
            xs: toRem(barHeight.xs)
        },
        button: {
            background: {
                default: theme.colors.menu.search.button.background.default,
                hover: theme.colors.menu.search.button.background.hover
            },
            color: {
                default: theme.colors.menu.search.button.icon.default,
                hover: theme.colors.menu.search.button.icon.hover
            }
        }
    }
    return {
        display: 'flex',
        height: config.barHeight.default,
        [theme.breakpoints.only('xs')]: {
            height: config.barHeight.xs
        },
        '& .searchInput': {
            height: '100%',
            width: '100%',
            border: 'none',
            backgroundColor: theme.colors.menu.search.input.background,
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '120%',
            fontSize: toRem(18),
            paddingLeft: toRem(19),
            color: theme.colors.menu.search.input.text.default,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(24),
                paddingLeft: toRem(40)
            },
            '&_wrapper': {
                display: 'block',
                flex: '1 1 auto',
                height: '100%',
                span: {
                    display: 'none'
                }
            },

            '&:focus': {
                color: theme.colors.menu.search.input.text.focus,
                border: 'none'
            },
            '&::placeholder': {
                color: theme.colors.menu.search.input.text.default
            }
        },
        '& .searchButton': {
            flex: '0 0 auto',
            width: config.barHeight.xs,
            height: config.barHeight.xs,
            fontSize: toRem(18),
            background: config.button.background.default,
            color: config.button.color.default,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'background, color',
            '&:hover': {
                background: config.button.background.hover,
                color: config.button.color.hover
            },
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(24),
                width: config.barHeight.default,
                height: config.barHeight.default
            },
            svg: {
                path: {
                    fill: 'currentColor'
                }
            }
        }
    }
}

export default style
