import { useCallback } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useNavigationStore } from '@hmn/rtl-web-core/context/navigation'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import { CloseIcon, HamburgerIcon, Icon } from '../../../Icon'
import styles from './NavigationButton.style'

const NavigationButtonStyled = styled.div(props => ({ ...styles(props) }))

function NavigationButton({ variant, fixed, ...rest }) {
    const [{ isOpen: isNavigationOpen }, setNavigationStore] = useNavigationStore()

    const { sendDataToGTM } = useGTM()
    const handleNavAction = useCallback(() => {
        setNavigationStore({ isOpen: !isNavigationOpen, searchIsOpen: false })
        sendDataToGTM(
            {
                eventCategory: 'Navigation',
                eventLabel: 'Navigation Icon'
            },
            true
        )
    }, [isNavigationOpen, sendDataToGTM, setNavigationStore])

    return (
        <NavigationButtonStyled variant={variant} fixed={fixed} isNavigationOpen={isNavigationOpen} {...rest}>
            <button type="button" onClick={handleNavAction} aria-label="Izbornik">
                <Icon icon={isNavigationOpen ? CloseIcon : HamburgerIcon} />
            </button>
        </NavigationButtonStyled>
    )
}

const navigationButtonVariants = Object.freeze({
    ALPHA: 'alpha', // Panel button
    BETA: 'beta' // Header button
})

NavigationButton.propTypes = {
    variant: PropTypes.oneOf([...Object.values(navigationButtonVariants)]),
    fixed: PropTypes.bool
}

NavigationButton.defaultProps = {
    variant: navigationButtonVariants.ALPHA,
    fixed: false
}

export { navigationButtonVariants }
export default withErrorBoundary(NavigationButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NavigationButton]: ', error, componentStack)
    }
})
