// @TODO: Move everything theme related configs from globals folder
import { breakpoints as globalBreakpoints, createMediaQueries } from '../../helpers/theme'
import {
    normalize,
    transitions as globalTransitions,
    typography as globalTypography,
    utilities,
    zIndex as globalZIndex
} from '../globals'
import { colorsProfile, paletteProfile } from '../globals/colors'
import { layoutTheme } from './profileLayout'

// Define theme overrides here
export const profileDefault = {
    normalize,
    breakpoints: {
        ...createMediaQueries(globalBreakpoints)
    },
    colors: {
        ...paletteProfile,
        ...colorsProfile
    },
    global: {},
    zIndex: {
        ...globalZIndex
    },
    typography: {
        ...globalTypography,
        fontFamily: {
            default: 'Roboto',
            alternative: 'Roboto Condensed'
        }
    },
    transitions: {
        ...globalTransitions
    },
    layoutTheme,
    utilities
}
