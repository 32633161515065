import { createTheme } from '@mui/material'

import { profileDefault } from './profileDefault'
import { rtl } from './rtl'
import { zenaDark } from './zenaDark'
import { zenaDefault } from './zenaDefault'

export const themes = {
    rtl: createTheme(rtl),
    zenaDefault: createTheme(zenaDefault),
    zenaDark: createTheme(zenaDark),
    profileDefault: createTheme(profileDefault)
}
