import { Ad, ClosePosition, ConvergerPostion, SourceType, YesOrNo } from '../EternalAds.interface'

export type ConvergerData = {
    ad: Ad
    clickTagName?: string
    clickUrl?: string
    clickUrlUnesc: string
    closePosition: ClosePosition
    fileImage: string
    fileMobileImage: string
    imageBackgroundColor: string
    hasGlobalClickTag: YesOrNo
    height: string
    htmlContent?: string
    offsetX: string
    offsetY: string
    openInNewTab: YesOrNo
    position: ConvergerPostion
    sourceType: SourceType
    thirdPartyImpressionUrl: string
    thirdPartyTagsHtml?: string
    viewUrlUnesc: string
    width: string
}

export enum PositionX {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center'
}
export enum PositionY {
    TOP = 'top',
    BOTTOM = 'bottom',
    CENTER = 'center'
}

export const invertPosition = <T extends PositionX | PositionY>(position: T): T => {
    switch (position) {
        case PositionX.LEFT:
            return PositionX.RIGHT as T
        case PositionX.RIGHT:
            return PositionX.LEFT as T
        case PositionY.TOP:
            return PositionY.BOTTOM as T
        case PositionY.BOTTOM:
            return PositionY.TOP as T
        default:
            return position
    }
}

export const positionMap: { [key in ConvergerPostion]: [PositionX, PositionY] } = {}

export type ParsedConvergerDimensions = {
    height: number | undefined
    width: number | undefined
}
