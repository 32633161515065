/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Hamburger = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 32,4.4134324 H 0 V 3.31 H 32 Z M 32,16.551241 H 0 V 15.447808 H 32 Z M 18.758621,28.689 H 0 v -1.103432 h 18.758621 z"
        fill="#141313"
    />,
    'Hamburger'
)

export default Hamburger
