import { SlotDivId } from '../../types/ads/Ad.interface'
import createFastContext from '../createFastContext'

type RefreshSlotsStore = SlotDivId[]
// const uniqueDivIdsFilter = (divId: SlotDivId, index: number, array: SlotDivId[]) => array.indexOf(divId) === index

export const RefreshSlotsContext = createFastContext<RefreshSlotsStore>([])

const refreshSlotsProcessor = (divId: SlotDivId | SlotDivId[]) => (Array.isArray(divId) ? divId : [divId])
export const useRefreshSlots = () => RefreshSlotsContext.useStore(undefined, refreshSlotsProcessor)
