import { memo, useCallback, useRef } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'

const getMidasScriptSource = (id, siteName) => {
    switch (siteName) {
        case 'rtl.hr':
        case 'danas.hr': {
            return id === 'midasWidget__10112'
                ? 'https://cdn2.midas-network.com/Scripts/midasWidget-25-1398-10112.js'
                : 'https://cdn2.midas-network.com/Scripts/midasWidget-1-452-10002-10003.js'
        }
        case 'zena.hr':
            return 'https://cdn2.midas-network.com/Scripts/midasWidget-24-108-10447.js'
        case 'net.hr':
            return 'https://cdn.midas-network.com/Widget/IndexAsync/2?portalWidgetId=162'
        default:
            return null
    }
}
const getMidasScriptId = (id, siteName) => {
    switch (siteName) {
        case 'rtl.hr':
        case 'danas.hr': {
            if (id === 'midasWidget__10112') {
                return 'midas-script-alt'
            }
            return 'midas-script'
        }
        default:
            return 'midas-script'
    }
}
function PromotionItemController({ id, siteName }) {
    const containerRef = useRef()

    const onReadyHandler = useCallback(() => {
        containerRef.current?.querySelectorAll('a').forEach(externalLink => {
            const relChunks = (externalLink.getAttribute('rel') || '').split(' ')
            if (!relChunks.includes('noopener')) {
                const rel = [...relChunks, 'noopener'].join(' ')
                externalLink.setAttribute('rel', rel)
            }
        })
    }, [])
    const scriptSrc = getMidasScriptSource(id, siteName)
    if (!scriptSrc) {
        return null
    }
    return (
        <>
            <div id={id} ref={containerRef} style={{ height: '100%', width: '100%' }} />
            {siteName === 'zena.hr' ? (
                <Script async src={scriptSrc} />
            ) : (
                <Script id={getMidasScriptId(id, siteName)} src={scriptSrc} onReady={onReadyHandler} />
            )}
        </>
    )
}

PromotionItemController.propTypes = {
    id: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired
}

export default withErrorBoundary(memo(PromotionItemController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[PromotionItemController]: ', error, componentStack)
    }
})
