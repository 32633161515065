// import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useWindowScroll } from '@hmn/rtl-web-core/hooks'

import styles, { motionVariants } from './Panel.style'

const PanelStyled = styled.div(props => ({ ...styles(props) }))

function Panel({ children, ...rest }) {
    const { direction, position } = useWindowScroll()
    const panelState = position > 100 && direction === 'down' ? 'closed' : 'open'
    return (
        <PanelStyled {...rest}>
            <motion.div className="headerPanel" initial="open" animate={panelState} variants={motionVariants()}>
                <div className="headerPanel_inner">{children}</div>
            </motion.div>
        </PanelStyled>
    )
}

export default withErrorBoundary(Panel, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[HeaderPanel]: ', error, componentStack)
    }
})
