import { SettingsActionTypes as ActionTypes } from '../actions/settings'

const initialState = {
    isLoaded: false,
    imagesConfig: {
        variations: []
    }
}

const settingsReducer = (state = initialState, { newStore, type, variations, isLoaded } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.SET_IMAGES_VARIATIONS: {
            return {
                ...state,
                imagesConfig: {
                    variations
                }
            }
        }

        case ActionTypes.SET_LOADED: {
            return {
                ...state,
                isLoaded
            }
        }

        default:
            return state
    }
}

export { settingsReducer }
