/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react'

const defaultAllowedOrigins = process.env.NEXT_PUBLIC_CMS_ORIGINS.split(',')

const isValidUrl = urlString => {
    // eslint-disable-next-line max-len
    const regexp =
        // eslint-disable-next-line max-len
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(urlString)
}

/**
 * Hook used for cross-domain communication, mostly used for CMS resource preview.
 *  * `initialData`, `type` & `source` are required parameters.
 *
 * Usage example while visiting single article, e.g. '/articles/88fba02e-f813-11ea-bd16-ee6fe3166418?messageType=preview&messageSource=@CMS':
 *
 *      import { useCrossDomainCommunication as usePreview, useOne } from '../hooks'
 *
 *      const { data: articleWithPreview } = usePreview(article, { type: messageType, source: messageSource })
 *
 * Then simply use articleWithPreview throughout the rest of your page, so whenver CMS preview is connected, it will show the preview data.
 *
 * @param {*} [initialData={}]
 * @param {*} [params={}]
 * @return {*}
 */
const useCrossDomainCommunication = (
    initialData = {},
    { type = 'preview', source = '@CMS', allowedOrigin = '' } = {}
) => {
    const [data, setData] = useState(initialData)

    const allowedOrigins = useMemo(() => [...new Set([allowedOrigin, ...defaultAllowedOrigins])], [allowedOrigin])

    useEffect(() => {
        if (initialData && Object.keys(initialData)?.length) {
            // Set initialData to be returned if everything else fails
            setData(initialData)
        }

        if (source === undefined) {
            console.error('[useCrossDomainCommunication]: Message source is required, please provide message source.')
            return () => {}
        }

        if (type === undefined) {
            console.error('[useCrossDomainCommunication]: Message type is required, please provide message type.')
            return () => {}
        }

        if (allowedOrigin && typeof allowedOrigin !== 'string' && !isValidUrl(allowedOrigin)) {
            console.error(
                // eslint-disable-next-line max-len
                `[useCrossDomainCommunication]: Provided origin '${allowedOrigin}' is not valid. Please provide valid origin.`
            )
        }

        const handleMessage = (event = {}) => {
            // console.log('[Preview Debugger]:', event)
            const { origin: eventOrigin } = event
            const { data: eventData, source: eventSource, type: eventType } = event?.data || {}

            // Validate that message is coming from the right source and is of right type
            if (allowedOrigins.includes(eventOrigin) && eventSource === source && eventType === type) {
                setData(eventData)
            }
        }

        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [type, source, initialData, isValidUrl])

    return {
        data
    }
}

export default useCrossDomainCommunication
