/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const RtlLogo = createSvgIcon(
    <>
        <path
            d="M21.017 7.639c.902 0 1.676.733 1.676 1.589 0 .916-.71 1.589-1.676 1.589H16.31V7.639h4.706z"
            fill="#FFFFFF"
        />
        <path
            d="M38.681 0v22H0V0h38.681zM7.285 6.111V16.44h9.026V12.71h3.03l4.512 3.728h8.188l-5.286-3.728h.967c1.934 0 3.48-1.467 3.48-3.3 0-1.833-1.546-3.3-3.48-3.3H7.285zM39.682 22h39.326V0H39.682v22zM47.74 6.111h23.983v1.956h-7.027v8.739h-9.929v-8.74H47.74V6.112zM80.008 0v22h39.326V0H80.008zm30.3 16.806H86.97V6.11h10.316v8.739h12.958v1.956h.064z"
            fill="#FFFFFF"
        />
    </>,
    'RtlLogo'
)

export default RtlLogo
