// @TODO: Cleanup handle events (or extend, or add more) as we progress through development

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { CheckMarkSmallIcon, Icon } from '../../../Icon'
import { Error } from '../Error'
import { Help } from '../Help'
import { Label, labelVariants } from '../Label'
import styles from './Checkbox.style'

const CheckboxStyled = styled.div(props => ({ ...styles(props) }))

function Checkbox({
    name,
    id,
    value,
    label,
    labelComponent,
    checked,
    onBlur,
    onChange,
    onFocus,
    onClick,
    variant,
    size,
    required,
    disabled,
    error,
    errorMessage,
    helpText,
    className,
    iconComponent: CheckboxIcon,
    ...rest
}) {
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (disabled && focused) {
            setFocused(false)
            if (onBlur) {
                onBlur()
            }
        }
    }, [disabled, focused, onBlur])

    // onFocus
    const handleFocus = event => {
        if (disabled) {
            event.stopPropagation()
            return
        }
        if (onFocus) {
            onFocus(event)
        }
        setFocused(true)
    }

    // onBlur
    const handleBlur = event => {
        if (onBlur) {
            onBlur(event)
        }
        setFocused(false)
    }

    // onChange
    const handleChange = (event, ...args) => {
        if (onChange) {
            onChange(event, ...args)
        }
    }

    return (
        <CheckboxStyled
            checked={checked}
            disabled={disabled}
            error={error}
            size={size}
            variant={variant}
            className={className}
            {...rest}>
            <div className="checkboxWrap" onClick={onClick} role="presentation">
                <div className="checkboxCheck">
                    <input
                        type="checkbox"
                        name={name}
                        aria-invalid={!!error}
                        id={id}
                        value={value}
                        checked={checked}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required={required}
                        disabled={disabled}
                    />
                    <span className="checkboxCheck_icon">
                        {CheckboxIcon ? <CheckboxIcon /> : <Icon icon={CheckMarkSmallIcon} viewBox="0 0 28 28" />}
                    </span>
                </div>
                <Label
                    label={label}
                    labelComponent={labelComponent}
                    id={id}
                    error={error}
                    required={required}
                    focused={focused}
                    variant={labelVariants.SECONDARY}
                    className="checkboxLabel"
                />
            </div>
            <Error error={error} errorMessage={errorMessage} className="checkboxError" />
            <Help text={helpText} className="checkboxHelp" />
        </CheckboxStyled>
    )
}

const checkboxVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary'
})

const checkboxSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

Checkbox.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.string,
    labelComponent: PropTypes.node,
    checked: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([...Object.values(checkboxVariants)]),
    size: PropTypes.oneOf([...Object.values(checkboxSizes)]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    helpText: PropTypes.string,
    className: PropTypes.string,
    iconComponent: PropTypes.elementType
}

Checkbox.defaultProps = {
    name: undefined,
    id: undefined,
    value: undefined,
    label: undefined,
    labelComponent: undefined,
    checked: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onClick: () => {},
    variant: checkboxVariants.PRIMARY,
    size: checkboxSizes.NORMAL,
    required: false,
    disabled: false,
    error: false,
    errorMessage: undefined,
    helpText: undefined,
    className: undefined,
    iconComponent: undefined
}

export { checkboxVariants, checkboxSizes }

export default withErrorBoundary(Checkbox, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Checkbox]: ', error, componentStack)
    }
})
