/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react'

import { useNativeAdsState } from '../../../../selectors/ads'
import Icon from '../../../Icon/Icon.component'
import CloseCircle from '../../../Icon/icons/CloseCircle'
import { InjectHtml } from '../components/InjectHtml'
import { Invisible } from '../components/Invisible'
import { TrackingPixel } from '../components/TrackingPixel'
import { ParsedConvergerData /* , useConvergerMockData */ } from './Converger.helpers'
import { CLEAR_CONVERGER_CALLBACK_KEY, INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME } from './Converger.provider'
import ConvergerStyled, { StyledCloseButton } from './Converger.style'
import ConvergerCreative from './ConvergerCreative.component'

const Converger = () => {
    const convergerData = useNativeAdsState('converger') as ParsedConvergerData
    // const convergerMockData = useConvergerMockData()
    const { ad, creative, dimensions, clickOptions } = convergerData || {}
    const numOfTiles = 20

    useEffect(() => {
        // Function to update the size state
        const updateSize = () => {
            const r: HTMLElement | null = document.querySelector(':root')
            const height = window.document.getElementById('converger_creative')?.getBoundingClientRect().height
            const scale = height ? height / (dimensions?.height ?? 0) : 1

            r?.style.setProperty(
                '--conv-size',
                `${scale * (dimensions?.width ?? 0)}px ${scale * (dimensions?.height ?? 0)}px`
            )
            r?.style.setProperty('--conv-offset', '0%')
        }

        // INFO: dynamic CSS variables
        // This is a hack to make the converger creative responsive
        updateSize()

        // window.addEventListener('resize', updateSize)
        // return () => window.removeEventListener('resize', updateSize)
    }, [])

    const onCloseCallback = useCallback(() => {
        if (typeof window === 'undefined') return
        window?.[CLEAR_CONVERGER_CALLBACK_KEY]?.()
    }, [])

    if (!ad) return null

    return (
        <>
            <ConvergerStyled theme={undefined} creative={creative} dimensions={dimensions} numOfTiles={numOfTiles}>
                <ConvergerCreative ad={ad} clickOptions={clickOptions} numOfTiles={numOfTiles} />

                <StyledCloseButton onClick={onCloseCallback}>
                    {/* @ts-expect-error: js component prop types are not being detected properly */}
                    <Icon icon={CloseCircle} size={35 * 1.3} />
                </StyledCloseButton>
            </ConvergerStyled>

            <Invisible>
                {/* Impression tracking via pixels */}
                <TrackingPixel src={clickOptions?.viewUrlUnesc} />
                <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} />
                {/* Impression tracking via html*/}
                {clickOptions?.thirdPartyTagsHtml && (
                    <InjectHtml
                        windowKey={INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME}
                        html={clickOptions?.thirdPartyTagsHtml}
                    />
                )}
            </Invisible>
        </>
    )
}

export default Converger
