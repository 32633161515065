import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    height: '100%',
    display: 'grid',
    '& .userSettings_basic': {
        marginBottom: toRem(30),
        paddingBottom: toRem(30),
        display: 'flex',
        flexWrap: 'wrap',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: toRem(40),
        alignItems: 'center',
        borderBottom: `2px solid ${theme.colors.profile.border.default}`
    },
    '& .userSettings_emailLabel': {
        fontSize: toRem(13),
        color: theme.colors.profile.text.alternative
    },
    '& .userSettings_email': {
        fontSize: toRem(16),
        lineHeight: 1
    },
    '& .userSettings_changePass': {
        margin: toRems([10, 0])
    },
    '& .userSettings_form': {
        display: 'grid',
        alignSelf: 'flex-start',
        gridColumnGap: toRem(20),
        [theme.breakpoints.up('md')]: {
            gridColumnGap: toRem(40)
        }
    },
    '& .textField_half': {
        gridColumn: 'span 6'
    },
    '& .textField_full': {
        gridColumn: 'span 12'
    },
    // Restyling material-ui
    // // @TODO: Remove when we get rid of material-ui
    '& .MuiFormLabel-root': {
        padding: toRems([0, 10, 0, 10])
    },
    '& .MuiInputBase-root': {
        padding: toRems([0, 10, 8, 10]),
        borderBottom: `2px solid ${theme.colors.profile.form.input.border.default}`,
        color: theme.colors.profile.form.input.text.default,
        '&::before': {
            content: 'none'
        }
    },
    '& .MuiFormControl-root': {
        marginBottom: toRem(30)
    },
    '& .MuiInputLabel-formControl': {
        color: theme.colors.profile.form.input.label.default
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.colors.profile.form.input.text.focus
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
    },
    '& input:-internal-autofill-selected:': {
        backgroundColor: 'transparent'
    },
    '& .MuiSelect-select': {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: toRem(15),
            display: 'block',
            height: toRem(25),
            width: toRem(25),
            background: 'url(/images/profile/select-arrow.svg)'
        }
    },
    '& .MuiSelect-icon': {
        display: 'none'
    },
    '& .MuiPaper-elevation8': {
        borderRadius: 0,
        boxShadow: 'initial !important',
        border: `1px solid ${theme.colors.profile.form.input.border.default} !important`
    },
    // Enf of restyling material-ui
    '& .userSettings_buttons': {
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(30)
        }
    },
    '& .userSettings_requiredNotice': {
        margin: toRems([10, 0]),
        padding: toRem(10),
        gridColumn: 'span 12',
        fontSize: toRem(14),
        background: '#FFB600'
    },
    '& .userSettings_footer': {
        marginTop: toRem(10),
        alignSelf: 'end',
        fontSize: toRem(15),
        color: theme.colors.profile.text.alternative
    },
    '& .userSettings_footerButton': {
        // @TODO: Implement small button variant insted of doing it here!
        fontSize: toRem(15),
        [theme.breakpoints.down('sm')]: {
            marginTop: toRem(40),
            textAlign: 'center'
        }
    }
})

export default style
