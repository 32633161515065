export default {
    // baseLayout: {},
    // borderRadius: toRem(15),
    // Icon wrap
    '.svgIcon': {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        fontSize: 'inherit',
        lineHeight: 1
    },
    '.adsbox': {
        width: '0px',
        height: '0px',
        overflow: 'hidden',
        marginTop: '-10px',
        zIndex: '-1'
    },
    header: {
        height: {
            xs: 54,
            md: 100
        }
    },
    navigation: {
        panel: {
            // values typeof number are treated as px and converted to rem, string values are passed on
            maxWidth: {
                default: 800,
                md: '100vw',
                xs: '100%'
            },
            header: {
                height: {
                    md: 100,
                    xs: 54
                }
            }
        }
    },
    htmlContent: {
        title: {
            marginBottom: 50
        },
        article: {
            marginLeft: `calc(50% - 50vw)`,
            marginRight: `calc(50% - 50vw)`,
            xs: {
                marginBottom: 0
            },
            md: {
                marginBottom: 0
            }
        },
        image: {
            xs: {
                marginBottom: 20
            },
            md: {
                marginBottom: 25
            }
        },
        xs: {
            blockVerticalMargin: 20
        },
        md: {
            blockVerticalMargin: 25
        }
    }
}
