import useClipboard from 'react-use-clipboard'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { SocialItem, socialItemColorVariants } from '..'
import styles from './ShareButton.style'

const ShareButtonStyled = styled.li(props => ({ ...styles(props) }))

const getShareUrl = (share, url) => {
    switch (share) {
        case 'viber':
            return `viber://forward?text=${encodeURIComponent(url)}`
        case 'whatsapp':
            return `whatsapp://send?text=${encodeURIComponent(url)}`
        case 'facebook':
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
        case 'messenger':
            // @TODO: check if appID is needed
            return `fb-messenger://share?link=${encodeURIComponent(url)}`
        default:
            return null
    }
}

function ShareButton({ social, shareUrl, ...rest }) {
    const [isCopied, setCopied] = useClipboard(shareUrl, {
        successDuration: 1000
    })

    const [isDesktop] = useBreakpoints('md')

    // Note: Messenger works only on mobile/tablet, so hide the button on desktop.
    if (!social || !shareUrl || (isDesktop && social === 'messenger')) {
        return null
    }

    const onSocialUrlClick = () => {
        if (!isDesktop && navigator.share) {
            navigator.share({ url: shareUrl })
        } else if (!isCopied) {
            setCopied(shareUrl)
        }
    }

    const href = getShareUrl(social, shareUrl)

    return (
        <ShareButtonStyled {...rest}>
            {isDesktop && isCopied && social === 'url' && (
                <div className="shareButton_popUp_wrapper">
                    <span className="shareButton_popUp">Kopirano</span>
                </div>
            )}
            <SocialItem
                icon={social}
                href={href}
                hrefIsExternal
                onClick={social === 'url' ? onSocialUrlClick : undefined}
                color={socialItemColorVariants.META}
            />
        </ShareButtonStyled>
    )
}

ShareButton.propTypes = {
    shareUrl: PropTypes.string,
    social: PropTypes.string
}

ShareButton.defaultProps = {
    shareUrl: undefined,
    social: undefined
}

export default withErrorBoundary(ShareButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SocialShareButton]: ', error, componentStack)
    }
})
