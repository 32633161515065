// Colors must not be used directly in components, but they should be (re)assigned here instead

const paletteDefault = {
    // Primary colors
    zenaDarkPink: '#B70060',
    zenaLightPink: '#E20177',
    zenaGrey: '#DBDBDB',
    primary: '#000000',
    // Secondary colors
    secondary: '#FFFFFF',
    grey600: '#333333',
    grey500: '#797979',
    grey400: '#8D8D8D',
    grey300: '#CFCFCF',
    grey200: '#E9E9E9',
    grey100: '#F6F6F6',
    contrast: '#FFFFFF',
    white: '#FFFFFF',
    // Tertiary colors
    tertiary: '#F6F6F6',
    shade: '#E9E9E9',
    // Other colors
    pink400: '#FE4FAB',
    pink300: '#FFB5DC',
    purple: '#CD00D1',
    green: '#20AB3F',
    voyo: '#6A34FB',
    // Development
    devWarning: '#81FF0D' // dev error visual notification color - stick you in the eye
}

const paletteDark = {
    // Primary colors
    zenaDarkPink: '#B70060',
    zenaLightPink: '#E20177',
    zenaGrey: '#DBDBDB',
    primary: '#FFFFFF',
    // Secondary colors
    secondary: '#121212',
    grey600: '#333333',
    grey500: '#797979',
    grey400: '#8D8D8D',
    grey300: '#CFCFCF',
    grey200: '#E9E9E9',
    grey100: '#F6F6F6',
    contrast: '#000000',
    white: '#FFFFFF',
    black: '#000000',
    // Tertiary colors
    tertiary: '#000000',
    shade: '#222222',
    // Other colors
    pink400: '#FE4FAB',
    pink300: '#FFB5DC',
    voyo: '#6A34FB',
    // Development
    devWarning: '#81FF0D' // dev error visual notification color - stick you in the eye
}

const paletteProfile = {
    // Primary colors
    primary: '#19347C',
    zenaLightPink: '#E20177',
    // Secondary colors
    secondary: '#224195',
    grey500: '#666666',
    grey400: '#888888',
    grey300: '#A1A1A1',
    grey200: '#EBEBEB',
    grey100: '#F2F2F2',
    contrast: '#FFFFFF',
    white: '#FFFFFF',
    black: '#000000',
    // Tertiary colors
    tertiary: '#132861',
    blue100: '#D2D9EC',
    blue200: '#79829A',
    // Development
    devWarning: '#81FF0D' // dev error visual notification color - stick you in the eye
}

const getColors = palette => {
    // Configure global colors here
    const colors = {
        separator: palette.shade,
        text: {
            default: palette.primary,
            alternative: palette.grey400,
            accent: palette.zenaLightPink,
            white: palette.contrast,
            black: palette.black,
            inverted: palette.black
        },
        subtitle: {
            default: palette.grey400
        },
        card: {
            inner: {
                content: {
                    text: {
                        default: palette.grey500,
                        inverted: palette.grey400,
                        accent: palette.zenaLightPink
                    },
                    title: {
                        default: palette.primary,
                        inverted: palette.contrast,
                        hover: palette.zenaLightPink
                    },
                    background: {
                        default: palette.contrast,
                        inverted: palette.primary
                    }
                }
            }
        },
        comments: {
            add: {
                background: palette.grey100,
                tip: palette.grey400
            },
            avatar: {
                date: palette.grey500,
                name: palette.zenaLightPink
            },
            border: {
                default: palette.grey400
            },
            rate: {
                default: palette.primary,
                selected: palette.zenaLightPink
            },
            item: {
                divider: palette.grey200,
                background: palette.white,
                text: palette.primary,
                counseling: {
                    default: palette.primary,
                    expert: palette.green
                }
            },
            circle: {
                stroke: palette.zenaLightPink
            }
        },
        background: {
            inverted: palette.primary,
            body: {
                default: palette.contrast,
                inverted: palette.primary,
                secondary: palette.secondary,
                tertiary: palette.shade,
                light: palette.grey100
            }
        },
        border: {
            default: palette.primary,
            light: palette.grey300,
            lighter: palette.zenaGrey,
            darker: palette.grey600,
            accent: palette.zenaLightPink
        },
        decoration: {
            default: palette.zenaLightPink,
            alternative: palette.grey300,
            inverted: palette.white
        },
        shadow: {
            default: palette.primary
        },
        heading: {
            basic: {
                text: {
                    default: palette.primary,
                    accent: palette.zenaLightPink,
                    white: palette.contrast
                }
            },
            label: {
                text: {
                    default: palette.zenaLightPink
                },
                border: {
                    default: palette.zenaLightPink
                }
            }
        },
        link: {
            primary: {
                text: {
                    default: palette.zenaLightPink,
                    hover: palette.zenaLightPink,
                    active: palette.zenaDarkPink,
                    focus: palette.zenaLightPink
                },
                border: {
                    default: palette.pink300,
                    hover: palette.contrast,
                    active: palette.zenaDarkPink,
                    focus: palette.pink400
                }
            }
        },
        button: {
            alpha: {
                text: {
                    default: palette.primary,
                    hover: palette.contrast,
                    active: palette.contrast,
                    focus: palette.contrast,
                    disabled: palette.grey600
                },
                background: {
                    default: palette.grey100,
                    hover: palette.primary,
                    active: palette.primary,
                    focus: palette.primary,
                    disabled: palette.grey300
                },
                border: {
                    default: palette.primary,
                    hover: palette.primary,
                    active: palette.primary,
                    focus: palette.primary,
                    disabled: palette.grey300
                }
            },
            beta: {
                text: {
                    default: palette.white,
                    hover: palette.white,
                    active: palette.white,
                    focus: palette.white,
                    disabled: palette.white
                },
                background: {
                    default: palette.zenaLightPink,
                    hover: palette.primary,
                    active: palette.primary,
                    focus: palette.primary,
                    disabled: palette.grey400
                },
                border: {
                    default: palette.primary,
                    hover: palette.primary,
                    active: palette.primary,
                    focus: palette.primary,
                    disabled: palette.grey300
                }
            }
        },
        list: {
            dash: {
                default: palette.zenaLightPink
            },
            decimal: {
                default: palette.zenaLightPink
            }
        },
        modal: {
            background: {
                default: {
                    content: palette.contrast,
                    backdrop: palette.primary
                }
            }
        },
        article: {
            blockquote: {
                background: palette.contrast,
                border: palette.primary
            },
            block: {
                border: palette.primary
            },
            highlighted: {
                background: palette.grey100,
                border: palette.zenaLightPink
            }
        },
        pill: {
            text: {
                default: palette.primary,
                hover: palette.zenaLightPink
            },
            background: {
                default: palette.contrast
            },
            border: {
                default: palette.zenaGrey,
                hover: palette.zenaLightPink
            }
        },
        error: {
            dev: {
                text: palette.primary,
                background: palette.devWarning
            }
        },
        media: {
            color: palette.white,
            background: palette.zenaLightPink
        },
        footer: {
            background: palette.tertiary,
            text: {
                primary: palette.primary,
                highlight: palette.zenaLightPink,
                secondary: palette.grey500,
                subtle: palette.grey300
            },
            separator: palette.shade,
            social: {
                title: palette.zenaLightPink,
                icon: {
                    default: palette.grey300,
                    hover: palette.zenaDarkPink
                }
            }
        },
        header: {
            background: palette.contrast,
            border: palette.grey200,
            button: {
                background: {
                    default: palette.contrast,
                    hover: palette.primary
                },
                icon: {
                    default: palette.primary,
                    hover: palette.contrast
                }
            },
            login: {
                title: {
                    loggedIn: palette.zenaLightPink,
                    loggedOut: palette.grey400
                },
                icon: {
                    xs: {
                        loggedIn: palette.zenaDarkPink,
                        loggedOut: palette.primary
                    },
                    md: {
                        loggedIn: palette.zenaDarkPink,
                        loggedOut: palette.grey400
                    }
                }
            }
        },
        menu: {
            background: palette.tertiary,
            button: {
                background: {
                    default: palette.tertiary,
                    hover: palette.primary
                },
                icon: {
                    default: palette.primary,
                    hover: palette.contrast
                }
            },
            text: {
                primary: palette.primary,
                highlight: palette.zenaLightPink,
                secondary: palette.grey500,
                subtle: palette.grey300
            },
            search: {
                button: {
                    background: {
                        default: palette.zenaLightPink,
                        hover: palette.primary
                    },
                    icon: {
                        default: palette.white,
                        hover: palette.white
                    }
                },
                input: {
                    background: palette.contrast,
                    text: {
                        default: palette.shade,
                        focus: palette.primary
                    }
                }
            }
        },
        survey: {
            backgroundColor: palette.grey100,
            input: {
                borderColor: palette.zenaLightPink
            },
            check: {
                borderColor: palette.grey300,
                backgroundColor: palette.white,
                color: palette.zenaLightPink
            }
        },
        poll: {
            background: palette.grey100,
            item: {
                background: palette.white,
                check: {
                    background: {
                        default: palette.grey200,
                        alternative: palette.grey300,
                        selected: palette.zenaLightPink,
                        image: palette.white
                    }
                },
                response: {
                    default: palette.zenaLightPink,
                    alternative: palette.purple
                }
            }
        },
        product: {
            card: {
                content: {
                    text: palette.grey500,
                    title: {
                        default: palette.primary,
                        hover: palette.zenaLightPink
                    },
                    background: palette.contrast
                },
                badge: {
                    color: palette.zenaLightPink,
                    background: palette.contrast
                }
            },
            scoreSummary: {
                primary: palette.primary,
                secondary: palette.grey400,
                button: {
                    default: {
                        background: palette.zenaLightPink,
                        text: palette.secondary
                    },
                    hover: {
                        background: palette.zenaDarkPink,
                        text: palette.secondary
                    }
                }
            },
            reviewSummary: {
                background: palette.grey100,
                text: {
                    default: palette.primary,
                    highlight: palette.zenaLightPink
                },
                icon: {
                    default: palette.primary,
                    highlight: palette.zenaLightPink
                }
            },
            prevNext: {
                button: {
                    background: palette.contrast,
                    color: palette.primary
                }
            }
        },
        meta: {
            button: {
                default: {
                    title: palette.primary,
                    icon: palette.primary,
                    value: palette.contrast
                },
                green: {
                    title: palette.green,
                    icon: palette.green,
                    value: palette.contrast
                }
            },
            social: {
                button: {
                    default: palette.primary,
                    hover: palette.zenaDarkPink
                },
                popUp: {
                    text: palette.primary,
                    background: palette.shade
                }
            }
        },
        prevNext: {
            panel: {
                background: palette.contrast
            },
            button: {
                background: palette.contrast,
                color: palette.primary
            }
        },
        profile: {
            text: {
                default: palette.primary,
                contrast: palette.contrast,
                alternative: palette.grey400,
                secondary: palette.black
            },
            background: {
                default: palette.primary,
                secondary: palette.secondary,
                tertiary: palette.tertiary,
                light: palette.grey200,
                lighter: palette.grey100
            },
            border: {
                default: palette.grey200
            },
            avatar: {
                background: {
                    main: palette.white,
                    image: palette.white
                }
            },
            navigation: {
                text: {
                    default: palette.grey500,
                    light: palette.grey300,
                    contrast: palette.contrast,
                    hover: palette.primary,
                    active: palette.primary
                }
            },
            button: {
                alpha: {
                    text: {
                        default: palette.contrast,
                        hover: palette.contrast,
                        active: palette.contrast,
                        focus: palette.contrast
                    },
                    background: {
                        default: palette.primary,
                        hover: palette.secondary,
                        active: palette.primary,
                        focus: palette.primary
                    },
                    border: {
                        default: palette.primary,
                        hover: palette.secondary,
                        active: palette.primary,
                        focus: palette.primary
                    }
                },
                beta: {
                    text: {
                        default: palette.primary,
                        hover: palette.contrast,
                        active: palette.contrast,
                        focus: palette.contrast
                    },
                    background: {
                        default: palette.contrast,
                        hover: palette.primary,
                        active: palette.primary,
                        focus: palette.primary
                    },
                    border: {
                        default: palette.primary,
                        hover: palette.primary,
                        active: palette.primary,
                        focus: palette.primary
                    }
                },
                simple: {
                    text: {
                        default: palette.grey400,
                        hover: palette.grey400,
                        active: palette.grey400,
                        focus: palette.grey400
                    }
                }
            },
            tab: {
                head: {
                    background: {
                        default: palette.white
                    },
                    text: {
                        active: palette.primary,
                        inactive: palette.grey400,
                        hover: palette.primary
                    },
                    indicator: {
                        active: palette.primary,
                        inactive: palette.grey200
                    }
                }
            },
            form: {
                input: {
                    label: {
                        default: palette.blue200
                    },
                    text: {
                        default: palette.primary,
                        placeholder: palette.grey500,
                        disabled: palette.grey500,
                        focus: palette.grey500
                    },
                    background: {
                        default: palette.grey300,
                        focus: palette.white
                    },
                    border: {
                        default: palette.primary,
                        hover: palette.grey300,
                        focus: palette.grey300
                    }
                },
                switch: {
                    slider: {
                        default: palette.white,
                        hover: palette.white,
                        checked: palette.white
                    },
                    background: {
                        default: palette.blue100,
                        hover: palette.blue100,
                        checked: palette.primary
                    },
                    border: {
                        default: palette.grey300,
                        hover: palette.grey400,
                        checked: palette.grey300
                    }
                },
                checkbox: {
                    icon: {
                        default: palette.white,
                        hover: palette.white,
                        checked: palette.primary
                    },
                    background: {
                        default: palette.white,
                        hover: palette.white,
                        checked: palette.white
                    },
                    border: {
                        default: palette.primary,
                        hover: palette.primary,
                        checked: palette.primary
                    }
                },
                // @TODO: input error colors
                error: {
                    text: palette.primary,
                    border: palette.devWarning,
                    background: palette.devWarning
                }
            },
            error: {
                dev: {
                    text: palette.white,
                    background: palette.devWarning
                }
            },
            testerica: {
                badge: {
                    color: palette.zenaLightPink,
                    background: palette.white
                }
            }
        },
        calendar: {
            text: {
                label: palette.grey400,
                day: palette.grey300
            }
        },
        columnist: {
            avatar: {
                background: palette.contrast,
                color: palette.zenaLightPink
            },
            card: {
                title: palette.primary,
                label: palette.grey500,
                subtitle: palette.grey400,
                description: palette.primary,
                border: palette.zenaLightPink
            }
        },
        placeholder: {
            title: palette.primary,
            background: palette.contrast
        }
    }
    return colors
}

const colorsDefault = getColors(paletteDefault)
const colorsDark = getColors(paletteDark)
const colorsProfile = getColors(paletteProfile)

export { paletteDefault, colorsDefault, paletteDark, colorsDark, paletteProfile, colorsProfile }

export default {
    ...paletteDefault,
    ...colorsDefault
}
