import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { zoneNameMatchesDeviceType } from '../../helpers/ads/zoneNameMatchesDeviceType'
import { AdPositionDevice } from '../../types/ads/Ad.interface'
import useUpdatedRef from '../functional/useUpdatedRef'
import useDeviceType from '../layout/useDeviceType'

const MAX_RETRY = 4

const adsWindowKeyName = '__rtl_dfp'

const runTimeoutWithRetry = ({
    callback,
    timeout,
    onSuccessCallback,
    onMaxRetryCallback,
    retry = 0
}: {
    callback: (retry: number) => boolean
    timeout: number
    onSuccessCallback: () => void
    onMaxRetryCallback: () => void
    retry?: number
}) => {
    if (retry >= MAX_RETRY) {
        onMaxRetryCallback?.()
        return
    }
    const finished = callback(retry)
    if (finished) {
        onSuccessCallback?.()
        return
    }
    setTimeout(() => {
        runTimeoutWithRetry({ callback, timeout, onSuccessCallback, onMaxRetryCallback, retry: retry + 1 })
    }, timeout)
}

export const checkDfpDivExists = (divId: string) => !!window[adsWindowKeyName]?.[divId]
const useExistingSlots = (zones: AdPositionDevice, canStartCheck = false) => {
    const [enabled, setEnabled] = useState(false)
    const [deviceType] = useDeviceType()
    const divIdsToCheck = useMemo(
        () =>
            Object.entries(zones)
                .filter(([zoneName]) => zoneNameMatchesDeviceType(zoneName, deviceType))
                .map(([, zone]) => zone.divId),
        [zones, deviceType]
    )
    const divIdsToCheckRef = useUpdatedRef(divIdsToCheck)
    const checkDivIds = useCallback(() => {
        if (typeof window === 'undefined') return true
        const divIds = divIdsToCheckRef.current || []

        const missingDivIds = divIds.filter(id => !checkDfpDivExists(id))
        if (missingDivIds.length === 0) {
            return true
        }
        // console.warn('ads', { missingDivIds, divIdsToCheck })
        divIdsToCheckRef.current = [...missingDivIds]
        return false
    }, [])

    const checkRanRef = useRef(false)
    useEffect(() => {
        if (!canStartCheck) return
        if (checkRanRef.current) return
        checkRanRef.current = true
        // console.log('ads: checking for slots')
        runTimeoutWithRetry({
            callback: checkDivIds,
            timeout: 80,
            onSuccessCallback: () => {
                setEnabled(true)
            },
            onMaxRetryCallback: () => {
                setEnabled(true)
            }
        })
    }, [canStartCheck, checkDivIds])

    const resetState = useCallback(() => {
        setEnabled(false)
        checkRanRef.current = false
    }, [])

    const router = useRouter()

    useEffect(() => {
        router.events.on('routeChangeStart', resetState)
        return () => {
            router.events.off('routeChangeStart', resetState)
        }
    }, [])

    return enabled
}

export const SlotRenderAnchor = ({ divId }) => {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return () => {}
        }

        if (!window[adsWindowKeyName]) {
            window[adsWindowKeyName] = {}
        }
        if (!divId) {
            return () => {}
        }

        window[adsWindowKeyName][divId] = true

        return () => {
            window[adsWindowKeyName][divId] = false
        }
    }, [divId])
    return null
}

export default useExistingSlots
