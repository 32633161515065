/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ArrowDown = createSvgIcon(
    <path d="M17 19.171l3.293-3.293 1.414 1.415L16 23l-5.707-5.707 1.414-1.415L15 19.171V9h2v10.171z" />,
    'ArrowDown'
)

export default ArrowDown
