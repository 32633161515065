// @NOTE: Graphic sizes are in px so they don't brake with rescaling
/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, widthXs, heightXs, widthMd, heightMd, fillVariant }) => {
    const devWarnBg = theme.colors.error.dev.background

    const getSVGFill = color => (color ? color.replace('#', '') : devWarnBg.replace('#', ''))

    const fillColor = {
        variant: {
            default: {
                fill: theme.colors.decoration.default
            },
            alternative: {
                fill: theme.colors.decoration.alternative
            },
            inverted: {
                fill: theme.colors.decoration.inverted
            }
        }
    }

    const fill = fillColor?.variant[fillVariant]?.fill

    const config = {
        variant: {
            alpha: {
                background: `url("data:image/svg+xml,%3csvg width='164' height='100' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M163.512 0.353591L63.5117 100.354L62.8046 99.6465L162.805 -0.353516L163.512 0.353591ZM54.4893 100.354L154.489 0.353591L153.782 -0.353516L53.7822 99.6465L54.4893 100.354ZM145.466 0.353591L45.4663 100.354L44.7592 99.6465L144.759 -0.353516L145.466 0.353591ZM36.4438 100.354L136.444 0.353591L135.737 -0.353516L35.7367 99.6465L36.4438 100.354ZM127.421 0.353591L27.4214 100.354L26.7143 99.6465L126.714 -0.353516L127.421 0.353591ZM18.3989 100.354L118.399 0.353591L117.692 -0.353516L17.6918 99.6465L18.3989 100.354ZM109.376 0.353591L9.37646 100.354L8.66936 99.6465L108.669 -0.353516L109.376 0.353591ZM100.354 0.353591L0.354004 100.354L-0.353104 99.6465L99.6469 -0.353516L100.354 0.353591Z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e") no-repeat center / contain`,
                widthXs: widthXs || 108,
                heightXs: heightXs || 66,
                widthMd: widthMd || 164,
                heightMd: heightMd || 100
            },
            beta: {
                background: `url("data:image/svg+xml,%3csvg width='164' height='100' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M-.35321.353591L99.6468 100.354l.7072-.7075L.353896-.353516-.35321.353591zM108.669 100.354L8.66925.353591l.70711-.707107L109.376 99.6465l-.707.7075zM17.6922.353591L117.692 100.354l.707-.7075L18.3993-.353516l-.7071.707107zM126.715 100.354L26.7147.353591l.7071-.707107L127.422 99.6465l-.707.7075zM35.7371.353591L135.737 100.354l.707-.7075L36.4442-.353516l-.7071.707107zM144.76 100.354L44.7596.353591l.7071-.707107L145.467 99.6465l-.707.7075zM53.782.353591l100 100.000409.707-.7075L54.4892-.353516l-.7072.707107zm9.0225 0L162.805 100.354l.707-.7075L63.5116-.353516l-.7071.707107z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e") no-repeat center / contain`,
                widthXs: widthXs || 108,
                heightXs: heightXs || 66,
                widthMd: widthMd || 164,
                heightMd: heightMd || 100
            },
            gamma: {
                background: `url("data:image/svg+xml,%3csvg width='20' height='4' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 1.001v-1A13.967 13.967 0 015.371 1a12.963 12.963 0 009.629 0A13.96 13.96 0 0120 0v1a12.96 12.96 0 00-4.629.926A13.963 13.963 0 015 1.927a12.96 12.96 0 00-5-.926z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 110,
                heightXs: heightXs || 15,
                widthMd: widthMd || 190,
                heightMd: heightMd || 64
            },
            delta: {
                background: `url("data:image/svg+xml,%3csvg width='4' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.99867 20h1.0001C3.99959 19.9381 4 19.8762 4 19.8143c0-1.7606-.33289-3.5212-.99867-5.1857C1.76513 11.5381 1.76513 8.0905 3.00132 5c.64238-1.60593.97486-3.30139.99744-5H2.99867c-.02252 1.5725-.33113 3.14187-.92582 4.6286C.74128 7.9575.74128 11.6711 2.07285 15c.64151 1.6038.95011 3.3037.92582 5z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 16,
                heightXs: heightXs || 110,
                widthMd: widthMd || 64,
                heightMd: heightMd || 190
            },
            epsilon: {
                background: `url("data:image/svg+xml,%3csvg width='36' height='7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 4.43533V3.43529c3.02951.02465 6.06266-.53585 8.92677-1.68149 5.84603-2.3384 12.36753-2.338399 18.21353 0C29.9833 2.89099 32.9928 3.45164 36 3.43575v1.00001c-3.1333.01589-6.2689-.56861-9.2311-1.75348-5.6076-2.243038-11.8632-2.243039-17.47074 0C6.31484 3.8756 3.15561 4.45995 0 4.43533z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 82,
                heightXs: heightXs || 82,
                widthMd: widthMd || 132,
                heightMd: heightMd || 112
            },
            zeta: {
                background: `url("data:image/svg+xml,%3Csvg width='31' height='87' xmlns='http://www.w3.org/2000/svg' fill='none'%3E%3Cpath d='M0 1.78121V.367004L.074097.292908 15.5556 15.7744 31 .329986V1.74421L15.5556 17.1886.074097 1.70711 0 1.78121z' fill='%23E20177'/%3E%3Cpath d='M0 11.6331v-1.4142l.074097-.0741L15.5556 25.6262 31 10.1819v1.4142L15.5556 27.0405.074097 11.559 0 11.6331z' fill='%23E20177'/%3E%3Cpath d='M0 21.4849v-1.4142l.074097-.0741L15.5556 35.4781 31 20.0337v1.4142L15.5556 36.8923.074097 21.4108 0 21.4849z' fill='%23E20177'/%3E%3Cpath d='M0 31.3368v-1.4143l.074097-.0741L15.5556 45.3299 31 29.8855v1.4142L15.5556 46.7441.074097 31.2627 0 31.3368z' fill='%23E20177'/%3E%3Cpath d='M0 41.1886v-1.4142l.074097-.0741L15.5556 55.1818 31 39.7374v1.4142L15.5556 56.596.074097 41.1145 0 41.1886z' fill='%23E20177'/%3E%3Cpath d='M0 51.0405v-1.4142l.074097-.0741L15.5556 65.0337 31 49.5893v1.4142L15.5556 66.4479.074097 50.9664 0 51.0405z' fill='%23E20177'/%3E%3Cpath d='M0 60.8924v-1.4143l.074097-.074L15.5556 74.8855 31 59.4411v1.4143L15.5556 76.2998.074097 60.8183 0 60.8924z' fill='%23E20177'/%3E%3Cpath d='M0 70.7442V69.33l.074097-.0741L15.5556 84.7373 31 69.2929v1.4143L15.5556 86.1516.074097 70.6701 0 70.7442z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3C/svg%3E") center / contain`,
                widthXs: widthXs || 144,
                heightXs: heightXs || 60,
                widthMd: widthMd || 277,
                heightMd: heightMd || 86
            },
            eta: {
                background: `url("data:image/svg+xml,%3csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 1.70715V.292938L6.00004 6.2929 12 .292939V1.70715L6.00004 7.70711 0 1.70715z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 24,
                heightXs: heightXs || 8,
                widthMd: widthMd || 48,
                heightMd: heightMd || 8
            },
            theta: {
                background: `url("data:image/svg+xml,%3csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M39.4342 0h1.1314L48 7.43443v1.13138L39.4342 0zm-8 0h1.1314L48 15.4344v1.1314L31.4342 0zm-8 0h1.1314L48 23.4344v1.1314L23.4342 0zm-8 0h1.1314L48 31.4344v1.1314L15.4342 0zM7.43419 0h1.13138L48 39.4344v1.1314L7.43419 0zM0 .565854V0h.565517L48 47.4345V48h-.5659L0 .565854zM0 8.56581V7.43443L40.5656 48h-1.1314L0 8.56581zm0 7.99999v-1.1314L32.5656 48h-1.1314L0 16.5658zm0 8v-1.1314L24.5656 48h-1.1314L0 24.5658zm0 8v-1.1314L16.5656 48h-1.1314L0 32.5658zm0 8v-1.1314L8.56557 48H7.4342L0 40.5658zM.565571 48H0v-.5656L.565571 48zM48 .565807L47.4342 0H48v.565807z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 25,
                heightXs: heightXs || 30,
                widthMd: widthMd || 60,
                heightMd: heightMd || 40
            },
            iota: {
                background: `url("data:image/svg+xml,%3csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M 39.434 48 L 40.566 48 L 48 40.566 L 48 39.434 L 39.434 48 Z M 31.434 48 L 32.566 48 L 48 32.566 L 48 31.434 L 31.434 48 Z M 23.434 48 L 24.566 48 L 48 24.566 L 48 23.434 L 23.434 48 Z M 15.434 48 L 16.566 48 L 48 16.566 L 48 15.434 L 15.434 48 Z M 7.434 48 L 8.566 48 L 48 8.566 L 48 7.434 L 7.434 48 Z M 0 47.434 L 0 48 L 0.566 48 L 48 0.565 L 48 0 L 47.434 0 L 0 47.434 Z M 0 39.434 L 0 40.566 L 40.566 0 L 39.434 0 L 0 39.434 Z M 0 31.434 L 0 32.566 L 32.566 0 L 31.434 0 L 0 31.434 Z M 0 23.434 L 0 24.566 L 24.566 0 L 23.434 0 L 0 23.434 Z M 0 15.434 L 0 16.566 L 16.566 0 L 15.434 0 L 0 15.434 Z M 0 7.434 L 0 8.566 L 8.566 0 L 7.434 0 L 0 7.434 Z M 0.566 0 L 0 0 L 0 0.566 L 0.566 0 Z M 48 47.434 L 47.434 48 L 48 48 L 48 47.434 Z' fill='%23${getSVGFill(
                    fill
                )}'/%3e%3c/svg%3e")`,
                widthXs: widthXs || 25,
                heightXs: heightXs || 30,
                widthMd: widthMd || 60,
                heightMd: heightMd || 40
            },
            kappa: {
                background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 38 48'%3E%3Cpath fill='%23E20177' fill-rule='evenodd' d='M39.43 0h1.14L48 7.43v1.14L39.43 0zm-8 0h1.14L48 15.43v1.14L31.43 0zm-8 0h1.14L48 23.43v1.14L23.43 0zm-8 0h1.14L48 31.43v1.14L15.43 0zm-8 0h1.14L48 39.43v1.14L7.43 0zM0 .57V0h.57L48 47.43V48h-.57L0 .57zm0 8V7.43L40.57 48h-1.14L0 8.57zm0 8v-1.14L32.57 48h-1.14L0 16.57zm0 8v-1.14L24.57 48h-1.14L0 24.57zm0 8v-1.14L16.57 48h-1.14L0 32.57zm0 8v-1.14L8.57 48H7.43L0 40.57zM.57 48H0v-.57l.57.57zM48 .57L47.43 0H48v.57z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
                widthXs: widthXs || 25,
                heightXs: heightXs || 30,
                widthMd: widthMd || 60,
                heightMd: heightMd || 40
            }
        }
    }

    return {
        background: config?.variant[variant]?.background,
        width: config?.variant[variant]?.widthXs,
        height: config?.variant[variant]?.heightXs,
        pointerEvents: 'none',
        [theme.breakpoints.up('md')]: {
            width: config?.variant[variant]?.widthMd,
            height: config?.variant[variant]?.heightMd
        }
    }
}

export default style
