/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Review = createSvgIcon(
    <>
        <path d="M 4.1443163,0.2597199 C 3.3479635,0.43306571 2.7471567,0.72237669 2.2642965,1.2052266 1.0596702,2.4098632 1.0596702,4.3486765 1.0596702,8.2263235 V 20.564908 c 0,3.877606 0,5.816512 1.2046263,7.021066 0.4828602,0.48285 1.083667,0.77219 1.8800198,0.945547 z" />
        <path d="M 9.285499,5.59375e-8 C 7.5697366,5.59375e-8 6.2335707,7.1547754e-5 5.1726374,0.10442818 V 28.685603 c 1.0609333,0.104364 2.3970992,0.104429 4.1128616,0.104429 h 5.193291 l -1.994175,-1.994177 5.088862,-5.088862 3.022391,3.022391 7.197508,-7.197508 V 8.2257233 c 0,-3.8776471 -2.83e-4,-5.8161467 -1.20494,-7.0207833 C 25.383882,3.1370276e-4 23.445259,5.59375e-8 19.567653,5.59375e-8 Z M 27.793376,20.439798 l -7.197508,7.197508 -3.022391,-3.022391 -2.180941,2.18094 1.994176,1.994177 h 2.180941 c 1.864455,0 3.272028,-0.0027 4.373932,-0.136561 l 3.715231,-3.715231 c 0.133718,-1.101671 0.13656,-2.510393 0.13656,-4.373931 z" />
        <path d="M 30.94033,21.655536 20.595763,32 l -5.20349,-5.203387 2.181257,-2.181256 3.022233,3.022336 8.163311,-8.163413 z" />
    </>,
    'Review'
)

export default Review
