// @TODO: shitty WIP version for launch, replace it when we develop a complete solution for GTM

import { useCallback } from 'react'

import useBreakpoints from './useBreakpoints'
import { useRouteChangeComplete, useRouteChangeStart } from './useRouteChange'

const getEventKey = params => JSON.stringify(params)
const sentThisNavigation = new Map()

// we block events during transition to prevent sending the same data multiple times
let blockEventsRef = false
const blockEvents = () => {
    // console.log('gtm blocked events')
    blockEventsRef = true
}
const unblockEvents = () => {
    // console.log('gtm unblocked events')
    blockEventsRef = false
}
const checkIfEventsAreBlocked = () => blockEventsRef

const checkSentThisNavigation = params =>
    // console.log('gtm check sentThisNavigation', sentThisNavigation.has(getEventKey(params)), sentThisNavigation)
    sentThisNavigation.has(getEventKey(params))

const setSentThisNavigation = params => {
    sentThisNavigation.set(getEventKey(params), true)
    // console.log('gtm set sentThisNavigation', sentThisNavigation.current)
}

const resetSentThisNavigation = params => {
    // console.log('stats gtm reset sentThisNavigation', sentThisNavigation)
    if (!params) {
        return sentThisNavigation.clear()
    }
    return sentThisNavigation.delete(getEventKey(params))
}

export { resetSentThisNavigation as resetGTMEvents }

// This is a hack to prevent sending the same data to GTM multiple times
// sending GTM data needs to be refactored anyway
// this hook has to be used in _app.js
export const useGTMSetup = () => {
    const onStartHandler = useCallback(() => {
        blockEvents()
        // resetSentThisNavigation()
    }, [])
    useRouteChangeStart(onStartHandler, false)

    // we reset the sentThisNavigation on route change in case the user SPA navigates to the same page multiple times
    const onCompleteHandler = useCallback(() => {
        unblockEvents()
        resetSentThisNavigation()
    }, [])
    useRouteChangeComplete(onCompleteHandler, false)
}

// const debugGtmParams = (params) => {

// if (!window["gtmDebugParams"]) window["gtmDebugParams"] = []
// window["gtmDebugParams"].push(params)
// console.table(window["gtmDebugParams"])
// }

const useGTM = () => {
    const [isDesktop] = useBreakpoints('md')

    /**
     * GTM dataLayer.push
     * @param {Object} params
     * @param {boolean} detectDevice
     * @returns {function}
     *
     * Push object data to GTM
     *
     * ```js
     * import { useGTM } from '../hooks'
     *
     * const { sendDataToGTM } = useGTM()
     *
     * const handleClick = () => {
     *    sendDataToGTM(
     *        {
     *            eventA: 'value_A',
     *            eventB: 'value_B',
     *            eventC: {
     *                eventC_A: 'value_C_A',
     *                eventC_B: 'value_C_B'
     *            },
     *            action: 'value_Action' // if 'action' event is set and detectDevice is true, detectDevice will be ignored
     *        },
     *        true // If set, object data will contain "action: 'Desktop || Mobile'"
     *    )
     * }
     * ```
     */
    const sendDataToGTM = useCallback(
        (params = {}, detectDevice = false, ignoreTransitionBlock = false, ignoreMapBlock = false) => {
            const device = isDesktop ? 'Desktop' : 'Mobile'
            if (!Object.keys(params)?.length || !process.browser) {
                return false
            }
            if (!window.dataLayer) {
                window.dataLayer = window.dataLayer || []
            }
            const data = {
                ...(detectDevice && { action: device }),
                ...params
            }
            if (
                process.browser &&
                (ignoreMapBlock || !checkSentThisNavigation(data)) &&
                (ignoreTransitionBlock || !checkIfEventsAreBlocked())
            ) {
                // console.log('GTM stats sending', data)
                setSentThisNavigation(data)
                // debugGtmParams(data)
                // console.log('GTM stats sending', data)
                window.dataLayer?.push({ ...data })
                return true
            }
            return false
            // console.log('GTM stats not sending', data)
        },
        [isDesktop]
    )
    return {
        sendDataToGTM
    }
}
export default useGTM
