// @TODO: Change password input is not styled!
import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    '& .globalProfileHead': {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    '& .globalProfileHead_label': {
        color: theme.colors.profile.text.alternative,
        fontSize: toRem(12),
        lineHeight: 1.4
    },
    '& .globalProfileHead_username': {
        margin: toRems([4, 0, 10]),
        color: theme.colors.profile.text.secondary,
        fontFamily: theme.typography.fontFamily.alternative,
        fontWeight: theme.typography.fontWeight.bold,
        fontStyle: 'initial',
        fontSize: toRem(32),
        lineHeight: 1,
        wordBreak: 'break-word'
    },
    // Restyling material-ui
    // @TODO: Remove when we get rid of material-ui
    '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: toRem(30)
    },
    '& .MuiFormLabel-root': {
        padding: toRems([0, 0, 0, 28])
    },
    '& .MuiInputBase-input': {
        padding: toRems([0, 20, 15, 20]),
        borderBottom: `2px solid ${theme.colors.profile.form.input.border.default} !important`,
        color: theme.colors.profile.form.input.text.default
    },
    '& .MuiInputLabel-formControl': {
        color: theme.colors.profile.form.input.label.default
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.colors.profile.form.input.text.focus
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
    },
    '& input:-internal-autofill-selected:': {
        backgroundColor: 'transparent'
    },
    '& .MuiSelect-select': {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: toRem(-5),
            right: toRem(15),
            display: 'block',
            height: toRem(25),
            width: toRem(25),
            background: 'url(/images/profile/select-arrow.svg)'
        }
    },
    '& .MuiSelect-icon': {
        display: 'none'
    },
    '& .MuiPaper-elevation8': {
        borderRadius: 0,
        boxShadow: 'initial !important',
        border: `1px solid ${theme.colors.profile.form.input.border.default} !important`
    }
    // Enf of restyling material-ui
})

export default style
