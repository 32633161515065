import getHashFromDate from '../getHashFromDate'
import replaceAll from '../replaceAll'

const generateImageURLFromTemplate = (urlTemplate, imageId, width, height, instanceId, instanceType, updatedAt) => {
    const replacements = {
        '{image_id}': imageId,
        '{instance_max_width}': width,
        '{instance_max_height}': height,
        '{instance_id}': instanceId,
        '{format_extension}': instanceType
    }

    return `${replaceAll(urlTemplate || '', replacements)}${updatedAt ? `?v=${getHashFromDate(updatedAt)}` : ''}`
}
export default generateImageURLFromTemplate
