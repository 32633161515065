import stripHtml from '../../js/stripHtmlTags'
import truncateString from '../../js/truncateString'
import parseHtmlEntities from '../../parseHtmlEntities'

const parseDescription = (description, truncate = true, maxLength = 160, truncateEnd = '...') => {
    const parsed = parseHtmlEntities(stripHtml(description))
    return truncate ? truncateString(parsed, maxLength, truncateEnd) : parsed
}

export default parseDescription
