import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Link } from '../..'
import styles from './Item.style'

const ItemStyled = styled.span(props => ({ ...styles(props) }))

function Item({ link }) {
    const router = useRouter()
    const { href, label } = link || {}
    const { pathname } = router || {}
    const getCurrentNavItem = () => pathname === href
    if (!link) {
        return null
    }
    return (
        <ItemStyled active={getCurrentNavItem()}>
            <Link className="navigationItem" href={href}>
                {label}
            </Link>
        </ItemStyled>
    )
}

Item.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string
    })
}

Item.defaultProps = {
    link: undefined
}

export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NavigationItem]: ', error, componentStack)
    }
})
