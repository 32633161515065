/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Share = createSvgIcon(
    <path d="M 16,32 C 24.8366,32 32,24.8365 32,16 32,7.1634 24.8366,0 16,0 7.1634,0 0,7.1634 0,16 0,24.8365 7.1634,32 16,32 Z M 9.3398,20.5601 c 0,-4.6241 3.6722,-8.4141 8.2384,-8.6519 V 8.2178 l 7.3156,5.9583 -7.3156,5.9801 v -3.688 c -3.0165,0.1603 -5.7219,1.9037 -7.0854,4.6303 l -1.153,2.684 z" />,
    'Share'
)

export default Share
