export type AmpDimensions = {
    width: number
    height: number
}

export type ImageOutput = {
    ratio: number
    width: number
    height: number
    source: string
    placeholder: string
    ampDimensions: AmpDimensions
}

/**
 * This function is used only for returning original image output.
 */
const getImageOutput = (ratio0: number | undefined, width: number, height: number, source: string): ImageOutput => {
    const ratio = ratio0 || 1

    return {
        ratio,
        width,
        height,
        source,
        /**
         * Note: Why is the placeholder the same as the source?
         * Warning: Do not remove the placeholder because
         * the images do not display properly afterwards.
         * Note: It has to do something with image being already loaded.
         * Hint: Probably a wrong variable name is being used somewhere.
         */
        placeholder: source,
        ampDimensions: {
            width,
            height: Math.round(width / ratio)
        }
    }
}

export default getImageOutput
