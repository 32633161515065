const LCP_LIMIT = 37000
const DEFAULT_WIDTH = 0
const DEFAULT_HEIGHT = 0

export const parseCreativeChunks = (
    width: number = DEFAULT_WIDTH,
    height: number = DEFAULT_HEIGHT,
    lcpLimit: number = LCP_LIMIT
): { parsedWidth: number; parsedHeight: number; numberOfChunks: number; chunkPositions: number[][] } => {
    const parsedWidth = width
    const parsedHeight = height
    if (parsedHeight * parsedWidth <= lcpLimit) {
        return {
            parsedWidth,
            parsedHeight,
            numberOfChunks: 1,
            chunkPositions: [[0, 0]]
        }
    }

    const numberOfChunks = Math.ceil((parsedHeight * parsedWidth) / (lcpLimit * 2)) * 2
    const chunkHeight = Math.ceil(parsedHeight / numberOfChunks)
    const chunkPositions = Array.from({ length: numberOfChunks }, (_, i) => [0, -i * chunkHeight])

    return {
        parsedWidth,
        parsedHeight,
        numberOfChunks,
        chunkPositions
    }
}
