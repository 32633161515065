import toRem from './convertPxToRem'

/**
 * Convert pixels to rem using root font size
 * @param values
 * @param returnArray
 * @returns {array}
 */
const convertPxToRems = (values, returnArray = false) => {
    const responseArray = values.map(value => toRem(value))
    if (returnArray) {
        return responseArray
    }
    return responseArray.join(' ')
}

export default convertPxToRems
