import { useMemo } from 'react'

const getPostType = ({ item, pageType }) => {
    switch (pageType) {
        case 'article':
            return [
                item?.taxons?.['article-type']?.[0]?.slug === 'article-type-prev-next' ? 'prev-next' : 'article',
                'post'
            ]
        default:
            return ['web-page', 'generic']
    }
}

export const createArticleTargetingArguments = ({ item, appName = 'rtlhr', pageType = 'article' }) => {
    if (!item) {
        return {}
    }
    const path = item?.sitemap?.href ? item.sitemap.href.split('/').filter(el => el) : []
    const realSlug = path.length > 0 ? path.pop() : []
    return {
        post_type: getPostType({ item, pageType }),
        post_slug: item?.slug ? [item.slug] : [realSlug],
        post_tag: item?.tags?.map(tag => tag.taxon.slug),
        [`${appName}_category`]: path
    }
}

const useArticleTargetingArguments = ({ item, appName = 'rtlhr', pageType = 'article' }) =>
    useMemo(() => createArticleTargetingArguments({ item, appName, pageType }), [item, appName, pageType])

export default useArticleTargetingArguments
