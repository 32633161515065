import { AdsActionTypes as ActionTypes } from '../actions/ads'

const initialState = {
    adManifest: {},
    displaySlots: {},
    refreshSlots: [],
    filledSlots: {},
    customFilledStates: {},
    inarticleAdMounted: false,
    native: {},
    voyoData: {}
}

const adsReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    {
        newStore,
        manifest,
        type,
        divId,
        slotFilled,
        adZone,
        targetingArguments = [],
        maxAdWidth,
        divsWithStatus = {},
        nativeData,
        nativeType,
        voyoData,
        key,
        isFilled
    }
) => {
    switch (type) {
        case ActionTypes.SET_VOYO_DATA: {
            return {
                ...state,
                voyoData: { ...state.voyoData, ...(voyoData || {}) }
            }
        }
        case ActionTypes.SET_CUSTOM_FILLED_STATE: {
            return {
                ...state,
                customFilledStates: { ...state.customFilledStates, [key]: isFilled }
            }
        }
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.SET_AD_MANIFEST: {
            return {
                ...state,
                adManifest: manifest || { enabled: false }
            }
        }
        case ActionTypes.DISPLAY_DFP_SLOT: {
            if (!adZone) {
                return state
            }

            return {
                ...state,
                displaySlots: { ...state.displaySlots, [adZone]: { targetingArguments, maxAdWidth, divId } }
            }
        }

        case ActionTypes.CLEAR_DISPLAYED_DFP_SLOT: {
            if (!adZone) {
                return state
            }
            const newDisplaySlots = { ...state.displaySlots }
            delete newDisplaySlots[adZone]
            return {
                ...state,
                displaySlots: newDisplaySlots
            }
        }

        case ActionTypes.CLEAR_DISPLAYED_DFP_SLOTS: {
            return {
                ...state,
                displaySlots: {},
                filledSlots: {},
                refreshSlots: [],
                inarticleAdMounted: false
            }
        }

        case ActionTypes.DFP_SLOT_FILLED: {
            if (divId) {
                return { ...state, filledSlots: { ...state.filledSlots, [divId]: slotFilled } }
            }
            return state
        }

        case ActionTypes.DFP_SLOTS_FILLED: {
            if (divId) {
                return { ...state, filledSlots: { ...state.filledSlots, ...divsWithStatus } }
            }
            return state
        }

        case ActionTypes.CLEAR_FILLED_DFP_SLOTS: {
            return {
                ...state,
                filledSlots: {}
            }
        }

        case ActionTypes.REFRESH_DFP_SLOT: {
            if (divId) {
                return { ...state, refreshSlots: [...state.refreshSlots, divId] }
            }
            return state
        }

        case ActionTypes.INARTICLE_AD_MOUNTED: {
            return {
                ...state,
                inarticleAdMounted: true
            }
        }

        case ActionTypes.SET_NATIVE_AD_DATA: {
            if (!nativeType) {
                return state
            }
            if (!nativeData) {
                const newNative = { ...state.native }
                delete newNative[nativeType]
                return {
                    ...state,
                    native: newNative
                }
            }
            return {
                ...state,
                native: { ...state.native, [nativeType]: nativeData }
            }
        }

        case ActionTypes.MODIFY_NATIVE_AD_DATA: {
            if (!nativeType || !nativeData || !state.native[nativeType]) {
                return state
            }
            return {
                ...state,
                native: { ...state.native, [nativeType]: { ...state.native[nativeType], ...nativeData } }
            }
        }

        case ActionTypes.CLEAR_NATIVE_ADS: {
            if (!nativeType) {
                return {
                    ...state,
                    native: {}
                }
            }
            const newNative = { ...state.native }
            delete newNative[nativeType]
            return {
                ...state,
                native: newNative
            }
        }

        default:
            return state
    }
}

export { adsReducer }
