import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useEnvironment } from '../../../hooks'

function MidasPixel({ id }) {
    const { thirdPartyEnabled } = useEnvironment()
    const { midasPixel: hasConsent } = useSelector(state => state.user)

    if (!hasConsent || !thirdPartyEnabled) {
        return null
    }

    return (
        <div
            className="MidasPixel"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `<!-- Midas Pixel Code -->
                <img height="1" width="1" style="display:none" src="https://cdn.midas-network.com/MidasPixel/IndexAsync/${id}" />
                <!-- DO NOT MODIFY -->
                <!-- End Midas Pixel Code -->`
            }}
        />
    )
}

MidasPixel.propTypes = {
    id: PropTypes.string
}

MidasPixel.defaultProps = {
    id: 'faf14fa9-6a3b-4e8d-869f-af0afe0283af' // Net.hr default
}

export default MidasPixel
