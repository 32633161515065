import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

const searchSelector = s => s.search
const navigationSelector = s => s.navigation

const useDocumentScrollDisable = () => {
    const { isOpen: isSearchOpen } = useSelector(searchSelector)
    const { isOpen: isNavigationOpen } = useSelector(navigationSelector)

    // @TODO: implement this https://github.com/Threespot/freeze-scroll
    const enableBodyScroll = useCallback(() => {
        if (!window) {
            return
        }
        if (document.readyState === 'complete') {
            document.body.style.overflowY = 'visible'
            // document.body.style.marginRight = ''
        } else {
            window.addEventListener('load', enableBodyScroll)
        }
    }, [])

    const disableBodyScroll = useCallback(() => {
        if (!window) {
            return
        }
        if (document.readyState === 'complete') {
            document.body.style.overflowY = 'hidden'
            // document.body.style.marginRight = '16px'
        }
    }, [])

    useEffect(() => {
        if (!window) {
            return
        }
        if (isSearchOpen || isNavigationOpen) {
            disableBodyScroll()
            return
        }
        enableBodyScroll()
    }, [enableBodyScroll, disableBodyScroll, isSearchOpen, isNavigationOpen])
}

export default useDocumentScrollDisable
