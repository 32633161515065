/* eslint-disable @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'

export type StyleProps = {
    theme?: any
    contentHeight?: number | string
    maxContentWidth?: number | string
    contentWidth?: string | number
    sides?: boolean
    zIndex?: number
    isFixed?: boolean
    offsetTop?: number | string
}

const styles = ({
    theme,
    contentHeight = '100%',
    maxContentWidth = 1200,
    contentWidth = '100%',
    zIndex: zIndexInput,
    isFixed = true
}: StyleProps) => {
    const { wallpaper, navigationDesktop, navigation, header } = theme.zIndex ?? {}
    const itemAboveZindex = navigationDesktop ?? navigation ?? header ?? 1000
    const zIndex = zIndexInput ?? wallpaper ?? itemAboveZindex - 1
    return {
        position: 'relative',
        width: '100vw',
        display: 'none',
        // minHeight: sides ? 0 : toRem(200),
        // background: 'lightblue',
        overflow: 'visible',
        zIndex: isFixed ? zIndex ?? 1000 : 1,
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        '.Wallpaper': {
            '&_container': {
                position: isFixed ? 'fixed' : 'absolute',
                top: '90px',
                left: 0,
                width: '100vw',
                height: '100%',
                pointerEvents: 'none',
                [theme.breakpoints.up('md')]: {
                    top: '50px',

                    '.has_subnavigation &': {
                        top: '80px'
                    }
                }
            },
            '&_content': {
                pointerEvents: 'none',
                position: 'relative',
                margin: '0 auto',
                overflow: 'visible',
                width: contentWidth,
                height: contentHeight,
                maxWidth: maxContentWidth,
                '&_top': {
                    pointerEvents: 'all'
                }
            }
        }
    }
}
const Styled = createStyledComponent({
    tag: 'div',
    styles,
    blockProps: ['contentHeight', 'maxContentWidth', 'contentWidth']
})
export default Styled
