import axios from 'axios'

const instance = axios.create()

// Add a request interceptor
// instance.interceptors.request.use(function(config) {
//   // Do something before request is sent

//   const URL = `${config.method?.toUpperCase()} ${config.baseURL}/${config.url}`

//   console.log("URL: ", URL, config)

//   // if (config.baseURL?.startsWith('https://wip-gateway.rtl.hr')) {
//   //   console.log('ovo je upit na api', { config })
//   // }

//   return config;
// }, function(error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

// // No error handling
// instance.interceptors.response.use(
//     response => response,
//     error => Promise.resolve({ data: null })
// )

// instance.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data

//     // if (response.config.baseURL?.startsWith('https://wip-gateway.rtl.hr')) {
//     //   console.log('ovo je odgovor na api', { data: response.data })
//     // }

//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });

export default instance
// debug try removing console.error due to connection
// export default props =>
//     instance(props)
//         .then(r => r)
//         .catch(e => {
//             console.error('axios error')
//         })
