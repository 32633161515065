const constants = {
    CLOSE: 'NAVIGATION/CLOSE_NAVIGATION',
    OPEN: 'NAVIGATION/OPEN_NAVIGATION',
    TOGGLE: 'NAVIGATION/TOGGLE_NAVIGATION',
    SET_LOGO_COLOR: 'NAVIGATION/SET_LOGO_COLOR'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE }),
    setLogoColor: color => ({ type: constants.SET_LOGO_COLOR, color }),
    setEntityType: currentEntityType => ({ type: constants.SET_CURRENT_ENTITY_TYPE, currentEntityType })
}

export { constants as NavigationActionTypes, actions as NavigationActions }
