// eslint-disable-next-line @typescript-eslint/naming-convention
import React, { useMemo } from 'react'

import { SourceType } from '../../EternalAds.interface'
import { parseCreativeChunks } from '../../helpers/parseCreativeChunks'
import { useAdElementHref } from '../../hooks/useAdElementHref'
import type { WallpaperSideProps } from '../Wallpaper.interface'

const WallpaperCreative = ({
    element,
    ad,
    clickOptions,
    className
}: Pick<WallpaperSideProps, 'element' | 'ad' | 'clickOptions' | 'className'>) => {
    const href = useAdElementHref(ad, element)
    const { openInNewTab } = clickOptions
    const { width, fileImage, fileUrl, position, height, sourceType } = element
    // console.log({ sourceType, href, openInNewTab })

    const { parsedHeight, numberOfChunks, chunkPositions } = useMemo(
        () => parseCreativeChunks(width, height),
        [width, height]
    )
    if (sourceType === SourceType.IFRAME) {
        return (
            <div
                className={className}
                style={{
                    width,
                    height,
                    border: 0,
                    position: 'absolute'
                }}>
                <a
                    href={href}
                    target={openInNewTab ? '_blank' : '_self'}
                    style={{
                        width,
                        height,
                        pointerEvents: 'all',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 2
                    }}
                />
                <iframe
                    src={fileUrl}
                    scrolling="no"
                    style={{
                        width,
                        height,
                        border: 0,
                        pointerEvents: 'none',
                        zIndex: 1
                    }}
                />
            </div>
        )
    }

    if (numberOfChunks > 1) {
        return (
            <a
                className={className}
                href={href}
                target={openInNewTab ? '_blank' : '_self'}
                style={{
                    width,
                    height,
                    border: 0,
                    pointerEvents: 'all',
                    position: 'absolute'
                }}>
                {chunkPositions.map((chunkPosition, index) => (
                    <div
                        key={index}
                        style={{
                            width,
                            height: Math.ceil(parsedHeight / numberOfChunks),
                            background: `url(${fileImage || fileUrl}) ${position?.[0] || 0}px ${
                                chunkPosition?.[1] || 0
                            }px no-repeat`,
                            position: 'absolute',
                            pointerEvents: 'none',
                            top: Math.abs(chunkPosition[1])
                        }}
                    />
                ))}
            </a>
        )
    }

    return (
        <a
            href={href}
            target={openInNewTab ? '_blank' : '_self'}
            className={className}
            style={{
                width,
                height,
                pointerEvents: 'all',
                background: `url(${fileImage || fileUrl})${
                    position ? ` ${position.map(int => int + 'px').join(' ')}` : ''
                } no-repeat`
            }}
        />
    )
}
export default WallpaperCreative
