export const createDidomiConnectedScriptHandler = (name: string, handlerScript: string) => {
    if (!handlerScript) {
        return null
    }
    if (!name) {
        return `(function(){${handlerScript}})()`
    }
    return `
    (function(){
        function trackingScript() {
            ${handlerScript}
        }

        function handleConsent() {
            if (window.Didomi) {
                if (window.Didomi.getUserConsentStatusForVendor('${name}')) {
                    trackingScript();
                } else {
                    window.didomiEventListeners = window.didomiEventListeners || [];
                    window.didomiEventListeners.push({
                        event: 'notice.clickagree',
                        listener: function () {
                            if (window.Didomi.getUserConsentStatusForVendor('${name}')) {
                                trackingScript();
                            }
                        }
                    });
                }
            }
        }

        handleConsent();
    })()
`
}
