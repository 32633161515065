// @NOTE: Secondary variant and small and large size is not used anywhere and the design is not set. Change them as you need.

import { toRem } from '../../../../../../helpers/theme'

const config = {
    switch: {
        spacing: 4,
        width: 38,
        height: 24
    },
    label: {
        fontSize: toRem(16),
        vSpace: toRem(2),
        hSpace: toRem(7)
    }
}

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, disabled, checked }) => ({
    position: 'relative',
    margin: 0,
    padding: 0,
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    '& .switchToggleWrap': {
        display: 'flex',
        flexDirection: 'row',
        ...(!checked &&
            !disabled && {
                '&:hover': {
                    '& .switchToggle': {
                        // borderColor: error
                        //     ? theme.colors.profile.form.error.border
                        //     : theme.colors.profile.form.switch.border?.hover,
                        backgroundColor: theme.colors.profile.form.switch.background?.hover
                    }
                }
            })
    },
    '& .switchToggle': {
        display: 'block',
        position: 'relative',
        flexShrink: 0,
        padding: toRem(config.switch.spacing),
        width: toRem(config.switch.width),
        height: toRem(config.switch.height),
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor: error ? theme.colors.profile.form.error.border : theme.colors.profile.form.switch.border?.default,
        borderRadius: toRem(100),
        backgroundColor: theme.colors.profile.form.switch.background?.default || '#FFFFFF',
        cursor: 'pointer',
        transitionDuration: theme.transitions.input.switch.default.duration,
        transitionTimingFunction: theme.transitions.input.switch.default.timingFunction,
        transitionDelay: theme.transitions.input.switch.default.delay,
        transitionProperty: 'background-color, border-color',
        overflow: 'hidden',
        ...(checked && {
            // borderColor: error
            //     ? theme.colors.profile.form.error.border
            //     : theme.colors.profile.form.switch.border?.checked,
            backgroundColor: theme.colors.profile.form.switch.background?.checked
        }),
        ...(disabled && {
            cursor: 'default',
            pointerEvents: 'none',
            filter: 'greyscale(100%)',
            opacity: 0.5
        }),
        input: {
            position: 'absolute',
            zIndex: theme.zIndex.over,
            top: 0,
            left: 0,
            display: 'block',
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            cursor: 'inherit',
            opacity: 0
        },
        '&_slider': {
            width: '50%',
            height: '100%',
            backgroundColor: theme.colors.profile.form.switch.slider?.default,
            borderRadius: '100%',
            display: 'block',
            transform: 'translateX(0)',
            pointerEvents: 'none',
            transitionDuration: theme.transitions.input.switch.default.duration,
            transitionTimingFunction: theme.transitions.input.switch.default.timingFunction,
            transitionDelay: theme.transitions.input.switch.default.delay,
            transitionProperty: 'background-color, transform',
            ...(checked && {
                backgroundColor: theme.colors.profile.form.switch.slider?.checked,
                transform: 'translateX(100%)'
            })
        }
    },
    '& .switchToggleLabel': {
        marginTop: config.label.vSpace,
        paddingLeft: config.label.hSpace,
        paddingRight: 0,
        fontSize: config.label.fontSize,
        ...(disabled && {
            cursor: 'default',
            pointerEvents: 'none'
        })
    },
    '& .switchToggleError': {
        // paddingLeft: 0
    },
    '& .switchToggleHelp': {
        // paddingLeft: 0
    }
})

export default style
