/* eslint-disable @typescript-eslint/naming-convention */
import Script, { ScriptProps } from 'next/script'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import { useUIDSeed } from 'react-uid'

import googletagSlotsToIds from '../../helpers/ads/googletagSlotsToIds'
import { GoogleTagSlot, NativeGoogleTagIdCallback } from '../../types/ads/Ad.interface'

const PREBID_TIMEOUT = 2000
const IPROM_VARIABLE_NAME = '_ipromNS'
const PREBID_VARIABLE_NAME = 'iprompbjs'

declare global {
    interface Window {
        [IPROM_VARIABLE_NAME]?: (command: string, ...args: any[]) => void
        ipromNS?: {
            execute: (args: any) => void
            getCurrGAMRequestId: () => string
            getBiddingFinished: (id: string) => boolean
            setGAMPrebidTimeout: (id: string, value: boolean) => void
        }
    }
}

const useRefWithDependency = value => {
    const refVal = useRef(value)
    useEffect(() => {
        refVal.current = value
    }, [value])

    return refVal
}

const useIprom = ({ enabled = false, sitePath = [] }: { enabled?: boolean; sitePath?: string[] }) => {
    const isClient = typeof window !== 'undefined'

    const isEnabled = enabled && isClient

    const globalsMountedRef = useRef(false)
    // set up needed globals, runs only once
    useEffect(() => {
        if (!isEnabled || globalsMountedRef.current) {
            return
        }

        globalsMountedRef.current = true
        window[PREBID_VARIABLE_NAME] = window[PREBID_VARIABLE_NAME] || {}
        window[PREBID_VARIABLE_NAME].que = window[PREBID_VARIABLE_NAME].que || []

        window[IPROM_VARIABLE_NAME] =
            window[IPROM_VARIABLE_NAME] ||
            ((...rest) => {
                if (typeof window.ipromNS !== 'undefined' && window.ipromNS.execute) {
                    window.ipromNS.execute(rest)
                } else {
                    // @ts-expect-error: it is defined by now
                    ;(window[IPROM_VARIABLE_NAME].q = window[IPROM_VARIABLE_NAME].q || []).push(rest)
                }
            })
    }, [isEnabled])

    // init sitePath, also runs once
    const isMountedRef = useRef(false)
    useEffect(() => {
        if (!isEnabled || isMountedRef.current || !window?.[IPROM_VARIABLE_NAME]) {
            return
        }
        window[IPROM_VARIABLE_NAME]?.('init', {
            sitePath /* second value of sitePath array is equal to SID */,
            cookieConsent: true /* based on site cookie policy */
        })
        isMountedRef.current = true
    }, [isEnabled, sitePath])

    const timeoutRefs = useRef({})
    const enabledRef = useRefWithDependency(isEnabled)

    const displayAdUnits = useCallback((googletagSlots: GoogleTagSlot[], nativeDisplay: NativeGoogleTagIdCallback) => {
        if (!enabledRef.current || googletagSlots.length === 0) {
            return
        }
        if (!window[IPROM_VARIABLE_NAME] || !window.ipromNS || !isMountedRef.current) {
            if (typeof nativeDisplay === 'function') {
                nativeDisplay?.(googletagSlots.map(slot => slot.getSlotElementId()))
            }
            return
        }

        window[IPROM_VARIABLE_NAME]('requestBidsGAMSlots', googletagSlots)
        const biddingId = window.ipromNS?.getCurrGAMRequestId?.()

        timeoutRefs.current[biddingId] = setTimeout(() => {
            const biddingFinished = window.ipromNS?.getBiddingFinished?.(biddingId)
            window.ipromNS?.setGAMPrebidTimeout(biddingId, true)
            // @TODO: clean up before final implementation

            if (!biddingFinished) {
                // @TODO: clean up before final implementation
                if (typeof nativeDisplay === 'function') {
                    nativeDisplay?.(googletagSlots.map(slot => slot.getSlotElementId()))
                }
            }
        }, PREBID_TIMEOUT)
    }, [])
    const ipromDisplayCallback = useCallback(
        (googletagSlots: GoogleTagSlot[], nativeDisplay: NativeGoogleTagIdCallback) =>
            displayAdUnits(googletagSlots, gptSlots => nativeDisplay(googletagSlotsToIds(gptSlots))),
        [displayAdUnits]
    )

    const ipromRefreshCallback = useCallback(
        (googletagSlots: GoogleTagSlot[], nativeRefresh: NativeGoogleTagIdCallback) =>
            displayAdUnits(googletagSlots, nativeRefresh),
        []
    )

    return [ipromDisplayCallback, ipromRefreshCallback]
}

const IpromComponent = ({
    domains,
    strategy,
    async,
    defer
}: {
    domains: string[]
    strategy: ScriptProps['strategy']
    async: boolean
    defer: boolean
}) => {
    const uid = useUIDSeed()
    return (
        <>
            {domains.map((source, i) => (
                <Script
                    strategy={strategy}
                    id={`iprom_script_${i}`}
                    key={uid(source)}
                    defer={defer}
                    async={async}
                    src={`https://cdn.${source}/ipromNS.js`}
                />
            ))}
        </>
    )
}

IpromComponent.propTypes = {
    domains: PropTypes.arrayOf(PropTypes.string),
    strategy: PropTypes.string,
    async: PropTypes.bool,
    defer: PropTypes.bool
}

IpromComponent.defaultProps = {
    domains: ['ipromcloud.com'],
    strategy: 'afterInteractive',
    async: true,
    defer: true
}

export { IpromComponent, useIprom }
