import { useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'

import useBaseApiURL from './useBaseApiURL'
import useSettings from './useSettings'

const useSearch = (searchQuery, itemsPerPage = 20) => {
    const { xClientName: clientName } = useSettings()
    const baseApiURL = useBaseApiURL()

    const {
        data: searchData,
        hasNextPage: canLoadMore,
        fetchNextPage: loadMore
    } = useInfiniteQuery(
        ['elastic/search', { query: searchQuery }],
        async (_key, { query }, pageIndex = 0) => {
            const response = await fetch(`${baseApiURL}/combined/search`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'x-client-name': clientName
                },
                body: JSON.stringify({
                    limit: itemsPerPage,
                    page: pageIndex + 1,
                    query
                })
            })
            const data = await response.json()
            return data
        },
        {
            enabled: !!searchQuery,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60 * 0.5,
            cacheTIme: 1000 * 60 * 0.5,
            getNextPageParam: (lastGroup, allGroups) => (lastGroup.length ? allGroups.length : null)
        }
    )

    const searchResults = useMemo(
        () => (searchData?.pages || []).reduce((all, group) => [...all, ...group], []),
        [searchData?.pages]
    )

    return {
        data: searchResults,
        canLoadMore,
        loadMore
    }
}

export default useSearch
