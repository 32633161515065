import { useMemo } from 'react'

const useGtmArticleTags = inputTags =>
    useMemo(() => {
        const tags = (Array.isArray(inputTags) && inputTags) || inputTags?.tags || []
        return tags.reduce((acc, tag) => {
            if (tag?.taxon?.title) {
                acc.push(tag.taxon.title)
            } else if (typeof tag === 'string') {
                acc.push(tag)
            }
            return acc
        }, [])
    }, [inputTags])

export default useGtmArticleTags
