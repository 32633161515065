const extensionMimeTypeMap = Object.freeze({
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff'
})

const FileExtensionRegex = /\.([0-9a-z]+)(?:[?#]|$)/i

export const getImageMimeType = (url: string): string | null => {
    if (!url) return null
    const fileType = FileExtensionRegex.exec(url)?.[1]
    if (!fileType || !extensionMimeTypeMap[fileType]) return null
    return extensionMimeTypeMap[fileType]
}
