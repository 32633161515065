import { toRem, toRems } from '../../../../../../helpers/theme'

const config = {
    checkbox: {
        width: toRem(20),
        height: toRem(20)
    },
    label: {
        textDecoration: {
            default: 'none',
            checked: 'none'
        },
        opacity: {
            default: 1,
            checked: 1
        },
        fontSize: toRem(16),
        vSpace: 0,
        hSpace: toRem(7)
    }
}

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, disabled, checked, error }) => ({
    position: 'relative',
    margin: toRems([5, 0]),
    padding: 0,
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    '& .checkboxWrap': {
        display: 'flex',
        flexDirection: 'row',
        ...(!checked &&
            !disabled && {
                '&:hover': {
                    '& .checkboxCheck': {
                        color: theme.colors.profile.form.checkbox.icon?.hover,
                        borderColor: error
                            ? theme.colors.profile.form.error.border
                            : theme.colors.profile.form.checkbox.border?.hover,
                        backgroundColor: theme.colors.profile.form.checkbox.background?.hover,
                        '&_icon': {
                            opacity: 1
                        }
                    }
                }
            })
    },
    '& .checkboxCheck': {
        display: 'block',
        position: 'relative',
        flexShrink: 0,
        width: config.checkbox.width,
        height: config.checkbox.height,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: error
            ? theme.colors.profile.form.error.border
            : theme.colors.profile.form.checkbox.border?.default,
        backgroundColor: theme.colors.profile.form.checkbox.background?.default || '#FFFFFF',
        color: theme.colors.profile.form.checkbox.icon?.default,
        fontSize: config.checkbox.width,
        cursor: 'pointer',
        transitionDuration: theme.transitions.input.checkbox.default.duration,
        transitionTimingFunction: theme.transitions.input.checkbox.default.timingFunction,
        transitionDelay: theme.transitions.input.checkbox.default.delay,
        transitionProperty: 'color, background-color, border-color',
        overflow: 'hidden',
        ...(checked && {
            color: theme.colors.profile.form.checkbox.icon?.checked,
            borderColor: error
                ? theme.colors.profile.form.error.border
                : theme.colors.profile.form.checkbox.border?.checked,
            backgroundColor: theme.colors.profile.form.checkbox.background?.checked
        }),
        ...(disabled && {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.7
        }),
        input: {
            position: 'absolute',
            zIndex: theme.zIndex.over,
            top: 0,
            left: 0,
            display: 'block',
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            cursor: 'inherit',
            opacity: 0
        },
        '&_icon': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
            opacity: 0,
            transitionDuration: theme.transitions.input.checkbox.default.duration,
            transitionTimingFunction: theme.transitions.input.checkbox.default.timingFunction,
            transitionDelay: theme.transitions.input.checkbox.default.delay,
            transitionProperty: 'opacity',
            ...(checked && {
                opacity: 1
            }),
            svg: {
                path: {
                    fill: 'currentColor',
                    transitionDuration: theme.transitions.input.checkbox.default.duration,
                    transitionTimingFunction: theme.transitions.input.checkbox.default.timingFunction,
                    transitionDelay: theme.transitions.input.checkbox.default.delay,
                    transitionProperty: 'fill'
                }
            }
        }
    },
    '& .checkboxLabel': {
        marginTop: config.label.vSpace,
        paddingLeft: config.label.hSpace,
        paddingRight: 0,
        fontSize: config.label.fontSize,
        textDecoration: config.label.textDecoration.default,
        opacity: config.label.opacity.default,
        transitionDuration: theme.transitions.input.checkbox.default.duration,
        transitionTimingFunction: theme.transitions.input.checkbox.default.timingFunction,
        transitionDelay: theme.transitions.input.checkbox.default.delay,
        transitionProperty: 'opacity',
        ...(checked && {
            textDecoration: config.label.textDecoration.checked,
            opacity: config.label.opacity.checked
        }),
        ...(disabled && {
            cursor: 'default',
            pointerEvents: 'none'
        })
    },
    '& .checkboxError': {
        // paddingLeft: 0
    },
    '& .checkboxHelp': {
        // paddingLeft: 0
    }
})

export default style
