import { toRem, toRems } from '../../../../../../helpers/theme'

const config = {
    xs: {
        primary: {
            fontSize: 14,
            margin: [0, 0, 10],
            padding: [0, 24],
            icon: {
                size: 24,
                spacing: [-8, 0, -5, 0]
            }
        },
        secondary: {
            fontSize: 16,
            margin: [0],
            padding: [0, 10],
            icon: {
                size: 26,
                spacing: [-8, 2, -6, -4]
            }
        }
    },
    md: {
        primary: {
            fontSize: 16,
            margin: [0, 0, 12],
            padding: [0, 24],
            icon: {
                size: 26,
                spacing: [-8, 2, -6, -4]
            }
        },
        secondary: {
            fontSize: 16,
            margin: [0],
            padding: [0, 10],
            icon: {
                size: 26,
                spacing: [-8, 2, -6, -4]
            }
        }
    },
    alignLeft: {
        paddingTop: {
            small: 12,
            normal: 16,
            large: 22
        }
    }
}

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, size, alignLeft, hasIcon, error, focused, id, isMobileOnly }) => {
    const getLabelColor = () => {
        let labelColor = 'currentColor'
        if (focused && !error) {
            labelColor = 'currentColor'
        }
        if (error) {
            labelColor = theme.colors.profile.error.text
        }
        return labelColor
    }

    const fontWeight = {
        primary: theme.typography.fontWeight.black,
        secondary: theme.typography.fontWeight.normal
    }

    return {
        display: 'block',
        margin: toRems(config.xs[variant]?.margin),
        padding: toRems(config.xs[variant]?.padding),
        fontSize: toRem(config.xs[variant]?.fontSize),
        lineHeight: 1.2,
        color: getLabelColor(),
        fontWeight: fontWeight[variant],
        ...(id && {
            cursor: 'pointer'
        }),
        ...(hasIcon &&
            variant === 'primary' && {
                paddingLeft: 0
            }),
        [theme.breakpoints.up('md')]: {
            margin: toRems(config.md[variant]?.margin),
            padding: toRems(config.md[variant]?.padding),
            fontSize: toRem(config.md[variant]?.fontSize),
            ...(alignLeft && {
                paddingTop: toRem(config.alignLeft.paddingTop[size]),
                paddingLeft: 0,
                textAlign: 'right'
            }),
            ...(hasIcon &&
                variant === 'primary' && {
                    paddingLeft: 0
                }),
            ...(isMobileOnly && {
                position: 'absolute !important',
                width: '1px',
                height: '1px',
                padding: 0,
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                whiteSpace: 'nowrap',
                border: 0
            })
        },

        sup: {
            // color: theme.colors.coolDarkRed
        },
        '& .inputLabel_icon': {
            display: 'inline-flex',
            verticalAlign: 'middle',
            margin: toRems(config.xs[variant]?.icon.spacing),
            fontSize: toRem(config.xs[variant]?.icon.size),
            [theme.breakpoints.up('md')]: {
                margin: toRems(config.md[variant]?.icon.spacing),
                fontSize: toRem(config.md[variant]?.icon.size)
            }
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
