import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        title: {
            fontSize: toRem(12),
            lineHeight: toRem(24)
        },
        line: {
            width: toRem(36),
            height: toRem(1)
        }
    }
    return {
        display: 'flex',
        flexFlow: 'column',
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column-reverse',
            width: toRem(32)
        },
        '& .title': {
            textAlign: 'center',
            '& .text': {
                display: 'inline-block',
                verticalAlign: 'middle',
                fontSize: config.title.fontSize,
                lineHeight: config.title.lineHeight,
                fontWeight: 'bold',
                color: theme.colors.footer.social.title,
                letterSpacing: '0.5em',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    transform: 'rotate(-90deg)  translateX(-100%)',
                    transformOrigin: 'left top',
                    width: 'fit-content'
                }
            },
            '& .line': {
                display: 'inline-block',
                verticalAlign: 'middle',
                width: config.line.width,
                height: config.line.height,
                background: theme.colors.footer.social.title,
                border: 'none',
                margin: toRem(10),
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    width: config.line.height,
                    height: config.line.width
                }
            },
            [theme.breakpoints.down('sm')]: {
                width: config.title.lineHeight,
                margin: 'auto'
            }
        },
        '& .socialIcons': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                marginLeft: toRem(-4)
            },
            '&_item': {
                display: 'flex',
                margin: toRem(4)
            }
        }
    }
}

export default style
