// @TODO: Update these for production, settings them rather loose for ease of development
const reactQuerySettings = {
    staleTime: Infinity, // 30 sec for both client & server side, set to Infinity to never go stale
    cacheTime: Infinity, // 30 sec for both client & server side, set to Infinity to OPTIMIZE* garbage collection (no timers are running which means nothing is stopping GC), this is only in-memory cache
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000) // exponential backoff from 1ms to 30sec if retry is enabled
}

/**
 * OPTIMIZED* cache explanation:
 * cacheTime being set to Infinity on the server means that there are no timers running that could stop the cache from being garbage collected by node.
 * That was what previously caused the high consumption. If you made many requests, each time a new QueryClient would be created that would sit around for 30 seconds.
 * Now, the QueryClient should just be garbage collected like any other unused class / variable.
 */

const constants = {
    IMAGES_RATIO_TOLERANCE: 0.3,
    IMAGES_DIMENSION_TOLERANCE: 380,
    IMAGES_TRANSITION_DURATION_MS: 150,
    IMAGE_LQIP_FILTER_NAME: 'LQIP'
}

export default {
    reactQuerySettings,
    constants
}
