import { getImageMimeType } from '../../mimeType'

export const processOpenGraphImages = (images, defaultTitle) =>
    images
        ?.slice(0, 1)
        ?.filter(image => image)
        ?.map(image => (typeof image === 'string' ? { url: image } : image))
        ?.map(image => {
            if (!image.alt) {
                image.alt = image.name || defaultTitle
            }
            if (!image.type && image.url) {
                image.type = getImageMimeType(image.url)
            }
            return image
        }) || []
