import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ArrowRightIcon, Icon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './PrevNextButton.style'

const PrevNextButtonStyled = styled.div(props => ({ ...styles(props) }))

function PrevNextButton({
    className,
    buttonTitle,
    title,
    href,
    hrefAs,
    onClick,
    titleTagComponent: TitleTag,
    reverseAligned,
    ...rest
}) {
    const titleHtmlProps = useInnerHtml(title)
    if (!title || !buttonTitle || !(href || onClick)) {
        return null
    }
    return (
        <PrevNextButtonStyled className={className} reverseAligned={reverseAligned} {...rest}>
            <Link shallow scroll={false} href={href} as={hrefAs}>
                <button type="button" className="prevNextButton" onClick={onClick}>
                    <div className="prevNextButton_title">
                        <Icon icon={ArrowRightIcon} /> {buttonTitle && <span>{buttonTitle}</span>}
                    </div>
                    <TitleTag className="prevNextButton_content" {...titleHtmlProps} />
                </button>
            </Link>
        </PrevNextButtonStyled>
    )
}

PrevNextButton.propTypes = {
    titleTagComponent: PropTypes.elementType,
    className: PropTypes.string,
    title: PropTypes.string,
    buttonTitle: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    onClick: PropTypes.func,
    reverseAligned: PropTypes.bool
}

PrevNextButton.defaultProps = {
    titleTagComponent: 'h3',
    className: undefined,
    title: undefined,
    buttonTitle: 'PREVIOUS',
    href: undefined,
    hrefAs: undefined,
    onClick: undefined,
    reverseAligned: false
}

export default PrevNextButton
