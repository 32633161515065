import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useViewportWidth } from '@hmn/rtl-web-core/hooks'

import { Circle, circleVariants } from '..'
import styles from './Overall.style'

const OverallStyled = styled.div(props => ({ ...styles(props) }))

function Overall({ className, size, value, max, variant, label, labelRightWidth, ...rest }) {
    const viewportWidth = useViewportWidth()
    if (!value || !max || max <= 0 || !size) {
        return null
    }

    const percentage = (value / max) * 100
    const strokeWidth = 2

    return (
        <OverallStyled
            className={className}
            circleSize={size}
            labelRightWidth={labelRightWidth}
            variant={variant}
            viewportWidth={viewportWidth}
            {...rest}>
            <div className="overallCircle">
                <h1 className="overallText">{value.toFixed(1)}</h1>
                <Circle size={size} strokeWidth={strokeWidth} percentage={percentage} variant={circleVariants.BETA} />
            </div>
            {label && <p className="overallLabel">{label}</p>}
        </OverallStyled>
    )
}

const overallVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Overall.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    labelRightWidth: PropTypes.number,
    variant: PropTypes.oneOf([...Object.values(overallVariants)])
}

Overall.defaultProps = {
    className: undefined,
    size: undefined,
    value: undefined,
    max: undefined,
    label: undefined,
    labelRightWidth: 0,
    variant: overallVariants.ALPHA
}

export { overallVariants }
export default Overall
