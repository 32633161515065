import { useMemo } from 'react'

// It's not possible to chose prefix or suffix equal to this since we use it for pluralization
const delimiter = '||||'

// So far we only support these locale groups if Intl.PluralRules is not supported by browser, e.g. iOS Safari
const supportedLocales = ['hr', 'hr-HR', 'hr-Latn-HR', 'ba', 'bs-Latn-BA', 'bs-Cyrl-BA', 'rs', 'srl-RS', 'sr-RS']

// We must escape the prefix and the suffix because we're not allowed to use special regex characters like {} or []
const escape = token => token.replace(/[.*+?^${}()|[\]\\]/g, '$&')

// Construct custom regex token if passed as option
const constructRegexToken = options => {
    const prefix = (options && options.prefix) || '%{'
    const suffix = (options && options.suffix) || '}'

    if (prefix === delimiter || suffix === delimiter) {
        throw new RangeError(`'${delimiter} is reserved.`)
    }

    return new RegExp(`${escape(prefix)}(.*?)${escape(suffix)}`, 'g')
}

// Get plural rules for selected locale if supported
const supportedPluralRules = (count, locale = 'hr') => {
    if (typeof Intl === 'undefined') {
        if (!supportedLocales.includes(locale)) {
            throw new Error(`'${locale}' locale is not supported yet in your browser`)
        }

        const lastTwo = count % 100
        const end = lastTwo % 10

        if (lastTwo !== 11 && end === 1) {
            return 'one' // 0
        }

        if (end >= 2 && end <= 4 && !(lastTwo >= 12 && lastTwo <= 14)) {
            return 'few' // 1
        }

        return 'other' // 2
    }
    return new Intl.PluralRules(locale).select(count)
}

const getPluralTypeIndex = (count, locale) => {
    let index

    switch (supportedPluralRules(count, locale)) {
        case 'one':
            index = 0
            break
        case 'few':
            index = 1
            break
        case 'other':
            index = 2
            break
        default:
            index = 0
    }

    return index
}

const transformPhrase = (phrase, substitutions, { locale, regexToken } = {}) => {
    // We got nothing to interpolate or nothing to substitue, just return our inputs
    if (!phrase.length || substitutions === null) {
        return phrase
    }

    if (typeof phrase !== 'string') {
        throw new Error(`'${phrase}' cannot be interpolated on because it's not of String type`)
    }

    const defaultRegexToken = /%\{(.*?)\}/g
    const interpolationRegex = regexToken ? constructRegexToken(regexToken) : defaultRegexToken

    let result = phrase

    const options = typeof substitutions === 'number' ? { c: substitutions } : substitutions

    if (options.c !== null && result) {
        const texts = result.split(delimiter)
        result = (texts[getPluralTypeIndex(options.c, locale)] || texts[0]).trim()
    }

    result = result.replace(interpolationRegex, (expression, argument) => {
        if (options[argument] === 'undefined' || options[argument] === null) {
            return expression
        }
        return argument.replace(argument, options[argument])
    })

    return result
}

// const testPhrase = "Hi, your name is %{name}. You are a %{job} and you like %{hobby}."
// transformPhrase(testPhrase, { name: 'Yo', job: 'JOB', hobby: 'HOBBEY' })
// Hi, your name is Yo. You are a JOB and you like HOBBEY.'

const usePluralize = (phrase, options) => {
    const pluralizedPhrase = useMemo(() => transformPhrase(phrase, options), [phrase, options])

    return pluralizedPhrase
}

export default usePluralize
