const getComponentName = Component => Component.displayName || Component.name || 'Unknown'

export const createWrapperHOC = (Wrapper, wrappedNamePrefix = '') => {
    const wrapperName = getComponentName(Wrapper)
    const displayName = wrappedNamePrefix || `with${wrapperName}`

    const WrapperHOC = (Component, wrapperProps) => {
        // @TODO: Add capability of extracting Wrapper's props from Component's props
        function Wrapped(props) {
            return (
                <Wrapper {...wrapperProps}>
                    <Component {...props} />
                </Wrapper>
            )
        }

        // Format for display in DevTools
        const name = getComponentName(Component)
        Wrapped.displayName = `${displayName}(${name})`

        return Wrapped
    }
    // Format for display in DevTools
    WrapperHOC.displayName = `hocWrapper(${wrapperName})`

    return WrapperHOC
}
