import getConfig from 'next/config'

const { publicRuntimeConfig = {} } = getConfig()

const { xClientName: currentClient = process.env.NEXT_PUBLIC_API_CLIENT } = publicRuntimeConfig

const initialDataHardkooNet = [
    {
        id: '87d630da-92c1-11eb-822a-0242ac120012',
        name: '[custom] Square',
        slug: 'custom-square',
        method: 'fill',
        aspect_ratio_name: 'Ratio 1:1',
        aspect_ratio_value: 1,
        filters: [],
        instances: [
            {
                id: 'a6deacb8-9d08-11eb-9a43-cedfb09875dd',
                max_width: 100,
                max_height: 100
            },
            {
                id: 'a6debb68-9d08-11eb-9c1f-cedfb09875dd',
                max_width: 360,
                max_height: 360
            },
            {
                id: 'a6debfaa-9d08-11eb-ba19-cedfb09875dd',
                max_width: 480,
                max_height: 480
            },
            {
                id: 'a6dec202-9d08-11eb-91e0-cedfb09875dd',
                max_width: 768,
                max_height: 768
            },
            {
                id: 'a6dec3e2-9d08-11eb-ae80-cedfb09875dd',
                max_width: 1024,
                max_height: 1024
            },
            {
                id: 'a6dec59a-9d08-11eb-9105-cedfb09875dd',
                max_width: 1280,
                max_height: 1280
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/87d630da-92c1-11eb-822a-0242ac120012/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'ba03ebd6-9d09-11eb-8a41-cedfb09875dd',
        name: '[custom] Wide-screen',
        slug: 'custom-wide-screen',
        method: 'fill',
        aspect_ratio_name: 'Ratio 16:9',
        aspect_ratio_value: 1.7777778,
        filters: [],
        instances: [
            {
                id: 'ba0546a2-9d09-11eb-8621-cedfb09875dd',
                max_width: 100,
                max_height: 57
            },
            {
                id: 'ba054a44-9d09-11eb-9efc-cedfb09875dd',
                max_width: 360,
                max_height: 203
            },
            {
                id: 'ba054c6a-9d09-11eb-9f33-cedfb09875dd',
                max_width: 480,
                max_height: 270
            },
            {
                id: 'ba054e7c-9d09-11eb-870c-cedfb09875dd',
                max_width: 768,
                max_height: 432
            },
            {
                id: 'ba055070-9d09-11eb-a6aa-cedfb09875dd',
                max_width: 1024,
                max_height: 576
            },
            {
                id: 'ba0555f2-9d09-11eb-b6b1-cedfb09875dd',
                max_width: 1280,
                max_height: 720
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/ba03ebd6-9d09-11eb-8a41-cedfb09875dd/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'e6f823a4-9d0a-11eb-a11c-cedfb09875dd',
        name: '[custom] Ultra-wide',
        slug: 'custom-ultra-wide',
        method: 'fill',
        aspect_ratio_name: 'Ratio 21:9',
        aspect_ratio_value: 2.3333333,
        filters: [],
        instances: [
            {
                id: 'e6f83920-9d0a-11eb-b3b2-cedfb09875dd',
                max_width: 100,
                max_height: 43
            },
            {
                id: 'e6f83aec-9d0a-11eb-9d65-cedfb09875dd',
                max_width: 360,
                max_height: 155
            },
            {
                id: 'e6f83c5e-9d0a-11eb-8952-cedfb09875dd',
                max_width: 480,
                max_height: 206
            },
            {
                id: 'e6f83dd0-9d0a-11eb-a8d6-cedfb09875dd',
                max_width: 768,
                max_height: 330
            },
            {
                id: 'e6f83f1a-9d0a-11eb-905b-cedfb09875dd',
                max_width: 1024,
                max_height: 439
            },
            {
                id: 'e6f8406e-9d0a-11eb-b666-cedfb09875dd',
                max_width: 1280,
                max_height: 549
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/e6f823a4-9d0a-11eb-a11c-cedfb09875dd/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: '6f1b75b0-9d0b-11eb-b4b2-cedfb09875dd',
        name: '[custom] Landscape',
        slug: 'custom-landscape',
        method: 'fill',
        aspect_ratio_name: 'Ratio 3:2',
        aspect_ratio_value: 1.5,
        filters: [],
        instances: [
            {
                id: '6f1ba210-9d0b-11eb-9ff9-cedfb09875dd',
                max_width: 100,
                max_height: 67
            },
            {
                id: '6f1ba512-9d0b-11eb-a49f-cedfb09875dd',
                max_width: 360,
                max_height: 240
            },
            {
                id: '6f1ba788-9d0b-11eb-a717-cedfb09875dd',
                max_width: 480,
                max_height: 320
            },
            {
                id: '6f1ba9b8-9d0b-11eb-a3c5-cedfb09875dd',
                max_width: 768,
                max_height: 512
            },
            {
                id: '6f1babc0-9d0b-11eb-a918-cedfb09875dd',
                max_width: 1024,
                max_height: 683
            },
            {
                id: '6f1badc8-9d0b-11eb-90ad-cedfb09875dd',
                max_width: 1280,
                max_height: 854
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/6f1b75b0-9d0b-11eb-b4b2-cedfb09875dd/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'e3458688-9d0b-11eb-b2f5-cedfb09875dd',
        name: '[custom] Portrait',
        slug: 'custom-portrait',
        method: 'fill',
        aspect_ratio_name: 'Ratio 2:3',
        aspect_ratio_value: 0.6666667,
        filters: [],
        instances: [
            {
                id: 'e3459f1a-9d0b-11eb-ba89-cedfb09875dd',
                max_width: 100,
                max_height: 150
            },
            {
                id: 'e345a2bc-9d0b-11eb-81a8-cedfb09875dd',
                max_width: 360,
                max_height: 540
            },
            {
                id: 'e345a546-9d0b-11eb-acb2-cedfb09875dd',
                max_width: 480,
                max_height: 720
            },
            {
                id: 'e345a780-9d0b-11eb-afa5-cedfb09875dd',
                max_width: 768,
                max_height: 1152
            },
            {
                id: 'e345a9f6-9d0b-11eb-963a-cedfb09875dd',
                max_width: 1024,
                max_height: 1536
            },
            {
                id: 'e345ac26-9d0b-11eb-9a19-cedfb09875dd',
                max_width: 1280,
                max_height: 1920
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/e3458688-9d0b-11eb-b2f5-cedfb09875dd/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: '42bc9a34-9d0c-11eb-a404-cedfb09875dd',
        name: '[custom] No aspect ratio image',
        slug: 'custom-no-aspect-ratio-image',
        method: 'fit',
        aspect_ratio_name: null,
        aspect_ratio_value: null,
        filters: [],
        instances: [
            {
                id: '42bcb0c8-9d0c-11eb-a6c9-cedfb09875dd',
                max_width: 100,
                max_height: 100
            },
            {
                id: '42bcb35c-9d0c-11eb-9f9b-cedfb09875dd',
                max_width: 360,
                max_height: 360
            },
            {
                id: '42bcb56e-9d0c-11eb-98ab-cedfb09875dd',
                max_width: 480,
                max_height: 480
            },
            {
                id: '42bcb76c-9d0c-11eb-86af-cedfb09875dd',
                max_width: 768,
                max_height: 768
            },
            {
                id: '42bcba00-9d0c-11eb-aec9-cedfb09875dd',
                max_width: 1024,
                max_height: 1024
            },
            {
                id: '42bcbbf4-9d0c-11eb-8f97-cedfb09875dd',
                max_width: 1280,
                max_height: 1280
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/42bc9a34-9d0c-11eb-a404-cedfb09875dd/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    }
]

const initialDataHardkooZena = [
    {
        id: '2dd14004-38e2-11eb-a900-0242ac15000f',
        name: '[custom] Original aspect ratio',
        slug: 'custom-original-aspect-ratio',
        method: 'fit',
        aspect_ratio_name: null,
        aspect_ratio_value: null,
        filters: [],
        instances: [
            {
                id: '2dd15120-38e2-11eb-916a-0242ac15000f',
                max_width: 930,
                max_height: 620
            },
            {
                id: '2dd1533c-38e2-11eb-8306-0242ac15000f',
                max_width: 1600,
                max_height: 1200
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/2dd14004-38e2-11eb-a900-0242ac15000f/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'cbf7fd72-38e2-11eb-b297-0242ac15000f',
        name: '[custom] Square',
        slug: 'custom-square',
        method: 'fill',
        aspect_ratio_name: 'Ratio 1:1',
        aspect_ratio_value: 1,
        filters: [],
        instances: [
            {
                id: 'cbf80c04-38e2-11eb-ac63-0242ac15000f',
                max_width: 500,
                max_height: 500
            },
            {
                id: 'cbf80ec0-38e2-11eb-aca8-0242ac15000f',
                max_width: 1000,
                max_height: 1000
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/cbf7fd72-38e2-11eb-b297-0242ac15000f/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: '6bb44fb8-38e4-11eb-b16c-0242ac15000f',
        name: '[custom] 2:3',
        slug: 'custom-2-3',
        method: 'fill',
        aspect_ratio_name: 'Ratio 2:3',
        aspect_ratio_value: 0.6666667,
        filters: [],
        instances: [
            {
                id: '6bb457b0-38e4-11eb-bb81-0242ac15000f',
                max_width: 200,
                max_height: 300
            },
            {
                id: '6bb45922-38e4-11eb-9969-0242ac15000f',
                max_width: 500,
                max_height: 750
            },
            {
                id: '6bb45a3a-38e4-11eb-a2a3-0242ac15000f',
                max_width: 1000,
                max_height: 1500
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/6bb44fb8-38e4-11eb-b16c-0242ac15000f/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'a861ff3c-38e4-11eb-8556-0242ac15000f',
        name: '[custom] 4:5',
        slug: 'custom-4-5',
        method: 'fill',
        aspect_ratio_name: 'Ratio 4:5',
        aspect_ratio_value: 0.8,
        filters: [],
        instances: [
            {
                id: 'a8620806-38e4-11eb-bdec-0242ac15000f',
                max_width: 200,
                max_height: 250
            },
            {
                id: 'a862098c-38e4-11eb-b60d-0242ac15000f',
                max_width: 500,
                max_height: 625
            },
            {
                id: 'a8620ab8-38e4-11eb-a28e-0242ac15000f',
                max_width: 1000,
                max_height: 1250
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/a861ff3c-38e4-11eb-8556-0242ac15000f/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    },
    {
        id: 'db3ec0a2-38e4-11eb-949e-0242ac15000f',
        name: '[custom] 3:2',
        slug: 'custom-3-2',
        method: 'fill',
        aspect_ratio_name: 'Ratio 3:2',
        aspect_ratio_value: 1.5,
        filters: [],
        instances: [
            {
                id: 'db3ec836-38e4-11eb-ba87-0242ac15000f',
                max_width: 300,
                max_height: 200
            },
            {
                id: 'db3ec9a8-38e4-11eb-ba45-0242ac15000f',
                max_width: 600,
                max_height: 400
            },
            {
                id: 'db3ecb38-38e4-11eb-8317-0242ac15000f',
                max_width: 1200,
                max_height: 800
            }
        ],
        client_owned: true,
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/db3ec0a2-38e4-11eb-949e-0242ac15000f/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ]
    }
]

const initialDataHardkooRtl = [
    {
        slug: 'custom-landscape-1',
        id: '250b251e-2baa-11ec-b283-3a925a8303a3',
        name: '[custom] Landscape',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '250c10be-2baa-11ec-8a01-3a925a8303a3',
                max_width: 100,
                max_height: 67
            },
            {
                id: '250c7b62-2baa-11ec-8710-3a925a8303a3',
                max_width: 360,
                max_height: 240
            },
            {
                id: '250c7e8c-2baa-11ec-b02b-3a925a8303a3',
                max_width: 480,
                max_height: 320
            },
            {
                id: '250c81c0-2baa-11ec-a9b4-3a925a8303a3',
                max_width: 768,
                max_height: 512
            },
            {
                id: '250c84c2-2baa-11ec-acfc-3a925a8303a3',
                max_width: 1024,
                max_height: 683
            },
            {
                id: '250c87c4-2baa-11ec-b71b-3a925a8303a3',
                max_width: 1280,
                max_height: 854
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/250b251e-2baa-11ec-b283-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio 3:2',
        aspect_ratio_value: 1.5,
        client_owned: true
    },
    {
        slug: 'custom-no-aspect-ratio-image-1',
        id: '2545d47a-2baa-11ec-9bfe-3a925a8303a3',
        name: '[custom] No aspect ratio image',
        method: 'fit',
        filters: [],
        instances: [
            {
                id: '2545d95c-2baa-11ec-b354-3a925a8303a3',
                max_width: 100,
                max_height: 100
            },
            {
                id: '2545dce0-2baa-11ec-8f44-3a925a8303a3',
                max_width: 360,
                max_height: 360
            },
            {
                id: '2545dff6-2baa-11ec-9b3f-3a925a8303a3',
                max_width: 480,
                max_height: 480
            },
            {
                id: '2545e33e-2baa-11ec-bd16-3a925a8303a3',
                max_width: 768,
                max_height: 768
            },
            {
                id: '2545e6f4-2baa-11ec-a4c8-3a925a8303a3',
                max_width: 1024,
                max_height: 1024
            },
            {
                id: '2545ea46-2baa-11ec-9096-3a925a8303a3',
                max_width: 1280,
                max_height: 1280
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/2545d47a-2baa-11ec-9bfe-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: null,
        aspect_ratio_value: null,
        client_owned: true
    },
    {
        slug: 'custom-portrait-1',
        id: '25639578-2baa-11ec-a5ee-3a925a8303a3',
        name: '[custom] Portrait',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '25639ba4-2baa-11ec-a2ad-3a925a8303a3',
                max_width: 100,
                max_height: 150
            },
            {
                id: '25639fbe-2baa-11ec-93cf-3a925a8303a3',
                max_width: 360,
                max_height: 540
            },
            {
                id: '2563a2d4-2baa-11ec-9aa7-3a925a8303a3',
                max_width: 480,
                max_height: 720
            },
            {
                id: '2563a5e0-2baa-11ec-b6a7-3a925a8303a3',
                max_width: 768,
                max_height: 1152
            },
            {
                id: '2563a8f6-2baa-11ec-8ee4-3a925a8303a3',
                max_width: 1024,
                max_height: 1536
            },
            {
                id: '2563acd4-2baa-11ec-9888-3a925a8303a3',
                max_width: 1280,
                max_height: 1920
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/25639578-2baa-11ec-a5ee-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio 2:3',
        aspect_ratio_value: 0.6666667,
        client_owned: true
    },
    {
        slug: 'custom-square-1',
        id: '258621ce-2baa-11ec-9b66-3a925a8303a3',
        name: '[custom] Square',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '25862656-2baa-11ec-bbf5-3a925a8303a3',
                max_width: 100,
                max_height: 100
            },
            {
                id: '258629d0-2baa-11ec-8ac3-3a925a8303a3',
                max_width: 360,
                max_height: 360
            },
            {
                id: '25862cdc-2baa-11ec-b2c7-3a925a8303a3',
                max_width: 480,
                max_height: 480
            },
            {
                id: '25862fe8-2baa-11ec-897e-3a925a8303a3',
                max_width: 768,
                max_height: 768
            },
            {
                id: '258632f4-2baa-11ec-9be2-3a925a8303a3',
                max_width: 1024,
                max_height: 1024
            },
            {
                id: '258635f6-2baa-11ec-88cc-3a925a8303a3',
                max_width: 1280,
                max_height: 1280
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/258621ce-2baa-11ec-9b66-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio 1:1',
        aspect_ratio_value: 1,
        client_owned: true
    },
    {
        slug: 'custom-ultra-wide-1',
        id: '25a921a6-2baa-11ec-8afa-3a925a8303a3',
        name: '[custom] Ultra-wide',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '25a92610-2baa-11ec-ba68-3a925a8303a3',
                max_width: 100,
                max_height: 43
            },
            {
                id: '25a9296c-2baa-11ec-83ea-3a925a8303a3',
                max_width: 360,
                max_height: 155
            },
            {
                id: '25a92c82-2baa-11ec-b5da-3a925a8303a3',
                max_width: 480,
                max_height: 206
            },
            {
                id: '25a930a6-2baa-11ec-94a6-3a925a8303a3',
                max_width: 768,
                max_height: 330
            },
            {
                id: '25a933d0-2baa-11ec-b7e2-3a925a8303a3',
                max_width: 1024,
                max_height: 439
            },
            {
                id: '25a936d2-2baa-11ec-8e02-3a925a8303a3',
                max_width: 1280,
                max_height: 549
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/25a921a6-2baa-11ec-8afa-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio 21:9',
        aspect_ratio_value: 2.3333333,
        client_owned: true
    },
    {
        slug: 'custom-wide-screen-1',
        id: '25cafe84-2baa-11ec-b2bc-3a925a8303a3',
        name: '[custom] Wide-screen',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '25cb030c-2baa-11ec-9878-3a925a8303a3',
                max_width: 100,
                max_height: 57
            },
            {
                id: '25cb0672-2baa-11ec-b330-3a925a8303a3',
                max_width: 360,
                max_height: 203
            },
            {
                id: '25cb0992-2baa-11ec-906f-3a925a8303a3',
                max_width: 480,
                max_height: 270
            },
            {
                id: '25cb0d16-2baa-11ec-ae16-3a925a8303a3',
                max_width: 768,
                max_height: 432
            },
            {
                id: '25cb1022-2baa-11ec-b57c-3a925a8303a3',
                max_width: 1024,
                max_height: 576
            },
            {
                id: '25cb1374-2baa-11ec-ad52-3a925a8303a3',
                max_width: 1280,
                max_height: 720
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/25cafe84-2baa-11ec-b2bc-3a925a8303a3/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio 16:9',
        aspect_ratio_value: 1.7777778,
        client_owned: true
    },
    {
        slug: 'custom-ht',
        id: '82051178-8837-11ec-b27c-2e5aa192bc64',
        name: '[custom] HT',
        method: 'fill',
        filters: [],
        instances: [
            {
                id: '8206cd1a-8837-11ec-ae54-2e5aa192bc64',
                max_width: 594,
                max_height: 880
            }
        ],
        url_template:
            'https://d19p4plxg0u3gz.cloudfront.net/{image_id}/v/82051178-8837-11ec-b27c-2e5aa192bc64/{instance_max_width}x{instance_max_height}-{instance_id}.{format_extension}',
        formats: [
            {
                label: 'JPEG',
                extension: 'jpeg',
                type: 'image/jpeg'
            },
            {
                label: 'WebP',
                extension: 'webp',
                type: 'image/webp'
            }
        ],
        priority: 1,
        aspect_ratio_name: 'Ratio HT',
        aspect_ratio_value: 0.675,
        client_owned: true
    }
]

const getInitialDataHardkoo = client => {
    if (client === 'nethr') {
        return initialDataHardkooNet
    }

    if (client === 'rtlhr' || client === 'danashr') {
        return initialDataHardkooRtl
    }

    return initialDataHardkooZena
}

const useImagesConfig = () => ({
    imagesConfig: getInitialDataHardkoo(currentClient),
    isLoaded: true
})

export { initialDataHardkooNet, initialDataHardkooZena, initialDataHardkooRtl }

export default useImagesConfig
