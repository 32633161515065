/* eslint-disable @typescript-eslint/naming-convention */

import { keyframes } from '@emotion/react'

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'
import type { ParsedConvergerData } from './Converger.helpers'
import { PositionX, PositionY } from './Converger.interface'

export type StyleProps = {
    theme?: any
    creative?: ParsedConvergerData['creative']
    dimensions?: ParsedConvergerData['dimensions']
    numOfTiles: number
}

const animationDuration = '1s'

const closeTopDoor = keyframes`
    0% { top: -100%; animation-timing-function: ease-in; }
    50% { top: 0; animation-timing-function: ease-out; }
    65% { top: -8%; animation-timing-function: ease-in; }
    80% { top: 0; animation-timing-function: ease-out; }
    90% { top: -2%; animation-timing-function: ease-in; }
    100% { top: 0; }`
const closeLeftDoor = keyframes`
    0% { left: -50%; animation-timing-function: ease-in; }
    50% { left: 0; animation-timing-function: ease-out; }
    65% { left: -4%; animation-timing-function: ease-in; }
    80% { left: 0; animation-timing-function: ease-out; }
    90% { left: -1%; animation-timing-function: ease-in; }
    100% { left: 0; }`
const closeRightDoor = keyframes`
    0% { right: -50%; animation-timing-function: ease-in; }
    50% { right: 0; animation-timing-function: ease-out; }
    65% { right: -4%; animation-timing-function: ease-in; }
    80% { right: 0; animation-timing-function: ease-out; }
    90% { right: -1%; animation-timing-function: ease-in; }
    100% { right: 0; }`

// dynamically generate nth-child styles
const tileNthChildStyles = (tilesNum: number): Record<string, any> => {
    return Array.from({ length: tilesNum }).reduce<Record<string, any>>((acc, _, i) => {
        const index = i + 1
        acc[`&:nth-of-type(${index})`] = {
            backgroundPosition: `50% calc(${-i * (100 / tilesNum)}vh + var(--conv-offset, 0%))`
        }
        return acc
    }, {})
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = ({ theme, creative, numOfTiles, dimensions }) => {
    const { fileImage, fileMobileImage, imageBackgroundColor } = creative

    return {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10003,
        width: '100%',
        height: '100lvh',

        '.container': {
            pointerEvents: 'all',
            display: 'block',
            width: '100%',
            height: '100lvh',

            '&:is(:hover, .is_active)': {
                '.tiles.is_left': {
                    animation: `${closeTopDoor} ${animationDuration} forwards`
                }
            },

            [theme.breakpoints.up('md')]: {
                '&:is(:hover, &.is_active)': {
                    // change animation for desktop
                    '.tiles.is_left': {
                        animation: `${closeLeftDoor} ${animationDuration} forwards`
                    },

                    '.tiles.is_right': {
                        animation: `${closeRightDoor} ${animationDuration} forwards`
                    }
                }
            }
        },

        '.tiles': {
            position: 'absolute',
            width: '100%',
            height: '100lvh',
            overflow: 'hidden',

            backgroundColor: '#fff',

            '&.is_right': {
                display: 'none'
            },

            '@media (min-width: 768px)': {
                width: '50%',
                top: 0,

                '&.is_left': {
                    left: 0
                },

                '&.is_right': {
                    right: 0,
                    display: 'block',

                    '> .tile': {
                        marginLeft: '-100%'
                    }
                }
            }
        },

        '.tile': {
            width: '100%',
            height: `${100 / numOfTiles}%`,
            background: `url(${fileMobileImage || fileImage}) no-repeat center center`,
            backgroundColor: `${imageBackgroundColor}`,
            // backgroundColor: 'red',
            backgroundSize: fileMobileImage ? 'min(140%, 70vh)' : 'min(240%, 140vh)',
            pointerEvents: 'none',

            ...tileNthChildStyles(numOfTiles),

            [theme.breakpoints.up('md')]: {
                width: '200%',
                height: `${100 / numOfTiles}%`,
                backgroundImage: `url(${fileImage})`,
                // backgroundSize: 'var(--conv-size, 100%)'
                backgroundSize: 'min(140%, 150vh)'
            }
        }
    }
}

const Styled = createStyledComponent({
    tag: 'div',
    styles,
    blockProps: ['height', 'width', 'dimensions', 'numOfTiles']
})
export default Styled

type CloseButtonProps = {
    theme?: any
    positionY?: PositionY
    positionX?: PositionX
    size?: number
}

export const StyledCloseButton = createStyledComponent({
    styles: ({ size = 30 }: CloseButtonProps) => {
        const offset = size / 2

        return {
            pointerEvents: 'all',

            position: 'absolute',
            top: offset,
            right: offset,
            zIndex: 10004,

            width: size + 'px',
            height: size + 'px'
        }
    },
    tag: 'button',
    forwardProps: ['onClick']
})
