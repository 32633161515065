/**
 * Check if number.
 * @param n
 * @returns {boolean}
 */

const isNumeric = (n: any): boolean =>
    n !== '' && n !== null && !Array.isArray(n) && !Number.isNaN(+n) && Number.isFinite(+n)

export default isNumeric
