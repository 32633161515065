const sitemapToBreadcrumbs = sitemap => {
    let basePath = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}`
    if (sitemap?.[0]?.slug === 'naslovnica') {
        return [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@type': 'WebPage',
                    name: 'Naslovnica',
                    url: basePath,
                    id: basePath
                }
            }
        ]
    }
    return [
        {
            title: 'Naslovnica'
        },
        ...(sitemap || [])
    ]?.map(({ title, slug }, index) => {
        if (slug) {
            basePath += `/${slug}`
        }
        return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@type': 'WebPage',
                name: title,
                url: basePath,
                id: basePath
            }
        }
    })
}

export default sitemapToBreadcrumbs
