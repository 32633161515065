/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

import { useEnvironment, useNavigatedCallback } from '../../../hooks'

function OneSignal({ appId, safariAppId }) {
    const { thirdPartyEnabled, isLocalhost } = useEnvironment()
    const [render, setRender] = useState(false)
    useNavigatedCallback(() => {
        setRender(true)
    })
    if (!render || !thirdPartyEnabled || !process.browser || !appId || isLocalhost) {
        return null
    }
    return (
        <>
            <Script
                id="oneSignalSDK"
                src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
                strategy="lazyOnload"
                defer
            />
            <Script
                id="oneSignalScript"
                strategy="lazyOnload"
                defer
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: `window.OneSignal = window.OneSignal || [];
                    OneSignal.push(function() {
                        OneSignal.init({
                            appId: "${appId}", ${safariAppId ? `\nsafari_web_id: "${safariAppId}",` : ''}
                            notifyButton: {
                                enable: true,
                            },
                        });
                    });
                    window.upScoreOneSignalRetries = 0;
                    function setUpScoreOneSignal(userId){
                        if(upScore != null && upScore.data != null){
                            upScore.data.custom_onesignal = userId;
                        }else if(window.upScoreOneSignalRetries <= 10){
                            window.setTimeout(setUpScoreOneSignal, 3000);
                        }
                        window.upScoreOneSignalRetries += 1;
                    }
                    OneSignal.push(function() {
                        OneSignal.getUserId(setUpScoreOneSignal);
                    });`
                }}
            />
        </>
    )
}

OneSignal.propTypes = {
    appId: PropTypes.string,
    safariAppId: PropTypes.string
}

OneSignal.defaultProps = {
    appId: undefined,
    safariAppId: undefined
}

export default OneSignal
