import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { toRem, toRems } from '../../../../helpers/theme'

const normalizeDimension = dimension =>
    typeof dimension === 'string' && dimension?.includes('auto')
        ? dimension
        : parseInt(`${dimension}`.replace(/[^0-9]+/g, ''), 10)

const shimmer = keyframes({
    '0%': {
        backgroundPosition: 'top right'
    },
    '100%': {
        backgroundPosition: 'top left'
    }
})

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, imageWidth, imageHeight, rounded, ratio, fullHeight, isAmp }) => {
    const width = normalizeDimension(imageWidth)

    const height = normalizeDimension(imageHeight)

    const getRatio = () => {
        if (ratio) {
            return `${(1 / ratio) * 100}%`
        }

        if (!Number.isNaN(imageWidth) || !Number.isNaN(imageHeight)) {
            if (typeof imageHeight === 'string' && imageHeight?.includes('auto')) {
                return '100%'
            }

            return `${(height / width) * 100}%`
        }

        return '100%'
    }

    return {
        position: 'relative',
        width: '100%',
        ...(fullHeight && {
            height: '100%'
        }),
        paddingBottom: !isAmp && getRatio(),
        borderRadius: rounded ? toRem(4) : 0,
        '&.isAmp': {
            paddingBottom: 0
        },
        ...(!isAmp && {
            '& .shimmerFallback': {
                background: `
                    linear-gradient(
                        to right,
                        rgb(246, 247, 248) 0%,
                        rgb(237, 238, 241) 20%,
                        rgb(246, 247, 248) 40%,
                        rgb(246, 247, 248) 100%
                )`,
                // Note: This worked before, but now works without.
                // top: 0,
                // left: 0,
                // bottom: 0,
                // right: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                backgroundSize: '2250px 2250px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0 0',
                animation: `${shimmer} 1.5s ease-in-out infinite`
            }
        }),
        '& .ampImage img': {
            margin: 0,
            objectFit: 'contain'
        },
        '.image_info': {
            position: 'absolute',
            bottom: 0,
            fontSize: toRem(11),
            backgroundColor: theme.colors.background.body.inverted,
            color: theme.colors.text.white,
            padding: toRems([5, 10, 5, 10]),
            opacity: 0.7
        }
    }
}

export default style

export const ProgressiveImageStyled = styled.div(props => ({ ...style(props) }))
