import { useSelector } from 'react-redux'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { useEnvironment } from '../../../hooks'

function LinkerPixel({ campaignId }) {
    const { thirdPartyEnabled } = useEnvironment()
    const { linkerPixel: hasConsent } = useSelector(state => state.user)

    if (!hasConsent || !thirdPartyEnabled) {
        return null
    }
    return <Image style={{ display: 'none' }} src={`https://linker.hr/m.php?campaign=${campaignId}`} />
}

LinkerPixel.propTypes = {
    campaignId: PropTypes.string.isRequired
}

export default LinkerPixel
