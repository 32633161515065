/*
 @NOTE
 INITIAL LOAD action is used to indicate whether or not initial load has occured
 flag is used in useGemius hook
*/
const constants = {
    SET_LINKER_LW_LOADED: 'INITIAL_LOAD/SET_LINKER_LW_LOADED',
    SET_LINKER_SPLIDE_LOADED: 'INITIAL_LOAD/SET_LINKER_SPLIDE_LOADED',
    SET_UPSCORE_LOADED: 'INITIAL_LOAD/SET_SET_UPSCORE_LOADED',
    SET_TYPEKIT_LOADED: 'INITIAL_LOAD/SET_TYPEKIT_LOADED',
    SET_ENVIRONMENT: 'INITIAL_LOAD/SET_ENVIRONMENT',
    SET_UPSCORE_DOMAIN: 'INITIAL_LOAD/SET_UPSCORE_DOMAIN',
    SET_UPSCORE_READY: 'INITIAL_LOAD/SET_UPSCORE_READY'
}

const actions = {
    setLinkerLwLoaded: linkerLwLoaded => ({ type: constants.SET_LINKER_LW_LOADED, linkerLwLoaded }),
    setLinkerSplideLoaded: linkerSplideLoaded => ({ type: constants.SET_LINKER_SPLIDE_LOADED, linkerSplideLoaded }),
    setUpscoreLoaded: upScoreLoaded => ({ type: constants.SET_UPSCORE_LOADED, upScoreLoaded }),
    setUpscoreDomain: upScoreDomain => ({ type: constants.SET_UPSCORE_DOMAIN, upScoreDomain }),
    setUpscoreReady: upScoreReady => ({ type: constants.SET_UPSCORE_READY, upScoreReady }),
    setEnvironment: environment => ({ type: constants.SET_ENVIRONMENT, environment })
}

export { constants as InitialLoadActionTypes, actions as InitialLoadActions }
