import PropTypes from 'prop-types'

function ConditionalWrapper({ condition, Wrapper, children, ...rest }) {
    return condition ? <Wrapper {...rest}>{children}</Wrapper> : children
}

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    Wrapper: PropTypes.elementType.isRequired
}

export default ConditionalWrapper
