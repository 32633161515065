import { useCallback } from 'react'

import { useNativeAdDispatch } from '../../../../dispatchers/ads'
import { useRouteChangeStart } from '../../../../hooks/useRouteChange'
import useWindowAttachedValue from '../../../../hooks/useWindowAttachedValue'
import { parseConvergerData } from './Converger.helpers'
import { ConvergerData } from './Converger.interface'

export const INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME = 'inj_conv_script'
export const CLEAR_CONVERGER_CALLBACK_KEY = 'clearConverger'
const ConvergerDataProvider = () => {
    const setData = useNativeAdDispatch('converger')

    const setConvergerCallback = useCallback(
        (e: ConvergerData) => {
            // console.log('event', e)
            // @TODO: implement error Converger with message in case google admanager preview query param is set
            // if it errors on regular client, just log the error
            const parsed = parseConvergerData(e)
            // console.log('Converger data', { sent: e, parsed })
            setData(parsed)
        },
        [setData]
    )

    const clearConvergerCallback = useCallback(() => {
        if (typeof window !== 'undefined') {
            window[INJECTED_CONVERGER_SCRIPTS_VARIABLE_NAME] = {}
        }
        setData(null)
    }, [setData])

    useRouteChangeStart(clearConvergerCallback)

    // useEffect(() => {
    //     setConvergerCallback(mockDataConverger)
    // }, [setConvergerCallback])

    // important so the template can attach itself to the app
    useWindowAttachedValue(setConvergerCallback, 'setConverger')
    // important so converger can close itself via close button
    useWindowAttachedValue(clearConvergerCallback, CLEAR_CONVERGER_CALLBACK_KEY)
    return null
}

export default ConvergerDataProvider
