import { Ad, ClosePosition, InscreenPostion, SourceType, YesOrNo } from '../EternalAds.interface'

export type InscreenData = {
    ad: Ad
    clickTagName?: string
    clickUrl?: string
    clickUrlUnesc: string
    closePosition: ClosePosition
    fileImage?: string
    fileUrl?: string
    hasGlobalClickTag: YesOrNo
    height: string
    htmlContent?: string
    offsetX: string
    offsetY: string
    openInNewTab: YesOrNo
    position: InscreenPostion
    sourceType: SourceType
    thirdPartyImpressionUrl: string
    thirdPartyTagsHtml?: string
    viewUrlUnesc: string
    width: string
}

export enum PositionX {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center'
}
export enum PositionY {
    TOP = 'top',
    BOTTOM = 'bottom',
    CENTER = 'center'
}

export const invertPosition = <T extends PositionX | PositionY>(position: T): T => {
    switch (position) {
        case PositionX.LEFT:
            return PositionX.RIGHT as T
        case PositionX.RIGHT:
            return PositionX.LEFT as T
        case PositionY.TOP:
            return PositionY.BOTTOM as T
        case PositionY.BOTTOM:
            return PositionY.TOP as T
        default:
            return position
    }
}

export const positionMap: { [key in InscreenPostion]: [PositionX, PositionY] } = {
    [InscreenPostion.TOP]: [PositionX.CENTER, PositionY.TOP],
    [InscreenPostion.TOP_RIGHT]: [PositionX.RIGHT, PositionY.TOP],
    [InscreenPostion.RIGHT]: [PositionX.RIGHT, PositionY.CENTER],
    [InscreenPostion.BOTTOM_RIGHT]: [PositionX.RIGHT, PositionY.BOTTOM],
    [InscreenPostion.BOTTOM]: [PositionX.CENTER, PositionY.BOTTOM],
    [InscreenPostion.BOTTOM_LEFT]: [PositionX.LEFT, PositionY.BOTTOM],
    [InscreenPostion.LEFT]: [PositionX.LEFT, PositionY.CENTER],
    [InscreenPostion.TOP_LEFT]: [PositionX.LEFT, PositionY.TOP],
    [InscreenPostion.CENTER]: [PositionX.CENTER, PositionY.CENTER]
}

export type ParsedInscreenDimensions = {
    height: number | undefined
    width: number | undefined
    positionX: PositionX
    positionY: PositionY
    closePositionX: PositionX
    closePositionY: PositionY
    closeHidden: boolean
}
