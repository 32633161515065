import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
// eslint-disable-next-line
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { SocialShareButton } from '..'
import styles from './Share.style'

const ShareStyled = styled.ul(props => ({ ...styles(props) }))

function Share({ shareUrl, social, ...rest }) {
    const uid = useUIDSeed()
    const router = useRouter()
    const { asPath, pathname } = router || {}

    if (!social?.length) {
        return null
    }

    const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${asPath || pathname}`

    return (
        <ShareStyled {...rest}>
            {social.map((item, index) => (
                <SocialShareButton social={item} shareUrl={url} key={uid(item.id || index)} />
            ))}
        </ShareStyled>
    )
}

Share.propTypes = {
    shareUrl: PropTypes.string,
    social: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.string)])
}

Share.defaultProps = {
    shareUrl: undefined,
    social: undefined
}

export default withErrorBoundary(Share, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SocialShare]: ', error, componentStack)
    }
})
