import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant, isHeadingPink }) => {
    const config = {
        sm: {
            paddingTop: toRem(25),
            borderTop: `1px solid ${theme.colors.comments.item.divider}`,
            flexDirection: 'column',
            commentsItem: {
                borderLeft: 0,
                minHeight: toRem(60),
                paddingLeft: 0
            }
        },
        md: {
            paddingTop: 0,
            borderTop: 0,
            flexDirection: 'row',
            commentsItem: {
                borderLeft: `1px solid ${theme.colors.comments.border.default}`,
                minHeight: toRem(80),
                paddingLeft: toRem(30.5)
            }
        },
        variant: {
            beta: {
                commentsItem: {
                    body: {
                        paddingTop: toRem(4)
                    }
                }
            }
        }
    }
    return {
        display: 'flex',
        flexDirection: config?.sm.flexDirection,
        borderTop: config?.sm.borderTop,
        paddingTop: config?.sm.paddingTop,
        [theme.breakpoints.up('md')]: {
            flexDirection: config?.md.flexDirection,
            borderTop: config?.md.borderTop,
            paddingTop: config?.md.paddingTop,
            marginBottom: toRem(30)
        },
        '& .commentsItem': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            paddingLeft: config?.sm.commentsItem.paddingLeft,
            borderLeft: config?.sm.commentsItem.borderLeft,
            minHeight: config?.sm.commentsItem.minHeight,
            [theme.breakpoints.up('md')]: {
                paddingLeft: config?.md.commentsItem.paddingLeft,
                borderLeft: config?.md.commentsItem.borderLeft,
                minHeight: config?.md.commentsItem.minHeight,
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: toRem(15),
                    height: toRem(15),
                    borderTop: `1px solid ${theme.colors.comments.border.default}`,
                    borderLeft: `1px solid ${theme.colors.comments.border.default}`,
                    top: '20%',
                    left: toRem(-8),
                    background: theme.colors.comments.item.background,
                    transform: 'skewX(45deg)'
                }
            },
            '&_heading': {
                paddingTop: toRem(10),
                fontWeight: theme.typography.fontWeight.bold,
                fontSize: toRem(24),
                lineHeight: 1.8,
                color: isHeadingPink ? theme.colors.text.accent : theme.colors.text.default
            },
            '&_body': {
                paddingTop: config?.variant[variant]?.commentsItem?.body?.paddingTop || toRem(10),
                fontStyle: 'normal',
                fontWeight: theme.typography.fontWeight.medium,
                fontSize: toRem(18),
                lineHeight: 1.8,
                color: theme.colors.comments.item.text,
                marginBottom: toRem(18)
            }
        }
    }
}

export default style
