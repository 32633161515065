import { useMemo } from 'react'

import { ClosePosition, InscreenPostion } from '../EternalAds.interface'
import { parseIntegerValue } from '../helpers/parseIntegerValue'
import { parseYesOrNo } from '../helpers/parseYesOrNo'
import {
    InscreenData,
    invertPosition,
    ParsedInscreenDimensions,
    positionMap,
    PositionX,
    PositionY
} from './Inscreen.interface'
import mockDataInscreen from './Inscreen.mock'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseCallbacks = (callbacks?: string): ((...args: any[]) => void)[] => {
    if (!callbacks) return []
    return callbacks
        .split(',')
        .map(callb => {
            try {
                const parsedCallback = JSON.parse(callb)
                if (parsedCallback && typeof parsedCallback === 'function') {
                    return (...args) => {
                        try {
                            parsedCallback(...args)
                        } catch (e) {
                            console.error('Error in Inscreen callback', e)
                        }
                    }
                }
            } catch (err) {
                console.error('Error parsing Inscreen callback', err)
                return false
            }
            return false
        })
        .filter(s => typeof s == 'function') as ((...args: any[]) => void)[]
}

export const parsePosition = (position?: InscreenPostion | ClosePosition): [PositionX, PositionY] => {
    if (!position) return [PositionX.CENTER, PositionY.BOTTOM]
    const [x = PositionX.CENTER, y = PositionY.BOTTOM] = positionMap[position] || []
    return [x, y]
}

export const parseClosePosition = (
    inscreenPositionX: PositionX,
    inscreenPositionY: PositionY,
    closePosition?: ClosePosition
): [PositionX, PositionY] => {
    const [closeX, closeY] = parsePosition(closePosition)
    return [
        inscreenPositionX === PositionX.CENTER ? closeX : invertPosition(inscreenPositionX),
        inscreenPositionY === PositionY.CENTER ? closeY : invertPosition(inscreenPositionY)
    ]
}
export const parseInscreenData = (data?: InscreenData) => {
    if (!data) return {}
    const {
        ad,
        clickUrlUnesc,
        clickUrl,
        closePosition,
        height,
        openInNewTab,
        thirdPartyImpressionUrl,
        position,
        viewUrlUnesc,
        width,
        thirdPartyTagsHtml,
        hasGlobalClickTag,
        sourceType,
        fileImage,
        fileUrl,
        htmlContent
    } = data

    const [positionX, positionY] = parsePosition(position)
    const [closePositionX, closePositionY] = parseClosePosition(positionX, positionY, closePosition)
    const dimensions: ParsedInscreenDimensions = {
        height: parseIntegerValue(height),
        width: parseIntegerValue(width),
        positionX,
        positionY,
        closePositionX,
        closePositionY,
        closeHidden: closePosition === ClosePosition.HIDDEN
    }
    const clickOptions = {
        openInNewTab: parseYesOrNo(openInNewTab),
        hasGlobalClickTag: parseYesOrNo(hasGlobalClickTag),
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        clickUrlUnesc,
        thirdPartyTagsHtml,
        clickUrl
    }
    const creative = {
        sourceType,
        fileImage,
        fileUrl,
        htmlContent
    }

    return {
        creative,
        dimensions,
        clickOptions,
        ad
    }
}

export type ParsedInscreenData = ReturnType<typeof parseInscreenData>

export type ParsedInscreenElement = ParsedInscreenData['creative']

export const useInscreenMockData = () =>
    useMemo(() => parseInscreenData(mockDataInscreen as unknown as InscreenData), [])
