import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as easings from '../helpers/easings'
import throttle from '../helpers/throttle'

export const { linear, quad, cubic, quart, quint, sine, expo, circ, back, elastic, bounce } = easings

const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
})

const getScroll = previousValue => {
    const position = window.scrollY
    let direction = 'down'
    if (typeof previousValue?.position === 'number' && position < previousValue.position) {
        direction = 'up'
    }
    return {
        position,
        direction
    }
}

const getTargetY = target => {
    if (typeof target === 'number') {
        return target
    }
    const targetEl = typeof target === 'string' ? document.querySelector(target) : target
    if (targetEl && targetEl.getBoundingClientRect) {
        const targetY = targetEl.getBoundingClientRect().y
        const windowY = window.pageYOffset
        return targetY + windowY
    }
    return -1
}

const scrollToTarget = (target, options = {}) => {
    const { duration, speed, offset = 0, ease = linear.easeNone } = options
    const targetY = getTargetY(target)
    if (targetY < 0) {
        return {
            promise: Promise.resolve(),
            cancel: () => {}
        }
    }

    const startY = window.pageYOffset
    const distance = targetY - startY + offset
    let totalTime = 250
    if (duration) {
        totalTime = duration
    } else if (speed) {
        totalTime = Math.abs(distance) / speed
    }
    let startTime = 0

    let rafId

    const cancel = () => {
        window.cancelAnimationFrame(rafId)
    }

    const promise = new Promise(resolve => {
        const step = timestamp => {
            if (!startTime) {
                startTime = timestamp
            }
            const time = timestamp - startTime
            const percent = totalTime ? ease(Math.min(time / totalTime, 1)) : 1

            window.scrollTo(0, startY + distance * percent)
            rafId = window.requestAnimationFrame(time <= totalTime ? step : resolve)
        }
        rafId = window.requestAnimationFrame(step)
    })

    return {
        cancel,
        promise
    }
}

const useWindowContext = () => {
    const [size, setSize] = useState({
        width: 0,
        height: 0
    })

    useEffect(() => {
        const onResize = () => {
            setSize(getSize())
        }

        const onResizeThrottled = throttle(onResize, 250)

        window.addEventListener('resize', onResizeThrottled)

        onResizeThrottled()

        return () => {
            window.removeEventListener('resize', onResizeThrottled)
        }
    }, [])

    const value = useMemo(
        () => ({
            width: size.width,
            height: size.height
        }),
        [size]
    )

    return value
}

export const useScrollToCallback = () => {
    const cancelRef = useRef()

    return useCallback(async (target, { duration = 0, speed = 0, offset = 0, ease = sine.easeInOut } = {}) => {
        if (cancelRef.current) {
            cancelRef.current()
        }
        const { cancel, promise } = scrollToTarget(target, {
            duration,
            speed,
            offset,
            ease
        })
        cancelRef.current = cancel
        await promise
    }, [])
}

/**
 * `useWindow`
 *
 * Provides `width` and `height` values.
 *
 * As well as `scrollTo` helper.
 *
 * ```js
 * import { useWindow, back } from './'
 * const { width, scrollTo } = useWindow()
 * const elementRef = useRef()
 *
 * // Calculate number of columns to render
 * const columnNumber = Math.round(width / 200)
 *
 * const onClick = () => {
 *      // Find element with query selector, and scroll to it over a duration of time
 *      scrollTo('#some-element', { duration: 10, offset: -20 })
 *      // Scroll to element ref, with applied easing, and speed of 0.1px/ms (100px/s)
 *      scrollTo(elementRef.current, { speed: 0.1, ease: back.easeOut })
 * }
 * ```
 */
const useWindow = () => {
    // const { width, height, position, direction } = useContext(WindowContext)
    const { width, height } = useWindowContext()

    const scrollTo = useScrollToCallback()

    return {
        width,
        height,
        scrollTo
    }
}

const useWindowScroll = () => {
    const [scroll, setScroll] = useState({
        position: 0,
        direction: 'down'
    })

    useEffect(() => {
        const onScroll = () => {
            setScroll(state => getScroll(state))
        }
        const onScrollThrottled = throttle(onScroll, 250)
        window.addEventListener('scroll', onScrollThrottled)
        onScrollThrottled()
        return () => {
            window.removeEventListener('scroll', onScrollThrottled)
        }
    }, [])

    return scroll
}

const useWindowIsScrolling = () => {
    const [isScrolling, setIsScrolling] = useState(false)

    useEffect(() => {
        let timeout = null

        const onScroll = () => {
            if (timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            }

            setIsScrolling(true)
            timeout = setTimeout(() => {
                setIsScrolling(false)
            }, 1000)
        }
        window.addEventListener('scroll', onScroll)

        return () => {
            if (timeout !== null) {
                clearTimeout(timeout)
                timeout = null
            }

            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    return isScrolling
}

const useWindowAnchorDetect = slug => {
    const [anchor, setAnchor] = useState('')
    useEffect(() => {
        if (slug === 'hot') {
            if (window.location.href.includes('#lijepenase')) {
                // window.location = window.location.href.replace('#lijepenase', '')
                setAnchor(window.location.href)
            }
        }
    }, [slug])

    return anchor
}

export { useWindowScroll, useWindowIsScrolling, useWindowAnchorDetect }

export default useWindow
