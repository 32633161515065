import { EntityType } from '../../types/entity'
import { ClientNavigationContext } from './ClientNavigation.context'
import { ClientNavigationStore } from './ClientNavigation.types'

// @NOTE: selector selects a subset ofs the store, duh
const isTransitioningSelector = (store: ClientNavigationStore) => store.isTransitioning
// @NOTE: processor is a function that takes the new value for the selector and returns it as a partial of store
const isTransitioningProcessor = (value?: boolean) => ({ isTransitioning: value || false })
export const useIsTransitioning = () =>
    ClientNavigationContext.useStore(isTransitioningSelector, isTransitioningProcessor)

const clientTransitionSelector = (store: ClientNavigationStore) => ({
    isTransitioning: store.isTransitioning,
    hasNavigated: store.hasNavigated,
    hasNavigationStarted: store.hasNavigationStarted
})
// @NOTE: dont need a processor here since SelectorOutput is a subset of Store
// const clientNavigationProcessor = (value?: Partial<ReturnType<typeof clientTransitionSelector>>) => value || {}
export const useClientTransitionStore = () =>
    ClientNavigationContext.useStore(
        clientTransitionSelector // , clientNavigationProcessor
    )

const hasNavigatedSelector = (store: ClientNavigationStore) => store.hasNavigated
const hasNavigatedProcessor = (value?: boolean) => ({ hasNavigated: value ?? false })
export const useHasNavigated = () => ClientNavigationContext.useStore(hasNavigatedSelector, hasNavigatedProcessor)

const currentEntityTypeSelector = (store: ClientNavigationStore) => store.currentEntityType
const currentEntityTypeProcessor = (value?: EntityType) => ({ currentEntityType: value || 'loading' })
export const useCurrentEntityTypeStore = () =>
    ClientNavigationContext.useStore(currentEntityTypeSelector, currentEntityTypeProcessor)[0]
export const useCurrentEntityTypeDispatch = () => ClientNavigationContext.useDispatch(currentEntityTypeProcessor)

const inarticleAdMouncedSelector = (store: ClientNavigationStore) => store.inarticleAdMounted
const inarticleAdMouncedProcessor = (value?: boolean) => ({ inarticleAdMounted: value || false })
export const useInarticleAdMountedStore = () =>
    ClientNavigationContext.useStore(inarticleAdMouncedSelector, inarticleAdMouncedProcessor)
export const useInarticleAdMountedDispatch = () => ClientNavigationContext.useDispatch(inarticleAdMouncedProcessor)

const clientNavigationSelector = (store: ClientNavigationStore) => store.clientNavigationState
export const useClientNavigationState = () => ClientNavigationContext.useStore(clientNavigationSelector)[0]
const adsNavigationStateSelector = (store: ClientNavigationStore) => store.adsNavigationState
export const useAdsNavigationState = () => ClientNavigationContext.useStore(adsNavigationStateSelector)[0]
