import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Graphic.style'

const GraphicStyled = styled.div(props => ({ ...styles(props) }))

function Graphic({ className, widthXs, heightXs, widthMd, heightMd, variant, fillVariant, ...rest }) {
    return (
        <GraphicStyled
            className={className}
            variant={variant}
            fillVariant={fillVariant}
            widthXs={widthXs}
            heightXs={heightXs}
            widthMd={widthMd}
            heightMd={heightMd}
            {...rest}
        />
    )
}

const graphicVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon',
    ZETA: 'zeta',
    ETA: 'eta',
    THETA: 'theta',
    IOTA: 'iota',
    KAPPA: 'kappa'
})

const graphicFillVariants = Object.freeze({
    DEFAULT: 'default',
    ALTERNATIVE: 'alternative',
    INVERTED: 'inverted'
})

Graphic.propTypes = {
    className: PropTypes.string,
    widthXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    widthMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heightXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heightMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf([...Object.values(graphicVariants)]),
    fillVariant: PropTypes.oneOf([...Object.values(graphicFillVariants)])
}

Graphic.defaultProps = {
    className: undefined,
    widthXs: undefined,
    widthMd: undefined,
    heightXs: undefined,
    heightMd: undefined,
    variant: graphicVariants.ALPHA,
    fillVariant: graphicFillVariants.DEFAULT
}

export { graphicVariants, graphicFillVariants }

export default Graphic
