/* eslint-disable @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../../helpers/components/createStyledComponent'
// import { toRem } from '../../../../../helpers/theme'

export type StyleProps = { theme?: any; height?: number; pageWidth?: number }
const styles = ({ theme, height = 200, pageWidth = 960 }: StyleProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    height: '100%',
    width: pageWidth,
    backgroundColor: theme.colors.background.mainDefault,
    '.WallpaperTop': {
        position: 'relative',
        height: '100%',
        margin: '0 auto',
        overflow: 'visible',
        '&_container': {
            pointerEvents: 'all',
            position: 'absolute',
            top: 0,
            height
        },
        '&_error': {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15% 7%',
            '& > span': {
                backgroundColor: 'rgba(155,35,0,0.7)',
                backdropFilter: 'blur(10px)',
                padding: '10px 20px',
                border: 'solid red 1px',
                fontSize: 20,
                fontWeight: 'bold',
                color: 'white'
            }
        }
    }
})

const Styled = createStyledComponent({ tag: 'div', styles, blockProps: ['left', 'width', 'height', 'pageWidth'] })

export default Styled
