import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: toRem(5),
    '& .rateItem_title': {
        display: 'flex',
        textAlign: 'center',
        flex: 1,
        fontWeight: theme.typography.fontWeight.bold,
        fontSize: toRem(14),
        lineHeight: 1.8,
        letterSpacing: '0.25em',
        textTransform: 'uppercase'
    },
    '& .rateItem_rates': {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        '& svg': {
            width: toRem(30),
            height: toRem(30),
            '&.selected path': {
                fill: theme.colors.comments.rate.selected
            },
            '& path': {
                fill: theme.colors.comments.rate.default
            },
            '&:hover': {
                cursor: 'pointer'
            }
        }
    }
})

export default style
