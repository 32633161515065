import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, widthXs, widthMd }) => ({
    opacity: 0.5,
    minHeight: toRem(20),
    pointerEvents: 'none',
    ...(variant === 'alpha' && {
        width: toRem(widthXs || 25),
        background: theme.colors.decoration.default
    }),
    ...(variant === 'beta' && {
        width: '100%',
        height: '100%',
        border: `${widthXs || 25}px solid ${theme.colors.decoration.default}`
    }),
    [theme.breakpoints.up('md')]: {
        ...(variant === 'alpha' && {
            width: toRem(widthMd || 30)
        }),
        ...(variant === 'beta' && {
            width: '100%',
            height: '100%',
            border: `${widthMd || 30}px solid ${theme.colors.decoration.default}`
        })
    }
})

export default style
