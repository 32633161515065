import getConfig from 'next/config'
import { useMemo } from 'react'

import { ImageMeta } from '../types/seo'
import useImageURL from './useImageURL'

const { publicRuntimeConfig = {} } = getConfig()
const { assetPrefix } = publicRuntimeConfig

const PUBLIC_ROOT =
    process.env.NEXT_PUBLIC_APP_ROOT_RTL ||
    process.env.NEXT_PUBLIC_APP_ROOT_NET ||
    process.env.NEXT_PUBLIC_APP_ROOT_ZENA

const imagesToSeo = (images: string[]) =>
    images?.reduce((acc, image) => {
        if (!image) {
            return acc
        }
        if (typeof image === 'string') {
            const dimensions = image
                ?.split('/')
                ?.pop()
                ?.split('-')?.[0]
                .split('x')
                .map(s => parseInt(s, 10))
                .filter(Boolean)

            if (dimensions)
                acc.push({
                    url: image,
                    ...(dimensions.length === 2
                        ? {
                              width: dimensions[0],
                              height: dimensions[1]
                          }
                        : {})
                })
            return acc
        }
        acc.push(image)
        return acc
    }, [] as ImageMeta[])

const useSeoImages = (
    images,
    defaultImage = {
        url: `${assetPrefix || PUBLIC_ROOT || ''}/images/share/default.png`,
        width: 700,
        height: 350
    },
    imageRatio = '3:2',
    imageWidth = 1280,
    imageHeight = 1280
) => {
    const imageIds =
        images
            ?.filter(image => (typeof image === 'string' && !image.match(/^http(s)*/gim)) || (image?.id && !image?.url))
            .map(image => image?.id || image)
            .filter(Boolean) || []

    const loadedImages0 = useImageURL(imageIds, imageRatio, imageWidth, imageHeight, 'jpg')
    const loadedImages = Array.isArray(loadedImages0) ? loadedImages0 : [loadedImages0]

    const imageMeta = useMemo(() => {
        const filteredImages = images?.filter(Boolean)?.map(image => {
            if (typeof image === 'string' && image.match(/^http(s)*/gim)) {
                return image
            }
            if (image?.url) {
                return image.url
            }
            return loadedImages.shift()
        })
        if (filteredImages?.length === 0) {
            return defaultImage ? [defaultImage] : []
        }
        return imagesToSeo(filteredImages)
    }, [images, loadedImages])
    return { imageMeta }
}
export default useSeoImages
