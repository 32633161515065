import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: toRems([40, 0]),
    '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.colors.profile.border.default}`
    },
    '& .globalProfileTesterForm': {
        width: '90%',
        '&_title': {
            marginBottom: toRem(20),
            color: theme.colors.profile.text.default,
            fontFamily: theme.typography.fontFamily.alternative,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: toRem(18),
            lineHeight: 1.2,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(20)
            }
        },
        '&_form': {
            margin: toRems([20, 0])
        },
        '&_interests': {
            marginBottom: toRem(20)
        }
    },
    '& .textField_half': {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%'
        }
    },
    // Restyling material-ui
    // // @TODO: Remove when we get rid of material-ui
    '& .MuiFormLabel-root': {
        padding: toRems([0, 10, 0, 10])
    },
    '& .MuiInputBase-root': {
        padding: toRems([0, 10, 10, 10]),
        borderBottom: `2px solid ${theme.colors.profile.form.input.border.default}`,
        color: theme.colors.profile.form.input.text.default,
        '&::before': {
            content: 'none'
        }
    },
    '& .MuiFormControl-root': {
        marginBottom: toRem(30)
    },
    '& .MuiInputLabel-formControl': {
        color: theme.colors.profile.form.input.label.default
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.colors.profile.form.input.text.focus
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
    },
    '& input:-internal-autofill-selected:': {
        backgroundColor: 'transparent'
    },
    '& .MuiSelect-select': {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: toRem(15),
            display: 'block',
            height: toRem(25),
            width: toRem(25),
            background: 'url(/images/profile/select-arrow.svg)'
        }
    },
    '& .MuiSelect-icon': {
        display: 'none'
    },
    '& .MuiPaper-elevation8': {
        borderRadius: 0,
        boxShadow: 'initial !important',
        border: `1px solid ${theme.colors.profile.form.input.border.default} !important`
    }
    // Enf of restyling material-ui
})

export default style
