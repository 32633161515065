import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Icon, RtlLogo } from '../Icon'
import styles from './Header.style'

const HeaderStyled = styled.div(props => ({ ...styles(props) }))

function Header({ className, ...rest }) {
    return (
        <HeaderStyled className={className} {...rest}>
            <Icon className="rtlLogo" icon={RtlLogo} size={120} viewBox="0 0 120 22" />
        </HeaderStyled>
    )
}

Header.propTypes = {
    className: PropTypes.string
}

Header.defaultProps = {
    className: undefined
}

export default withErrorBoundary(Header, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileHeader]: ', error, componentStack)
    }
})
