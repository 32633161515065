const constants = {
    LOGIN: 'USER/LOGIN_USER',
    LOGOUT: 'USER/LOGOUT_USER',
    SET_CONSENT_STRING: 'USER/SET_CONSENT_STRING'
}

const actions = {
    login: id => ({ type: constants.LOGIN, id }),
    logout: () => ({ type: constants.LOGOUT }),
    setConsentString: consentString => ({
        consentString,
        type: constants.SET_CONSENT_STRING
    })
}

export { constants as UserActionTypes, actions as UserActions }
