import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, sticky, stickyHeight, activeTab, activeTabWidth, activeTabOffset, indicatorHeight }) => ({
    position: 'relative',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    '& .tabs': {
        '&Head': {
            overflowX: 'hidden',
            display: 'flex',
            position: 'relative',
            minHeight: sticky ? stickyHeight || 0 : 0,
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: toRem(indicatorHeight),
                width: '100%',
                background: theme.colors.profile.tab.head.indicator.inactive,
                pointerEvents: 'none'
            },
            '&_inner': {
                display: 'flex',
                position: 'relative',
                overflowX: 'hidden',
                background: sticky && theme.colors.profile.tab.head.background.default,
                ...(sticky && {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: theme.zIndex.over
                }),
                '&::after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: 0,
                    left: toRem(activeTabOffset),
                    height: toRem(indicatorHeight),
                    width: toRem(activeTabWidth),
                    background: theme.colors.profile.tab.head.indicator.active,
                    transitionDuration: theme.transitions.tab.head.indicator.duration,
                    transitionTimingFunction: theme.transitions.tab.head.indicator.timingFunction,
                    transitionDelay: theme.transitions.tab.head.indicator.delay,
                    transitionProperty: 'left, width'
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%'
                }
            },
            '&_item': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: toRem(indicatorHeight),
                cursor: 'pointer',
                [`&:nth-of-type(${activeTab + 1})`]: {
                    pointerEvents: 'none',
                    '& .tabsHead_itemLabel': {
                        color: theme.colors.profile.tab.head.text.active,
                        fontWeight: theme.typography.fontWeight.semiBold
                    }
                },
                '&:not(:last-of-type)': {
                    [theme.breakpoints.up('md')]: {
                        marginRight: toRem(60)
                    }
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%'
                },
                '&Label': {
                    padding: toRems([6, 0]),
                    fontSize: toRem(16),
                    fontFamily: theme.typography.fontFamily.alternative,
                    lineHeight: toRem(20),
                    fontWeight: theme.typography.fontWeight.regular,
                    textTransform: 'uppercase',
                    letterSpacing: 0,
                    wordBreak: 'break-all',
                    color: theme.colors.profile.tab.head.text.inactive,
                    transitionDuration: theme.transitions.tab.head.text.duration,
                    transitionTimingFunction: theme.transitions.tab.head.text.timingFunction,
                    transitionDelay: theme.transitions.tab.head.text.delay,
                    transitionProperty: 'color',
                    '&:hover': {
                        color: theme.colors.profile.tab.head.text.hover
                    },
                    [theme.breakpoints.down('md')]: {
                        textAlign: 'center'
                    }
                }
            }
        },
        '&Body': {
            width: '100%',
            overflowX: 'hidden',
            '&_inner': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                transform: `translateX(calc(${activeTab} * -100%))`,
                transitionDuration: theme.transitions.tab.body.item.duration,
                transitionTimingFunction: theme.transitions.tab.body.item.timingFunction,
                transitionDelay: theme.transitions.tab.body.item.delay,
                transitionProperty: 'transform'
            },
            '&_item': {
                flex: '1 0 100%',
                flexBasis: '100%',
                width: '100%'
            }
        }
    }
})

export default style
