import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        xs: {
            avatar: {
                size: 36
            },
            userIcon: {
                size: 19
            }
        },

        md: {
            avatar: {
                size: 42
            },
            userIcon: {
                size: 16
            }
        }
    }
    // makes centering, margins, etc. far easier
    const avatarMarginXs = -((config.xs.avatar.size - config.xs.userIcon.size) / 2)
    const avatarMarginMd = -(config.md.avatar.size / 2)
    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto',
        flex: '0 0 auto',
        padding: toRem(17),
        marginTop: toRem(5),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(0),
            padding: 0
        },
        '& .loginButton': {
            position: 'relative',
            textAlign: 'center',
            height: toRem(config.xs.avatar.size),
            width: toRem(config.xs.avatar.size),
            margin: toRem(avatarMarginXs),
            display: 'flex',
            flex: '0 0 auto',
            alignItems: 'center',
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
                width: 'auto',
                height: 'auto',
                margin: toRems([avatarMarginMd, 0])
            }
        },
        '& .avatar': {
            width: toRem(config.xs.avatar.size),
            height: toRem(config.xs.avatar.size),
            [theme.breakpoints.up('md')]: {
                width: toRem(config.md.avatar.size),
                height: toRem(config.md.avatar.size)
            },
            '&_image': {
                position: 'relative',
                borderRadius: '50%',
                backgroundImage: 'none',
                width: toRem(config.xs.avatar.size),
                height: toRem(config.xs.avatar.size),
                [theme.breakpoints.up('md')]: {
                    width: toRem(config.md.avatar.size),
                    height: toRem(config.md.avatar.size)
                },
                '& img': {
                    borderRadius: '50%',
                    padding: toRem(5)
                },
                '&_progressive': {
                    borderRadius: '50%',
                    position: 'relative',
                    padding: 0
                }
            },
            '&_button': {
                position: 'relative',
                textAlign: 'center',
                height: toRem(config.xs.avatar.size),
                width: toRem(config.xs.avatar.size),
                margin: toRem(avatarMarginXs),
                display: 'flex',
                flex: '0 0 auto',
                alignItems: 'center',
                flexDirection: 'row',
                [theme.breakpoints.up('md')]: {
                    width: 'auto',
                    height: 'auto'
                }
            },
            '&_icon': {
                textAlign: 'center',
                color: theme.colors.header?.login?.icon?.xs?.loggedIn || theme.colors.devWarning,
                fontSize: toRem(24),
                marginTop: toRem(5),
                [theme.breakpoints.up('md')]: {
                    fontSize: toRem(30),
                    marginTop: toRem(4),
                    color: theme.colors.header?.login?.icon?.md?.loggedIn || theme.colors.devWarning
                },
                path: {
                    fill: 'currentColor'
                }
            }
        },
        '& .title': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                fontSize: toRem(12),
                lineHeight: toRem(24),
                letterSpacing: '0.1em',
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeight.bold,
                textTransform: 'uppercase',
                marginLeft: toRem(5)
            },
            '&.loggedIn': {
                color: theme.colors.header?.login?.title?.loggedIn || theme.colors.devWarning
            },
            '&.loggedOut': {
                color: theme.colors.header?.login?.title?.loggedOut || theme.colors.devWarning
            }
        },
        '& .line': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                width: toRem(15),
                height: toRem(1),
                background: theme.colors.header?.login?.title?.loggedOut || theme.colors.devWarning,
                margin: toRems([0, 10])
            }
        },
        '& .userIcon': {
            fontSize: toRem(config.xs.userIcon.size),
            color: theme.colors.header?.login?.icon?.xs?.loggedOut || theme.colors.devWarning,
            [theme.breakpoints.up('md')]: {
                color: theme.colors.header?.login?.icon?.md?.loggedOut || theme.colors.devWarning,
                fontSize: toRem(config.md.userIcon.size),
                marginRight: toRem(15)
            },
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
