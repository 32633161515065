import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useNavigationStore } from '@hmn/rtl-web-core/context/navigation'

import styles, { motionVariants } from './Panel.style'

const PanelStyled = styled.div(props => ({ ...styles(props) }))

function Panel({ children, ...rest }) {
    // eslint-disable-next-line no-unused-vars
    const [{ isOpen }] = useNavigationStore()
    return (
        <PanelStyled {...rest}>
            <motion.div
                className="navigationPanel"
                initial="closed"
                animate={isOpen ? 'open' : 'closed'}
                variants={motionVariants}>
                <div className="navigationPanel_inner">{children}</div>
            </motion.div>
        </PanelStyled>
    )
}

export default withErrorBoundary(Panel, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NavigationPanel]: ', error, componentStack)
    }
})
