import { responsive, toRem } from '../../helpers/theme'
import typography from '../globals/typography'

const basicSpacing = [
    { padding: toRem(30) },
    { padding: toRem(32) },
    { padding: toRem(34) },
    { padding: toRem(36) },
    { padding: toRem(38) },
    { padding: toRem(40) }
]

export const cardStyleConfig = {
    '1x1': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(18) },
                { fontSize: toRem(18) },
                { fontSize: toRem(19) },
                { fontSize: toRem(19) },
                { fontSize: toRem(20) },
                { fontSize: toRem(20) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {
            display: 'none'
        }
    },
    '1x2': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(24) },
                { fontSize: toRem(24) },
                { fontSize: toRem(26) },
                { fontSize: toRem(26) },
                { fontSize: toRem(28) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {
            display: 'initial'
        }
    },
    '2x1': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(18) },
                { fontSize: toRem(20) },
                { fontSize: toRem(22) },
                { fontSize: toRem(24) },
                { fontSize: toRem(26) },
                { fontSize: toRem(28) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {
            display: 'none'
        }
    },
    '2x2': {
        spacing: {
            ...responsive([
                { padding: toRem(30) },
                { padding: toRem(40) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) }
            ])
        },
        title: {
            ...responsive([
                { fontSize: toRem(28) },
                { fontSize: toRem(28) },
                { fontSize: toRem(32) },
                { fontSize: toRem(34) },
                { fontSize: toRem(34) },
                { fontSize: toRem(36) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.black
        },
        lead: {
            display: 'initial'
        }
    }
}
