const constants = Object.freeze({
    IMA_LOADED: 'VIDEOJS/IMA_LOADED',
    IMA_LOADING_FAILED: 'VIDEOJS/IMA_LOADING_FAILED'
})

const actions = {
    imaLoaded: () => ({ type: constants.IMA_LOADED }),
    imaLoadingFailed: () => ({ type: constants.IMA_LOADING_FAILED })
}

export { constants as VideoJsActionTypes, actions as VideoJsActions }
