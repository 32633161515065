import { useCallback } from 'react'

import { useNativeAdDispatch } from '../../../../dispatchers/ads'
import { useRouteChangeStart } from '../../../../hooks/useRouteChange'
import useWindowAttachedValue from '../../../../hooks/useWindowAttachedValue'
import { parseInscreenData } from './Inscreen.helpers'
import { InscreenData } from './Inscreen.interface'

export const INJECTED_INSCREEN_SCRIPTS_VARIABLE_NAME = 'inj_insc_script'
export const CLEAR_INSCREEN_CALLBACK_KEY = 'clearInscreen'
const InscreenDataProvider = () => {
    const setData = useNativeAdDispatch('inscreen')

    const setInscreenCallback = useCallback(
        (e: InscreenData) => {
            // console.log('event', e)
            // @TODO: implement error Inscreen with message in case google admanager preview query param is set
            // if it errors on regular client, just log the error
            const parsed = parseInscreenData(e)
            // console.log('Inscreen data', { sent: e, parsed })
            setData(parsed)
        },
        [setData]
    )

    const clearInscreenCallback = useCallback(() => {
        if (typeof window !== 'undefined') {
            window[INJECTED_INSCREEN_SCRIPTS_VARIABLE_NAME] = {}
        }
        setData(null)
    }, [setData])

    useRouteChangeStart(clearInscreenCallback)

    // useEffect(() => {
    //     setInscreenCallback(mockDataInscreen)
    // }, [setInscreenCallback])

    // important so the template can attach itself to the app
    useWindowAttachedValue(setInscreenCallback, 'setInscreen')
    // important so inscreen can close itself via close button
    useWindowAttachedValue(clearInscreenCallback, CLEAR_INSCREEN_CALLBACK_KEY)
    return null
}

export default InscreenDataProvider
