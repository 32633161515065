export enum ClosePosition {
    TOP_LEFT = 'Top/Left',
    TOP_RIGHT = 'Top/Right',
    BOTTOM_LEFT = 'Bottom/Left',
    BOTTOM_RIGHT = 'Bottom/Right',
    HIDDEN = 'Hidden'
}

export enum InscreenPostion {
    TOP = 'Top',
    TOP_RIGHT = 'Top/Right',
    RIGHT = 'Right',
    BOTTOM_RIGHT = 'Bottom/Right',
    BOTTOM = 'Bottom',
    BOTTOM_LEFT = 'Bottom/Left',
    LEFT = 'Left',
    TOP_LEFT = 'Top/Left',
    CENTER = 'Center'
}

export enum RevealerPostion {
    RIGHT = 'Right',
    LEFT = 'Left'
}

export enum ConvergerPostion {}

export enum PositionInContainer {
    ABOVE = 'Above',
    BELOW = 'Below'
}

export enum YesOrNo {
    YES = 'YES',
    NO = 'NO'
}

export enum SourceType {
    UPLOADED_IMAGE = 'Uploaded Image',
    IMAGE_URL = 'Image URL',
    IFRAME = 'iFrame',
    HTML = 'Html code',
    NONE = 'None'
}

export interface Ad {
    ADUNIT: string
    CACHEBUSTER: string
    CLICK_URL_UNESC: string
    CLICK_URL_ESC: string
    CLICK_URL_ESC_ESC: string
}
