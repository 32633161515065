import { useQuery } from 'react-query'

import dataProvider from '@hmn/data-provider'

import { appQuerySettings } from '../queries/utils'
import { createQueryClient, getQueryKey, useQueryKey } from './useData'

const useOne = (resource, params = {}, options = {}) => {
    const { initialData, enabled = true, disableBlock = false } = options
    const queryKey = useQueryKey({ type: 'one', resource, params })
    const {
        data: { data = initialData } = {},
        isFetching,
        error,
        status,
        refetch
    } = useQuery(queryKey, () => dataProvider.getOne(resource, params), {
        initialData,
        ...appQuerySettings,
        // If params contains 'id', and if 'id' is undefined, don't fetch just yet
        enabled: !!(params && params.id && enabled && !disableBlock)
    })

    // hook logic
    return {
        data,
        loading: isFetching,
        status,
        error,
        refetch
    }
}

export const getOne = async (resource, params = {}, injectedQueryClient = undefined) => {
    const queryClient = injectedQueryClient || createQueryClient()
    const queryKey = getQueryKey({ type: 'one', resource, params })
    const { data } = await queryClient.fetchQuery(queryKey, () => dataProvider.getOne(resource, params), {
        ...appQuerySettings
    })

    return {
        data
    }
}

export const setOne = async (resource, params = {}, data = [], injectedQueryClient = undefined) => {
    const queryClient = injectedQueryClient || createQueryClient()
    const queryKey = getQueryKey({ type: 'one', resource, params })
    queryClient.setQueryData(
        queryKey,
        { data },
        {
            ...appQuerySettings
        }
    )
}

export default useOne
