import { PersistentQueryActionTypes as ActionTypes } from '../actions/persistent-query'

const initialState = {}

// eslint-disable-next-line default-param-last
const persistentQueryReducer = (state = initialState, { newStore, type, key, value }) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.SET_PERSISTENT_QUERY: {
            if (!key) {
                return state
            }
            return {
                ...state,
                [key]: value
            }
        }
        case ActionTypes.REMOVE_PERSISTENT_QUERY: {
            if (!key || !state[key]) {
                return state
            }
            const newState = { ...state }
            delete newState[key]
            return newState
        }
        default:
            return state
    }
}

export { persistentQueryReducer }
