import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { findFirstTitle, findImageIds, findParagraphContent } from '../../helpers/blockList'
import safelyJoinSlugs from '../../helpers/safelyJoinSlugs'
import usePrevNextItems from './usePrevNextItems'

const usePrevNextContent = ({ article, currentPage, totalPages, blockList }) => {
    const router = useRouter()

    const fullArticleHref = useMemo(() => {
        const url =
            article?.sitemap?.href ||
            (article?.path && `/${safelyJoinSlugs(article?.path?.map(p => p.slug))}`) ||
            article?.href ||
            ''
        return url ? `${url}?fullArticle=1` : null
    }, [article, router])

    const { prev, next } = usePrevNextItems({ article, currentPage, totalPages, blockList })

    const { pageBody, currentTitle, pagedImages } = useMemo(
        () => ({
            pageBody: findParagraphContent(blockList[currentPage - 1])
                .join(' ')
                .trim(),
            currentTitle: findFirstTitle(blockList[currentPage - 1]),
            pagedImages: findImageIds(blockList[currentPage - 1])
        }),
        [blockList, currentPage]
    )

    return { fullArticleHref, prev, next, pageBody, currentTitle, pagedImages }
}
export default usePrevNextContent
