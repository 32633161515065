const constants = {
    CLOSE_DRAWER: 'DRAWERS/CLOSE_DRAWER',
    OPEN_DRAWER: 'DRAWERS/OPEN_DRAWER'
}

const actions = {
    openDrawer: (drawerId, options) => ({ type: constants.OPEN_DRAWER, drawerId, options }),
    closeDrawer: drawerId => ({ type: constants.CLOSE_DRAWER, drawerId })
}

export { constants as DrawerActionTypes, actions as DrawerActions }
