import { useAmp } from 'next/amp'
import PropTypes, { InferProps } from 'prop-types'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useAdsNavigationState } from '../../context/clientNavigation'
import { createWrapperHOC } from '../../helpers/components/createWrapperHOC'

const RenderInView = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component = React.Fragment,
    viewPorts = 0,
    rootMargin,
    children,
    delay = 0,
    ...componentProps
}: PropsWithChildren<InferProps<typeof RenderInView.propTypes>>) => {
    const isAmp = useAmp()
    const [isBrowser, setIsBrowser] = useState(false)
    useEffect(() => {
        setIsBrowser(true)
    }, [])

    const navigationState = useAdsNavigationState()

    const finalRootMargin = useMemo(() => {
        if (rootMargin) {
            return rootMargin
        }
        if (!isBrowser) {
            return '800px 0px'
        }
        const viewportHeight = window.innerHeight
        if (viewPorts < 0 || !viewportHeight) {
            return '0px 0px'
        }
        return `${viewPorts * viewportHeight}px 0px`
    }, [viewPorts, rootMargin, isBrowser])

    const [wasInView, setWasInView] = useState(false)

    const timeoutRef = useRef(null)
    useEffect(
        () => () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        },
        []
    )

    const onChangeCallback = useCallback(
        isInView => {
            if (isInView && navigationState === 'ready') {
                setWasInView(true)
            }
        },
        [navigationState]
    )

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
        initialInView: false,
        delay,
        rootMargin: finalRootMargin,
        onChange: onChangeCallback
    })
    if (isAmp || wasInView) {
        return <Component {...componentProps}>{children}</Component>
    }

    return (
        <>
            <div ref={ref} />
            {inView && <Component {...componentProps}>{children}</Component>}
        </>
    )
}

RenderInView.propTypes = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: PropTypes.elementType,
    /*
     * viewPorts: A number which specifies a set of offsets to add to the root's bounding box when calculating intersections,
     * basically how many viewports away from entering the viewport the component should be rendered,
     * the default value is 1.5
     */
    viewPorts: PropTypes.number,
    /*
     * rootMargin: A string which specifies a set of offsets to add to the root's bounding box when calculating intersections,
     * specify exactly how far from entry should the component be before rendering,
     * overrides viewPorts value
     */
    rootMargin: PropTypes.string,
    delay: PropTypes.number
}

RenderInView.defaultProps = {
    viewPorts: 1.5,
    rootMargin: undefined,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: React.Fragment,
    delay: 0
}

const withRenderInView = createWrapperHOC(RenderInView)
// (Component, renderInViewProps) => {
//     function Wrapped(props) {
//         return <RenderInView Component={Component} {...renderInViewProps} {...props} />
//     }

//     // Format for display in DevTools
//     const name = Component.displayName || Component.name || 'Unknown'
//     Wrapped.displayName = `withRenderInView(${name})`

//     return Wrapped
// }

export { withRenderInView }
export default RenderInView
