import { useMemo } from 'react'

import useSeoImages from './useSeoImages.ts'

const useVideoThumbnail = ({ video, width = 1000, height = 800, aspectRatio = '16:9' }) => {
    const { imageMeta: smallImages } = useSeoImages(
        [video?.image || video?.thumbnail],
        undefined,
        aspectRatio,
        width,
        height
    )
    const thumbnailUrl = useMemo(
        () => smallImages?.[0]?.url || video?.image?.original_url || video?.thumbnail?.original_url,
        [smallImages, video]
    )
    // console.log('video', { video, image: video?.image, thumb: video?.thumbnail, thumbnailUrl })
    return thumbnailUrl
}

export default useVideoThumbnail
