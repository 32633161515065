// @TODO: Fix ilustration dimensions when aspect radio on image component is fixed
/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { GlobalProfileFooterLinks } from '../FooterLinks'
import styles from './Sidebar.style'

const SidebarStyled = styled.div(props => ({ ...styles(props) }))

function Sidebar({
    title,
    titleTagComponent: TitleTag,
    lead,
    image,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    alt,
    className,
    ...rest
}) {
    const titleHtmlProps = useInnerHtml(title)
    const leadHtmlProps = useInnerHtml(lead)

    const footerLinks = [
        {
            label: 'Uvjeti korištenja',
            href: `${process.env.NEXT_PUBLIC_HUMAN_API_TRUE_ROOT_URL}/terms-of-use?_locale=hr&_style=light`
        },
        {
            label: 'Zaštita privatnosti',
            href: `${process.env.NEXT_PUBLIC_HUMAN_API_TRUE_ROOT_URL}/privacy-policy?_locale=hr&_style=light`
        }
    ]

    return (
        <SidebarStyled className={className} {...rest}>
            <div className="globalProfileSidebar_inner">
                {image && <Image src={image} width="352" height="346" alt={alt || title} />}
                <div className="globalProfileSidebar_content">
                    {title && <TitleTag className="globalProfileSidebar_title" {...titleHtmlProps} />}
                    {lead && <p className="globalProfileSidebar_lead" {...leadHtmlProps} />}
                </div>
                <GlobalProfileFooterLinks className="globalProfileSidebar_footerLinks" footerLinks={footerLinks} />
            </div>
        </SidebarStyled>
    )
}

Sidebar.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string
}

Sidebar.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    image: undefined,
    imageWidth: 352,
    imageHeight: 364,
    alt: undefined
}

export default withErrorBoundary(Sidebar, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileSidebar]: ', error, componentStack)
    }
})
