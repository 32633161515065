import { VideoJsActionTypes as ActionTypes } from '../actions/videojs'

const initialState = {
    imaLoaded: false,
    imaLoadingFailed: false
}

const videoJsReducer = (state = initialState, { type, newStore } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.IMA_LOADED: {
            return {
                ...state,
                imaLoaded: true
            }
        }

        case ActionTypes.IMA_LOADING_FAILED: {
            return {
                ...state,
                imaLoadingFailed: true
            }
        }

        default:
            return state
    }
}

export { videoJsReducer }
