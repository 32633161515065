// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react'

import createIsolatedHook from '../helpers/createIsolatedHook'
import type { ParsedVoyoSlider } from '../helpers/middleware/parseVoyoSlider'
import { StrictAppNames } from '../types/app'
import { useConnectEnvironment } from './useEnvironment'
import { useGTMSetup } from './useGTM'
import { IsolatedUsePersistentQueryParam } from './usePersistentQueryParam'
import useScrollDisable from './useScrollDisable'
import { useVoyoDataConnect } from './useVoyoDataConnect'

export type UseAppProps = {
    appName: StrictAppNames
    voyoData?: { [key: string]: ParsedVoyoSlider }
}

const IsolatedUseScrollDisable = createIsolatedHook(useScrollDisable)
const IsolatedUseGTMSetup = createIsolatedHook(useGTMSetup)
const IsolatedVoyoDataConnect = createIsolatedHook(useVoyoDataConnect)
const IsolatedUseConnectEnvironment = createIsolatedHook(useConnectEnvironment)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IsolatedAppHooks = (props: UseAppProps) => {
    return (
        <>
            <IsolatedUsePersistentQueryParam queryKey="ipromConsole" />
            <IsolatedUseScrollDisable />
            <IsolatedUseGTMSetup />
            <IsolatedVoyoDataConnect voyoData={props.voyoData || {}} />
            <IsolatedUseConnectEnvironment />
        </>
    )
}
