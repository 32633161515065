import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { useWindow } from '../../hooks'
import styles from './StickyWrapper.style'

const StickyWrapperStyled = styled.div(props => ({ ...styles(props) }))

// @NOTE: This is a hack to get sticky state to refresh when ads are loaded because of some reason the sticky state would get reset on prev-next pages
const filledSlotsSelector = state => state?.ads?.filledSlots

function StickyWrapper({ className, children, ...rest }) {
    const isAmp = useAmp()
    const { height } = useWindow()
    const [isSticky, setIsSticky] = useState(false)
    const [contentHeight, setContentHeight] = useState(isAmp ? 30 : 0)
    const wrapperRef = useRef()
    const contentRef = useRef()

    useEffect(() => {
        const handleResize = () => {
            requestAnimationFrame(() => {
                const { height: contentBoundingHeight = 0 } = contentRef?.current?.getBoundingClientRect() || {}
                setContentHeight(contentBoundingHeight)
            })
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleScroll = useCallback(() => {
        requestAnimationFrame(() => {
            const { top: wrapperPosition = 0 } = wrapperRef?.current?.getBoundingClientRect() || {}
            setIsSticky(wrapperPosition + contentHeight > height)
        })
    })

    // @NOTE: This is a hack to get sticky state to refresh when ads are loaded because of some reason the sticky state would get reset on prev-next pages
    const filledAdSlots = useSelector(filledSlotsSelector)

    useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [contentHeight, height, handleScroll, filledAdSlots])

    return (
        <StickyWrapperStyled {...rest}>
            <div className="stickyWrapper" style={{ height: !isAmp ? contentHeight : 50 }} ref={wrapperRef}>
                <div
                    ref={contentRef}
                    className={`stickyWrapper_inner ${className}`}
                    style={isSticky ? { position: 'fixed', bottom: 0, left: 0 } : null}>
                    {children}
                </div>
            </div>
        </StickyWrapperStyled>
    )
}

StickyWrapper.propTypes = {
    className: PropTypes.string
}

StickyWrapper.defaultProps = {
    className: undefined
}

export default StickyWrapper
