/* eslint-disable react/no-unknown-property */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicVariants } from '../Decoration/components'
import { Icon, ZenaHrLogo } from '../Icon'
import { Link } from '../Link'
import { Navigation } from '../Navigation'
import { NavigationButton, navigationButtonVariants } from '../Navigation/components/NavigationButton'
import LoginButton from './components/LoginButton/LoginButton.component'
import { HeaderPanel } from './components/Panel'
import styles from './Header.style'

const HeaderStyled = styled.header(props => ({ ...styles(props) }))

function Header({ errorPage = false, graphicVariant, primaryMenu, secondaryMenu, ...rest }) {
    return (
        <>
            <HeaderStyled {...rest}>
                <div className="headerWrapper">
                    <HeaderPanel>
                        <div className="header">
                            {graphicVariant && <Graphic variant={graphicVariant} className="header_graphic" />}
                            {!errorPage && <LoginButton className="header_user" />}
                            <Link href="/" as="/" className="header_logo" title="Naslovnica - Žena.hr">
                                <span className="header_logo_label">Naslovnica - Žena.hr</span>
                                <Icon icon={ZenaHrLogo} width="123" height="25" viewBox="0 0 103 25" />
                            </Link>
                            {!errorPage && <NavigationButton variant={navigationButtonVariants.BETA} fixed />}
                        </div>
                    </HeaderPanel>
                </div>
            </HeaderStyled>
            <Navigation primaryMenu={primaryMenu} secondaryMenu={secondaryMenu} />
        </>
    )
}

Header.propTypes = {
    graphicVariant: PropTypes.oneOf([...Object.values(graphicVariants)]),
    primaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    secondaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    errorPage: PropTypes.bool
}

Header.defaultProps = {
    graphicVariant: graphicVariants.GAMMA,
    primaryMenu: undefined,
    secondaryMenu: undefined,
    errorPage: false
}

export default withErrorBoundary(Header, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Header]: ', error, componentStack)
    }
})
