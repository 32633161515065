// @TODO: Revisit props real data is connected, when form functionality is implemented
import { useRef } from 'react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { useOne } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../../../Button'
import { Icon, UnionIcon } from '../../../Icon'
import { CommentsAvatar } from '..'
import styles from './Add.style'

const CommentsAddStyled = styled.div(props => ({ ...styles(props) }))

function CommentsAdd({ className, ...rest }) {
    const { data: user } = useOne('users/me')
    const commentAddForm = useRef()

    // @TODO: when real data is connected include this
    // if (!user) {
    //     return null
    // }

    const onSubmit = () => {
        // eslint-disable-next-line no-alert
        alert('TODO: onSubmit action, validation')
    }

    return (
        <CommentsAddStyled className={className} {...rest}>
            <CommentsAvatar user={user} imageWidth={70} imageHeight={70} />
            <Formik
                innerRef={commentAddForm}
                onSubmit={onSubmit}
                initialValues={{
                    comment: ''
                }}>
                {({ isSubmitting }) => (
                    <Form className="commentsAdd">
                        <div className="commentsAdd_input">
                            <Field name="comment">
                                {({ field }) => (
                                    <textarea
                                        className="commentsAdd_input_item"
                                        placeholder="Unesi komentar..."
                                        {...field}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className="commentsAdd_submit">
                            <Button
                                type="submit"
                                title="Komentiraj"
                                disabled={isSubmitting}
                                className="commentsAdd_submit_button"
                                variant={buttonVariants.BETA}
                                iconComponent={<Icon icon={UnionIcon} />}>
                                Komentiraj
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </CommentsAddStyled>
    )
}

CommentsAdd.propTypes = {
    className: PropTypes.string
}

CommentsAdd.defaultProps = {
    className: undefined
}

export default CommentsAdd
