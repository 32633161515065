// import { isValidElement } from 'react'

import { SourceType } from '../EternalAds.interface'
import { parseIntegerValue } from '../helpers/parseIntegerValue'
import { parseYesOrNo } from '../helpers/parseYesOrNo'
import {
    ParsedWallpaperDimensions,
    WallpaperCreativeType,
    WallpaperData,
    WallpaperElementData,
    WallpaperPart
} from './Wallpaper.interface'

export const isUndef = <T>(val: T): boolean => typeof val === 'undefined'
export const isEmpty = <T>(val: T): boolean => isUndef(val) || !val

export const topElementExists = (
    element: ParsedWallpaperElement
    // options: NonNullable<ParsedWallpaperData['options']>
): boolean => !!element && element.sourceType !== SourceType.NONE && (!!element.fileImage || !!element.fileUrl)

export const topElementValid = (element: ParsedWallpaperElement): { isValid: boolean; errorMessage: string } => {
    if (isEmpty(element)) return { isValid: false, errorMessage: 'element is undefined' }
    if (isEmpty(element?.sourceType)) return { isValid: false, errorMessage: 'element.sourceType is undefined' }
    if (element?.sourceType === SourceType.NONE) return { isValid: false, errorMessage: 'element.sourceType is None' }

    if (isEmpty(element?.fileImage) && isEmpty(element?.fileUrl))
        return { isValid: false, errorMessage: 'element.fileImage && element.fileUrl is undefined' }
    if (isEmpty(element.height) || isEmpty(element.width)) {
        return { isValid: false, errorMessage: 'element.height || element.width is undefined' }
    }
    return { isValid: true, errorMessage: '' }
}
const parseWallpaperElementProperties = ({
    element,
    position,
    creativeType,
    topElement,
    dimensions
}: {
    element: WallpaperElementData
    position: WallpaperPart
    creativeType: WallpaperCreativeType
    topElement: WallpaperElementData
    dimensions: ParsedWallpaperDimensions
}) => {
    const { width, hasGlobalClickTag, ...rest } = element
    const { pageWidth, height, topHeight } = dimensions
    const isTop = position === WallpaperPart.TOP
    const isLeft = position === WallpaperPart.LEFT
    const isRight = position === WallpaperPart.RIGHT
    const output = {
        width: parseIntegerValue(width) || (isTop && pageWidth) || undefined,
        hasGlobalClickTag: parseYesOrNo(hasGlobalClickTag),
        position: [0, 0],
        height: isTop ? topHeight : height,
        ...rest
    }
    if (creativeType === WallpaperCreativeType.SINGLE) {
        const sidesWidth = (dimensions.width || 0) - (dimensions.pageWidth || 0)
        return {
            ...output,
            width: isTop ? pageWidth : sidesWidth / 2 || output.width,
            height: isTop ? topHeight : height,
            sourceType: topElement.sourceType,
            fileImage: topElement.fileImage,
            fileUrl: topElement.fileUrl,
            htmlContent: topElement.htmlContent,
            position: isLeft
                ? [0, 0]
                : isRight
                ? [-((dimensions.pageWidth || 0) + sidesWidth / 2), 0]
                : [-sidesWidth / 2, 0]
        }
    }

    return output
}

export type ParsedWallpaperElement = ReturnType<typeof parseWallpaperElementProperties>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseCallbacks = (callbacks?: string): ((...args: any[]) => void)[] => {
    if (!callbacks) return []
    return callbacks
        .split(',')
        .map(callb => {
            try {
                const parsedCallback = JSON.parse(callb)
                if (parsedCallback && typeof parsedCallback === 'function') {
                    return (...args) => {
                        try {
                            parsedCallback(...args)
                        } catch (e) {
                            console.error('Error in wallpaper callback', e)
                        }
                    }
                }
            } catch (err) {
                console.error('Error parsing wallpaper callback', err)
                return false
            }
            return false
        })
        .filter(s => typeof s == 'function') as ((...args: any[]) => void)[]
}
export const parseWallpaperData = (data?: WallpaperData) => {
    if (!data) return {}
    const {
        creativeType,
        ad,
        animatedSides,
        clickUrlUnesc,
        closePosition,
        height,
        left,
        openInNewTab,
        pageWidth,
        right,
        showSides,
        thirdPartyImpressionUrl,
        top,
        topHeight,
        viewUrlUnesc,
        width,
        thirdPartyTagsHtml
    } = data

    const options = {
        creativeType,
        animatedSides: parseYesOrNo(animatedSides),
        showSides,
        closePosition
    }
    const dimensions: ParsedWallpaperDimensions = {
        height: parseIntegerValue(height),
        width: parseIntegerValue(width),
        topHeight: parseIntegerValue(topHeight),
        pageWidth: parseIntegerValue(pageWidth)
    }
    const clickOptions = {
        openInNewTab: parseYesOrNo(openInNewTab),
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        clickUrlUnesc,
        thirdPartyTagsHtml
    }
    const commonProps = {
        creativeType,
        topElement: top,
        dimensions
    }
    const leftParsed = parseWallpaperElementProperties({
        element: left,
        position: WallpaperPart.LEFT,
        ...commonProps
    })
    const rightParsed = parseWallpaperElementProperties({
        element: right,
        position: WallpaperPart.RIGHT,
        ...commonProps
    })
    const topParsed = parseWallpaperElementProperties({
        element: top,
        position: WallpaperPart.TOP,
        ...commonProps
    })
    const topElementValidity = topElementValid(topParsed)
    return {
        elements: {
            left: leftParsed,
            right: rightParsed,
            top: topParsed
        },
        dimensions,
        options,
        clickOptions,
        ad,
        topFilled: topElementValidity.isValid || false,
        topElementValidity
    }
}

export type ParsedWallpaperData = ReturnType<typeof parseWallpaperData>
