import { useContext } from 'react'

import { SettingsContext } from '../controllers'

const useSettings = () => {
    const settings = useContext(SettingsContext)

    return {
        ...settings
    }
}

export default useSettings
