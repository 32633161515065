import { InitialLoadActionTypes as ActionTypes } from '../actions/initialLoad'
import parseClientEnvironment from '../helpers/parseClientEnvironment'

const initialState = {
    gfcLoaded: false,
    linkerLwLoaded: false,
    linkerSplideLoaded: false,
    upScoreLoaded: false,
    upScoreDomain: null,
    upScoreReady: false,
    environment: parseClientEnvironment()
}

const initialLoadReducer = (
    state = initialState,
    {
        newStore,
        type,
        gfcLoaded,
        linkerLwLoaded,
        linkerSplideLoaded,
        upScoreLoaded,
        upScoreDomain,
        upScoreReady,
        environment
    } = {}
) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.SET_ENVIRONMENT: {
            return {
                ...state,
                environment
            }
        }

        case ActionTypes.SET_GFC_LOADED: {
            return {
                ...state,
                gfcLoaded
            }
        }

        case ActionTypes.SET_LINKER_LW_LOADED: {
            return {
                ...state,
                linkerLwLoaded
            }
        }

        case ActionTypes.SET_LINKER_SPLIDE_LOADED: {
            return {
                ...state,
                linkerSplideLoaded
            }
        }

        case ActionTypes.SET_UPSCORE_LOADED: {
            return {
                ...state,
                upScoreLoaded
            }
        }

        case ActionTypes.SET_UPSCORE_DOMAIN: {
            return {
                ...state,
                upScoreDomain
            }
        }

        case ActionTypes.SET_UPSCORE_READY: {
            return {
                ...state,
                upScoreReady
            }
        }

        default:
            return state
    }
}

export { initialLoadReducer }
