/* eslint-disable quote-props, max-len */
export default {
    // Display
    // Show for Screen Reader
    '.showForSr': {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: 0
    },
    // Text
    '.u-textLeft': {
        textAlign: 'left'
    },
    '.u-textCenter': {
        textAlign: 'center'
    },
    '.u-textRight': {
        textAlign: 'right'
    },
    '.u-textJustify': {
        textAlign: 'justify'
    },
    '.u-textUppercase': {
        textTransform: 'uppercase'
    },
    '.u-textUppercaseFirst': {
        '&::first-letter': {
            textTransform: 'uppercase'
        }
    },
    '.u-textLowercase': {
        textTransform: 'lowercase'
    },
    '.u-textCapitalize': {
        textTransform: 'capitalize'
    },
    '.u-textTruncate': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordWrap: 'normal'
    },
    '.u-textBreak': {
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    },
    '.u-textNowrap': {
        whiteSpace: 'nowrap'
    },
    // Box sizing
    '.no-padding': {
        padding: 0
    },
    // Flex align
    '.u-alignSelf': {
        '&_start': {
            alignSelf: 'start'
        },
        '&_center': {
            alignSelf: 'center'
        },
        '&_end': {
            alignSelf: 'end'
        }
    }
}
