import { useCallback, useEffect, useRef } from 'react'

/**
 * This hook allows for normal use of `setInterval` in a declarative way
 * whenever you want to repeat something on interval.
 *
 * Usage:
 *
 *      const pollDelay = 5000
 *      const [items, setItems] = useState([])
 *
 *      const fetchItems = useCallback(async () => {
 *           // Fetch items from API
 *      }, [])
 *
 *      useInterval(
 *          () => {
 *              fetchItems()
 *          },
 *          {
 *          delay: pollDelay
 *          }
 *      )
 *
 *      // with interval destruction
 *      useInterval(
 *          (cleanUp) => {
 *              if( someConditionIsSatisfied ){
 *                  cleanUp()
 *              }
 *          },
 *          {
 *              delay: 5000
 *          }
 *      )
 *
 *      // with runInterval
 *      const { runInterval } = useInterval(
 *          someCallback,
 *          {
 *              delay: 5000,
 *              enabled: false
 *          }
 *      )
 *
 *      // some code ----
 *
 *      useEffect(()=>{
 *          if(someCondition){
 *              runInterval()
 *          }
 *      },[someCondition])
 *
 * @param {func} callback, can accept cleanUp function
 * @param {object} options (default: undefined)
 * @param {number} options.delay (default: null)
 * @param {boolean} options.enabled (default: true)
 * @param {boolean} options.runImmediately (default: false) - runs cb right away if true and enabled = true
 * @param {number} options.maxRetries (default: 0) - if N > 0, will stop interval after N retries
 * @returns {obj} callbacks - functions that control running of the interval
 * @returns {func} callbacks.cleanUp - clearsInterval
 * @returns {func} callbacks.runInterval -  if interval is not running (was stopped or wasn't enabled),
 *                                          this callback will start it again (won't start double),
 *                                          accepts a number as only param, which redefines the delay for the interval,
 *                                          runImmidiately still applies (default is false)
 */
const useInterval = (callback, options) => {
    const { delay = null, enabled = true, runImmediately = false, maxRetries = 0 } = options || {}

    // @NOTE: this can be dropped if deemed unneccesary
    const checkDelay = useCallback(
        inputDelay => {
            if (inputDelay <= 300 && inputDelay !== null) {
                // eslint-disable-next-line no-console
                console.warn(
                    `[useInterval]: Are you sure you need to run this callback every ${inputDelay}ms?`,
                    callback
                )
            }
        },
        [callback]
    )

    const savedCallback = useRef(callback)
    const intervalId = useRef(null) // also running state of the interval

    const cleanUp = useCallback(() => {
        if (intervalId.current !== null) {
            clearInterval(intervalId.current)
            intervalId.current = null
        }
    }, [])

    const retriesRef = useRef(0)

    const checkRetries = useCallback(() => {
        if (maxRetries && retriesRef.current >= maxRetries) {
            cleanUp()
            return false
        }
        return true
    }, [maxRetries, cleanUp])

    const runInterval = useCallback(
        inputDelay => {
            const internalDelay = inputDelay !== null ? inputDelay : delay
            if (!checkRetries()) {
                cleanUp()
                return
            }
            checkDelay(internalDelay)
            if (intervalId.current === null && internalDelay !== null) {
                const tick = () => {
                    if (!checkRetries()) {
                        return
                    }
                    retriesRef.current += 1
                    savedCallback.current?.(cleanUp)
                }
                if (runImmediately) {
                    tick()
                }
                intervalId.current = setInterval(tick, internalDelay)
            }
        },
        [delay, checkDelay, runImmediately, cleanUp]
    )

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        checkDelay(delay)
        if (enabled) {
            runInterval(delay)
        }
        return cleanUp
    }, [delay, enabled, checkDelay, cleanUp])

    return { runInterval, cleanUp }
}

export default useInterval
