/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Video = createSvgIcon(
    <>
        <path
            d="M29.999 19c-3.916 0-7.047.175-9.316.369l-.043.004c-.943.08-1.714.146-2.342.292-.664.155-1.21.407-1.693.88-.485.476-.747 1.025-.914 1.696-.16.636-.241 1.423-.342 2.388l-.004.042c-.183 1.752-.345 3.762-.345 5.4 0 1.64.162 3.649.345 5.401l.004.042c.1.966.183 1.752.342 2.388.167.671.43 1.22.914 1.695.483.474 1.029.726 1.693.881.628.146 1.399.212 2.342.293l.043.004c2.269.194 5.4.368 9.316.368s7.046-.175 9.316-.369l.042-.003c.943-.08 1.714-.147 2.342-.293.665-.155 1.21-.407 1.693-.88.485-.476.747-1.025.914-1.696.16-.636.241-1.422.342-2.388l.004-.042c.183-1.752.345-3.762.345-5.4 0-1.64-.162-3.648-.345-5.401l-.004-.042c-.1-.965-.183-1.752-.342-2.388-.167-.67-.43-1.22-.914-1.695-.482-.474-1.028-.726-1.693-.88-.628-.147-1.399-.213-2.342-.293l-.043-.004C37.046 19.175 33.915 19 30 19zm-9.214 1.565c2.237-.192 5.334-.365 9.214-.365s6.976.173 9.213.365c.996.085 1.681.145 2.215.27.508.117.837.285 1.125.567.285.28.46.61.59 1.13.136.544.21 1.246.317 2.264.182 1.74.338 3.7.338 5.276s-.156 3.535-.338 5.276c-.106 1.017-.18 1.72-.317 2.264-.13.519-.305.849-.59 1.13-.288.281-.617.45-1.125.567-.534.125-1.219.185-2.215.27-2.237.191-5.334.364-9.213.364s-6.977-.173-9.214-.364c-.996-.085-1.68-.145-2.215-.27-.508-.118-.837-.286-1.125-.568-.285-.28-.46-.61-.59-1.13-.136-.544-.21-1.246-.317-2.263-.182-1.741-.338-3.701-.338-5.276s.156-3.535.338-5.276c.106-1.018.18-1.72.317-2.264.13-.52.305-.85.59-1.13.288-.282.617-.45 1.125-.568.534-.124 1.219-.184 2.215-.27z"
            fill="#fff"
        />
        <path d="M29.976 25.416l.05.033 3.394 2.263.046.03c.525.35.987.659 1.31.951.345.311.671.726.671 1.307s-.326.996-.67 1.307c-.324.293-.786.6-1.311.95l-3.49 2.327c-.642.428-1.193.796-1.652 1.012-.471.223-1.063.393-1.65.078-.587-.314-.774-.9-.85-1.415-.074-.502-.074-1.165-.074-1.936v-4.586-.06c0-.771 0-1.434.074-1.936.076-.515.263-1.101.85-1.415.587-.314 1.179-.145 1.65.078.459.216 1.01.584 1.652 1.012zm-2.62.24s.003-.002.012-.002l-.012.002zm.03 0c.042.004.131.025.297.104.346.163.805.467 1.51.937l3.395 2.263c.585.39.952.637 1.183.846a.883.883 0 01.174.194l-.003.005a.883.883 0 01-.171.19c-.231.208-.598.455-1.183.845l-3.395 2.263c-.705.47-1.164.774-1.51.937a1.05 1.05 0 01-.297.105 1.045 1.045 0 01-.078-.306c-.056-.378-.058-.928-.058-1.776v-4.526c0-.848.002-1.398.058-1.776.027-.182.059-.269.078-.306zm-.03 8.688s.005 0 .012.002c-.009 0-.012-.002-.012-.002zm.038.016c.007.005.01.01.01.01s-.004-.002-.01-.01zm0-8.72c.006-.008.01-.01.01-.01s-.003.005-.01.01z" />
    </>,
    'Video'
)

export default Video
