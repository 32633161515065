import dataProvider from '@hmn/data-provider'

const getResourceUri = resource => {
    const resourceBase = resource.replace('taxonomies/', '').split('/').shift()
    return `taxonomies/${resourceBase}/taxons`
}

/**
 * Get a list of taxons from taxonomy
 *
 * @param {string} resource
 * @return {Object}
 * { data, total }
 */
export const getList = async resource => {
    const resourceUri = getResourceUri(resource)

    const result = await dataProvider.getList(resourceUri)

    const { data, total } = result

    return {
        data,
        total: total || data?.length || 0
    }
}

/**
 * Attach a taxon on taxonomy
 *
 * @param {string} resource
 * @param {Object} params
 * @param {Object} params.data
 * @return {Object}
 * { data }
 */
export const createOne = async (resource, params) => {
    const { data: payload } = params
    const resourceUri = getResourceUri(resource)

    const result = await dataProvider.create(resourceUri, {
        data: payload
    })

    await new Promise(resolve => {
        setTimeout(resolve, 5000)
    })

    const { data } = result

    return {
        data
    }
}

/**
 * Update a taxon on taxonomy
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string|number} params.id
 * @param {Object} params.data
 * @return {Object}
 * { data }
 */
export const updateOne = async (resource, params) => {
    const { id, data: payload } = params
    const resourceUri = getResourceUri(resource)

    const result = await dataProvider.update(resourceUri, {
        id,
        data: payload
    })

    const { data } = result

    return {
        data
    }
}

/**
 * Remove a taxon from taxonomy
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string|number} params.id
 * @return {*}
 */
export const deleteOne = async (resource, params) => {
    const { id } = params
    const resourceUri = getResourceUri(resource)

    await dataProvider.delete(resourceUri, {
        id
    })

    return 'ok'
}
