/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Three = createSvgIcon(
    <path
        d="M10 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20zm0-18.438c-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.785 8.438-8.438 0-4.652-3.785-8.438-8.438-8.438zm3.906 11.446a.781.781 0 00-.781-.781h-6.25a.781.781 0 100 1.562h6.25c.431 0 .781-.35.781-.781zM6.562 6.484a.977.977 0 110 1.953.977.977 0 010-1.953zm5.86.977a.977.977 0 101.953 0 .977.977 0 00-1.953 0z"
        fill="#000"
    />,
    'Three'
)

export default Three
