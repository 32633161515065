import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { GlobalProfileHeader, GlobalProfileNavigation } from '@hmn/rtl-zena-ui/components/GlobalProfile/components'

import style from './ProfileLayout.style'

const LayoutStyled = styled.main(props => ({ ...style(props) }))

function ProfileLayout({ children, from }) {
    const router = useRouter()
    const primaryMenu = [
        { label: 'Tvoji podaci', href: '/profil' },
        { label: 'Zena.hr', href: '/profil/zena-hr' }
    ]

    const handleCloseProfile = () => {
        router.push(from)
    }

    return (
        <LayoutStyled>
            <GlobalProfileHeader />
            <GlobalProfileNavigation primaryMenu={primaryMenu} handleCloseProfile={handleCloseProfile} />
            {children}
        </LayoutStyled>
    )
}

ProfileLayout.propTypes = {
    from: PropTypes.string
}

ProfileLayout.defaultProps = {
    from: '/'
}

export default ProfileLayout
