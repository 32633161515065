import useBreakpoints from '../useBreakpoints'
/**
 * # Warning: Causes a component rerender on client side
 * uses useBreakpoints which is all kinds of bad
 * `useDeviceType
 * Provides a deviceType value based on current breakpoint (client reliant)
 * Initial value on ssr (no window) is null
 *
 * returns [deviceType:string,isDesktop:boolean,isMobile:boolean]
 * ```
 */
const useDeviceType = () => {
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)
    return [deviceType, deviceType === 'desktop', deviceType === 'mobile']
}

export default useDeviceType
