const constants = {
    CLOSE: 'MODALS/CLOSE_MODAL',
    OPEN: 'MODALS/OPEN_MODAL',
    TOGGLE: 'MODALS/TOGGLE_MODAL'
}

const actions = {
    open: id => ({ type: constants.OPEN, id }),
    close: id => ({ type: constants.CLOSE, id }),
    toggle: id => ({ type: constants.TOGGLE, id })
}

export { constants as ModalActionTypes, actions as ModalActions }
