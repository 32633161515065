import { danasAdditionalRootCategories, isDanas } from './danas0'

export interface DanasRootCategoryConstructorParams {
    slug: string
    omitSlug?: boolean
}

export { isDanas } from './danas0'

export const danasCategoryHoles: string[][] = [
    ['videovijesti', 'show-videa'],
    ['videovijesti', 'kockica-videa']
]

export const isDanasCategoryHole = (slugs: string[][]): boolean => {
    const holesGlued = danasCategoryHoles.map(hole => hole.join('/'))
    const slugsGlued = slugs.join('/')

    for (const hole of holesGlued) {
        if (slugsGlued.startsWith(hole)) {
            return true
        }
    }

    return false
}

export class DanasRootCategory {
    protected slug: string
    protected omitSlug: boolean

    constructor(params: DanasRootCategoryConstructorParams) {
        this.slug = params.slug
        this.omitSlug = params.omitSlug || false
    }

    getSlug(): string {
        return this.slug
    }

    shouldOmitSlug(): boolean {
        return this.omitSlug
    }
}

export const danasRootCategories: DanasRootCategory[] = [
    new DanasRootCategory({ slug: 'vijesti', omitSlug: true }),
    ...danasAdditionalRootCategories.map(
        additionalRootCategorySlug => new DanasRootCategory({ slug: additionalRootCategorySlug })
    )
]

export const filterRootCategoriesBySlug = (slug: string): DanasRootCategory[] =>
    danasRootCategories.filter((rootCategory: DanasRootCategory) => rootCategory.getSlug() === slug)

export const danasRootCategoriesSlugs: string[] = danasRootCategories.map((rootCategory: DanasRootCategory) =>
    rootCategory.getSlug()
)

export const findDanasRootCategory = (firstSlug: string): DanasRootCategory | undefined =>
    filterRootCategoriesBySlug(firstSlug)[0]

export const isDanasRootCategory = (firstSlug: string): boolean => filterRootCategoriesBySlug(firstSlug).length > 0

export const isNotDanasRootCategory = (firstSlug: string): boolean => !isDanasRootCategory(firstSlug)

export const omitDanasRootCategorySlug = (slugs: string[], isDanasOverride?: boolean): string[] => {
    const [firstSlug, ...otherSlugs] = slugs

    if (isDanasOverride || isDanas) {
        const danasRootCategory = findDanasRootCategory(firstSlug)

        if (danasRootCategory === undefined) {
            return slugs
        }

        if (danasRootCategory.shouldOmitSlug()) {
            return otherSlugs
        }

        return slugs
    }

    return slugs
}

export const prepareUriAndOmitDanasRootCategorySlug = (uri: string, isDanasOverride?: boolean): string => {
    if (!uri) return ''
    // Splits URL intro array separated by `/` and removes empty string chunks.
    // `uri` has leading `/` which when split converts to empty string which we need to filter out.
    const cleanedUri: string[] = uri.split('/').filter((s: string) => typeof s === 'string' && s.length > 0)
    if (!cleanedUri.length) return ''
    // It the client is `danashr`, and the root category is to be removed, it removes it.
    return omitDanasRootCategorySlug(cleanedUri, isDanasOverride).join('/')
}

export const omitDanasRootCategorySlugFromUrl = (url: string, isDanasOverride?: boolean): string => {
    let origin: string
    let pathname: string
    try {
        const urlObject = new URL(url)
        origin = urlObject.origin + '/'
        pathname = urlObject.pathname
    } catch (err) {
        origin = ''
        pathname = url
    }
    return `${origin}${prepareUriAndOmitDanasRootCategorySlug(pathname, isDanasOverride)}`
}
