import { MergeTypes } from '../../../../types/helpers/MergeTypes'
import { Ad, ClosePosition, SourceType, YesOrNo } from '../EternalAds.interface'
import type { ParsedWallpaperData, ParsedWallpaperElement } from './Wallpaper.helpers'

export type WallpaperElementData = {
    sourceType: SourceType
    fileImage?: string
    fileUrl?: string
    width: string
    hasGlobalClickTag: YesOrNo
    clickUrl?: string
    clickTagName?: string
    htmlContent?: string
}

export enum ShowSides {
    BOTH = 'Both',
    RIGHT = 'Right only',
    LEFT = 'Left only'
}

export enum WallpaperPart {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top'
}
export enum WallpaperCreativeType {
    SINGLE = 'Single creative (Skin)',
    MULTIPLE = 'Multi creative (Wallpaper/Skin)'
}
export type WallpaperData = {
    ad: Ad
    animatedSides: YesOrNo
    clickUrlUnesc: string
    closePosition: ClosePosition
    creativeType: WallpaperCreativeType
    height: string
    left: WallpaperElementData
    openInNewTab: YesOrNo
    pageWidth: string
    right: WallpaperElementData
    showSides: ShowSides
    thirdPartyImpressionUrl: string
    thirdPartyTagsHtml?: string
    top: WallpaperElementData
    topHeight: string
    viewUrlUnesc: string
    width: string
}

export type ParsedWallpaperDimensions = {
    height: number | undefined
    width: number | undefined
    topHeight: number | undefined
    pageWidth: number | undefined
}

export type CommonWallpaperElementProps = {
    element: ParsedWallpaperElement
    ad: Ad
    className?: string
    options: NonNullable<ParsedWallpaperData['options']>
    dimensions: NonNullable<ParsedWallpaperData['dimensions']>
    clickOptions: NonNullable<ParsedWallpaperData['clickOptions']>
    isPreview?: boolean
    layoutOptions?: {
        maxHeight?: number
    }
}

export type WallpaperSideProps = MergeTypes<
    CommonWallpaperElementProps,
    {
        left?: boolean
    }
>

export type WallpaperTopProps = CommonWallpaperElementProps
