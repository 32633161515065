import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { SocialItem, socialItemColorVariants } from '..'
import styles from './FooterBar.style'

const FooterBarStyled = styled.div(props => ({ ...styles(props) }))

function FooterBar({ title, links, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)

    if (!links?.length) {
        return null
    }

    return (
        <FooterBarStyled {...rest}>
            <div className="title">
                <div className="line" />
                <div className="text" {...titleHtmlProps} />
            </div>
            <ul className="socialIcons">
                {links
                    .filter(link => link?.href)
                    .map(link => (
                        <li key={link.href} className="socialIcons_item">
                            <SocialItem
                                icon={link.icon}
                                href={link.href}
                                colorVariant={socialItemColorVariants.FOOTER}
                                hrefIsExternal
                            />
                        </li>
                    ))}
            </ul>
        </FooterBarStyled>
    )
}

FooterBar.propTypes = {
    title: PropTypes.string,
    links: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

FooterBar.defaultProps = {
    title: 'Pratite nas',
    links: undefined
}

export default withErrorBoundary(withTheme(FooterBar), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[FooterBar]: ', error, componentStack)
    }
})
