/* eslint-disable no-console */
import { useMemo } from 'react'
import { QueryCache, QueryClient, useQuery } from 'react-query'
import getConfig from 'next/config'

import httpClient from '../http/client'
import { handleError } from '../next/data-fetching/handle-errors'
import { appQuerySettings } from '../queries/utils'
import { isDev } from './useBaseApiURL'
import useSettings from './useSettings'

const enabledLog = false

const { publicRuntimeConfig = {} } = getConfig()
const { xClientName: clientNameSSR = process.env.NEXT_PUBLIC_API_CLIENT } = publicRuntimeConfig
const apiRootUrl = process.env.NEXT_PUBLIC_API_ROOT_URL
const internalApiRootUrl = process.env.NEXT_PUBLIC_API_ROOT_URL_INTERNAL

// @TODO: Define default page limit, expose it through env variables?
const DEFAULT_PAGE_LIMIT = 30

const dateProperties = [
    'published_start',
    'published_at',
    'published_end',
    'archived_at',
    'created_at',
    'updated_at',
    'publish_requested_at',
    'launched_at'
]

// eslint-disable-next-line no-unused-vars
const formatProperties = item => {
    if (!item || typeof item !== 'object') {
        return item
    }
    const keys = Object.keys(item)
    if (!keys.length) {
        return item
    }
    return keys.reduce((o, key) => {
        if (dateProperties.includes(key) && typeof item[key] === 'string' && item[key]) {
            o[key] = new Date(item[key])
        } else {
            o[key] = item[key]
        }
        return o
    }, {})
}

/**
 * This does standard data mutation for example
 * parses date strings into valid Date objects.
 *
 * @param {Array|object} data
 * @return {Array|object}
 */
const formatData = data => {
    if (Array.isArray(data)) {
        return data.map(item => formatProperties(item))
    }

    return formatProperties(data)
}

/**
 * Create QueryClient instace with cache
 * and app wide SSR settings.
 *
 * If req is provided or running in the browser it
 * will try to resolve global QueryClient instance
 * for that request/browser.
 *
 * You can also provide your own cache instance, if not
 * new one will be created on each call.
 *
 * Most of the time you do not need to use this method directly
 * bust just use createDataClient on the server side or useDataClient
 * on the client side.
 *
 * @param {*} [{ req }={}]
 * @return {QueryClient}
 */
export const createQueryClient = ({ req } = {}) => {
    if (req?.queryClient) {
        return req.queryClient
    }

    const queryCache = new QueryCache()

    const queryClient = new QueryClient({
        queryCache,
        defaultOptions: {
            queries: {
                ...appQuerySettings
            }
        }
    })

    return queryClient
}

const fetch = async ({ resource, params = {}, options = { useInternalApiUrl: false } }) => {
    // default namespace comes from elasticsearch.controller, moving forward there will be more different namespaces
    const { namespace = 'combined', limit = DEFAULT_PAGE_LIMIT, page = 1, search, query } = params

    const { useInternalApiUrl } = options

    const apiUrl = (() => {
        if (useInternalApiUrl && !isDev) {
            return internalApiRootUrl
        }

        return apiRootUrl
    })()

    const { data } = await httpClient({
        url: resource,
        ...options,
        headers: {
            'content-type': 'application/json',
            'x-client-name': options?.extend?.clientName,
            ...options?.headers
        },
        method: options?.method?.toLowerCase() || 'post', // Default method is POST because we're mostly sending ES queries in request bodies
        ...(options?.method?.toLowerCase() !== 'get' && {
            data: {
                limit,
                page,
                ...search
            }
        }),
        params: query,
        baseURL: [apiUrl, namespace].filter(Boolean).join('/'),
        timeout: options?.extend?.timeout || 40000
    })

    const formattedData = formatData(data)

    return formattedData
}

/**
 * Consruct reusable query key for easier query cache control.
 *
 * @param {*} { type, resource = '', params = {} }
 */
const createQueryKey = ({ type, resource = '', params, ...rest }) =>
    [type, ...(resource?.toString()?.split('/') || ['resource-missing']), params, rest]
        .filter(value => typeof value !== 'object' || Array.isArray(value) || !!Object.keys(value || {})?.length)
        .filter(Boolean)

/**
 * Use the above query key constructor in hook form, for reusability within client side query hooks
 *
 * @param {*} { type, resource = '', params = {} }
 */
export const useQueryKey = ({ queryKey: injectedQueryKey, type, resource = '', params = {}, ...rest }) =>
    useMemo(
        () => injectedQueryKey || createQueryKey({ type, resource, params, ...rest }),
        [type, resource, JSON.stringify(params), JSON.stringify(rest), injectedQueryKey]
    )

/**
 * Use the above query key constructor in get form, this is only for naming convention consistency & readability
 *
 * @param {*} { type, resource = '', params = {} }
 */
export const getQueryKey = ({ type, resource = '', params = {}, ...rest }) =>
    createQueryKey({ type, resource, params, ...rest })

/**
 * Validate resource payload before proceeding with request, for better DX.
 *
 * @param {*} resource
 */
const validateResource = resource => {
    try {
        if (!resource) {
            throw new Error('Resource is required')
        }

        if (resource && typeof resource !== 'string') {
            throw new Error(
                // eslint-disable-next-line max-len
                `Invalid resource type provided, expected 'string' type, received '${typeof resource}' type instead.`
            )
        }
    } catch (error) {
        console.error('[useData] Error -', isDev ? error : error.message)
        return false
    }

    return !!resource
}

export const useData = ({
    resource,
    queryKey: injectedQueryKey,
    params = {},
    options = {},
    enabled = true,
    errorHandlers = []
} = {}) => {
    validateResource(resource)

    const { xClientName: clientName } = useSettings()

    const { preview = false } = options

    const resourceUri = preview ? `${resource}?preview=true` : resource

    const requestOptions = {
        method: options?.method?.toLowerCase() || 'get',
        extend: {
            clientName,
            ...options?.extend?.request
        },
        ...options?.request
    }

    const generatedQueryKey = useQueryKey({ type: 'one', resource: resourceUri, params })
    const queryKey = useMemo(() => injectedQueryKey || generatedQueryKey, [injectedQueryKey, generatedQueryKey])

    const { data, status, error, isFetching, refetch } = useQuery(
        queryKey,
        () =>
            fetch({
                resource: resourceUri,
                params,
                options: requestOptions
            }).catch(handleError(errorHandlers)),
        {
            ...appQuerySettings,
            ...(options?.settings || {}),
            enabled: !!enabled
        }
    )

    return {
        data,
        queryKey,
        status,
        error,
        isFetching,
        refetch
    }
}

/**
 * Fetch data for single resource
 *
 * @param {*} param0
 * @returns
 */
const getData = async ({
    resource,
    queryKey: injectedQueryKey,
    params = {},
    options = {},
    queryClient: injectedQueryClient
}) => {
    validateResource(resource)

    const { preview = false } = options

    const resourceUri = preview ? `${resource}?preview=true` : resource

    const queryClient = injectedQueryClient || createQueryClient()

    const requestOptions = {
        method: 'get',
        extend: {
            clientName: clientNameSSR,
            ...options?.extend?.request
        },
        ...options?.request,
        useInternalApiUrl: true
    }

    const queryKey = injectedQueryKey || getQueryKey({ type: 'one', resource: resourceUri, params })

    const data = await queryClient.fetchQuery(
        queryKey,
        () =>
            fetch({
                resource: resourceUri,
                params,
                options: requestOptions
            }),
        {
            ...(options?.settings || {})
        }
    )

    return {
        queryKey,
        data
    }
}

/**
 * Fetch paginated data list for provided resource on server side, both pagination & infinite load are supported.
 *
 * @param {*} param0
 * @returns
 */
const getInfiniteData = async ({
    resource,
    params = {},
    options = {},
    enabled = true,
    queryClient: injectedQueryClient
}) => {
    validateResource(resource)

    const queryClient = injectedQueryClient || createQueryClient()

    const requestOptions = {
        extend: {
            clientName: clientNameSSR,
            ...options?.extend?.request
        },
        ...options?.request,
        useInternalApiUrl: true
    }

    const queryKey = getQueryKey({ type: 'list', resource, params })

    const data = await queryClient.fetchInfiniteQuery(
        queryKey,
        ({ pageParam = 1 }) =>
            fetch({
                resource,
                params: {
                    page: pageParam,
                    ...params
                },
                options: requestOptions
            }),
        {
            enabled: !!enabled,
            ...(options?.settings || {}),
            getNextPageParam: (lastPage, allPages) => {
                if (isDev && enabledLog) {
                    console.log('[useData] getNextPageParam', {
                        lastPage,
                        allPages
                    })
                }

                const { limit = DEFAULT_PAGE_LIMIT } = params
                const morePagesExist = lastPage?.length >= limit || lastPage?.data?.length >= limit

                if (!morePagesExist) {
                    return undefined
                }

                return allPages.length + 1
            }
        }
    )

    return {
        queryKey,
        data
    }
}

/**
 * This is just an internal method that creates usual data methods
 * that share the same queryClient.
 *
 * Do not use this method directly but
 * use:
 * - createDataClient on the server side
 * - useDataClient on the client side
 *
 * @param {*} { queryClient }
 */
const createDataMethods = ({ queryClient }) => ({
    queryClient,
    getData: async ({ resource, queryKey, params = {}, options = {}, enabled = true }) =>
        getData({ resource, queryKey, params, options, enabled, queryClient }),
    getInfiniteData: async ({ resource, params = {}, options = {}, enabled = true }) =>
        getInfiniteData({ resource, params, options, enabled, queryClient })
})

/**
 * Create data fetching methods with injected queryClient
 * from the server side request.
 *
 * Created to be used on the server side.
 *
 * @param {*} { req }
 * @return {*}
 */
export const createDataClient = ({ req }) => {
    const queryClient = createQueryClient({ req })

    return createDataMethods({ queryClient })
}
