/* eslint-disable @typescript-eslint/naming-convention */
import { useAmp } from 'next/amp'
import React, { ComponentType, useMemo } from 'react'

import useBreakpoints from '../../../../hooks/useBreakpoints'
import usePathQueryValue from '../../../../hooks/usePathQueryValue'
import { useNativeAdsState } from '../../../../selectors/ads'
import { AntiClsFrame } from '../../../AntiClsFrame'
import { InjectHtml } from '../components/InjectHtml'
import { Invisible } from '../components/Invisible'
import { TrackingPixel } from '../components/TrackingPixel'
import WallpaperSide from './components/WallpaperSide.component'
import WallpaperTop from './components/WallpaperTop.component'
import { ParsedWallpaperData, topElementExists } from './Wallpaper.helpers'
import { ShowSides } from './Wallpaper.interface'
import { INJECTED_WALLPAPER_SCRIPTS_VARIABLE_NAME } from './Wallpaper.provider'
import WallpaperStyled from './Wallpaper.style'

export type WallpaperProps = {
    minHeight?: number
    offsetTop?: number | string
    Placeholder?: ComponentType<any>
    placeholderProps?: any
}
const Wallapaper = ({ minHeight: minHeightInitial = 0, Placeholder, placeholderProps, offsetTop }: WallpaperProps) => {
    const [isDesktop] = useBreakpoints('md')
    const isAmp = useAmp()
    const wallpaperData = useNativeAdsState('wallpaper') as ParsedWallpaperData
    const {
        elements: { left = undefined, right = undefined, top = undefined } = {},
        dimensions,
        options,
        ad,
        clickOptions
    } = wallpaperData || {}

    const layoutOptions = useMemo(() => {
        return {
            top: {
                maxHeight: minHeightInitial + 50
            }
        }
    }, [minHeightInitial])

    const minHeight = useMemo(() => {
        if (
            typeof wallpaperData === 'undefined' ||
            typeof wallpaperData?.elements?.top === 'undefined' ||
            typeof wallpaperData?.dimensions?.topHeight === 'undefined'
        )
            return minHeightInitial
        const topExists = topElementExists(wallpaperData?.elements?.top)
        return topExists ? Math.max(wallpaperData?.dimensions?.topHeight, minHeightInitial) : minHeightInitial
    }, [minHeightInitial, wallpaperData])

    const googlePreview = usePathQueryValue({ key: 'google_preview' })
    const isPreview = !!googlePreview

    const { showSides } = options || {}
    const showLeft = !!left && (showSides === ShowSides.LEFT || showSides === ShowSides.BOTH)
    const showRight = !!right && (showSides === ShowSides.RIGHT || showSides === ShowSides.BOTH)
    return (
        // @ts-expect-error: convert AntiClsFrame and ErrorBoundary to TS and properly forward types
        <AntiClsFrame
            minHeightMd={minHeight + 'px'}
            displayXs="none"
            displayMd={isAmp ? 'none' : 'block'}
            pointerEvents={false}>
            {!isAmp && (showLeft || showRight) && ad && isDesktop && (
                <WallpaperStyled
                    offsetTop={offsetTop}
                    contentHeight={dimensions?.height}
                    contentWidth={dimensions?.pageWidth}
                    isFixed
                    sides>
                    <Invisible>
                        {/* Impression tracking via pixels */}
                        <TrackingPixel src={clickOptions?.viewUrlUnesc} />
                        <TrackingPixel src={clickOptions?.thirdPartyImpressionUrl} />
                        {/* Impression tracking via html*/}
                        {clickOptions?.thirdPartyTagsHtml && (
                            <InjectHtml
                                windowKey={INJECTED_WALLPAPER_SCRIPTS_VARIABLE_NAME}
                                html={clickOptions?.thirdPartyTagsHtml}
                            />
                        )}
                    </Invisible>
                    <div className="Wallpaper_container">
                        <div className="Wallpaper_content">
                            {showLeft && (
                                <WallpaperSide
                                    ad={ad}
                                    element={left}
                                    options={options}
                                    dimensions={dimensions}
                                    clickOptions={clickOptions}
                                    left
                                    isPreview={isPreview}
                                    className="Wallpaper_side Wallpaper_left"
                                />
                            )}
                            {showRight && (
                                <WallpaperSide
                                    ad={ad}
                                    element={right}
                                    options={options}
                                    dimensions={dimensions}
                                    clickOptions={clickOptions}
                                    isPreview={isPreview}
                                    className="Wallpaper_side Wallpaper_right"
                                />
                            )}
                        </div>
                    </div>
                </WallpaperStyled>
            )}
            <WallpaperStyled
                offsetTop={offsetTop}
                contentHeight={minHeight}
                contentWidth={dimensions?.pageWidth}
                isFixed
                zIndex={0}>
                <div className="Wallpaper_container">
                    <div className="Wallpaper_content Wallpaper_content_top">
                        {Placeholder ? (
                            <Placeholder maxHeight={minHeight + 'px'} {...(placeholderProps || {})} />
                        ) : null}
                        {!isAmp && top && ad && isDesktop && topElementExists(top) && (
                            <WallpaperTop
                                ad={ad}
                                element={top}
                                options={options}
                                dimensions={dimensions}
                                clickOptions={clickOptions}
                                layoutOptions={layoutOptions?.top}
                                isPreview={isPreview}
                                className="Wallpaper_top"
                            />
                        )}
                    </div>
                </div>
            </WallpaperStyled>
        </AntiClsFrame>
    )
}

export default Wallapaper
