import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Label.style'

const LabelStyled = styled.label(props => ({ ...styles(props) }))

function Label({
    label,
    labelComponent: LabelComponent,
    iconComponent: Icon,
    id,
    error,
    required,
    focused,
    variant,
    size,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    width,
    alignLeft,
    isMobileOnly,
    className,
    ...rest
}) {
    const labelHtmlProps = useInnerHtml(label)

    if (!label && !LabelComponent && !Icon) {
        return null
    }

    return (
        <LabelStyled
            htmlFor={id}
            id={id && `${id}-inputLabel`}
            error={error}
            focused={focused}
            variant={variant}
            size={size}
            alignLeft={alignLeft}
            hasIcon={Icon}
            isMobileOnly={isMobileOnly}
            className={className}
            {...rest}>
            {Icon && <span className="inputLabel_icon">{Icon}</span>}
            {label && <span {...labelHtmlProps} />}
            {LabelComponent && <> {LabelComponent}</>}
            {required && <sup>*</sup>}
        </LabelStyled>
    )
}

const labelVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
})

const labelSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

Label.propTypes = {
    label: PropTypes.string,
    labelComponent: PropTypes.node,
    iconComponent: PropTypes.node,
    id: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    focused: PropTypes.bool,
    variant: PropTypes.oneOf([...Object.values(labelVariants)]),
    size: PropTypes.oneOf([...Object.values(labelSizes)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignLeft: PropTypes.bool,
    isMobileOnly: PropTypes.bool,
    className: PropTypes.string
}

Label.defaultProps = {
    label: undefined,
    labelComponent: undefined,
    iconComponent: undefined,
    id: undefined,
    error: false,
    required: false,
    focused: false,
    variant: labelVariants.PRIMARY,
    size: labelSizes.NORMAL,
    width: undefined,
    alignLeft: false,
    isMobileOnly: false,
    className: undefined
}

export { labelVariants, labelSizes }

export default Label
