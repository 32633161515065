module.exports = Object.freeze({
    /**
     * NOTE:
     * If the client asks to return the 410 status code and
     * redirect the visitor to a specific URL, ignore the request.
     *
     * It is better to just redirect or to land on a 404 page.
     */
    GONE: 410,
    TEMPORARY_REDIRECT: 307,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    FORBIDDEN: 403
})
