// @TODO: Remove onClick prop after we develop a complete solution for GTM
// @NOTE: onClick prop should be used ony for GTM events!!!

import Link from 'next/link'
import PropTypes from 'prop-types'

// @NOTE: Sometimes Link component does not need 'a' tag in it because the component will handle the tag (see Button)

function HmnLink({ children, href, as, passHref, scroll, noTag, title, isExternal, className, onClick }) {
    if (!href || !children) {
        return null
    }

    if (href === '#') {
        return (
            <a href={href} title={title} aria-label={title} className={className} onClick={onClick}>
                {children}
            </a>
        )
    }

    return isExternal ? (
        // @NOTE: client requested norefferer removal
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={href}
            title={title}
            aria-label={title}
            className={className}
            rel="noopener"
            target="_blank"
            onClick={onClick}>
            {children}
        </a>
    ) : (
        <Link href={href} as={as} passHref={noTag ? passHref : false} scroll={scroll} legacyBehavior>
            {!noTag ? (
                // eslint-disable-next-line
                <a className={className} title={title} aria-label={title} onClick={onClick}>
                    {children}
                </a>
            ) : (
                children
            )}
        </Link>
    )
}

HmnLink.propTypes = {
    href: PropTypes.string,
    as: PropTypes.string,
    passHref: PropTypes.bool,
    scroll: PropTypes.bool,
    title: PropTypes.string,
    noTag: PropTypes.bool,
    isExternal: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
}

HmnLink.defaultProps = {
    href: undefined,
    as: undefined,
    passHref: false,
    scroll: true,
    title: undefined,
    noTag: false,
    isExternal: false,
    className: undefined,
    onClick: undefined
}

export default HmnLink
