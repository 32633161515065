// @TODO: Prototype - split strings, check if unit is percentage, pixels, something else

const Style = ({ width, height, maxWidth, maxHeight }) => ({
    width,
    height,
    maxWidth,
    maxHeight
})

export default Style
