import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

/**
 * Returns updated time
 *
 * @param {number} interval - update time every N seconds (defaults 10000 ms)
 * @returns {dayjs.Dayjs}
 */
const useTimeSync = (interval = 10000) => {
    const [time, setTime] = useState(() => dayjs())

    useEffect(() => {
        const syncTime = setInterval(() => {
            setTime(dayjs())
        }, interval)

        return () => clearInterval(syncTime)
    })

    return time
}

export default useTimeSync
