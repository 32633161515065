import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import useEnvironment from './useEnvironment'

const qpqFunctionName = '_coex'

// eslint-disable-next-line max-len
const qpqLoaderScript = `(function (u, p, s, c, r){u[r]=u[r]||function(p){(u[r].q=u[r].q||[]).push(p)},u[r].ls=1*new Date();
var a=p.createElement(s),m=p.getElementsByTagName(s)[0];a.async=1;a.src=c;m.parentNode.insertBefore(a,m)
})(window, document, 'script', 'https://thecrossnet.io/scripts/loader.js', '${qpqFunctionName}');`
const appendScript = () => {
    if (window?.[qpqFunctionName]) {
        return window?.[qpqFunctionName]
    }
    const script = document.createElement('script')
    script.innerHTML = qpqLoaderScript
    document.head.appendChild(script)
    return window?.[qpqFunctionName]
}

const initialLoadSelector = state => state.initialLoad
const useUpScoreLoader = () => {
    const { thirdPartyEnabled } = useEnvironment()
    const mountedRef = useRef(false)
    const [isReady, setReady] = useState(false)
    const timeoutRef = useRef(null)

    const { allLoaded } = useSelector(initialLoadSelector)

    useEffect(() => {
        if (thirdPartyEnabled && !mountedRef.current && allLoaded) {
            mountedRef.current = true
            timeoutRef.current = setTimeout(() => {
                appendScript()
                setReady(true)
            }, 3000)
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                timeoutRef.current = null
            }
            mountedRef.current = false
        }
    }, [allLoaded, thirdPartyEnabled])

    const defineWidget = (domain, itemId, container, key) => {
        window?.[qpqFunctionName]?.({ domain, object_id: itemId, container, key })
    }

    return { defineWidget, isReady }
}
export default useUpScoreLoader
