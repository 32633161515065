import deepmerge from 'deepmerge'
// eslint-disable-next-line @typescript-eslint/naming-convention
import React, {
    Component,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useSyncExternalStore
} from 'react'

import useUpdatedRef from '../hooks/functional/useUpdatedRef'

const arrayOverwriteMerge = (_destinationArray: any[], sourceArray: any[]) => sourceArray

// const returnInput = <T, RT = T>(input: T) => input as any as RT
/**
 * # createFastContext
 * - a fast context implementation that uses a ref to store the state
 * any components that use a specific store are subscribed to changes and will re-render only when the store changes
 * any children in the tree of the Provider that are not subscribed are not affected
 *
 * ## @LIMITATION: if you need a deep reset/overwrite do not use with deeply nested objects, as the store uses a naive deepmerge implementation
 * ## @POSSIBLE_SOLUTION: create a specific reset function that takes current store minus the required reset
 *
 * ## @CAVEAT: any change to the store triggers all of the subscribers to the store, if you need more precise rerendering, separate the store into multiple contexts
 *
 * ## @USAGE:
 * ``` ts
 * const navigationInitialState: NavigationStore = {
 *   isOpen: false,
 *   color: null,
 *   isTransitioning: false,
 * }
 * export const NavigationContext = createFastContext(navigationInitialState)
 *
 * // selector selects a subset of the store
 * const isTransitioningSelector = (store: NavigationStore) => store.isTransitioning
 * // processor is a function that takes the new value for the selector and returns it as a partial of store
 * const isTransitioningProcessor = (value?: boolean) => ({ isTransitioning: value || false })
 * export const useIsTransitioning = () => NavigationContext.useStore(isTransitioningSelector, isTransitioningProcessor)
 * ```
 * somewhere parent to the component:
 * ``` ts
 * <NavigationContext.Provider>{restOfAppRenderTree}</NavigationContext.Provider>
 * ```
 *
 * in the component:
 * ``` ts
 * const [navigationState,setNavigationState] = NavigationContext.useStore()
 * ```
 * or
 * ``` ts
 * const [isTransitioning,setIsTransitioning] = useIsTransitioning()
 * ```
 */

// const jsonify = <T,>(input: T) => JSON.parse(JSON.stringify(input)) as T
const createFastContext = <Store,>(initialState: Store) => {
    const useStoreData = (
        initialValue?: Partial<Store>,
        onChange?: (previousValue: Store, nextValue?: Store) => void
    ) => {
        const store = useRef(initialValue ? deepmerge(initialState, initialValue) : initialState)
        // useEffect(() => {
        //     if (typeof onChange === 'function') onChange(store.current)
        // }, [onChange])
        const get = useCallback(() => store.current, [])

        const subscribers = useRef(new Map<((store: Store) => any) | undefined, (() => void)[]>())
        const set = useCallback(
            (value: Partial<Store>, overwrite = false, overwriteArray = false) => {
                const callbacksToRun: (() => void)[] = []
                const newValue: Store = overwrite
                    ? (value as Store)
                    : deepmerge(store.current, value, {
                          arrayMerge: overwriteArray ? arrayOverwriteMerge : undefined,
                          clone: true
                      })
                const selectors = subscribers.current.keys()
                for (const selector of selectors) {
                    if (typeof selector === 'function') {
                        if (selector(store.current) === selector(newValue)) continue
                    }
                    const callbacks = subscribers.current.get(selector) || []
                    callbacksToRun.push(...callbacks)
                }
                if (typeof onChange === 'function') onChange(store.current, newValue)
                store.current = newValue
                callbacksToRun.forEach(callback => callback())
            },
            [onChange]
        )

        const createSubscribe = useCallback(
            (selector?: (store: Store) => unknown) => (callback: () => void) => {
                const deconstruct = () => {
                    const callbacks = subscribers.current.get(selector) || []
                    if (!callbacks.length) return
                    if (callbacks.length === 1) {
                        subscribers.current.delete(selector)
                    }
                    callbacks.splice(callbacks.indexOf(callback), 1)
                    if (!callbacks.length) {
                        subscribers.current.delete(selector)
                    }
                }
                if (!subscribers.current.has(selector)) {
                    subscribers.current.set(selector, [callback])
                    return deconstruct
                }
                subscribers.current.get(selector)?.push(callback)
                return deconstruct
            },
            []
        )

        return {
            get,
            set,
            createSubscribe
        }
    }

    type UseStoreDataReturnType = ReturnType<typeof useStoreData>

    const StoreContext = createContext<UseStoreDataReturnType | null>(null)
    /**
     * # createFastContext.Provider
     * - a provider that takes an optional initialValue and creates a store with it
     * - if no value is provided, the store is created with the initialState
     * @param value - optional initial value for the store
     * @param update - boolean | UpdateSettingsObject - if true or object is present, will update the store value when the prop value changes
     * @param update.overwrite - boolean, if true, the store is overwritten with the value, if false, the value is merged with the store
     * @param update.compareCallback - function that takes the previous store and the new value and returns a boolean, if true, the store is updated with the new value
     */
    const Provider = ({
        children,
        value,
        onChange
    }: {
        children: React.ReactNode
        value?: Partial<Store>
        onChange?: (previousValue: Store, nextValue?: Store) => void
    }) => <StoreContext.Provider value={useStoreData(value, onChange)}>{children}</StoreContext.Provider>

    /**
     * # createFastContext.useStore
     * @param selector - a function that takes the store and returns a subset of it
     */
    const useSelector = <SelectorFunction,>(
        selector?: SelectorFunction
    ): SelectorFunction extends (store: Store) => unknown ? ReturnType<SelectorFunction> : Store => {
        const store = useContext(StoreContext)
        if (!store) {
            throw new Error('Store not found')
        }

        const subscribe = useMemo(
            // @todo: fix typing of selector
            () => store.createSubscribe(selector as (store: Store) => unknown),
            [selector, store.createSubscribe]
        )

        const state = useSyncExternalStore(
            subscribe,
            () => (typeof selector === 'function' ? selector(store.get()) : store.get()),
            () => (typeof selector === 'function' ? selector(initialState) : initialState)
        )

        return state
    }

    const useDispatch = <ProcessorFunction,>(
        processor?: ProcessorFunction
    ): ProcessorFunction extends (value: any) => void
        ? (value: Parameters<ProcessorFunction>[0], overwrite?: boolean) => void
        : (value: Partial<Store>, overwrite?: boolean) => void => {
        const store = useContext(StoreContext)
        if (!store) {
            throw new Error('Store not found')
        }
        const set = useMemo(
            () =>
                typeof processor === 'function'
                    ? (value: any, overwrite?: boolean) => store.set(processor(value), false, overwrite)
                    : store.set,
            [processor, store.set]
        )

        return set as any
    }

    /**
     * # createFastContext.useStore
     * @param selector - a function that takes the store and returns a subset of it
     * @param processor - a function that takes the new value for the selector and returns it as a partial of store to be merged with the current store, makes it easier to declare "dispatchers"
     */
    const useStore = <ProcessorFunction, SelectorFunction>(
        selector?: SelectorFunction,
        processor?: ProcessorFunction
    ): Readonly<
        [
            SelectorFunction extends (store: Store) => unknown ? ReturnType<SelectorFunction> : Store,
            ProcessorFunction extends (value: any) => void
                ? (value: Parameters<ProcessorFunction>[0], overwrite?: boolean) => void
                : (value: Partial<Store>, overwrite?: boolean) => void
        ]
    > => {
        return [useSelector(selector), useDispatch(processor)]
    }

    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Provider,
        useStore,
        useDispatch,
        useSelector
    }
}
// @TODO: withFastContext helper

export default createFastContext
