import { signIn, signOut, useSession } from 'next-auth/react'

import dataProvider from '@hmn/data-provider'

const userValidation = user => {
    if (!user?.extended_attributes) {
        return false
    }

    const {
        extended_attributes: { first_name: name, last_name: surname, birthday, city, sex }
    } = user
    const facebookItem = user?.account?.identities?.filter(item => item.provider_name === 'facebook')
    return (
        !!(name || facebookItem?.first_name) && !!(surname || facebookItem?.last_name) && !!birthday && !!city && !!sex
    )
}

const HUMAN_API_LOGOUT_URL = process.env.NEXT_PUBLIC_HUMAN_API_LOGOUT_URL
const APP_ROOT_ZENA = process.env.NEXT_PUBLIC_APP_ROOT_ZENA

const common = {
    logIn: callbackUrl => signIn('human-login', { callbackUrl }),
    register: callbackUrl => signIn('human-register', { callbackUrl }),
    logOut: () =>
        signOut({
            callbackUrl: `${HUMAN_API_LOGOUT_URL}&redirect_uri=${APP_ROOT_ZENA}`
        })
}

export default function useUser() {
    const { data: session, update } = useSession()

    const isLoggedIn = !!session

    if (!isLoggedIn) {
        return {
            profile: undefined,
            isLoggedIn: false,
            ...common
        }
    }

    const profile = session.user

    return {
        profile,
        avatar: profile.public_url,
        update: data =>
            dataProvider
                .update('users', {
                    id: profile.id,
                    data
                })
                .then(async response => {
                    await update(response.data)

                    return response
                }),
        isTester: profile?.roles?.includes('ROLE_CLIENT_CUSTOM_PRODUCT_TESTER'),
        isValid: userValidation(profile),
        isLoggedIn,
        ...common
    }
}
