/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

// SVG - 32 width, 32 height

const Camera = createSvgIcon(
    <>
        <path d="M23.343 10.322h-1.79a.587.587 0 01-.427-.183l-1.572-1.635A1.615 1.615 0 0018.382 8h-4.76c-.443 0-.86.18-1.172.504l-1.573 1.635a.587.587 0 01-.426.183H8.66c-.913 0-1.657.772-1.657 1.722L7 21.435c0 .952.74 1.723 1.657 1.723h14.686c.914 0 1.657-.773 1.657-1.722v-9.392c0-.95-.74-1.722-1.657-1.722zm.602 11.114c0 .345-.27.626-.602.626H8.657a.614.614 0 01-.602-.626l.003-9.392c0-.345.27-.626.602-.626h1.791c.443 0 .86-.179 1.172-.504l1.572-1.634a.587.587 0 01.426-.184h4.761c.161 0 .312.065.426.184l1.573 1.634c.313.325.729.504 1.171.504h1.791c.333 0 .602.28.602.626v9.392z" />
        <path d="M16.002 12.083c-2.16 0-3.916 1.826-3.916 4.07s1.757 4.07 3.916 4.07 3.915-1.826 3.915-4.07-1.756-4.07-3.915-4.07zm0 7.044c-1.578 0-2.861-1.334-2.861-2.974 0-1.64 1.283-2.974 2.86-2.974 1.578 0 2.862 1.334 2.862 2.974 0 1.64-1.284 2.974-2.861 2.974zM8.66 9.096h1.13a.538.538 0 00.527-.548A.538.538 0 009.79 8H8.66a.538.538 0 00-.527.548c0 .303.236.548.527.548z" />
    </>,
    'Camera'
)

export default Camera
