import { useEffect, useState } from 'react'

export const isDev = process.env.NEXT_PUBLIC_APP_CONFIG_ENV === 'development'
// const isDev = process.env.NODE_ENV !== 'production'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getApiURL = (api, skipDev, externalOnly, _isBrowser = false) => {
    if (api === 'gateway') {
        if (externalOnly) {
            return process.env.NEXT_PUBLIC_API_ROOT_URL
        }

        if (skipDev) {
            return typeof window !== 'undefined'
                ? process.env.NEXT_PUBLIC_API_ROOT_URL
                : process.env.NEXT_PUBLIC_API_ROOT_URL_INTERNAL
        }

        return isDev || typeof window !== 'undefined'
            ? process.env.NEXT_PUBLIC_API_ROOT_URL
            : process.env.NEXT_PUBLIC_API_ROOT_URL_INTERNAL
    }

    if (api === 'human') {
        if (externalOnly) {
            return process.env.NEXT_PUBLIC_HUMAN_API_ROOT_URL
        }

        if (skipDev) {
            return typeof window !== 'undefined'
                ? process.env.NEXT_PUBLIC_HUMAN_API_ROOT_URL
                : process.env.NEXT_PUBLIC_HUMAN_API_ROOT_URL_INTERNAL
        }

        return isDev || typeof window !== 'undefined'
            ? process.env.NEXT_PUBLIC_HUMAN_API_ROOT_URL
            : process.env.NEXT_PUBLIC_HUMAN_API_ROOT_URL_INTERNAL
    }

    return ''
}

const useBaseApiURL = ({ api = 'gateway', skipDev = false, externalOnly = false } = {}) => {
    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => {
        setIsBrowser(true)
    })

    // Use internal URL's for server side communication
    // @TODO: This will be tricky when we introduce static generation and static rendering, because build time cannot access internal URL's
    const [url, setUrl] = useState(getApiURL(api, skipDev, externalOnly, isBrowser))

    useEffect(() => {
        // Use external URL's for client side communcation
        setUrl(getApiURL(api, skipDev, externalOnly, isBrowser))
    }, [api, skipDev, externalOnly, isBrowser])

    return url
}

const getBaseApiURL = ({ api = 'gateway', skipDev = false, externalOnly = false } = {}) =>
    getApiURL(api, skipDev, externalOnly)

export { getBaseApiURL }

export default useBaseApiURL
