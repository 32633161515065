import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, buttonWidth, buttonWidthMobile, isLoading, variant, simple }) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background
    // for development errors

    const config = {
        xs: {
            variant: {
                alpha: {
                    minHeight: toRem(50),
                    padding: toRems([5, 20]),
                    fontSize: toRem(16)
                },
                beta: {
                    fontSize: toRem(12),
                    minHeight: toRem(30),
                    padding: toRems([4, 10])
                }
            }
        },
        md: {
            variant: {
                alpha: {
                    minHeight: toRem(60),
                    padding: toRems([5, 20]),
                    fontSize: toRem(16)
                },
                beta: {
                    fontSize: toRem(12),
                    minHeight: toRem(30),
                    padding: toRems([4, 10])
                }
            }
        },
        borderWidth: toRem(1),
        borderStyle: 'solid'
    }

    return {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        margin: 0,
        outline: 0,
        lineHeight: 0,
        color: theme.colors.profile.button[variant]?.text?.default,
        cursor: 'pointer',
        transitionDuration: theme.transitions.button.default.duration,
        transitionTimingFunction: theme.transitions.button.default.timingFunction,
        transitionDelay: theme.transitions.button.default.delay,
        transitionProperty: 'color, background-color, border-color',
        userSelect: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...(!simple && {
            padding: config.xs?.variant[variant]?.padding,
            minWidth: buttonWidthMobile || buttonWidth || 0,
            minHeight: config.xs?.variant[variant]?.minHeight,
            textTransform: 'uppercase',
            borderColor: theme.colors.profile.button[variant]?.border?.default,
            backgroundColor: theme.colors.profile.button[variant]?.background?.default,
            borderStyle: config?.borderStyle,
            borderWidth: config?.borderWidth,
            fontFamily: theme.typography.fontFamily.alternative,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: config.xs?.variant[variant]?.fontSize
        }),
        ...(simple && {
            color: theme.colors.profile.button.simple.text.default
        }),
        ...(isLoading && {
            pointerEvents: 'none'
        }),
        [theme.breakpoints.up('md')]: {
            fontSize: config.md?.variant[variant]?.fontSize,
            ...(!simple && {
                padding: config.md?.variant[variant]?.padding,
                minWidth: buttonWidth || 0,
                minHeight: config.md?.variant[variant]?.minHeight
            })
        },
        '&:focus': {
            ...(!simple && {
                borderColor: theme.colors.profile.button[variant]?.border?.focus,
                backgroundColor: theme.colors.profile.button[variant]?.background?.focus || devWarnBg,
                color: theme.colors.profile.button[variant]?.text?.focus || devWarnTxt
            }),
            ...(simple && {
                textDecoration: 'underline'
            })
        },
        '&:hover': {
            ...(!simple && {
                borderColor: theme.colors.profile.button[variant]?.border?.hover,
                backgroundColor: theme.colors.profile.button[variant]?.background?.hover || devWarnBg,
                color: theme.colors.profile.button[variant]?.text?.hover || devWarnTxt
            }),
            ...(simple && {
                textDecoration: 'underline'
            })
        },
        '&:active': {
            ...(!simple && {
                borderColor: theme.colors.profile.button[variant]?.border?.active,
                backgroundColor: theme.colors.profile.button[variant]?.background?.active || devWarnBg,
                color: theme.colors.profile.button[variant]?.text?.active || devWarnTxt
            })
        }
    }
}

export default style
