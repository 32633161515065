import { toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, sizeXs, sizeMd }) => {
    const config = {
        xs: {
            variant: {
                primary: {
                    borderWidth: toRems([0, 0, sizeXs || 140, sizeXs || 140]),
                    borderColor: `transparent transparent ${theme.colors.decoration.default} transparent`
                },
                secondary: {
                    borderWidth: toRems([0, sizeXs || 140, sizeXs || 140, 0]),
                    borderColor: `transparent ${theme.colors.decoration.default} transparent transparent`
                }
            }
        },
        md: {
            variant: {
                primary: {
                    borderWidth: toRems([0, 0, sizeMd || 180, sizeMd || 180]),
                    borderColor: `transparent transparent ${theme.colors.decoration.default} transparent`
                },
                secondary: {
                    borderWidth: toRems([0, sizeMd || 180, sizeMd || 180, 0]),
                    borderColor: `transparent ${theme.colors.decoration.default} transparent transparent`
                }
            }
        }
    }

    return {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: config?.xs.variant[variant]?.borderWidth,
        borderColor: config?.xs.variant[variant]?.borderColor,
        opacity: 0.5,
        pointerEvents: 'none',
        [theme.breakpoints.up('md')]: {
            borderWidth: config?.md.variant[variant]?.borderWidth,
            borderColor: config?.md.variant[variant]?.borderColor
        }
    }
}

export default style
