import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Error } from '../Error'
import { Help } from '../Help'
import { Label, labelVariants } from '../Label'
import styles from './Switch.style'

const SwitchStyled = styled.div(props => ({ ...styles(props) }))

function Switch({
    name,
    id,
    value,
    label,
    labelComponent,
    checked,
    onBlur,
    onChange,
    onFocus,
    onClick,
    required,
    disabled,
    error,
    errorMessage,
    helpText,
    className,
    ...rest
}) {
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (disabled && focused) {
            setFocused(false)
            if (onBlur) {
                onBlur()
            }
        }
    }, [disabled, focused, onBlur])

    // onFocus
    const handleFocus = event => {
        if (disabled) {
            event.stopPropagation()
            return
        }
        if (onFocus) {
            onFocus(event)
        }
        setFocused(true)
    }

    // onBlur
    const handleBlur = event => {
        if (onBlur) {
            onBlur(event)
        }
        setFocused(false)
    }

    // onChange
    const handleChange = (event, ...args) => {
        if (onChange) {
            onChange(event, ...args)
        }
    }

    return (
        <SwitchStyled checked={checked} disabled={disabled} error={error} className={className} {...rest}>
            <div className="switchToggleWrap" onClick={onClick} role="presentation">
                <div className="switchToggle">
                    <input
                        type="checkbox"
                        name={name}
                        aria-invalid={!!error}
                        id={id}
                        value={value}
                        checked={checked}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required={required}
                        disabled={disabled}
                    />
                    <span className="switchToggle_slider" />
                </div>
                <Label
                    label={label}
                    labelComponent={labelComponent}
                    id={id}
                    error={error}
                    required={required}
                    focused={focused}
                    variant={labelVariants.SECONDARY}
                    className="switchToggleLabel"
                />
            </div>
            <Error error={error} errorMessage={errorMessage} className="switchToggleError" />
            <Help text={helpText} className="switchToggleHelp" />
        </SwitchStyled>
    )
}

Switch.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.string,
    labelComponent: PropTypes.node,
    checked: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    helpText: PropTypes.string,
    className: PropTypes.string
}

Switch.defaultProps = {
    name: undefined,
    id: undefined,
    value: undefined,
    label: undefined,
    labelComponent: undefined,
    checked: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onClick: () => {},
    required: false,
    disabled: false,
    error: false,
    errorMessage: undefined,
    helpText: undefined,
    className: undefined
}

export default withErrorBoundary(Switch, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[InputSwitch]: ', error, componentStack)
    }
})
