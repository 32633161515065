import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'

const getPropsRegex = (props: string[] = []) =>
    new RegExp(
        // eslint-disable-next-line security-node/non-literal-reg-expr
        `^(${props
            .map(s => s.split(',').map(c => c.trim().toLowerCase()))
            .flat(1)
            .join('|')})$`,
        'i'
    )
export const createStyledComponent = <T extends Record<string, any>>({
    tag = 'div',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    styles,
    blockProps = [],
    forwardProps = []
}: {
    tag?: keyof JSX.IntrinsicElements
    styles: (props: T) => any
    blockProps?: string[]
    forwardProps?: string[]
}) => {
    if (!styles) {
        throw new Error('Styles is required')
    }
    if (typeof styles !== 'function') {
        throw new Error('Styles must be a function')
    }
    const blockPropsRegex = getPropsRegex(blockProps)
    const forwardPropsRegex = getPropsRegex(forwardProps)
    return styled(tag, {
        shouldForwardProp: prop => {
            return isPropValid(prop) && (!!prop.match(forwardPropsRegex) || !prop.match(blockPropsRegex))
        }
    })<T>(styles)
}
