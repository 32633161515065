/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const SocialMessenger = createSvgIcon(
    <path d="M 16 0 A 16 16 0 0 0 0 16 A 16 16 0 0 0 16 32 A 16 16 0 0 0 32 16 A 16 16 0 0 0 16 0 z M 16 8 C 20.5067 8 24 11.300866 24 15.759766 C 24 20.218666 20.5067 23.519531 16 23.519531 C 15.1905 23.519531 14.413294 23.413491 13.683594 23.212891 C 13.541794 23.173891 13.392412 23.184741 13.257812 23.244141 L 11.669922 23.945312 C 11.254522 24.128712 10.785384 23.832806 10.771484 23.378906 L 10.726562 21.955078 C 10.721163 21.779678 10.642419 21.617 10.511719 21.5 C 8.9551487 20.108 8 18.092066 8 15.759766 C 8 11.300866 11.4934 8 16 8 z M 20.511719 13.386719 C 20.426634 13.379272 20.336178 13.400944 20.251953 13.464844 L 17.728516 15.380859 C 17.557716 15.510459 17.321891 15.511413 17.150391 15.382812 L 15.28125 13.980469 C 14.72035 13.559869 13.920775 13.707681 13.546875 14.300781 L 11.195312 18.029297 C 10.969812 18.387097 11.411147 18.790856 11.748047 18.535156 L 14.271484 16.619141 C 14.442284 16.489541 14.678209 16.488588 14.849609 16.617188 L 16.71875 18.019531 C 17.27955 18.440231 18.079325 18.292219 18.453125 17.699219 L 20.804688 13.970703 C 20.973813 13.702353 20.766972 13.409059 20.511719 13.386719 z " />,
    'SocialMessenger'
)

export default SocialMessenger
