// @TODO: append this map with other special characters https://dev.w3.org/html5/html-author/charref
const matchMap = {
    '&amp;': '&',
    '&quot;': '"',
    '&apos;': "'"
}

// @TODO: I can't believe that this is an actual case, investigate recursive approach to deal with double encoded cases
const doubleEncodedMap = Object.keys(matchMap).reduce((acc, key) => {
    const curr = matchMap[key]
    acc[key.replace('&', '&amp;')] = curr
    acc[key] = curr
    return acc
}, {})

// parse html entities of type &amp => "&""  |  &apos => "'"
const parseHtmlEntities = string =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    string.replace(/&((\w+);)+/gim, (match, _dec) => doubleEncodedMap[`${match}`] || match) // match word from map
export default parseHtmlEntities
