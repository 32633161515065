import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const defaultColor = '#F15B24'

const CategoryColorContext = createContext(defaultColor)

export function CategoryColorProvider({ children, categoryColor = defaultColor }) {
    return <CategoryColorContext.Provider value={categoryColor}>{children}</CategoryColorContext.Provider>
}

CategoryColorProvider.propTypes = {
    categoryColor: PropTypes.string
}

CategoryColorProvider.defaultProps = {
    categoryColor: defaultColor
}

export function useCategoryColor() {
    return useContext(CategoryColorContext)
}
