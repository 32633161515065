import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Triangle.style'

const TriangleStyled = styled.div(props => ({ ...styles(props) }))

function Triangle({ className, sizeXs, sizeMd, ...rest }) {
    return <TriangleStyled className={className} sizeXs={sizeXs} sizeMd={sizeMd} {...rest} />
}

const triangleVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
})

Triangle.propTypes = {
    className: PropTypes.string,
    sizeXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sizeMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf([...Object.values(triangleVariants)])
}

Triangle.defaultProps = {
    className: undefined,
    sizeXs: undefined,
    sizeMd: undefined,
    variant: triangleVariants.PRIMARY
}

export { triangleVariants }

export default Triangle
