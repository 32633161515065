import { memo, useCallback, useRef, useState } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'
import useInterval from '../../hooks/useInterval'

export function TwitterItemController({ id }) {
    const tweetContainer = useRef()
    const mountedRef = useRef(false)
    const [hasError, setHasError] = useState(false)
    const onTwitterReadyIntervalCallback = useCallback(
        stopInterval => {
            // console.log('onTwitterReadyIntervalCallback', id)
            if (mountedRef.current || !id) {
                // we have to do the mounted check since in some cases interval manages to run again before the current mounting try is finished
                // this results in the tweet being created twice
                stopInterval?.() // call stop interval once more just in case
                return
            }
            if (!window?.twttr?.widgets?.createTweet || !tweetContainer.current) {
                return
            }
            mountedRef.current = true
            try {
                // in try/catch just in case
                window.twttr.widgets.createTweet(id, tweetContainer.current, {
                    conversation: 'none', // or all
                    cards: 'visible', // or visible
                    linkColor: '#cc0000', // default is blue
                    theme: 'light' // or dark
                })
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('TwitterItem mounting error', err)
                setHasError(true)
            }
            stopInterval?.() // if we manage to mount, even with error, stop the interval
        },
        [setHasError, id]
    )

    // @NOTE: This is a workaround for the issue with the next.js Script component, the onReady callback is not called if the script is already loaded
    const { cleanUp } = useInterval(onTwitterReadyIntervalCallback, {
        delay: 1250,
        enabled: !!id,
        runImmediately: true,
        maxRetries: 30
    })

    // try onReady callback, might trigger before the interval
    const onReadyCallback = useCallback(() => {
        onTwitterReadyIntervalCallback(cleanUp)
    }, [cleanUp, onTwitterReadyIntervalCallback])

    if (!id) {
        return null
    }

    return (
        <>
            {hasError && <div>Twitter Embed error</div>}
            <div ref={tweetContainer} style={{ margin: 'auto' }} />
            <Script
                id="twitter-script"
                src="https://platform.twitter.com/widgets.js"
                crossOrigin="anonymous"
                onReady={onReadyCallback}
            />
        </>
    )
}

TwitterItemController.propTypes = {
    id: PropTypes.string
}

TwitterItemController.defaultProps = {
    id: undefined
}

export default withErrorBoundary(memo(TwitterItemController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[TwitterItemController]: ', error, componentStack)
    }
})
