import { useEffect } from 'react'

const useWindowAttachedValue = <T>(value: T, key: string, enabled = true) => {
    useEffect(() => {
        if (typeof window === 'undefined' || !key) {
            return () => {}
        }
        window[key.toString()] = value
        return () => delete window[key]
    }, [value, enabled, key])
}

export default useWindowAttachedValue
