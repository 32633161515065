// eslint-disable-next-line @typescript-eslint/naming-convention
import React, { useMemo } from 'react'

import { SourceType } from '../EternalAds.interface'
import { parseCreativeChunks } from '../helpers/parseCreativeChunks'
import { useAdElementHref } from '../hooks/useAdElementHref'
import { ParsedInscreenData } from './Inscreen.helpers'

const InscreenCreative = ({
    creative,
    ad,
    clickOptions,
    className,
    dimensions
}: {
    creative: NonNullable<ParsedInscreenData['creative']>
    ad: NonNullable<ParsedInscreenData['ad']>
    clickOptions: NonNullable<ParsedInscreenData['clickOptions']>
    dimensions: NonNullable<ParsedInscreenData['dimensions']>
    className?: string
}) => {
    const href = useAdElementHref(ad, clickOptions)
    const { openInNewTab } = clickOptions
    const { fileImage, fileUrl, sourceType } = creative
    const { width, height } = dimensions

    const { parsedHeight, numberOfChunks, chunkPositions } = useMemo(
        () => parseCreativeChunks(width, height),
        [width, height]
    )
    if (sourceType === SourceType.IFRAME) {
        return (
            <div
                className={className}
                style={{
                    width,
                    height,
                    border: 0,
                    position: 'absolute'
                }}>
                <a
                    href={href}
                    target={openInNewTab ? '_blank' : '_self'}
                    style={{
                        width,
                        height,
                        pointerEvents: 'all',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 2
                    }}
                />
                <iframe
                    src={fileUrl}
                    scrolling="no"
                    style={{
                        width,
                        height,
                        border: 0,
                        pointerEvents: 'none',
                        zIndex: 1
                    }}
                />
            </div>
        )
    }

    if (numberOfChunks > 1) {
        return (
            <a
                className={className}
                href={href}
                target={openInNewTab ? '_blank' : '_self'}
                style={{
                    width,
                    height,
                    border: 0,
                    display: 'block',
                    pointerEvents: 'all',
                    position: 'absolute'
                }}>
                {chunkPositions.map((chunkPosition, index) => (
                    <div
                        key={index}
                        style={{
                            width,
                            height: Math.ceil(parsedHeight / numberOfChunks),
                            background: `url(${fileImage || fileUrl}) 0px ${chunkPosition?.[1] || 0}px no-repeat`,
                            pointerEvents: 'none'
                        }}
                    />
                ))}
            </a>
        )
    }

    return (
        <a
            href={href}
            target={openInNewTab ? '_blank' : '_self'}
            className={className}
            style={{
                width,
                height,
                display: 'block',
                pointerEvents: 'all',
                background: `url(${fileImage || fileUrl}) no-repeat`
            }}
        />
    )
}
export default InscreenCreative
