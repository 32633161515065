import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Error.style'

const ErrorStyled = styled.div(props => ({ ...styles(props) }))

function Error({ error, errorMessage, className, ...rest }) {
    const errorMessageHtmlProps = useInnerHtml(errorMessage)

    if (!error || !errorMessage) {
        return null
    }

    return (
        <ErrorStyled className={className} role="alert" {...rest}>
            <div className="inputErrorInner" {...errorMessageHtmlProps} />
        </ErrorStyled>
    )
}

Error.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string
}

Error.defaultProps = {
    error: false,
    errorMessage: undefined,
    className: undefined
}

export default Error
