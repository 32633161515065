import { EntityType } from '../../types/entity'

export enum NavigationDirection {
    PUSH = 'PUSH',
    POP = 'POP'
}
export enum ClientNavigationState {
    LOADING = 'loading',
    READY = 'ready',
    TRANSITIONING = 'transitioning',
    RENDERING = 'rendering'
}
export type ClientNavigationStore = {
    isTransitioning: boolean
    hasNavigated: boolean
    inarticleAdMounted: boolean
    initialLoad: boolean
    domLoaded: boolean
    direction: NavigationDirection | null
    hasNavigationStarted: boolean
    currentEntityType: EntityType | 'loading'
    clientNavigationState: ClientNavigationState
    adsNavigationState: ClientNavigationState
}
