const constants = {
    CLOSE: 'SEARCH/CLOSE_SEARCH',
    OPEN: 'SEARCH/OPEN_SEARCH',
    TOGGLE: 'SEARCH/TOGGLE_SEARCH',
    SAVE: 'SEARCH/SAVE_SEARCH'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE }),
    save: search => ({ type: constants.SAVE, search })
}

export { constants as SearchActionTypes, actions as SearchActions }
