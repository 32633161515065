/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import { Icon, SearchIcon } from '../../../Icon'
import styles from './SearchBar.style'

const SEARCH_ROUTE = '/pretrazivanje'
const SEARCH_PARAM = 'q'

const SearchBarStyled = styled.form(props => ({ ...styles(props) }))
function SearchBar({ className, ...rest }) {
    const router = useRouter()
    const { sendDataToGTM } = useGTM()
    const [searchQuery, setSearchQuery] = useState('')
    const { isOpen: isSearchOpen } = useSelector(state => state.search)
    const searchInput = useRef()

    useEffect(() => {
        if (isSearchOpen) {
            searchInput.current.focus()
        }
    }, [isSearchOpen])

    const handleSearchSubmit = useCallback(
        e => {
            e.preventDefault()
            sendDataToGTM(
                {
                    eventCategory: 'Search',
                    eventLabel: 'Search submit'
                },
                true
            )
            router.push({ pathname: SEARCH_ROUTE, query: { [SEARCH_PARAM]: searchQuery } })
        },
        [searchQuery, router, sendDataToGTM]
    )

    useEffect(() => {
        if (router.pathname === SEARCH_ROUTE) {
            setSearchQuery(router.query[SEARCH_PARAM] || '')
        }
    }, [router.pathname, router.query])

    return (
        <SearchBarStyled className={className} autoComplete="off" onSubmit={handleSearchSubmit} {...rest}>
            <label className="searchInput_wrapper">
                <span>Tražilica</span>
                <input
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    type="text"
                    name="search"
                    className="searchInput"
                    placeholder="Počni tipkati pojam..."
                    ref={searchInput}
                />
            </label>

            <button type="submit" className="searchButton" aria-label="Traži">
                <Icon icon={SearchIcon} />
            </button>
        </SearchBarStyled>
    )
}

SearchBar.propTypes = {
    className: PropTypes.string
}

SearchBar.defaultProps = {
    className: undefined
}

export default withErrorBoundary(SearchBar, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SearchBar]: ', error, componentStack)
    }
})
