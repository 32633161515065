import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { AvatarIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import { Circle, circleVariants } from '..'
import styles from './Avatar.style'

const CommentsAvatarStyled = styled.div(props => ({ ...styles(props) }))

const getAvatarPlaceHolder = isAmp => {
    if (isAmp) {
        return <img src="/images/avatarAmp/avatarAmp.png" alt="avatarAmp" />
    }
    return <Icon icon={AvatarIcon} size={60} />
}

function CommentsAvatar({ user, isAnonymous, className, date, ...rest }) {
    const isAmp = useAmp()
    const commentDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])
    const commentTime = useMemo(() => `${dayjs(date).format('HH:mm')}`, [date])
    const avatarImage = user?.public_url?.replace(/^https?:/i, '')

    return (
        <CommentsAvatarStyled className={className} {...rest}>
            <div className="avatarInner">
                <div className="avatarImage_wrapper">
                    {!isAnonymous && avatarImage ? (
                        <Image
                            className="avatarImage"
                            classNameProgressive="avatarImage_progressive"
                            image={avatarImage}
                            alt={user?.display_name || ''}
                        />
                    ) : (
                        getAvatarPlaceHolder(isAmp)
                    )}
                    <Circle size={60} variant={circleVariants.ALPHA} />
                </div>
                <div className="avatarInfo">
                    <div className="avatarInfo_username">
                        {!isAnonymous ? user?.display_name || 'Anonimni korisnik' : 'Anonimni korisnik'}
                    </div>
                    {date && (
                        <div className="avatarInfo_date">
                            <time dateTime={commentDate}>{commentDate}</time>
                            <time dateTime={commentTime}>{commentTime}</time>
                        </div>
                    )}
                </div>
            </div>
        </CommentsAvatarStyled>
    )
}

CommentsAvatar.propTypes = {
    className: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    user: PropTypes.shape({
        public_url: PropTypes.string,
        display_name: PropTypes.string
    }),
    isAnonymous: PropTypes.bool
}

CommentsAvatar.defaultProps = {
    className: undefined,
    date: undefined,
    isAnonymous: false,
    user: undefined
}

export default CommentsAvatar
