const constants = {
    SET_IMAGES_VARIATIONS: 'SETTINGS/SET_IMAGES_VARIATIONS',
    SET_LOADED: 'SETTINGS/SET_LOADED'
}

const actions = {
    setImagesVariations: variations => ({ type: constants.SET_IMAGES_VARIATIONS, variations }),
    setLoaded: isLoaded => ({ type: constants.SET_LOADED, isLoaded })
}

export { constants as SettingsActionTypes, actions as SettingsActions }
