// eslint-disable-next-line no-unused-vars
import { breakpoints as globalBreakpoints, toRem } from '../../../helpers/theme'
import { colorsDefault } from '../../globals/colors'
import layoutDimension from './dimensions'
import grid from './grid'

const { values: breakpointValuesArr, unit } = globalBreakpoints

const modifiedLayoutBreakpointsArr = Object.values(breakpointValuesArr).map(bp => (bp !== 0 ? `${bp}${unit}` : `${bp}`))

const layoutTheme = {
    breakpoints: modifiedLayoutBreakpointsArr,
    variants: {
        // Grid variants
        ...grid,
        zeroDimensions: {
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
        },
        // Pages
        // Homepage
        homepage: {
            main: {
                flexDirection: 'column',
                width: '100%',
                paddingBottom: '50px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '100px'
                }
            },

            content: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.homepage?.content),
                paddingRight: '0',
                paddingLeft: '0',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            }
        },
        // Article Layout
        article: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                }
            },

            main: {
                width: '100%',
                flexDirection: 'column',
                paddingTop: '25px'
            },

            content: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto'
            },

            readMore: {
                margin: 'auto',
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                padding: '5px 25px 15px',

                '@media screen and (min-width: 768px)': {
                    padding: '15px 25px 25px'
                }
            },

            tags: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                padding: '25px 25px 35px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '25px 25px 55px'
                }
            },

            prevNext: {
                width: '100%',
                paddingTop: '25px',
                paddingBottom: '15px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '35px'
                }
            },

            meta: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingBottom: '50px'
                }
            },

            midas: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                paddingBottom: '30px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px'
                }
            },

            facebook: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px'
                }
            },

            related: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article?.related),
                padding: '40px 0px 80px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '50px 25px 120px'
                }
            },
            amp: {
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },
            listalica: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.article.related),
                paddingTop: '40px',
                paddingRight: '25px',
                paddingBottom: '40px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px',
                    paddingBottom: '50px'
                }
            }
        },
        hotOrNot: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                }
            },

            main: {
                width: '100%',
                flexDirection: 'column'
            },

            content: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.hotOrNot?.content),
                paddingRight: '0px',
                paddingLeft: '0px',
                paddingBottom: '35px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingBottom: '80px'
                }
            },

            meta: {
                width: '100%',
                maxWidth: '59.375rem',
                paddingLeft: '25px',
                paddingBottom: '30px',
                paddingRight: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingBottom: '50px',
                    paddingRight: '25px'
                }
            },

            midas: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.hotOrNot?.content),
                paddingBottom: '30px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px'
                }
            },

            facebook: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.hotOrNot?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            related: {
                width: '100%',
                maxWidth: '75.9375rem',
                padding: '40px 0px 50px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '50px 25px 100px'
                }
            }
        },
        // Recipe Layout
        recipe: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                }
            },

            row: {
                flexWrap: 'wrap',
                maxWidth: toRem(layoutDimension?.recipe?.content),
                margin: 'auto'
            },

            description: {
                width: '100%',
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0px',
                    paddingBottom: '10px',
                    paddingLeft: '0px'
                }
            },

            main: {
                display: 'grid',
                gridTemplateColumns: null,
                gridColumnGap: null,
                width: '100%',
                paddingBottom: '10px',

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: '300px auto',
                    gridColumnGap: '100px', // TODO: provjeriti ovo
                    paddingBottom: '40px'
                }
            },

            ingredients: {
                paddingRight: '25px',
                paddingBottom: '10px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0px',
                    paddingBottom: '35px'
                }
            },

            preparation: {
                paddingRight: '25px',
                paddingBottom: '10px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0px',
                    paddingBottom: '35px'
                }
            },

            advice: {
                paddingBottom: '35px'
            },

            info: {
                paddingRight: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0px',
                    paddingBottom: '25px',
                    paddingLeft: '0px'
                }
            },

            tags: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.recipe?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            meta: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.recipe?.content),
                paddingLeft: '25px',
                paddingRight: '25px',
                paddingBottom: '30px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            midas: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.recipe?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '125px',
                    paddingBottom: '50px',
                    paddingLeft: '125px'
                }
            },

            facebook: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.recipe?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            related: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.recipe?.related),
                padding: '40px 0px 80px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '50px 25px 120px'
                }
            }
        },
        // Tag Layout
        tag: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                },

                listing: {
                    gridColumn: 'span 12'
                }
            },

            main: {
                width: '100%',
                margin: 'auto',
                flexDirection: 'column',
                maxWidth: toRem(layoutDimension?.tag?.main),
                paddingBottom: '80px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '120px'
                }
            },

            head: {
                width: '100%',
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '45px',
                    paddingLeft: '25px'
                }
            },

            listing: {
                gridRowGap: '40px',
                display: 'grid',
                width: '100%',
                maxWidth: '100%',
                paddingBottom: '50px',

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    gridRowGap: '70px',
                    paddingBottom: '50px'
                },

                itemLarge: {
                    gridColumn: 'span 12',
                    alignSelf: 'center',
                    paddingRight: 0,

                    '@media screen and (min-width: 768px)': {
                        gridColumn: '1/8',
                        paddingRight: '3.125rem'
                    }
                }
            },

            loadMore: {
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            }
        },
        // Search Layout
        search: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                },

                listing: {
                    gridColumn: '1 / last-col'
                }
            },

            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.search?.main),
                paddingBottom: '80px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '120px'
                }
            },

            head: {
                width: '100%',
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '20px',
                    paddingLeft: '20px'
                }
            },

            listing: {
                display: 'grid',
                gridTemplateColumns: null,
                width: '100%',
                maxWidth: '100%',
                paddingRight: '0px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    gridRowGap: '70px',
                    paddingRight: '50px'
                }
            },

            loadMore: {
                paddingTop: '60px',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '40px',
                    paddingRight: '0px',
                    paddingLeft: '0px'
                }
            }
        },
        // Counseling Layout
        counseling: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.counseling?.head),
                    margin: 'auto'
                }
            },

            head: {
                margin: 'auto',
                width: '100%',
                maxWidth: toRem(layoutDimension?.counseling?.head),
                paddingRight: '25px',
                paddingBottom: '20px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '40px',
                    paddingLeft: '25px'
                }
            },

            main: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.counseling?.main),
                paddingRight: '25px',
                paddingBottom: '60px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '0px',
                    paddingBottom: '120px',
                    paddingLeft: '0px'
                }
            },

            content: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.counseling?.content),
                paddingBottom: '34px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '67px'
                }
            },

            form: {
                width: '100%'
            },

            loadMore_select: {
                padding: '60px 25px',

                '@media screen and (min-width: 768px)': {
                    padding: '80px 0px'
                }
            },

            loadMore: {
                paddingTop: '60px',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '80px',
                    paddingRight: '0px',
                    paddingLeft: '0px'
                }
            },

            meta: {
                margin: 'auto',
                width: '100%',
                padding: '40px 25px 50px',
                maxWidth: '59.375rem',

                '@media screen and (min-width: 768px)': {
                    padding: '70px 25px 50px'
                }
            },

            bottom: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.media?.meta),
                margin: 'auto'
            },

            related: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.counseling?.related),
                paddingBottom: '120px',
                margin: 'auto'
            }
        },
        // Static (page) Layout
        static: {
            main: {
                flexDirection: 'column',
                paddingTop: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px'
                }
            },

            head: {
                margin: 'auto',
                maxWidth: '81.25rem',
                paddingBottom: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '55px'
                }
            },

            content: {
                margin: 'auto',
                paddingLeft: '25px',
                paddingRight: '25px',
                maxWidth: '59.375rem',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            }
        },
        // Error Layout
        error: {
            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.error?.main),
                paddingTop: '80px',
                paddingBottom: '80px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '100px',
                    paddingBottom: '120px'
                }
            },

            head: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.error?.head),
                paddingRight: '25px',
                paddingBottom: '48px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '112px',
                    paddingLeft: '25px'
                }
            },
            listing: {
                paddingRight: '0px',
                paddingBottom: '56px',
                paddingLeft: '0px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '10px',
                    paddingBottom: '110px',
                    paddingLeft: '10px'
                }
            }
        },
        // Gallery/Video Layout
        media: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.media?.content),
                    margin: 'auto'
                }
            },

            content: {
                margin: 'auto',
                width: '100%',
                paddingBottom: '20px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '35px'
                }
            },

            main: {
                backgroundColor: colorsDefault.background.inverted,
                flexDirection: 'column'
            },

            meta: {
                width: '100%',
                maxWidth: '59.375rem',
                paddingLeft: '25px',
                paddingRight: '25px',
                paddingTop: '20px',
                paddingBottom: '30px',
                padding: '20px 25px 30px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '35px 25px 50px'
                }
            },

            tags: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.media?.meta),
                paddingTop: '40px',
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '70px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            related: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.media?.related),
                paddingRight: '0px',
                paddingBottom: '110px',
                paddingLeft: '0px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingBottom: '130px'
                }
            },

            facebook: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.media?.meta),
                padding: '40px 25px 30px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '70px 25px 50px'
                }
            },

            listalica: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.media.listalica),
                paddingTop: '40px',
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            }
        },
        // Category Layout
        category: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.category?.main)
                },

                listing: {
                    width: '100%',
                    gridColumn: 'span 12'
                }
            },

            main: {
                flexDirection: 'column',
                maxWidth: toRem(layoutDimension?.category?.main),
                paddingRight: '0px',
                paddingBottom: '80px',
                paddingLeft: '0px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '120px',
                    paddingLeft: '25px'
                }
            },

            head: {
                paddingRight: '25px',
                paddingBottom: '20px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '30px',
                    paddingLeft: '25px'
                }
            },

            listing: {
                display: 'grid',
                gridTemplateColumns: null,
                gridRowGap: '40px',

                '@media screen and (min-width: 768px)': {
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    gridRowGap: '70px'
                },
                itemLarge: {
                    gridColumn: 'span 12',
                    gridRow: null,
                    alignSelf: 'center',
                    paddingRight: '0',

                    '@media screen and (min-width: 768px)': {
                        gridColumn: '1/8',
                        gridRow: '1/3',
                        paddingRight: '50px'
                    },

                    '@media screen and (min-width: 1024px)': {
                        paddingRight: '100px'
                    }
                }
            },
            loadMore: {
                paddingTop: '60px',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '80px',
                    paddingRight: '0px',
                    paddingLeft: '0px'
                }
            }
        },
        // Product Layout
        product: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                },

                listing: {
                    gridColumn: 'span 12'
                }
            },
            main: {
                width: '100%',
                paddingTop: '32px',
                paddingBottom: '95px',
                flexDirection: 'column',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px',
                    paddingBottom: '150px'
                }
            },

            head: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingBottom: '40px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '80px'
                }
            },

            reviews: {
                width: '100%',
                paddingTop: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '0px'
                }
            },

            prevNext: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            tags: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                padding: '25px 25px 35px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '35px 25px 55px'
                }
            },

            meta: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            },

            form: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingTop: '25px',
                paddingRight: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '50px',
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            rates: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingRight: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '30px',
                    paddingLeft: '25px'
                }
            },

            related: {
                maxWidth: toRem(layoutDimension?.product?.content),
                paddingLeft: '0px',
                paddingRight: '0px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '25px',
                    paddingRight: '25px'
                }
            },

            listing: {
                margin: 'auto',
                maxWidth: toRem(layoutDimension?.product?.listing),
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            loadMore: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.product?.content),
                padding: '60px 25px',
                margin: 'auto'
            }
        },
        // Decko dana layout
        deckoDana: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                }
            },

            main: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.deckoDana?.main),
                paddingRight: '25px',
                paddingBottom: '30px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '100px',
                    paddingLeft: '25px'
                }
            },

            popular: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.deckoDana?.main),
                paddingBottom: '90px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '130px'
                }
            },

            loadMore: {
                paddingBottom: '50px',
                paddingTop: '40px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '70px'
                }
            },

            meta: {
                maxWidth: toRem(layoutDimension?.deckoDana?.main),
                paddingTop: '10px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '20px'
                }
            },

            form: {
                paddingTop: '50px',
                paddingBottom: '40px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '77px',
                    paddingBottom: '75px'
                }
            },

            rates: {
                paddingBottom: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '30px'
                }
            },

            tags: {
                paddingTop: '25px',
                paddingBottom: '35px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '35px',
                    paddingBottom: '55px'
                }
            },

            midas: {
                width: '100%',
                paddingLeft: '0px',
                paddingRight: '0px',

                '@media screen and (min-width: 768px)': {
                    paddingLeft: '150px',
                    paddingRight: '150px'
                }
            },
            prevNext: {
                margin: 'auto',
                width: '100%',
                paddingTop: '25px',
                paddingBottom: '35px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '35px',
                    paddingBottom: '55px'
                }
            }
        },
        // Giveaway layout
        giveaway: {
            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head),
                    margin: 'auto'
                }
            },

            content: {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: toRem(layoutDimension?.giveaway?.main),
                padding: '30px 25px 20px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '60px 25px 50px'
                }
            },

            form: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingBottom: '70px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '170px'
                }
            },

            popular: {
                width: '100%',
                maxWidth: '56.25rem',
                paddingBottom: '90px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '130px'
                }
            },
            winners: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '56.25rem',
                paddingRight: '25px',
                paddingBottom: '10px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '80px',
                    paddingLeft: '25px'
                }
            },

            title: {
                width: '100%',
                maxWidth: toRem(layoutDimension?.giveaway?.main),
                paddingBottom: '20px',
                paddingLeft: '25px',
                paddingRight: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '50px',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }
            },

            midas: {
                width: '100%',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '150px',
                    paddingLeft: '150px'
                }
            },

            facebook: {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: toRem(layoutDimension?.giveaway?.main),
                paddingRight: '25px',
                paddingBottom: '20px',
                paddingLeft: '25px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '50px',
                    paddingLeft: '25px'
                }
            }
        },

        tester: {
            head: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.tester?.head),
                padding: '30px 25px 60px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    padding: '60px 25px 140px'
                }
            },
            form: {
                width: '100%',
                paddingBottom: '70px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '170px'
                }
            }
        },
        // Calculator Layout
        calculator: {
            main: {
                flexDirection: 'column',
                width: '100%',
                maxWidth: toRem(layoutDimension?.calculator?.main),
                margin: 'auto',

                paddingBottom: '30px',
                '@media screen and (min-width: 768px)': {
                    paddingBottom: '100px'
                }
            },

            head: {
                width: '100%',
                paddingBottom: '25px',
                paddingTop: '30px',
                margin: 'auto',
                ddingBottom: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '60px',
                    paddingBottom: '50px'
                }
            },

            listing: {
                paddingRight: '25px',
                paddingLeft: '25px'
            },

            articles: {
                paddingRight: '0px',
                paddingBottom: '50px',
                paddingLeft: '0px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingLeft: '25px'
                }
            },

            divider: {
                padding: '25px',

                '@media screen and (min-width: 768px)': {
                    padding: '50px 25px'
                }
            },

            content: {
                paddingBottom: '30px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '65px'
                }
            },

            description: {
                margin: 'auto',
                width: '100%',
                paddingRight: '25px',
                paddingBottom: '40px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '65px',
                    paddingLeft: '25px'
                }
            }
        },

        columnist: {
            mainCard: {
                width: '100%'
            },

            ads: {
                billboard: {
                    width: '100%',
                    maxWidth: toRem(layoutDimension?.article?.head)
                },

                listing: {
                    width: '100%',
                    gridColumn: 'span 12'
                }
            },

            main: {
                maxWidth: toRem(layoutDimension?.category?.main),
                flexDirection: 'column',
                paddingRight: '0px',
                paddingBottom: '80px',
                paddingLeft: '0px',
                margin: 'auto',

                '@media screen and (min-width: 768px)': {
                    paddingRight: '25px',
                    paddingBottom: '120px',
                    paddingLeft: '25px'
                }
            },

            head: {
                paddingRight: '25px',
                paddingBottom: '20px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingBottom: '30px'
                }
            },

            listing: {
                display: 'grid',
                gridRowGap: '40px',
                gridTemplateColumns: '1fr',
                paddingTop: '40px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '0px',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridRowGap: '20px'
                }
            },

            items: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridColumnGap: '0px',
                gridRowGap: '40px',
                paddingTop: '10px',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '0px',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: '70px',
                    gridRowGap: '70px',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }
            },

            loadMore: {
                paddingTop: '60px',
                paddingRight: '25px',
                paddingLeft: '25px',

                '@media screen and (min-width: 768px)': {
                    paddingTop: '80px',
                    paddingRight: '0px',
                    paddingLeft: '0px'
                }
            }
        }
    }
}

export default {
    ...layoutTheme
}
