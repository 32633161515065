import { DrawerActionTypes as ActionTypes } from '../actions/drawer'

const initialState = {
    drawersOpen: []
}

const drawersReducer = (state = initialState, { newStore, type, drawerId, options } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE_DRAWER: {
            // If drawerId is provided, close only that drawer
            if (drawerId) {
                const otherDrawers = state.drawersOpen.filter(stateDrawer => stateDrawer.id !== drawerId)
                return {
                    ...state,
                    drawersOpen: otherDrawers
                }
            }

            // Else close all drawers
            return {
                ...state,
                drawersOpen: []
            }
        }

        case ActionTypes.OPEN_DRAWER: {
            const otherDrawers = state.drawersOpen.filter(stateDrawer => stateDrawer.id !== drawerId)
            const currentDrawer = {
                id: drawerId,
                options
            }
            return {
                ...state,
                drawersOpen: [...otherDrawers, currentDrawer]
            }
        }

        default:
            return state
    }
}

export { drawersReducer }
