import PropTypes from 'prop-types'

function Pudge({ hook: useHook, props, children }) {
    const result = useHook(...props)
    return children(result)
}
Pudge.propTypes = {
    hook: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.arrayOf(PropTypes.any)
}

Pudge.defaultProps = {
    props: []
}

export default Pudge
