/* eslint-disable camelcase */
import { globalSettings } from '../settings'
import generateImageURLFromTemplate from './image/generateImageURLFromTemplate'
import { getOriginal, wantsOriginalOrIsGif } from './image-original.ts'
import isNumeric from './isNumeric'

const { constants } = globalSettings
const { IMAGES_RATIO_TOLERANCE, IMAGE_LQIP_FILTER_NAME, IMAGES_DIMENSION_TOLERANCE } = constants

/**
 * Test whether provided URL is base64
 *
 * @param {*} urlString
 * @return {*}
 */
const isBase64 = (urlString = '') => {
    if (urlString?.length && urlString?.split(',')?.length) {
        const [, base64Encoding] = urlString.split(',')
        const regexp = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
        return regexp.test(base64Encoding) ? urlString : undefined
    }

    return undefined
}

/**
 * Test whether provided image string is valid URL, if it's valid then return URL else return undefined
 *
 * @param {*} urlString
 * @return {*}
 */
const isValidImageUrl = (urlString = '') => {
    // eslint-disable-next-line max-len
    const regexp =
        // eslint-disable-next-line max-len
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(urlString) ? urlString : undefined
}

/**
 * Test whether provided image string is local asset
 *
 * @param {*} urlString
 * @return {*}
 */
const isLocalImageUrl = (urlString = '') => {
    const regexp = /^(?!(?:https?|ftp):\/\/)?\/[^"]+?\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/
    return regexp.test(urlString) ? urlString : undefined
}

/**
 * Calculates image ratio from provided initial values or derives the ratio from config variations.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @param {*} initialWidth
 * @param {*} initialHeight
 * @return {*}
 */
const getImageRatio = (imageRatio, variations, initialWidth, initialHeight) => {
    const initialRatioFallback = parseInt(initialWidth, 10) / parseInt(initialHeight, 10) || 1

    if (imageRatio && typeof imageRatio !== 'string' && !Number.isNaN(imageRatio)) {
        // If provided initial ratio is number, we can use it right away
        return imageRatio
    }

    // Else Try and derive the image ratio from string, if provided, e.g. "4-3-35mm" or "4:3" or "1.3" should become 1.3333333
    if (imageRatio && typeof imageRatio === 'string' && !imageRatio?.toLowerCase().includes('original')) {
        return (
            variations.find(
                ({ aspect_ratio_name, aspect_ratio_slug }) =>
                    (aspect_ratio_name && aspect_ratio_name.toLowerCase().includes(imageRatio?.toLowerCase())) ||
                    (aspect_ratio_slug && aspect_ratio_slug.includes(imageRatio?.toLowerCase()))
            )?.aspect_ratio_value || initialRatioFallback
        )
    }

    if (imageRatio?.toLowerCase().includes('original')) {
        // If we're using original aspect ratio, we have to return false and derive it from image instance later
        return false
    }

    // Else return fallback
    return initialRatioFallback
}

/**
 * Derives variation instances only from config, no image response object is required.
 * Only image ID is required.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @return {*}
 */
const getImageVariationFromConfig = (imageVariationData, variations) => {
    // If we're using config variation directly instead of numeric ratio, just return the variation
    if (typeof imageVariationData === 'object' && !!Object.keys(imageVariationData)?.length) {
        return variations.find(({ id }) => id === imageVariationData?.id)
    }

    const imageRatio = imageVariationData
    // When we have ratio, first try to find variation using the exact ratio
    let variation = variations.find(configVariation => configVariation.aspect_ratio_value === imageRatio)

    // If no exact variation is found,  try and find image variation within configured ratio tolerance
    if (!variation) {
        variation = variations.find(
            ({ aspect_ratio_value }) =>
                aspect_ratio_value && Math.abs(aspect_ratio_value - imageRatio) <= IMAGES_RATIO_TOLERANCE
        )
    }

    // If we still don't have our variation, e.g. if no variation matches aspect ratio value, use first available fill variation or just use first variation
    if (!variation) {
        variation = variations.find(({ method }) => method === 'fill')?.[0] || variations[0]
    }

    return variation
}

const MAX_PAGE_WIDTH_MD = 1200

const parseWidthPercentageMd = width => {
    if (!width) {
        return 100
    }
    if (typeof width === 'number') {
        return width
    }
    if (typeof width === 'string' && (width.includes('%') || width.includes('vw'))) {
        return Math.min(100, parseInt(width, 10))
    }
    if (typeof width === 'string' && width.includes('px')) {
        return Math.ceil(MAX_PAGE_WIDTH_MD / parseInt(width, 10))
    }
    return width
}

/**
 * Derives image instances from either config or image response objects, based on variation instances provided
 *   * https://ericportis.com/posts/2014/srcset-sizes/
 *   * https://www.smashingmagazine.com/2014/05/responsive-images-done-right-guide-picture-srcset/
 *   * https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/
 *   * https://webdesign.tutsplus.com/tutorials/quick-tip-how-to-use-html5-picture-for-responsive-images--cms-21015
 *
 * @param {*} instances
 * @param {*} width
 * @param {*} height
 * @param {boolean} maxWidth
 * @param {boolean} maxHeight
 * @return {*}
 */
const getImageInstance = (
    instances,
    width,
    height,
    maxWidth,
    maxHeight,
    original,
    useLargestInstance,
    sizesSm,
    sizesMd,
    sizesXl
) => {
    let optimalInstance
    let optimalInstanceId
    let instanceRatio
    let instanceMaxWidth
    let instanceMaxHeight

    // Because API was changed, we need to buffer our tolerance significantly to find valid instances for original aspect ratio, yes, it's a hack
    const originalToleranceBuffer = 8

    const { id: lqipInstanceId } =
        instances.find(variationInstance =>
            variationInstance?.filters?.toLowerCase()?.includes(IMAGE_LQIP_FILTER_NAME?.toLowerCase())
        ) ||
        instances.sort((a, b) => (a?.max_width || 0) - (b?.max_width || 0))?.[0] ||
        {}

    const instanceWidth = parseInt(`${width}`.replace(/[^0-9]+/g, ''), 10)

    // We assume half the screen width + dimension tolerance as baseline for sizes prop, based on design
    // const instanceSizes = `(min-width: ${instanceWidth}px) calc(0.85 * (100vw - ${
    //     original ? IMAGES_DIMENSION_TOLERANCE * originalToleranceBuffer : IMAGES_DIMENSION_TOLERANCE
    // }px)), 100vw`

    const mdPercentage = parseWidthPercentageMd(sizesMd)
    const isAbsoluteSize = typeof sizesMd === 'string' && sizesMd.includes('px')

    const parsedRelativeSizeSm = typeof sizesSm === 'number' ? `${sizesSm}vw` : sizesSm
    const parsedRelativeSizeMd = typeof sizesMd === 'number' ? `${sizesMd}vw` : sizesMd
    const parsedRelativeSizeXl = typeof sizesXl === 'number' ? `${sizesXl}vw` : sizesXl

    const instanceSizes = [
        sizesXl && `(min-width: 1280px) ${parsedRelativeSizeXl}`,
        // for screen larger than mobile breakpoint, fetch size defined by design
        `(min-width: 768px) ${
            sizesMd
                ? `${
                      isAbsoluteSize
                          ? parsedRelativeSizeMd
                          : `min(${(MAX_PAGE_WIDTH_MD * mdPercentage) / 100}px, ${parsedRelativeSizeMd})`
                  }`
                : `${instanceWidth}px`
        }`,
        // for mobile screen
        sizesSm ? `${parsedRelativeSizeSm}` : `min(${instanceWidth}px, 100vw)`
    ]
        .filter(Boolean)
        .join(', ')
    const largestInstanceSizes = `(min-width: ${instanceWidth}px) calc(0.5 * 100vw), 100vw`

    if (useLargestInstance) {
        optimalInstance = instances?.[(instances?.length || 0) - 1]
    } else if (!Number.isNaN(parseFloat(instanceWidth))) {
        optimalInstance = instances
            .sort((a, b) => b.max_width - a.max_width)
            .find(
                variationInstance =>
                    Math.abs(variationInstance.max_width - instanceWidth) <=
                    (original ? IMAGES_DIMENSION_TOLERANCE * originalToleranceBuffer : IMAGES_DIMENSION_TOLERANCE)
            )
    } else if (!Number.isNaN(parseFloat(height))) {
        optimalInstance = instances
            .sort((a, b) => b.max_height - a.max_height)
            .find(
                variationInstance =>
                    Math.abs(variationInstance.max_height - height) <=
                    (original ? IMAGES_DIMENSION_TOLERANCE * originalToleranceBuffer : IMAGES_DIMENSION_TOLERANCE)
            )
    }

    if (optimalInstance && Object.keys(optimalInstance).length) {
        // eslint-disable-next-line no-extra-semi
        ;({ id: optimalInstanceId } = optimalInstance)

        // If we're using the "original" as image ratio, we need to calculate it from instance dimensions
        const { original_max_width, max_width, original_max_height, max_height } = optimalInstance
        if (max_width && max_height) {
            instanceRatio = original ? original_max_width / original_max_height : max_width / max_height

            if (maxWidth) {
                instanceMaxWidth = original_max_width || max_width
            }

            if (maxHeight) {
                instanceMaxHeight = original_max_height || max_height
            }
        }
    }

    return {
        lqipInstanceId,
        instanceWidth,
        instanceSizes: useLargestInstance ? largestInstanceSizes : instanceSizes,
        instanceRatio,
        optimalInstanceId,
        instanceMaxWidth,
        instanceMaxHeight
    }
}

/**
 * Generates image srcset for WebP, JPEG, provide placeholder values and source value for onLoad image trigger
 *
 * @param {*} instances
 * @param {*} sizes
 * @param {*} initialType
 * @param {*} imageId
 * @param {*} urlTemplate
 * @param {*} lqipInstanceId
 * @param {*} optimalInstanceId
 * @param {*} availableFormats
 * @return {*}
 */
const getImageData = (
    instances,
    sizes,
    initialType,
    imageId,
    urlTemplate,
    lqipInstanceId,
    optimalInstanceId,
    availableFormats,
    updatedAt,
    minSrcsetWidth = 100,
    maxSrcsetWidth = 2000
) => {
    const webpSrcSet = []
    const jpegSrcSet = []
    let lqip
    let src

    instances
        // Note: Sorts instances by max_width in order to prevent hydratation errors.
        .sort((a, b) => ((a.srcset_width || a.max_width) < (b.srcset_width || b.max_width) ? 1 : -1))
        .filter(
            ({ max_width, srcset_width }) =>
                (srcset_width ?? max_width) > minSrcsetWidth && (srcset_width ?? max_width) < maxSrcsetWidth
        ) // remove images that don't fit dimensions
        .forEach(instance => {
            const { max_height, max_width, id: instanceId, srcset_width } = instance
            // While iterating over instances, if current instance has LQIP filter, use it as LQIP src, else use it as srcset
            if (instanceId === lqipInstanceId) {
                lqip = generateImageURLFromTemplate(
                    urlTemplate,
                    imageId,
                    max_width,
                    max_height,
                    instanceId,
                    initialType || 'webp',
                    updatedAt
                )
            }

            if (instanceId === optimalInstanceId) {
                src = generateImageURLFromTemplate(
                    urlTemplate,
                    imageId,
                    max_width,
                    max_height,
                    instanceId,
                    initialType || 'webp',
                    updatedAt
                )
            }

            webpSrcSet.push(
                `${generateImageURLFromTemplate(
                    urlTemplate,
                    imageId,
                    max_width,
                    max_height,
                    instanceId,
                    'webp',
                    updatedAt
                )} ${srcset_width || max_width}w`
            )

            jpegSrcSet.push(
                `${generateImageURLFromTemplate(
                    urlTemplate,
                    imageId,
                    max_width,
                    max_height,
                    instanceId,
                    'jpeg',
                    updatedAt
                )} ${srcset_width || max_width}w`
            )
        })

    if (sizes?.length && (webpSrcSet?.length || jpegSrcSet?.length)) {
        return {
            lqip,
            src,
            srcSet: {
                sizes,
                webp: webpSrcSet.join(','),
                jpeg: jpegSrcSet.join(',')
            }
        }
    }

    return {}
}

const getAmpDimensions = (width, height, maxWidth, maxHeight) => {
    if ((isNumeric(width) && isNumeric(height)) || !isNumeric(maxWidth) || !isNumeric(maxHeight)) {
        return { width, height }
    }
    if (!isNumeric(width) && !isNumeric(height)) {
        return { width: maxWidth, height: maxHeight }
    }
    if (isNumeric(width) && !isNumeric(height)) {
        return { width, height: Math.round((maxHeight * width) / maxWidth) }
    }

    if (!isNumeric(width) && isNumeric(height)) {
        return { width: Math.round((maxWidth * height) / maxHeight), height }
    }

    return { width, height }
}

const parseInstances = (instances, originalAspectRatio) =>
    originalAspectRatio && originalAspectRatio < 1
        ? instances.map(instance => ({
              ...instance,
              srcset_width: Math.floor(instance.max_height * originalAspectRatio)
          }))
        : instances

export default function getVariationData(
    image,
    initialWidth,
    initialHeight,
    initialRatio,
    initialVariation,
    variations,
    original,
    imageRatioVariants,
    isLoaded,
    isAmp,
    useLargestInstance,
    initialType,
    updatedAt,
    sizesSm,
    sizesMd,
    sizesXl,
    originalAspectRatio,
    minSrcsetWidth,
    maxSrcsetWidth
) {
    let imageWidth = initialWidth || image?.original_width
    const isCustomOriginal = initialVariation?.name === imageRatioVariants.CUSTOM_ORIGINAL.name
    let imageHeight = isCustomOriginal ? Math.round(imageWidth / (image?.original_aspect_ratio || 1)) : initialHeight

    if (typeof image !== 'undefined' && wantsOriginalOrIsGif(original, image)) {
        return getOriginal(image, initialWidth, imageHeight, initialRatio)
    }

    let imageRatio = image?.original_aspect_ratio
    let imageData

    if (image && isLoaded) {
        // Get image id, but for original-aspect image we actually have to use image object because we're fetching dimensions from image response instead of using image config
        const imageId = typeof image === 'object' && Object.keys(image)?.length ? image?.id : image

        let variation

        if (variations.length) {
            if (!initialVariation) {
                // First, let's handle image ratio so we can find variation that we need
                imageRatio = getImageRatio(initialRatio, variations, initialWidth, initialHeight)
            }

            if (initialVariation && !isCustomOriginal && !isValidImageUrl(image)) {
                // If we have ratio, we can try and find variation from config
                variation = getImageVariationFromConfig(initialVariation || imageRatio, variations)
                // If we have no useful ratio, find the variation without ratio, e.g. variation with original aspect and derive ratio from its dimensions
            } else if (typeof image === 'object' && Object.keys(image)?.length) {
                variation = getImageVariationFromConfig(initialVariation || imageRatio, variations)
            } else if (isValidImageUrl(image)) {
                const { aspect_ratio_value: imageRatioT } = getImageVariationFromConfig(
                    initialVariation || imageRatio,
                    variations
                )
                imageRatio = imageRatioT
            } else {
                // maybe developer made an error and forgot to provide full image response when using ORIGINAL_ASPECT
            }
        }

        if (typeof variation === 'object' && Object.keys(variation).length) {
            const { instances = [], url_template, formats } = variation

            const parsedInstances = parseInstances(instances, originalAspectRatio)
            if (instances.length) {
                // Now that we have proper variation instances, lets get essentials required to construct image data

                const {
                    instanceWidth,
                    instanceSizes,
                    instanceRatio,
                    lqipInstanceId,
                    optimalInstanceId,
                    instanceMaxWidth,
                    instanceMaxHeight
                } = getImageInstance(
                    parsedInstances,
                    initialWidth,
                    initialHeight,
                    isAmp,
                    isAmp,
                    initialVariation?.name === imageRatioVariants.CUSTOM_ORIGINAL.name,
                    useLargestInstance,
                    sizesSm,
                    sizesMd,
                    sizesXl
                )

                imageWidth = instanceWidth
                imageHeight = isCustomOriginal
                    ? Math.round(initialWidth / (image?.original_aspect_ratio || 1))
                    : initialHeight
                imageRatio = instanceRatio

                const desiredFormat =
                    initialType ||
                    (!!formats?.length && formats?.find(({ extension }) => extension === 'webp')?.extension) ||
                    (!!formats?.length && formats?.[0]?.extension) ||
                    'jpeg'

                // Finally, construct the image data
                imageData = getImageData(
                    parsedInstances,
                    instanceSizes,
                    desiredFormat,
                    imageId,
                    url_template,
                    lqipInstanceId || optimalInstanceId,
                    optimalInstanceId,
                    formats,
                    updatedAt,
                    minSrcsetWidth,
                    maxSrcsetWidth
                )

                imageData.maxWidth = instanceMaxWidth
                imageData.maxHeight = instanceMaxHeight
            }
        }
    }

    const finalWidth = typeof initialWidth === 'string' && initialWidth.toLowerCase() === 'auto' ? 'auto' : imageWidth

    const finalHeight =
        typeof initialHeight === 'string' && initialHeight.toLowerCase() === 'auto' ? 'auto' : imageHeight

    // @NOTE: dirty hacky safety since amp preloading sends an object as image variable
    const originalImageUrl = imageData?.src || image || null

    return {
        ratio: imageRatio,
        width: finalWidth,
        height: finalHeight,
        srcSetData: isLocalImageUrl(originalImageUrl) ? [] : imageData?.srcSet,
        max_width: imageData?.maxWidth,
        max_height: imageData?.maxHeight,
        placeholder:
            isBase64(originalImageUrl) ||
            isLocalImageUrl(originalImageUrl) ||
            isValidImageUrl(originalImageUrl) ||
            imageData?.lqip,
        ampDimensions: isAmp && getAmpDimensions(finalWidth, finalHeight, imageData?.maxWidth, imageData?.maxHeight),
        /**
         * It's intended to keep 'source' the last attribute returned because React updates attributes in order.
         * If we keep 'source' the first one, Safari will immediately start to fetch 'src' before 'sizes' and 'srcSet' are even
         * updated by React. That causes multiple unnecessary requests if 'srcSet' and 'sizes' are defined.
         *
         * This cannot be reproduced in Chrome or Firefox.
         */
        source:
            isBase64(originalImageUrl) ||
            isLocalImageUrl(originalImageUrl) ||
            isValidImageUrl(originalImageUrl) ||
            imageData?.src
    }
}
