import Script from 'next/script'
import PropTypes from 'prop-types'

function GemiusPixel({ id }) {
    return (
        <Script
            id="gemius"
            dangerouslySetInnerHTML={{
                __html: `
                    <!--//--><![CDATA[//><!--
                        var pp_gemius_identifier = '${id}';
                        var pp_gemius_use_cmp = true;
                        var pp_gemius_cmp_timeout = 10000;
                        // lines below shouldn't be edited
                        function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event');(function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');gt.setAttribute('defer','defer'); gt.src=l+'://hr.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
                    //--><!]]>`
            }}
        />
    )
}

GemiusPixel.propTypes = {
    id: PropTypes.string.isRequired
}

export default GemiusPixel
