import { toRem, toRems } from '../../helpers/theme'

const style = ({ theme }) => {
    const { panel } = theme.global.navigation

    const config = {
        header: {
            height: {
                default: toRem(panel.header.height.default),
                xs: toRem(panel.header.height.xs)
            }
        },
        maxWidth: {
            default: toRem(panel.maxWidth.default),
            md: toRem(panel.maxWidth.md),
            xs: toRem(panel.maxWidth.xs)
        }
    }

    return {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.navigation,
        '& .panelHeader': {
            display: 'flex',
            height: config.header.height.xs,
            [theme.breakpoints.up('md')]: {
                height: config.header.height.default
            },
            '& .panelSearch': {
                flex: '1 1 auto'
            }
        },
        '& .panelContent': {
            position: 'relative',
            maxHeight: `calc(100vh - ${config.header.height.default})`,
            boxSizing: 'content-box',
            padding: toRems([56, 50, 86, 50]),
            maxWidth: config.maxWidth.default,
            [theme.breakpoints.down('md')]: {
                maxWidth: config.maxWidth.md
            },
            [theme.breakpoints.only('xs')]: {
                maxWidth: config.maxWidth.xs,
                padding: toRems([75, '10vw', 90, '12vw']),
                maxHeight: `calc(100vh - ${config.header.height.xs})`,
                overflowY: 'scroll'
            },
            '& .sideGraphic': {
                position: 'absolute',
                marginTop: toRem(-26),
                left: toRem(-20),
                width: toRem(44),
                height: `calc( 100% - ${toRem(90)})`,
                [theme.breakpoints.up('md')]: {
                    left: toRem(-24),
                    height: `calc( 100% - ${toRem(120)})`,
                    marginTop: toRem(-15),
                    width: toRem(44)
                }
            },
            '& .secondaryMenu': {
                marginTop: toRem(32),
                [theme.breakpoints.up('md')]: {
                    marginTop: toRem(61)
                }
            }
        }
    }
}

export default style
