/* eslint-disable camelcase */
import { useMemo } from 'react'

import generateImageURLFromTemplate from '../helpers/image/generateImageURLFromTemplate'
import { globalSettings } from '../settings'
import useImagesConfig from './useImagesConfig'

const { constants } = globalSettings
const { IMAGES_RATIO_TOLERANCE } = constants

/**
 * Calculates image ratio from provided initial values or derives the ratio from config variations.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @param {*} initialWidth
 * @param {*} initialHeight
 * @return {*}
 */
const getImageRatio = (imageRatio, variations, initialWidth, initialHeight) => {
    const initialRatioFallback = parseInt(initialWidth, 10) / parseInt(initialHeight, 10) || 1

    if (imageRatio && typeof imageRatio !== 'string' && !Number.isNaN(imageRatio)) {
        // If provided initial ratio is number, we can use it right away
        return imageRatio
    }

    // Else Try and derive the image ratio from string, if provided, e.g. "4-3-35mm" or "4:3" or "1.3" should become 1.3333333
    if (imageRatio && typeof imageRatio === 'string' && !imageRatio?.toLowerCase().includes('original')) {
        return (
            variations.find(
                ({ aspect_ratio_name, aspect_ratio_slug }) =>
                    (aspect_ratio_name && aspect_ratio_name.toLowerCase().includes(imageRatio?.toLowerCase())) ||
                    (aspect_ratio_slug && aspect_ratio_slug.includes(imageRatio?.toLowerCase()))
            )?.aspect_ratio_value || initialRatioFallback
        )
    }

    if (imageRatio?.toLowerCase().includes('original')) {
        // If we're using original aspect ratio, we have to return false and derive it from image instance later
        return false
    }

    // Else return fallback
    return initialRatioFallback
}
/**
 * Derives image instances from either config or image response objects, based on variation instances provided
 *   * https://ericportis.com/posts/2014/srcset-sizes/
 *   * https://www.smashingmagazine.com/2014/05/responsive-images-done-right-guide-picture-srcset/
 *   * https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/
 *   * https://webdesign.tutsplus.com/tutorials/quick-tip-how-to-use-html5-picture-for-responsive-images--cms-21015
 *
 * @param {*} instances
 * @param {*} width
 * @param {*} height
 * @param {boolean} maxWidth
 * @param {boolean} maxHeight
 * @return {*}
 */
const getSeoImageInstance = (instances, width, height) => {
    const instanceWidth = parseInt(`${width}`.replace(/[^0-9]+/g, ''), 10)
    const instanceHeight = parseInt(`${height}`.replace(/[^0-9]+/g, ''), 10)
    const optimalInstance = instances
        .sort((a, b) => {
            const widthDiff = (b?.max_width || 0) - (a?.max_width || 0)
            if (widthDiff === 0) {
                return b.max_height - a.max_height
            }
            return widthDiff
        })
        .find(
            instance =>
                (!instanceHeight || instance.max_height <= instanceHeight) &&
                (!instanceWidth || instance.max_width <= instanceWidth)
        )

    return optimalInstance
}

export const generateSingleImageURL = ({ imageId, variations, ratio, width, height, type }) => {
    const imageRatio = getImageRatio(ratio, variations, width, height)
    const realType = type === 'jpg' ? 'jpeg' : type

    // When we have ratio, frist try to find variation using the exact ratio
    let imageVariation = variations.find(configVariation => configVariation.aspect_ratio_value === imageRatio)

    // If no exact variation is found,  try and find image variation within configured ratio tolerance
    if (!imageVariation && imageRatio) {
        imageVariation = variations.find(
            ({ aspect_ratio_value }) =>
                aspect_ratio_value && Math.abs(aspect_ratio_value - imageRatio) <= IMAGES_RATIO_TOLERANCE
        )
    }

    // If we still don't have our variation, e.g. if no variation matches aspect ratio value, use first available fill variation or just use first variation
    if (!imageVariation) {
        imageVariation = variations.find(({ method }) => method === 'fill')?.[0] || variations[0]
    }

    const { instances = [], url_template } = imageVariation

    let optimalInstance = getSeoImageInstance(instances, width, height)
    // https://d19p4plxg0u3gz.cloudfront.net/e6870c3c-8f26-11ec-8b8f-0242ac120011/v/25cafe84-2baa-11ec-b2bc-3a925a8303a3/768x432-25cb0d16-2baa-11ec-ae16-3a925a8303a3.webp?v=l0som26w
    if (!optimalInstance) {
        optimalInstance = instances?.[0]
    }

    // console.log('found image', { instances, width, height, optimalInstance, imageRatio, variations })

    const { max_height, max_width, id: instanceId } = optimalInstance

    const imageUrl = generateImageURLFromTemplate(url_template, imageId, max_width, max_height, instanceId, realType)

    return imageUrl
}

/**
 * Get image url.
 *
 * @param {string|number|string[]|number[]} imageId
 * @param {('4:3','1:1','21:9','25:9','2:3','original')} ratio
 * @param {number} width
 * @param {number} height
 * @param {('jpg'|'webp')} type
 * @return {string|string[]|null}
 *
 * ```js
 * import { useImageUrl } from '../hooks'
 * const imageUrl = useImageUrl(1, 'original', 1000, undefined, 'jpg')
 * ```
 */
const useImageURL = (imageId, ratio = 'original', width = undefined, height = undefined, type = undefined) => {
    const { imagesConfig: variations = [], isLoaded } = useImagesConfig()
    return useMemo(() => {
        if (imageId && isLoaded) {
            if (Array.isArray(imageId)) {
                return imageId.map(
                    id => !!id && generateSingleImageURL({ imageId: id, variations, ratio, width, height, type })
                )
            }
            return !!imageId && generateSingleImageURL({ imageId, variations, ratio, width, height, type })
        }
        return null
    }, [imageId, ratio, width, height, type, variations, isLoaded])
}

export default useImageURL
