const prefix = '-'

const getAdZoneKey = (device: 'D' | 'M' = 'D', zoneName = '') => (device ? `${device} ${prefix} ` : '') + zoneName
export default getAdZoneKey

export const deviceTypeToAdZoneDevice = (deviceType: 'mobile' | 'desktop' | 'any' | null) => {
    switch (deviceType) {
        case 'mobile':
            return 'M'
        case 'desktop':
            return 'D'
        default:
            return null
    }
}
