import { Children, isValidElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Tabs.style'

const TabsStyled = styled.div(props => ({ ...styles(props) }))

const getTabLabel = (label, labelTag, index) => {
    const Tag = labelTag
    if (label && typeof label !== 'string') {
        return label
    }
    return <Tag className="tabsHead_itemLabel">{label || `Tab ${index + 1}`}</Tag>
}

function Tabs({ children, activeTabIndex, indicatorHeight, stickyHead, className, ...rest }) {
    const tabElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])
    const isActiveTabIndexValid = activeTabIndex >= 0 && activeTabIndex < tabElements.length
    const [activeTab, setActiveTab] = useState(isActiveTabIndexValid ? activeTabIndex : 0)
    const [activeTabWidth, setActiveTabWidth] = useState(100)
    const [activeTabOffset, setActiveTabOffset] = useState(100)
    const [stickyTabsHead, setStickyTabsHead] = useState(false)
    const [stickyTabsHeadHeight, setStickyTabsHeadHeight] = useState(0)
    const tabsHead = useRef()
    const tabsHeadInner = useRef()
    const activeTabElement = useRef()

    const uid = useUIDSeed()

    useEffect(() => {
        if (!isActiveTabIndexValid) {
            /* eslint-disable-next-line */
            console.warn(`Invalid tab index provided ${activeTabIndex}. Falling back to first tab.`)
            setActiveTab(0)
        } else {
            setActiveTab(activeTabIndex)
        }
    }, [activeTabIndex, isActiveTabIndexValid])

    const handleTabSlide = useCallback(
        direction => {
            const numTabs = tabElements.length - 1 // adjust for index
            if (direction === 'NEXT' && activeTab !== numTabs) {
                setActiveTab(activeTab === numTabs ? 0 : activeTab + 1)
            }
            if (direction === 'PREVIOUS' && activeTab !== 0) {
                setActiveTab(activeTab === 0 ? numTabs : activeTab - 1)
            }
        },
        [activeTab, tabElements.length]
    )

    const slideHandlers = useSwipeable({
        onSwipedLeft: () => handleTabSlide('NEXT'),
        onSwipedRight: () => handleTabSlide('PREVIOUS'),
        preventScrollOnSwipe: true,
        trackMouse: true
    })

    useEffect(() => {
        const handleScroll = () => {
            if (tabsHead.current) {
                setStickyTabsHead(tabsHead.current.getBoundingClientRect().top <= 0)
            }
        }

        const getTabsHeadHeight = () => {
            if (tabsHeadInner.current) {
                setStickyTabsHeadHeight(tabsHeadInner.current.getBoundingClientRect().height)
            }
        }

        const getIndicatorPosition = () => {
            if (tabsHeadInner.current && activeTabElement.current) {
                const indicatorOffset = tabsHeadInner.current.getBoundingClientRect().left

                setActiveTabWidth(activeTabElement.current.getBoundingClientRect().width)
                setActiveTabOffset(activeTabElement.current.getBoundingClientRect().left - indicatorOffset)
            }
        }

        getIndicatorPosition()
        window.addEventListener('resize', getIndicatorPosition)
        if (stickyHead) {
            getTabsHeadHeight()
            window.addEventListener('scroll', handleScroll)
        }

        return () => {
            window.removeEventListener('resize', getIndicatorPosition)
            if (stickyHead) {
                window.removeEventListener('scroll', handleScroll)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    if (!tabElements.length) {
        return null
    }

    return (
        <TabsStyled
            className={className}
            sticky={stickyTabsHead}
            stickyHeight={stickyTabsHeadHeight}
            activeTab={activeTab}
            activeTabWidth={activeTabWidth}
            activeTabOffset={activeTabOffset}
            indicatorHeight={indicatorHeight}
            {...rest}>
            <div className="tabsHead" ref={tabsHead}>
                <div className="tabsHead_inner" ref={tabsHeadInner}>
                    {tabElements.map((child, index) => {
                        const { label, labelTagComponent } = child.props
                        return (
                            <div
                                key={uid(child)}
                                role="presentation"
                                className="tabsHead_item"
                                onClick={() => setActiveTab(index)}>
                                <div ref={activeTab === index ? activeTabElement : null} className="tabsHead_itemInner">
                                    {getTabLabel(label, labelTagComponent, index)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="tabsBody" {...slideHandlers}>
                <div className="tabsBody_inner">
                    {tabElements.map(child => (
                        <div key={uid(child)} className="tabsBody_item">
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </TabsStyled>
    )
}

Tabs.propTypes = {
    className: PropTypes.string,
    indicatorHeight: PropTypes.number,
    activeTabIndex: PropTypes.number,
    stickyHead: PropTypes.bool
}

Tabs.defaultProps = {
    className: undefined,
    indicatorHeight: 2,
    activeTabIndex: 0,
    stickyHead: false
}

export default Tabs
