import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalActions } from '../actions'

const useModal = id => {
    const dispatch = useDispatch()
    const { isOpen: isModalOpen } = useSelector(state => state.modals)
    const isOpen = isModalOpen.includes(id)
    const close = useCallback(() => dispatch(ModalActions.close(id)), [id, dispatch])
    const open = useCallback(() => dispatch(ModalActions.open(id)), [id, dispatch])
    const toggle = useCallback(() => dispatch(ModalActions.toggle(id)), [id, dispatch])

    return { isOpen, open, close, toggle }
}

export default useModal
