import styled from '@emotion/styled'

import style from './Content.style'

const ContentStyled = styled.div(props => ({ ...style(props) }))

function Content({ children }) {
    return <ContentStyled>{children}</ContentStyled>
}

export default Content
