import { useEffect } from 'react'
import { useRouter } from 'next/router'

import useInitialLoadDispatch from '../dispatchers/initialLoad'
import parseClientEnvironment from '../helpers/parseClientEnvironment'
import { useEnvironment } from '../selectors/initialLoad'

export const useConnectEnvironment = () => {
    const router = useRouter()
    const dispatcher = useInitialLoadDispatch()

    useEffect(() => {
        dispatcher.setEnvironment(parseClientEnvironment(router.query))
    }, [dispatcher.setEnvironment, router.query])
}

export default useEnvironment
