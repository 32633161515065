import { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const SettingsContext = createContext({})

/**
 * Settings provider, used for fetching all kinds of app wide data
 *
 * @param {Object} { children }
 * @param {String} { clientName }
 * @returns
 */
function SettingsProvider({ children, clientName }) {
    const value = useMemo(
        () => ({
            xClientName: clientName
        }),
        [clientName]
    )

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

SettingsProvider.propTypes = {
    clientName: PropTypes.string
}

SettingsProvider.defaultProps = {
    clientName: process.env.NEXT_PUBLIC_API_CLIENT
}

export { SettingsContext }

export default SettingsProvider
