import { useCallback, useEffect, useRef } from 'react'

import useCurrentPageNumber from '../useCurrentPageNumber'
import { useScrollToCallback } from '../useWindow'

const usePrevNextScrolling = ({ offset = -100 } = {}) => {
    const ref = useRef(null)
    // proper way to set ref inside a hook
    const setRef = useCallback(node => {
        ref.current = node
    }, [])

    const articlePage = useCurrentPageNumber()
    const shouldScroll = useRef(false)
    const scrollTo = useScrollToCallback()
    // scrollTo is disabled on initial load, scrolls on any other articlePage change
    useEffect(() => {
        if (!shouldScroll.current || shouldScroll.current === articlePage) {
            shouldScroll.current = articlePage
            return
        }
        shouldScroll.current = articlePage
        if (ref.current) {
            scrollTo(ref.current, { offset })
        }
    }, [articlePage])

    return { contentRef: setRef, articlePage }
}

export default usePrevNextScrolling
