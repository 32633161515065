/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react'

type SetLocalStorageValueFunction = (value: any) => void
type DeleteLocalStorageKeyFunction = () => void

const useLocalStorage = <T>(
    key: string,
    initialValue?: T
): [T, SetLocalStorageValueFunction, DeleteLocalStorageKeyFunction] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined' || !key) {
            return initialValue
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // If error also return initialValue
            // eslint-disable-next-line no-console
            // console.log(error)
            return initialValue
        }
    })
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: any): void => {
            // console.log('storage set value event', value, key, window.localStorage)
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value
                // Save state
                setStoredValue(valueToStore)
                // Save to local storage
                if (typeof window !== 'undefined') {
                    // console.log('storage set value', key, valueToStore)
                    window.localStorage.setItem(key, JSON.stringify(valueToStore))
                }
            } catch (error) {
                console.error(error)
                // A more advanced implementation would handle the error case
                // eslint-disable-next-line no-console
                // console.log(error)
            }
        },
        [key, storedValue]
    )
    useEffect(() => {
        if (typeof window === 'undefined') {
            // console.log('no window.localStorage')
            return () => {}
        }
        const onStorageChangeListener = (event: StorageEvent) => {
            // console.log('storage event', event, key)
            const { key: eventKey, newValue, storageArea } = event
            if (eventKey === key && storageArea === window.localStorage) {
                if (newValue !== null) {
                    try {
                        setStoredValue(JSON.parse(newValue))
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error)
                    }
                }
            }
        }
        // console.log('adding storage listener')
        window.addEventListener('storage', onStorageChangeListener)
        return () => {
            window.removeEventListener('storage', onStorageChangeListener)
        }
    }, [setStoredValue, key])
    const deleteKey = () => {
        window.localStorage.removeItem(key)
    }
    return [storedValue, setValue, deleteKey]
}
export default useLocalStorage
