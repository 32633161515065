const constants = {
    SET_PERSISTENT_QUERY: 'PERSISTENT_QUERY/SET_PERSISTENT_QUERY',
    REMOVE_PERSISTENT_QUERY: 'PERSISTENT_QUERY/REMOVE_PERSISTENT_QUERY'
}

const actions = {
    setPersistentQuery: (key, value) => ({
        type: constants.SET_PERSISTENT_QUERY,
        value,
        key
    }),
    removePersistentQuery: key => ({
        type: constants.REMOVE_PERSISTENT_QUERY,
        key
    })
}

export { constants as PersistentQueryActionTypes, actions as PersistentQueryActions }
