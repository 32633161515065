import { useEffect, useState } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

/**
 * We use iframe to embed tiktok because tiktok is really
 * bad in loading embeds in the SPA and dynamic DOM.
 *
 * Embed SDK does not how to reload embed after node
 * delete/insert.
 *
 * Iframe makes sure that embed SDK is only initiated
 * once per embed and that DOM is not rewritten.
 *
 * Also this contains simple logic of syncing iframe
 * width/height with its content, inspired by
 * wordpress embed element.
 *
 * https://github.com/WordPress/gutenberg/blob/b77066b38ae7e7a8894b33f328ccfeedfd265c8f/packages/block-library/src/embed/embed-preview.js#L25
 *
 * @param {Object} { htmlContent, author, title }
 * @return {*}
 */
function TiktokIframeContainer({ htmlContent, author, title }) {
    const reg = /id="([^\s]+)"/g
    const id = htmlContent?.match(reg)?.[0]?.slice(4, (htmlContent?.match(reg)?.[0]?.length || 0) - 1)

    if (!author || !id) {
        return (
            <>
                {author} {id}
            </>
        )
    }

    return (
        <>
            <blockquote
                className="tiktok-embed"
                cite={`${author}/video/${id}`}
                data-video-id={id}
                style={{ maxWidth: 605, minWidth: 325 }}>
                <section>
                    {title?.split(' ')?.map(item => {
                        if (item?.includes('#')) {
                            return (
                                <a
                                    title={item?.replace('#', '')}
                                    key={item?.replace('#', '')}
                                    target="_blank"
                                    href={`https://www.tiktok.com/tag/${item?.replace('#', '')}`}
                                    rel="noreferrer">
                                    {item}
                                </a>
                            )
                        }
                        return item
                    })}
                </section>
            </blockquote>
            <Script src={`https://www.tiktok.com/embed.js?t=${new Date().toISOString()}`} />
        </>
    )
}

TiktokIframeContainer.propTypes = {
    htmlContent: PropTypes.string.isRequired,
    author: PropTypes.string,
    title: PropTypes.string
}

TiktokIframeContainer.defaultProps = {
    author: '',
    title: ''
}

function WithMutationObserver(props) {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const loadPolyfill = async () => {
            if (typeof window.IntersectionObserver === 'undefined') {
                await import('mutation-observer')
            }

            setLoaded(true)
        }

        loadPolyfill()
    }, [])

    if (!loaded) {
        return null
    }

    return <TiktokIframeContainer {...props} />
}

export default WithMutationObserver
