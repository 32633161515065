import { ComponentProps, FC, PropsWithChildren } from 'react'

import { RequiredFieldsOnly } from '../types/helpers/RequiredFieldsOnly'

type WrapperComponentType = FC<PropsWithChildren<any>>
type WrapperComponentMap = Record<string, WrapperComponentType>

type ComponentPropsWithoutChildren<T extends WrapperComponentType> = Omit<ComponentProps<T>, 'children'>

type CombinedWrapperValues<
    T extends WrapperComponentMap,
    O extends {
        [K in keyof T]?: ComponentPropsWithoutChildren<T[K]>
    }
> = {
    [K in keyof T]?: ComponentPropsWithoutChildren<T[K]>
} & {
    [K in keyof T as Omit<RequiredFieldsOnly<ComponentPropsWithoutChildren<T[K]>>, keyof O[K]> extends Record<
        string,
        never
    >
        ? never
        : K]: ComponentPropsWithoutChildren<T[K]>
}
/**
 * combineWrappers - nests multiple wrapper components into one, each with default values and values from props
 * @param wrappers - object with keys as wrapper names and values as wrapper components, each must support children, order of object keys is respected (see example), required
 * @param defaultValues - object with keys as wrapper names and values as default props for each wrapper, optional and partial
 * @returns CombinedWrapper - component that wraps children with all wrapper components
 *
 * @example
 * const ProviderA = ({ children, value }: PropsWithChildren<{ value: string }>) => (<div>
 *             {value}
 *             {children}
 *         </div>)
 *
 * const ProviderB = ({ children, value }: PropsWithChildren<{ value: number }>) => (<div>
 *             {value}
 *             {children}
 *         </div>)
 *
 * const ProviderNoValue: FC<{
 *     children: ReactNode
 * }> = ({ children }) => <div>{children}</div>
 *
 * const CombinedProvider = combineWrappers(
 *     {
 *         providerB: ProviderB,
 *         providerA: ProviderA,
 *         providerD: ProviderNoValue
 *     },
 *     {
 *         providerA: { value: 'h' } // even though value is required for ProviderA, if it's supplied in defaultValues, it won't be required in resulting CombinedProvider
 *     }
 * )
 *
 * const Example = () => {
 *     return (
 *         <CombinedProvider
 *             value={{
 *                 // providerA: { value: 'h' }, // this would be required if not supplied in defaultValues
 *                 providerB: { value: 5 },
 *                 providerC: { value: false } // optional
 *             }}>
 *             <div>Test</div>
 *         </CombinedProvider>
 *     )
 * }
 *
 * const SameWithNestedProviders = () => {
 *     return (
 *         <ProviderB value={5}>
 *             <ProviderA value="h">
 *                 <ProviderC value={false}>
 *                     <ProviderNoValue>
 *                         <div>Test</div>
 *                     </ProviderNoValue>
 *                 </ProviderC>
 *             </ProviderA>
 *         </ProviderB>
 *     )
 * }
 *
 */
const combineWrappers = <
    T extends WrapperComponentMap,
    D extends {
        [K in keyof T]?: ComponentPropsWithoutChildren<T[K]>
    }
>(
    wrappers: T,
    defaultValues: D
) => {
    const wrapperArray = Object.entries(wrappers).reverse()
    return ({ children, value }: PropsWithChildren<{ value: CombinedWrapperValues<T, D> }>) =>
        wrapperArray
            // eslint-disable-next-line @typescript-eslint/naming-convention
            .reduce((nestedChildren, [key, Wrapper]) => {
                const defaultValue = defaultValues?.[key] || {}
                const currentValue = value[key] || {}
                return (
                    <Wrapper key={key} {...defaultValue} {...currentValue}>
                        {nestedChildren}
                    </Wrapper>
                )
            }, children)
}

export { combineWrappers }
