import { useMemo } from 'react'

import { parseIntegerValue } from '../helpers/parseIntegerValue'
import { parseYesOrNo } from '../helpers/parseYesOrNo'
import { ParsedRevealerDimensions, RevealerData } from './Revealer.interface'
import mockDataRevealer from './Revealer.mock'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseCallbacks = (callbacks?: string): ((...args: any[]) => void)[] => {
    if (!callbacks) return []
    return callbacks
        .split(',')
        .map(callb => {
            try {
                const parsedCallback = JSON.parse(callb)
                if (parsedCallback && typeof parsedCallback === 'function') {
                    return (...args) => {
                        try {
                            parsedCallback(...args)
                        } catch (e) {
                            console.error('Error in Revealer callback', e)
                        }
                    }
                }
            } catch (err) {
                console.error('Error parsing Revealer callback', err)
                return false
            }
            return false
        })
        .filter(s => typeof s == 'function') as ((...args: any[]) => void)[]
}

export const parseRevealerData = (data?: RevealerData) => {
    if (!data) return {}
    const {
        ad,
        clickUrlUnesc,
        clickUrl,
        height,
        openInNewTab,
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        width,
        thirdPartyTagsHtml,
        hasGlobalClickTag,
        fileImage,
        imageBackgroundColor
    } = data

    const dimensions: ParsedRevealerDimensions = {
        height: parseIntegerValue(height),
        width: parseIntegerValue(width)
        // closeHidden: closePosition === ClosePosition.HIDDEN
    }
    const clickOptions = {
        openInNewTab: parseYesOrNo(openInNewTab),
        hasGlobalClickTag: parseYesOrNo(hasGlobalClickTag),
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        clickUrlUnesc,
        thirdPartyTagsHtml,
        clickUrl
    }
    const creative = {
        fileImage,
        imageBackgroundColor
    }

    return {
        creative,
        dimensions,
        clickOptions,
        ad,
        open: true
    }
}

export type ParsedRevealerData = ReturnType<typeof parseRevealerData>

export type ParsedRevealerElement = ParsedRevealerData['creative']

export const useRevealerMockData = () =>
    useMemo(() => parseRevealerData(mockDataRevealer as unknown as RevealerData), [])
