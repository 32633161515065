import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicVariants } from '../Decoration/components'
import { Menu, menuVariants } from './components/Menu'
import { NavigationButton, navigationButtonVariants } from './components/NavigationButton'
import { Panel } from './components/Panel'
import { SearchBar } from './components/SearchBar'
import styles from './Navigation.style'

const NavigationStyled = styled.div(props => ({ ...styles(props) }))

function Navigation({ graphicVariant, primaryMenu, secondaryMenu, ...rest }) {
    // @todo: connect functional search
    const gtmData = {
        eventCategory: 'Navigation',
        eventLabel: 'Navigation Item',
        eventValue: 'Menu'
    }

    return (
        <NavigationStyled {...rest}>
            <Panel>
                <div className="panelHeader">
                    <SearchBar className="panelSearch" />
                    <NavigationButton variant={navigationButtonVariants.ALPHA} />
                </div>
                <div className="panelContent">
                    <Graphic variant={graphicVariant} className="sideGraphic" />
                    <Menu links={primaryMenu} variant={menuVariants.ALPHA} gtmData={gtmData} />
                    <div className="secondaryMenu">
                        <Menu links={secondaryMenu} variant={menuVariants.BETA} gtmData={gtmData} />
                    </div>
                </div>
            </Panel>
        </NavigationStyled>
    )
}

Navigation.propTypes = {
    graphicVariant: PropTypes.oneOf([...Object.values(graphicVariants)]),
    primaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    secondaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Navigation.defaultProps = {
    graphicVariant: graphicVariants.THETA,
    primaryMenu: undefined,
    secondaryMenu: undefined
}

export default withErrorBoundary(Navigation, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Navigation]: ', error, componentStack)
    }
})
