// @TODO: Revisit after add comment form functionality is implemented
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Icon, RatingFiveIcon, RatingFourIcon, RatingOneIcon, RatingThreeIcon, RatingTwoIcon } from '../../../Icon'
import styles from './RateItem.style'

const CommentsRateItemStyled = styled.div(props => ({ ...styles(props) }))

function CommentsRateItem({ title, name, value, setFieldValue, ...rest }) {
    const onIconClick = newValue => {
        if (setFieldValue) {
            setFieldValue(name, newValue)
        }
    }

    if (!title) {
        return null
    }
    // @TODO: revisit later to optimise if needed.
    return (
        <CommentsRateItemStyled {...rest}>
            <div className="rateItem_title">{title}</div>
            <div className="rateItem_rates">
                <Icon
                    icon={RatingOneIcon}
                    size={20}
                    className={value === 1 ? 'selected' : ''}
                    onClick={() => onIconClick(1)}
                />
                <Icon
                    icon={RatingTwoIcon}
                    size={20}
                    className={value === 2 ? 'selected' : ''}
                    onClick={() => onIconClick(2)}
                />
                <Icon
                    icon={RatingThreeIcon}
                    size={20}
                    className={value === 3 ? 'selected' : ''}
                    onClick={() => onIconClick(3)}
                />
                <Icon
                    icon={RatingFourIcon}
                    size={20}
                    className={value === 4 ? 'selected' : ''}
                    onClick={() => onIconClick(4)}
                />
                <Icon
                    icon={RatingFiveIcon}
                    size={20}
                    className={value === 5 ? 'selected' : ''}
                    onClick={() => onIconClick(5)}
                />
            </div>
        </CommentsRateItemStyled>
    )
}

CommentsRateItem.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    setFieldValue: PropTypes.func
}

CommentsRateItem.defaultProps = {
    value: undefined,
    name: undefined,
    title: undefined,
    setFieldValue: undefined
}

export default CommentsRateItem
