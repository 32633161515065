import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    background: theme.colors.profile.background.secondary,
    position: 'relative',
    '& .globalProfileSidebar_inner': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        minHeight: `calc(100vh - ${toRem(60)})`,
        maxWidth: toRem(450),
        margin: '0 10% 0 auto',
        padding: toRems([50, 25])
    },
    // @TODO: Remove this below, this has to be solved in the image component - when image is loaded remove placeholder (logo) so we dont have a problem with transparent images.
    img: {
        background: theme.colors.profile.background.secondary,
        objectFit: 'contain !important'
    },
    '& .globalProfileSidebar_title': {
        marginBottom: toRem(10),
        fontFamily: theme.typography.fontFamily.alternative,
        color: theme.colors.profile.text.contrast,
        fontWeight: theme.typography.fontWeight.semiBold,
        fontStyle: 'initial',
        fontSize: toRem(32)
    },
    '& .globalProfileSidebar_lead': {
        fontFamily: theme.typography.fontFamily.default,
        color: theme.colors.profile.text.contrast,
        marginBottom: toRem(50),
        fontSize: toRem(18)
    },
    '& .globalProfileSidebar_footerLinks': {
        alignSelf: 'flex-end'
    }
})

export default style
