import { toRem } from '../../../../../helpers/theme'

const style = ({ theme, active }) => ({
    position: 'relative',
    height: '100%',
    '&:not(:last-of-type)': {
        marginRight: toRem(35),
        [theme.breakpoints.up('md')]: {
            marginRight: toRem(48)
        }
    },
    '& .navigationItem': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        fontSize: toRem(14),
        fontFamily: theme.typography.fontFamily.alternative,
        color: theme.colors.profile.navigation.text.default,
        transitionDuration: theme.transitions.default.duration,
        transitionTimingFunction: theme.transitions.default.timingFunction,
        transitionDelay: theme.transitions.default.delay,
        transitionProperty: 'color',
        textTransform: 'uppercase',
        ...(active && {
            color: theme.colors.profile.navigation.text.active,
            fontWeight: theme.typography.fontWeight.semiBold,
            '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                height: 2,
                background: theme.colors.profile.navigation.text.active
            }
        }),
        '&:hover': {
            color: theme.colors.profile.navigation.text.hover
        },
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(16)
        }
    }
})

export default style
