import merge from 'deepmerge'

import { createFontVariations } from '../../helpers/theme'

const style = theme => {
    const { fontSizes, rootFontSize, fontSettings } = theme.typography
    const globals = {
        html: {
            fontSize: '100%',
            [theme.breakpoints.up('md')]: {
                // fluid resize magic, with the magic number of 0.834vw
                fontSize: 'clamp(80%, 0.834vw, 100%)'
            }
        },
        body: {
            color: theme.colors.text.default
        },
        p: {
            color: theme.colors.heading.basic.text.default
        },
        h1: {
            color: theme.colors.heading.basic.text.default
        },
        h2: {
            color: theme.colors.heading.basic.text.accent
        },
        h3: {
            color: theme.colors.heading.basic.text.accent
        },
        h4: {
            color: theme.colors.heading.basic.text.accent
        },
        a: {
            color: theme.colors.link.primary.text.default,
            textDecoration: 'none'
        },
        'ol, ul': {
            margin: 0,
            padding: 0,
            listStyleType: 'none'
        },
        '& #amp-lightbox-gallery + div': {
            display: 'none'
        },
        '& #mobile': {
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },
        '& #desktop': {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        '& #mobileScaleDown': {
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },
        '& #desktopScaleDown': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
        // '& .i-amphtml-sidebar-mask': {
        //     display: 'none'
        // }
    }
    return merge.all([
        theme.normalize,
        theme.utilities,
        fontSettings,
        createFontVariations({ fontSizes, rootFontSize }),
        theme.global,
        globals
    ])
}

export default style
