// TODO: Revisit props when real data is connected
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Link } from '../../../Link'
import { CommentsAvatar } from '..'
import styles from './Item.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

function Item({
    user,
    isAnonymous,
    date,
    text,
    variant,
    heading,
    headingTagComponent: HeadingTag,
    isHeadingPink,
    href,
    hrefAs,
    children,
    ...rest
}) {
    const headingHtmlProps = useInnerHtml(heading)
    const textHtmlProps = useInnerHtml(text)

    return (
        <ItemStyled variant={variant} isHeadingPink={isHeadingPink} {...rest}>
            <CommentsAvatar user={user} isAnonymous={isAnonymous} date={date} imageWidth={70} imageHeight={70} />
            <ConditionalWrapper condition={!!href} Wrapper={Link} href={href} as={hrefAs}>
                <div className="commentsItem">
                    {variant === 'beta' && heading && (
                        <HeadingTag className="commentsItem_heading" {...headingHtmlProps} />
                    )}
                    {text && <div className="commentsItem_body" {...textHtmlProps} />}
                    {children && <div className="commentsItem_bottom">{children}</div>}
                </div>
            </ConditionalWrapper>
        </ItemStyled>
    )
}

const commentsItemVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Item.propTypes = {
    text: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    variant: PropTypes.oneOf([...Object.values(commentsItemVariants)]),
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isAnonymous: PropTypes.bool,
    headingTagComponent: PropTypes.elementType,
    isHeadingPink: PropTypes.bool,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    user: PropTypes.shape({})
}

Item.defaultProps = {
    text: undefined,
    date: undefined,
    variant: commentsItemVariants.ALPHA,
    heading: undefined,
    isAnonymous: false,
    headingTagComponent: 'div',
    isHeadingPink: false,
    href: undefined,
    hrefAs: undefined,
    user: undefined
}

export { commentsItemVariants }
export default Item
