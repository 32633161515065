import { useMutation, useQuery, useQueryClient } from 'react-query'

import { entityTaxon } from '../queries/requests'

const MutationAction = {
    CREATE: 'CREATE',
    DELETE: 'DELETE'
}

const useTaxonList = (resource, params = {}, options = {}) => {
    const queryClient = useQueryClient()
    const { enabled = true } = options

    const resourceUri = `taxonomies/${resource}`

    const queryKey = [resourceUri, params]

    const { data: queryResult = [], isSuccess } = useQuery(queryKey, () => entityTaxon.getList(resourceUri, params), {
        enabled,
        retry: false
    })

    const { mutate: queryMutation, isLoading } = useMutation(
        ({ taxons, action }) => {
            switch (action) {
                case MutationAction.CREATE:
                    if (Array.isArray(taxons)) {
                        return Promise.all(
                            taxons.map(async taxon =>
                                entityTaxon.createOne(resourceUri, {
                                    entity: params.entity,
                                    entityId: params.entityId,
                                    taxonId: taxon.id
                                })
                            )
                        )
                    }

                    return entityTaxon.createOne(resourceUri, {
                        entity: params.entity,
                        entityId: params.entityId,
                        taxonId: taxons.id
                    })

                case MutationAction.DELETE:
                    if (Array.isArray(taxons)) {
                        return Promise.all(
                            taxons.map(async taxon =>
                                entityTaxon.deleteOne(resourceUri, {
                                    entity: params.entity,
                                    entityId: params.entityId,
                                    taxonId: taxon.id
                                })
                            )
                        )
                    }

                    return entityTaxon.deleteOne(resourceUri, {
                        entity: params.entity,
                        entityId: params.entityId,
                        taxonId: taxons.id
                    })

                default:
                    return Promise.reject(new Error('Action has to be provided'))
            }
        },
        {
            onError: () => {
                /**
                 * This timeout is here because the server takes time to process the POST requests
                 * which add new taxons to the taxons bag. When the GET request arrives it returns
                 * the old data. With this timeout we give the server 300ms to process the last POST request.
                 *
                 * When the error happens it is ok to make the user wait 300ms because this happened
                 * because he pressed the button to submit the form repeatedly.
                 */
                setTimeout(() => {
                    queryClient.invalidateQueries(queryKey)
                }, 300)
            }
        }
    )

    return {
        data: queryResult,
        action: {
            create: async (taxons, opts = {}) => queryMutation({ taxons, action: MutationAction.CREATE }, opts),
            delete: async (taxons, opts = {}) => queryMutation({ taxons, action: MutationAction.DELETE }, opts)
        },
        isSuccess,
        mutationIsLoading: isLoading
    }
}

export default useTaxonList
