const outOfPageFormatEnumDefault = {
    2: 'TOP_ANCHOR',
    3: 'BOTTOM_ANCHOR',
    4: 'REWARDED',
    5: 'INTERSTITIAL',
    7: 'GAME_MANUAL_INTERSTITIAL',
    REWARDED: 4,
    TOP_ANCHOR: 2,
    BOTTOM_ANCHOR: 3,
    INTERSTITIAL: 5,
    GAME_MANUAL_INTERSTITIAL: 7,
    // REWARDED: 'REWARDED',
    // TOP_ANCHOR: 'TOP_ANCHOR',
    // BOTTOM_ANCHOR: 'BOTTOM_ANCHOR',
    // INTERSTITIAL: 'INTERSTITIAL',
    isDefault: true
}

export const getSlotOutOfPageId = (
    { divId, outOfPage, outOfPageType },
    outOfPageFormatEnum = (typeof window !== 'undefined' && window?.googletag?.enums?.OutOfPageSlot) ||
        outOfPageFormatEnumDefault
) => {
    const outOfPageId = outOfPage && outOfPageType && outOfPageFormatEnum?.[outOfPageType]
    return outOfPageId || divId
}

const getSlotDivId = (slot, outOfPageFormatEnum) =>
    (slot?.outOfPage && getSlotOutOfPageId(slot, outOfPageFormatEnum)) || slot.divId
export default getSlotDivId
