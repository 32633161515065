import { toRem } from '../../../../../helpers/theme'

const style = ({ theme }) => ({
    '&:not(:last-of-type)': {
        marginRight: toRem(24),
        position: 'relative',
        '&::after': {
            content: `'\u00B7'`,
            position: 'absolute',
            top: '50%',
            right: toRem(-14),
            transform: 'translateY(-50%)',
            color: theme.colors.profile.text.contrast
        }
    },
    '& .footerLinksItem': {
        display: 'block',
        fontSize: toRem(14),
        color: theme.colors.profile.text.contrast
    }
})

export default style
