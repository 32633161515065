// import PropTypes from 'prop-types'
import { DefaultSeo as DefaultNextSeo } from 'next-seo'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

function DefaultSeo({ ...rest }) {
    return <DefaultNextSeo {...rest} />
}

DefaultSeo.propTypes = {}

DefaultSeo.defaultProps = {}

export default withErrorBoundary(DefaultSeo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[DefaultSeo]: ', error, componentStack)
    }
})
