import PropTypes from 'prop-types'

export default function ImageAuthor({ author, source, hideAuthor }) {
    if (hideAuthor) {
        return null
    }

    if (!author && !source) {
        return null
    }

    return <div className="image_info">{author ? `Foto: ${author}` : `Izvor: ${source}`}</div>
}

ImageAuthor.defaultProps = {
    hideAuthor: false,
    author: undefined,
    source: undefined
}

ImageAuthor.propTypes = {
    author: PropTypes.string,
    source: PropTypes.string,
    hideAuthor: PropTypes.bool
}
