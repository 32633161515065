import { FiltersActionTypes as ActionTypes } from '../actions/filters'

const initialState = {
    isOpen: false
}

const filtersReducer = (state = initialState, { newStore, type } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }

        case ActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case ActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        default:
            return state
    }
}

export { filtersReducer }
