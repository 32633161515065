import { toRem } from '@hmn/rtl-zena-ui/helpers/theme'

const style = ({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1.5fr'
    },
    '& .globalProfile_sidebar': {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    // @TODO: Fix this later, this is a hack because empty ad is added to profile page.
    marginBottom: toRem(-39)
})

export default style
