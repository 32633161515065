import { useRouter } from 'next/router'
import { useMemo } from 'react'

const usePathQueryValue = ({ key }: { key: string }) => {
    const router = useRouter()
    const { query } = router
    return useMemo(() => {
        return query[key]
    }, [query, key])
}

export default usePathQueryValue
