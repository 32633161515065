/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ArrowLeft = createSvgIcon(
    <path
        d="M9.8 14l6.4-6.4-2.7-2.8L2.3 16l11.2 11.2 2.7-2.8L9.8 18h20v-4z"
        clipRule="evenodd"
        fill="#000"
        fillRule="evenodd"
    />,
    'ArrowLeft'
)

export default ArrowLeft
