import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Frame.style'

const FrameStyled = styled.div(props => ({ ...styles(props) }))

function Frame({ className, widthXs, widthMd, ...rest }) {
    return <FrameStyled className={className} widthXs={widthXs} widthMd={widthMd} {...rest} />
}

const frameVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Frame.propTypes = {
    className: PropTypes.string,
    widthXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    widthMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf([...Object.values(frameVariants)])
}

Frame.defaultProps = {
    className: undefined,
    widthXs: undefined,
    widthMd: undefined,
    variant: frameVariants.ALPHA
}

export { frameVariants }

export default Frame
