import { useCallback, useEffect, useRef } from 'react'

import { useHasNavigated, useIsTransitioning } from '../context/clientNavigation'
import { useRouteChangeComplete } from './useRouteChange'

/**
 * Use this when you want to trigger a cb after a set amount of navigations
 *
 * @param callback - callback to be called after the user has navigated
 * @param afterNavigations (default:1) - after how many navigations should cb trigger
 * @returns function to reset counter and triggered state
 */
const useNavigatedCallback = (callback, afterNavigations = 1) => {
    const calledRef = useRef(false)
    const afterNavRef = useRef(0)
    const [hasNavigated] = useHasNavigated()
    const [isTransitioning] = useIsTransitioning()
    useRouteChangeComplete(() => {
        afterNavRef.current += 1
    })
    const reset = useCallback(() => {
        afterNavRef.current = 0
        calledRef.current = false
    })
    useEffect(() => {
        if (!hasNavigated || !!isTransitioning || !callback || typeof callback !== 'function' || calledRef.current) {
            return
        }
        if (afterNavRef.current < afterNavigations) {
            return
        }
        calledRef.current = true
        callback()
    }, [callback, hasNavigated, isTransitioning])
    return reset
}
export default useNavigatedCallback
