import { UserActionTypes as ActionTypes } from '../actions/user'

const initialState = {
    id: null, // We must use 'null' instead of 'undefined' because of JSON serialization
    consentString: null
}

const userReducer = (state = initialState, { newStore, type, id, consentString } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.LOGIN: {
            return {
                ...state,
                id
            }
        }

        case ActionTypes.LOGOUT: {
            return {
                ...state,
                id: null // We must use 'null' instead of 'undefined' because of JSON serialization
            }
        }

        case ActionTypes.SET_CONSENT_STRING: {
            return {
                ...state,
                consentString
            }
        }

        default:
            return state
    }
}

export { userReducer }
