import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: toRem(20)
    },
    marginRight: toRem(5),
    whiteSpace: 'nowrap',
    '& .socialShare': {
        '&_item': {
            display: 'inline',
            marginRight: toRem(5)
        }
    }
})

export default style
