import useNewsletter from '../../../../hooks/useNewsletter'
import { GlobalProfileOption } from '..'

function NewsletterForm() {
    const { data: subscriptionData, update: subscriptionUpdate, isSuccess: newsletterIsSuccess } = useNewsletter()

    return (
        <>
            <GlobalProfileOption
                checked={newsletterIsSuccess && subscriptionData[process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_ZENA]}
                loading={subscriptionData[process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_ZENA] === undefined}
                onChange={status => subscriptionUpdate(process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_ZENA, status)}
                title="Novosti na zena.hr"
                description={
                    'Pretplati se na zena.hr newsletter. ' +
                    'Primaj novosti na portalu direktno u svoj inbox. Ne propusti ništa, nikad.'
                }
            />
            <GlobalProfileOption
                checked={newsletterIsSuccess && subscriptionData[process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_RTL]}
                loading={subscriptionData[process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_RTL] === undefined}
                onChange={status => subscriptionUpdate(process.env.NEXT_PUBLIC_MAILCHIMP_LIST_ID_RTL, status)}
                title="RTL i partneri"
                description="Pretplati se na ekskluzivne ponude RTL-a odabrane samo za tebe!"
            />
        </>
    )
}

export default NewsletterForm
