/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Comment = createSvgIcon(
    <path d="M 16.001953 0 C 7.1720537 0 0.001953125 7.1712052 0.001953125 16.001953 C 0.007286775 18.827789 0.77460639 21.591645 2.1972656 24.029297 L 0.02734375 30.537109 C -0.11166373 30.954216 0.28616164 31.349768 0.703125 31.210938 L 6.9257812 29.136719 C 9.5929466 30.983112 12.750905 31.995077 16 32 C 24.830367 31.999753 32 24.83012 32 16 C 32 7.1695961 24.831827 0.00020184539 16.001953 0 z " />,
    'Comment'
)

export default Comment
