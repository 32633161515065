import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Script from 'next/script'
import PropTypes from 'prop-types'

import { InitialLoadActions } from '../../actions'
import { useEnvironment } from '../../hooks'

// @TODO: implement this
// <script>
//  (function (u, p, s, c, r) {
//      (u[r] =
//          u[r] ||
//          function (p) {
//              (u[r].q = u[r].q || []).push(p)
//          }),
//          (u[r].ls = 1 * new Date())
//      const a = p.createElement(s)
//      const m = p.getElementsByTagName(s)[0]
//      a.async = 1
//      a.src = c
//      m.parentNode.insertBefore(a, m)
//  })(window, document, 'script', '//files.upscore.com/async/upScore.js', 'upScore')
//
//  upScore({config:{ domain: 'your_domain.com'}});
//  upScore({data:{  object_id: 'your_landingpage_id', object_type:"landingpage" }) // example data of first page
// </script>
const initialLoadSelector = state => state.initialLoad

function UpScoreContextProvider({ upScoreDomain, children }) {
    const { thirdPartyEnabled } = useEnvironment()
    const dispatch = useDispatch()
    const { upScoreLoaded } = useSelector(initialLoadSelector)

    useEffect(() => {
        window.upScore =
            window.upScore ||
            // eslint-disable-next-line func-names
            function (document) {
                ;(window.upScore.q = window.upScore.q || []).push(document)
            }
        window.upScore.ls = 1 * new Date()
        // eslint-disable-next-line no-undef
        upScore({ config: { domain: upScoreDomain, track_positions: true } })
    }, [])
    useEffect(() => {
        if (thirdPartyEnabled && upScoreLoaded) {
            dispatch(InitialLoadActions.setUpscoreReady(true))
            dispatch(InitialLoadActions.setUpscoreDomain(upScoreDomain))
        }
    }, [upScoreLoaded, thirdPartyEnabled, upScoreDomain, dispatch])

    if (!thirdPartyEnabled) {
        return children
    }

    return (
        <>
            {children}
            <Script
                id="upscore"
                src="//files.upscore.com/async/upScore.js"
                async
                // strategy="lazyOnLoad"
                onLoad={() => dispatch(InitialLoadActions.setUpscoreLoaded(true))}
            />
        </>
    )
}

UpScoreContextProvider.propTypes = {
    upScoreDomain: PropTypes.string
}

UpScoreContextProvider.defaultProps = {
    upScoreDomain: ''
}

export { UpScoreContextProvider }
