import dataProvider from '@hmn/data-provider'

import { getBaseApiURL } from '../../hooks'

/**
 * Get a list of sub-resource from entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @return {Object}
 * { data, total }
 */
export const getList = async (resource, params) => {
    const baseApiURL = getBaseApiURL({ api: 'human' })
    const { entity, entityId, search } = params || {}

    const response = await fetch(
        `${baseApiURL}/${entity}/${entityId}/${resource}${search ? `?search=${search}` : ''}`,
        {
            method: 'get',
            headers: {
                Authorization: `Bearer ${dataProvider.getToken()}`,
                'Accept-Language': dataProvider.getLanguage()
            }
        }
    )
    const data = await response.json()
    const total = parseInt(response.headers.get('Content-Range')?.split('/').pop(), 10)

    if (resource.indexOf('taxonomies/') === 0) {
        return {
            data: data.map(item => item.taxon),
            total
        }
    }

    if (resource.indexOf('sitemaps/') === 0) {
        return {
            data: data.map(item => item.sitemap_node),
            total
        }
    }

    return {
        data,
        total
    }
}

/**
 * Attach a sub-resource on entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @param {Object} params.data
 * @return {Object}
 * { data }
 */
export const createOne = async (resource, params) => {
    const baseApiURL = getBaseApiURL({ api: 'human' })
    const { entity, entityId, data: payload } = params || {}

    const response = await fetch(`${baseApiURL}/${entity}/${entityId}/${resource}`, {
        method: 'post',
        headers: {
            Authorization: `Bearer ${dataProvider.getToken()}`,
            'Accept-Language': dataProvider.getLanguage(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    const data = await response.json()

    return {
        data
    }
}

/**
 * Update a sub-resource from entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @param {string|number} params.id
 * @param {Object} params.data
 * @return {Object}
 * { data }
 */
export const updateOne = async (resource, params) => {
    const baseApiURL = getBaseApiURL({ api: 'human' })
    const { entity, entityId, id, data: payload } = params || {}

    const response = await fetch(`${baseApiURL}/${entity}/${entityId}/${resource}/${id}`, {
        method: 'put',
        headers: {
            Authorization: `Bearer ${dataProvider.getToken()}`,
            'Accept-Language': dataProvider.getLanguage(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    const data = await response.json()

    return {
        data
    }
}

/**
 * Remove a sub-resource from entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.id
 * @return {*}
 */
export const deleteOne = async (resource, params) => {
    const baseApiURL = getBaseApiURL({ api: 'human' })
    const { entity, entityId, id } = params || {}

    await fetch(`${baseApiURL}/${entity}/${entityId}/${resource}/${id}`, {
        method: 'delete',
        headers: {
            Authorization: `Bearer ${dataProvider.getToken()}`,
            'Accept-Language': dataProvider.getLanguage()
        }
    })

    return 'ok'
}
