// @TODO: Detect current menu item (useRouter, active state)
// @TODO: finish isButton if needed, else, remove it

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useGTM } from '@hmn/rtl-web-core/hooks'

import { Button } from '../../../Button'
import { Link } from '../../../Link'
import styles from './MenuItem.style'

const MenuItemStyled = styled.span(props => ({ ...styles(props) }))

function MenuItem({ variant, title, isButton, gtmData, ...rest }) {
    const { sendDataToGTM } = useGTM()
    if (!title) {
        return null
    }
    const Component = isButton ? Button : Link
    const handleClick = () => {
        sendDataToGTM(gtmData, true)
    }
    return (
        <MenuItemStyled variant={variant} isButton={isButton}>
            <Component className="menuItem" onClick={handleClick} {...rest}>
                {title}
            </Component>
        </MenuItemStyled>
    )
}

const menuItemVariants = Object.freeze({
    ALPHA: 'alpha', // Panel primary menuItem
    BETA: 'beta', // Panel secondary menuItem
    GAMMA: 'gamma', // Footer primary menuItem
    DELTA: 'delta', // Footer secondary menu
    EPSILON: 'epsilon' // Listing pages nav Widget
})

MenuItem.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(menuItemVariants)]),
    isButton: PropTypes.bool,
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

MenuItem.defaultProps = {
    title: undefined,
    variant: menuItemVariants.PRIMARY,
    isButton: false,
    gtmData: undefined
}

export { menuItemVariants }
export default withErrorBoundary(MenuItem, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[MenuItem]: ', error, componentStack)
    }
})
