import { forwardRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Icon.style'

const IconStyled = styled.span(props => ({ ...styles(props) }))

const Icon = forwardRef(({ icon: IconComponent, svg: SvgComponent, color, size, rotate, ...rest }, ref) => {
    if (SvgComponent) {
        // @TODO: Sanitize props if we're using SVGR component, but we should drop SVGR completely
        delete rest.theme
        delete rest.titleAccess
    }

    return (
        <IconStyled ref={ref} rotate={rotate} color={color} fontSizeProperty={size} svg={SvgComponent !== undefined}>
            {SvgComponent ? (
                <SvgComponent width={size} height={size} {...rest} />
            ) : (
                <IconComponent color={color} {...rest} />
            )}
        </IconStyled>
    )
})

Icon.propTypes = {
    icon: PropTypes.elementType,
    svg: PropTypes.elementType,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Icon.defaultProps = {
    icon: undefined,
    svg: undefined,
    color: undefined,
    size: 'inherit',
    rotate: undefined
}

export default Icon
