import { useEffect, useState } from 'react'

const useViewportWidth = (delay = 300) => {
    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => {
        setIsBrowser(true)
    })

    const [viewportWidth, setViewportWidth] = useState(() => (isBrowser ? window.innerWidth : 0))

    useEffect(() => {
        let resizeTimeout = null
        const resizeListener = () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => setViewportWidth(window.innerWidth), delay)
        }
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [delay])

    return viewportWidth
}

export default useViewportWidth
