import Script from 'next/script'
// import PropTypes from 'prop-types'

function NativeEventListener() {
    return (
        <Script
            id="events"
            key="events"
            async
            dangerouslySetInnerHTML={{
                __html: `
                        (function () {
                            var addEventListenerNative = window.addEventListener;
                            window.addEventListener = function (type) {
                                if (type !== 'unload') {
                                    return addEventListenerNative.apply(this, arguments);
                                }
                            };
                        })();
                    `
            }}
        />
    )
}

NativeEventListener.propTypes = {}

export default NativeEventListener
