// @TODO: Setup predefined fontSizes within theme and allow for easier selection, e.g. "small", "large", etc.

import { toRem } from '../../../../helpers/theme'
import { isColor } from '../../../../helpers/utils'

const style = ({ theme, color, fontSizeProperty, svg, rotate }) => ({
    display: 'inline-flex',
    verticalAlign: 'middle',
    userSelect: 'none',
    flexShrink: 0,
    fontSize: fontSizeProperty !== 'inherit' ? toRem(fontSizeProperty) : fontSizeProperty,
    ...(rotate && {
        transform: `rotate(${rotate}deg)`
    }),
    ...(svg && {
        path: {
            fill: isColor(color) ? color : theme.colors[color],
            stroke: isColor(color) ? color : theme.colors[color]
        },
        svg: {
            outline: '1px dashed red',
            path: {
                fill: isColor(color) ? color : theme.colors[color],
                stroke: isColor(color) ? color : theme.colors[color]
            }
        }
    })
})

export default style
