import React from 'react'

import { WallpaperSideProps } from '../Wallpaper.interface'
import WallpaperCreative from './WallpaperCreative.component'
import WallpaperSideStyled from './WallpaperSide.style'

const WallpaperSide = ({ element, left = false, ad, className, clickOptions, dimensions }: WallpaperSideProps) => {
    const { width } = element

    const { height, pageWidth } = dimensions

    return (
        <WallpaperSideStyled left={left} height={height} pageWidth={pageWidth} width={width} className={className}>
            <div className="WallpaperSide">
                <WallpaperCreative
                    ad={ad}
                    element={element}
                    clickOptions={clickOptions}
                    className="WallpaperSide_container"
                />
            </div>
        </WallpaperSideStyled>
    )
}

export default WallpaperSide
