import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { useCategoryColor } from '../context/category-color'

const useActiveCategory = () => {
    const router = useRouter()
    const { slugs } = router?.query || {}
    const { asPath } = router

    const path =
        asPath?.split('?')[0] === '/' ? ['naslovnica'] : slugs || asPath?.split('?')[0]?.split('/').filter(Boolean)

    const [firstCategorySlug, secondCategorySlug] = path

    const categoryColor = useCategoryColor()

    const { primary, secondary, color } = useMemo(
        () =>
            (!!path?.length && {
                // @TODO: maybe return full node
                primary: `/${firstCategorySlug}`,
                secondary: secondCategorySlug && `/${firstCategorySlug}/${secondCategorySlug}`,
                color: categoryColor
            }) ||
            {},
        [categoryColor, path]
    )

    return [primary, secondary, color]
}

export default useActiveCategory
