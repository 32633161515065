import { toRem, toRems } from '../../../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    margin: toRems([4, 0, 0]),
    padding: toRems([0, 22]),
    fontSize: toRem(14),
    lineHeight: 1.25,
    color: theme.colors.profile.error.text,
    fontStyle: theme.typography.font.style.normal
})

export default style
