/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Score = createSvgIcon(
    <>
        <path d="M 7.5201649,0.83866322 H 29.641084 c 1.300749,0 2.358917,1.05813268 2.358917,2.35887078 v 15.725822 c 0,1.300749 -1.058168,2.358813 -2.358917,2.358813 H 28.903226 V 6.2943083 c 0,-1.3007381 -1.058168,-2.3588749 -2.358916,-2.3588749 H 5.1612899 V 3.197534 c 0,-1.3007381 1.0581369,-2.35887078 2.358875,-2.35887078 z" />
        <path d="M 24.479794,5.9999496 H 2.3588744 C 1.0581363,5.9999496 -5.5e-7,7.0580864 -5.5e-7,8.3588245 V 24.084647 c 0,1.300748 1.05813685,2.358813 2.35887495,2.358813 H 4.770157 v 3.931458 c 0,0.701213 0.8504568,1.047742 1.342204,0.555974 l 4.487381,-4.487432 h 13.880052 c 1.300748,0 2.358916,-1.058065 2.358916,-2.358813 V 8.3588245 c 0,-1.3007381 -1.058168,-2.3588749 -2.358916,-2.3588749 z" />
    </>,
    'Score'
)

export default Score
