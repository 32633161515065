import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            marginBottom: toRem(12)
        }
    }
    const size = '60px'
    return {
        position: 'relative',
        width: toRem(230),
        marginRight: toRem(43),
        [theme.breakpoints.down('md')]: {
            marginBottom: config.sm.marginBottom
        },
        '& .avatarInner': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: toRem(230)
        },
        [theme.breakpoints.down('sm')]: {
            '& .avatarInner': {
                width: '100%'
            }
        },
        '& .avatarLink': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .avatarImage_wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: size,
            height: size
        },
        '& .avatarImage': {
            position: 'relative',
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundImage: 'none',
            '& img': {
                borderRadius: '50%',
                padding: toRem(5)
            }
        },
        '& .avatarImage_progressive': {
            position: 'relative',
            padding: 0
        },
        '& .avatarInfo': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: toRem(16),
            '&_username': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                fontSize: toRem(16),
                fontWeight: theme.typography.fontWeight.bold,
                lineHeight: 1.4,
                marginRight: toRem(8),
                color: theme.colors.comments.avatar.name
            },
            '&_date': {
                marginTop: toRem(5),
                fontSize: toRem(14),
                lineHeight: 1.4,
                color: theme.colors.comments.avatar.date,
                time: {
                    '&:not(:last-of-type)': {
                        '&::after': {
                            content: '"•"',
                            margin: toRems([0, 3])
                        }
                    }
                }
            }
        }
    }
}

export default style
