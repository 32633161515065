import React from 'react'

import createFastContext from '../createFastContext'
import { ClientNavigationContextConnector } from './ClientContextConnector'
import { ClientNavigationState, ClientNavigationStore, NavigationDirection } from './ClientNavigation.types'

const clientNavigationInitialState: ClientNavigationStore = {
    initialLoad: false,
    domLoaded: false,
    inarticleAdMounted: false,
    isTransitioning: false,
    hasNavigated: false,
    direction: NavigationDirection.PUSH,
    hasNavigationStarted: false,
    currentEntityType: 'loading',
    clientNavigationState: ClientNavigationState.LOADING,
    adsNavigationState: ClientNavigationState.LOADING
}
export const ClientNavigationContext = createFastContext(clientNavigationInitialState)
export const useClientNavigationStore = () => ClientNavigationContext.useStore()

export const ClientNavigationContextProvider = ({ children }) => (
    <ClientNavigationContext.Provider>
        <ClientNavigationContextConnector />
        {children}
    </ClientNavigationContext.Provider>
)
