import Blocks2Docbook from '@hmn/blocks2docbook'

export const namespace = 'hmn'
const blocks2Docbook = Blocks2Docbook({
    namespace,
    mediaUrlMatchers: {
        rtl: /^https?:\/\/((m|www)\.)?rtl\.hr\/.+/i
    }
})
const getBlocks = (docbook = '') => {
    let parsedBlocks = []
    if (Array.isArray(docbook)) {
        return docbook.map(({ ...block }) => block)
    }
    try {
        parsedBlocks = blocks2Docbook.parse(docbook)
    } catch (error) {
        parsedBlocks = []
    }
    return parsedBlocks.map(({ ...block }) => block)
}
export const getPagedBlocks = (docbook = '') => {
    const pageProps = {
        type: 'element',
        name: 'page'
    }
    const parsedBlocks = getBlocks(docbook)
    const pagedBlocks = parsedBlocks
        .reduce(
            (all, block) => {
                if (block.name === 'core/nextpage') {
                    all.push({
                        ...pageProps,
                        content: []
                    })
                } else {
                    all[all.length - 1].content.push(block)
                }
                return all
            },
            [
                {
                    ...pageProps,
                    content: []
                }
            ]
        )
        .filter(page => page.content.length > 0)

    return pagedBlocks
}
