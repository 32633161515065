import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    position: 'relative',
    margin: 'auto',
    width: toRem(140),
    height: toRem(140),
    borderRadius: '100%',
    background: theme.colors.profile.avatar.background.image,
    '& .avatarUpload': {
        '&_imageWrap': {
            width: '100%',
            height: '100%',
            borderRadius: '100%',
            overflow: 'hidden',
            backgroundImage: `url(/images/profile/placeholder-user-profile.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },
        '&_icon': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.colors.profile.text.contrast,
            background: theme.colors.profile.background.default,
            width: toRem(50),
            height: toRem(50),
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontSize: toRem(40),
            borderRadius: '100%',
            svg: {
                path: {
                    fill: 'currentColor'
                }
            },
            cursor: 'pointer'
        },
        '&_testerica_icon': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.colors.profile.testerica.badge.background,
            width: toRem(50),
            height: toRem(50),
            position: 'absolute',
            right: 0,
            top: 0,
            borderRadius: '100%',
            svg: {
                path: {
                    fill: 'currentColor'
                },
                width: toRem(45),
                height: toRem(45),
                color: theme.colors.profile.testerica.badge.color || theme.colors.devWarning
            }
        }
    }
})

export default style
