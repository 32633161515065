import { useCallback } from 'react'

import { useNativeAdDispatch } from '../../../../dispatchers/ads'
import { useRouteChangeStart } from '../../../../hooks/useRouteChange'
import useWindowAttachedValue from '../../../../hooks/useWindowAttachedValue'
import { parseWallpaperData } from './Wallpaper.helpers'
import { WallpaperData } from './Wallpaper.interface'

export const INJECTED_WALLPAPER_SCRIPTS_VARIABLE_NAME = 'inj_wp_script'

const WallpaperDataProvider = () => {
    const setData = useNativeAdDispatch('wallpaper')

    const setWallpaperCallback = useCallback(
        (e: WallpaperData) => {
            // console.log('event', e)
            // @TODO: implement error wallpaper with message in case google admanager preview query param is set
            // if it errors on regular client, just log the error
            const parsed = parseWallpaperData(e)
            setData(parsed)
        },
        [setData]
    )

    const clearWallpaperCallback = useCallback(() => {
        if (typeof window !== 'undefined') {
            window[INJECTED_WALLPAPER_SCRIPTS_VARIABLE_NAME] = {}
        }
        setData(null)
    }, [setData])

    useRouteChangeStart(clearWallpaperCallback)

    // important so the template can attach itself to the app
    useWindowAttachedValue(setWallpaperCallback, 'setWallpaper')
    return null
}

export default WallpaperDataProvider
