/* eslint-disable @typescript-eslint/naming-convention */
import {
    useEffect // useMemo
} from 'react'

import { useCustomFilledStateDispatch } from '../../../../../dispatchers/ads'
import {
    // isUndef,
    topElementExists
    // topElementValid
} from '../Wallpaper.helpers'
import type { WallpaperTopProps } from '../Wallpaper.interface'
import WallpaperCreative from './WallpaperCreative.component'
import WallpaperTopStyled from './WallpaperTop.style'

const WallpaperTop = ({
    element,
    ad,
    className,
    clickOptions,
    dimensions
}: // layoutOptions,
// isPreview = false
WallpaperTopProps) => {
    // const { width } = element

    const dispatchFilledState = useCustomFilledStateDispatch('wallpaper_top')

    useEffect(() => {
        dispatchFilledState(topElementExists(element))
    }, [top, dispatchFilledState])

    if (!topElementExists(element)) return null

    //     const { isValid, errorMessage } = useMemo<{ isValid: boolean; errorMessage: string }>(() => {
    //         const elementValidity = topElementValid(element)
    //         if (!elementValidity.isValid) return elementValidity
    //         if (!isUndef(layoutOptions?.maxHeight) && (element.height || 0) > (layoutOptions?.maxHeight || 0))
    //             return {
    //                 isValid: false,
    //                 errorMessage:
    //                     `Creative is too tall for currently reserved space (${layoutOptions?.maxHeight}px).\n` +
    //                     'This causes CLS, worse core web vitals results and hurts performance on Google.\n' +
    //                     'Make sure this ad is ran on minimal frequency possible to minimize negative effects'
    //             }
    //         return { isValid: true, errorMessage: '' }
    // }, [element, layoutOptions])

    const { pageWidth, topHeight } = dimensions
    return (
        <WallpaperTopStyled height={topHeight} pageWidth={pageWidth} className={className}>
            <div className="WallpaperTop">
                <WallpaperCreative
                    ad={ad}
                    element={element}
                    clickOptions={clickOptions}
                    className="WallpaperTop_container"
                />
                {/* {!isValid && isPreview && (
                    <div className="WallpaperTop_error">
                        <span>
                            {errorMessage}
                            <br />
                            <span style={{ fontSize: '.8em', fontWeight: 'light' }}>
                                This warning is only visible with "?google_preview=....". It won't show up to end users
                            </span>
                        </span>
                    </div>
                )} */}
            </div>
        </WallpaperTopStyled>
    )
}

export default WallpaperTop
