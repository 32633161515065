import createFastContext from '../createFastContext'

export type AdCallback = (zoneName: string | string[]) => void
type AdCallbackStore = {
    /**
     * This is a flag to indicate if the ads.provider is ready to recieve callback commands
     */
    ready: boolean
    /**
     * # Not implemented yet
     * A function to display an ad
     * @param zoneName {string | string[]} - The zone name or names to display
     * @returns boolean
     */
    displayAd: AdCallback
    /**
     * A function to refresh an ad
     * @param zoneName {string | string[]} - The zone name or names to refresh
     * @returns boolean
     */
    refreshAd: AdCallback
    /**
     * A function to destroy an ad
     * @param zoneName {string | string[]} - The zone name or names to destroy
     * @returns boolean
     */
    destroyAd: AdCallback
}
const adCallbackInitialState: AdCallbackStore = {
    ready: false,
    displayAd: () => false,
    refreshAd: () => false,
    destroyAd: () => false
}
export const AdCallbacksContext = createFastContext(adCallbackInitialState)

export const useAdCallbackDispatch = () => AdCallbacksContext.useDispatch()

/**
 * useAdCallbacks - returns the ad callback functions
 * @returns {AdCallbackStore}
 *
 * @example
 * const { displayAd, refreshAd, destroyAd } = useAdCallbacks()
    return (
        <div>
            <button
                style={{ marginLeft: '10px' }}
                type="button"
                onClick={() => {
                    displayAd('Floating - 1')
                }}>
                Display Floating 1
            </button>
            <button
                style={{ marginLeft: '10px' }}
                type="button"
                onClick={() => {
                    refreshAd('Floating - 1')
                }}>
                Refresh Floating 1
            </button>
            <button
                style={{ marginLeft: '10px' }}
                type="button"
                onClick={() => {
                    destroyAd('Floating - 1')
                }}>
                Destroy Floating 1
            </button>
        </div>
    )
 */
export const useAdCallbacks = () => AdCallbacksContext.useSelector()
