/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Rose = createSvgIcon(
    <>
        <path
            d="M8.762 3.132C8.424 1.682 6.394 1.09 2.672 0c2.545 1.702 1.817 4.01 1.306 5.632-.209.66-.381 1.207-.283 1.554l.023.02c.892-.406 1.62-1.14 2.467-1.994l.066-.067c.709-.714 1.502-1.5 2.511-2.013z"
            fill="#B70060"
        />
        <path d="M11.09 3.593c3.04 0 5.206 2.14 5.91 3.211 0 0-1.29-.08-2.11 0-3.785.367-4.434 5.434-8.656 5.187C2.857 11.794.887 9.604.324 8.533c.633.247 1.419.095 2.322 0 3.672-.387 4.644-4.94 8.443-4.94z" />
        <path transform="matrix(-1 0 0 1 17 0)" d="M0 0h16.676v12H0z" fill="#ffffff" />
    </>,
    'Rose'
)

export default Rose
