import { toRem } from '../../../../helpers/theme'

const style = ({ theme, colorVariant }) => {
    const config = {
        color: {
            variant: {
                meta: {
                    default: theme.colors.meta?.social?.button?.default || theme.colors.devWarning,
                    hover: theme.colors.meta?.social?.button?.hover || theme.colors.devWarning
                },
                footer: {
                    default: theme.colors.footer?.social?.icon?.default || theme.colors.devWarning,
                    hover: theme.colors.footer?.social?.icon?.hover || theme.colors.devWarning
                }
            }
        }
    }
    return {
        display: 'inline-flex',
        '& a, button': {
            display: 'flex',
            flexDirection: 'column',
            color: config.color.variant[colorVariant]?.default,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color',
            '&:hover': {
                color: config.color.variant[colorVariant]?.hover
            }
        },
        svg: {
            path: {
                fill: 'currentColor'
            },
            width: toRem(32),
            height: toRem(32),
            '@media (max-width:500px)': {
                width: toRem(25),
                height: toRem(25)
            },
            '@media (max-width:400px)': {
                width: toRem(22),
                height: toRem(22)
            },
            '@media (max-width:370px)': {
                width: toRem(20),
                height: toRem(20)
            }
        }
    }
}

export default style
