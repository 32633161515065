import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '@emotion/react'

const toArray = list => (Array.isArray(list) ? list : [list])
const getMediaQueries = (breakpoints, themeBreakpoints) => {
    const widths = toArray(breakpoints).map((breakpoint, index) => {
        const width = typeof breakpoint === 'string' ? themeBreakpoints.values[breakpoint] : breakpoint
        const query = window.matchMedia(`screen and (min-width: ${width}${themeBreakpoints.unit})`)
        return { index, width, query }
    })
    return widths.sort((a, b) => b.width - a.width)
}

const getActiveIndex = list => {
    const mediaQuery = list.find(({ query }) => query.matches)
    return mediaQuery ? mediaQuery.index : -1
}

const BreakpointsContext = createContext()

/**
 * # Warning: Causes a component rerender on client side
 * uses useState dependant on window events, leads to at least 1 guaranteed extra rerender
 * `useBreakpoints`
 *
 * Provides a value based of current breakpoint (client reliant)
 *
 * As well as function to update initial values
 *
 * ```js
 * import { useBreakpoints } from '../hooks'
 * const isDesktop = useBreakpoints('lg')
 * const columnNumber = useBreakpoints(['lg', 'sm'], [4, 2], 1)
 *
 * const [content, setContentValues] = useBreakpoints(
 *      'sm',
 *      { title: 'A', lead: 'B' },
 *      { title: 'a', lead: 'b' }
 * )
 * const onLoad = (data) => {
 *      setContentValues(
 *          { title: data.title, lead: data.lead },
 *          { title: data.title.toLowerCase(), lead: data.lead.toLowerCase() }
 *      )
 * }
 * ```
 */
// eslint-disable-next-line default-param-last
const useBreakpoints = (breakpoints, values = [], defaultValue) => {
    const { breakpoints: themeBreakpoints } = useTheme()
    const [localBreakpoints] = useState(toArray(breakpoints))
    const [localValues, setLocalValues] = useState(toArray(values))
    const [localDefault, setLocalDefault] = useState(defaultValue)

    const mediaQueries = useRef([])
    const [activeIndex, setActiveIndex] = useState(-1)

    const result = useMemo(() => {
        let value = typeof localDefault === 'undefined' ? false : localDefault
        let breakpoint = null
        if (activeIndex >= 0) {
            breakpoint = localBreakpoints[activeIndex]
            value = true
        }
        if (localValues.length > 0) {
            if (typeof localDefault !== 'undefined' && activeIndex < 0) {
                value = localDefault
            } else {
                value = localValues[Math.max(0, Math.min(activeIndex, localValues.length - 1))]
            }
        }
        return {
            value,
            breakpoint
        }
    }, [activeIndex, localBreakpoints, localValues, localDefault])

    useEffect(() => {
        if (!process.browser) {
            return () => {}
        }
        mediaQueries.current = getMediaQueries(localBreakpoints, themeBreakpoints)
        const onMediaQuery = () => {
            setActiveIndex(getActiveIndex(mediaQueries.current))
        }
        onMediaQuery()
        mediaQueries.current.forEach(({ query }) => {
            if (typeof query.addEventListener === 'function') {
                query.addEventListener('change', onMediaQuery)
            } else if (typeof query.addListener === 'function') {
                query.addListener(onMediaQuery)
            }
        })
        return () => {
            mediaQueries.current.forEach(({ query }) => {
                if (typeof query.removeEventListener === 'function') {
                    query.removeEventListener('change', onMediaQuery)
                } else if (typeof query.removeListener === 'function') {
                    query.removeListener(onMediaQuery)
                }
            })
        }
    }, [localBreakpoints])
    const setValues = (newValues, newDefaultValue) => {
        setLocalValues(toArray(newValues))
        if (typeof newDefaultValue !== 'undefined') {
            setLocalDefault(newDefaultValue)
        }
    }
    return [result.value, setValues]
}

export { BreakpointsContext }

export default useBreakpoints
