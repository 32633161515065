import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
// eslint-disable-next-line
import Head from 'next/head'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

const removeUndefinedObjectKeys = (object, ignoredKeys = []) =>
    Object.keys(object)
        .sort()
        .reduce((acc, curr) => {
            let value = object[curr]
            if (value === Object(value) && !Array.isArray(value)) {
                value = removeUndefinedObjectKeys(value)
                // if object has no other keys without ignored ones, remove the object
                if (ignoredKeys && Object.keys(value).filter(key => ignoredKeys.indexOf(key) === -1).length === 0) {
                    return acc
                }
            }
            if (!value && value !== 0) {
                // any null-ish value except 0
                return acc
            }
            acc[curr] = value
            return acc
        }, {})

function StructuredData({ data, debug }) {
    const uid = useUIDSeed()
    const dataWithSortedKeys = useMemo(
        () =>
            (data &&
                (Array.isArray(data)
                    ? data.map(({ ignoreKeys = [], ...dat }) =>
                          removeUndefinedObjectKeys(
                              {
                                  ...(dat || {})
                              },
                              ['@type', '@schema', ...ignoreKeys]
                          )
                      )
                    : [
                          removeUndefinedObjectKeys(
                              {
                                  ...data
                              },
                              ['@type', '@schema']
                          )
                      ])) ||
            [],
        [data]
    )

    if (!dataWithSortedKeys) {
        return null
    }

    return (
        <>
            <Head>
                {dataWithSortedKeys.map((dat, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <script
                        key={uid(`json-ld-${index}`)}
                        type="application/ld+json"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(dat) }}
                    />
                ))}
            </Head>

            {debug && (
                <div
                    style={{ position: 'fixed', background: 'lightgrey', zIndex: 80, top: '100px' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(dataWithSortedKeys, null, '\t').split(',')
                    }}
                />
            )}
        </>
    )
}

StructuredData.propTypes = {
    data: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    debug: PropTypes.bool
}

StructuredData.defaultProps = {
    debug: false,
    data: undefined
}
export default withErrorBoundary(StructuredData, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[StructuredData]: ', error, componentStack)
    }
})
