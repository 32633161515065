/* eslint-disable @typescript-eslint/naming-convention */
import React, { PropsWithChildren } from 'react'

import { NativeZonesContext } from '../../../context/ads/native.context'
import ConvergerDataProvider from './Converger/Converger.provider'
import InscreenDataProvider from './Inscreen/Inscreen.provider'
import RevealerDataProvider from './Revealer/Revealer.provider'
import WallpaperDataProvider from './Wallpaper/Wallpaper.provider'

const ExternalAdsProvider = ({ children }: PropsWithChildren<never>) => (
    <NativeZonesContext.Provider>
        {children}
        <ConvergerDataProvider />
        <InscreenDataProvider />
        <WallpaperDataProvider />
        <RevealerDataProvider />
    </NativeZonesContext.Provider>
)

export default ExternalAdsProvider
