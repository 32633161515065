import { SearchActionTypes as ActionTypes } from '../actions/search'

const initialState = {
    isOpen: false,
    current: {}
}

const searchReducer = (state = initialState, { newStore, type, search } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }

        case ActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case ActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        case ActionTypes.SAVE: {
            return {
                ...state,
                current: search
            }
        }

        default:
            return state
    }
}

export { searchReducer }
