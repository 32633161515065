import { breakpoints as globalBreakpoints, toRem, toRems } from '../../../helpers/theme'

const { values: breakpointValuesArr, unit } = globalBreakpoints

const modifiedLayoutBreakpointsArr = Object.values(breakpointValuesArr).map(bp => (bp !== 0 ? `${bp}${unit}` : `${bp}`))

const layoutTheme = {
    breakpoints: modifiedLayoutBreakpointsArr,
    variants: {
        // Pages
        // Zena Layout
        zena: {
            data: {
                display: 'block !important',
                maxWidth: toRem(600),
                ml: [0, 0, 0, '10%', '10%', '10%'],
                pt: toRems([40, 40, 40, 50, 50, 50], true),
                pb: toRems([40, 40, 40, 50, 50, 50], true),
                pr: toRems([25, 25, 25, 25, 25, 25], true),
                pl: toRems([25, 25, 25, 25, 25, 25], true)
            },
            form: {
                height: '100%'
            },
            settings: {
                display: [
                    'block !important',
                    'block !important',
                    'block !important',
                    'grid !important',
                    'grid !important',
                    'grid !important'
                ],
                alignSelf: 'flex-start',
                gridTemplateColumns: 'auto 1fr',
                gridGap: toRems([15, 15, 15, 20, 40, 40], true),
                maxWidth: toRem(600),
                ml: [0, 0, 0, '10%', '10%', '10%'],
                pt: toRems([40, 40, 40, 50, 50, 50], true),
                pr: toRems([25, 25, 25, 25, 25, 25], true),
                pl: toRems([25, 25, 25, 25, 25, 25], true)
            },
            head: {
                alignSelf: 'center',
                pt: toRems([20, 20, 20, 0, 0, 0], true),
                pb: toRems([40, 40, 40, 0, 0, 0], true)
            },
            options: {
                gridColumn: '1/4'
            },
            content: {
                width: '100%',
                maxWidth: '100%',
                gridTemplateColumns: 'auto 1fr 1fr',
                gridColumnGap: toRem(40),
                gridRowGap: toRem(20),
                px: toRems([15, 15, 15, 20, 40, 40], true)
            }
        }
    }
}

export default {
    ...layoutTheme
}
