import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Switch } from '../Input/components/Switch'
import styles from './Option.style'

const OptionStyled = styled.div(props => ({ ...styles(props) }))

function Option({ title, checked, loading, onChange, description, className, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)
    const descriptionHtmlProps = useInnerHtml(description)

    if (!title && !description) {
        return null
    }

    return (
        <OptionStyled className={className} {...rest}>
            <div className="globalProfileOption">
                {title && <div className="globalProfileOption_title" {...titleHtmlProps} />}
                {description && <div className="globalProfileOption_description" {...descriptionHtmlProps} />}
            </div>
            {onChange ? (
                <Switch disabled={loading} checked={checked} onChange={e => onChange(e.target.checked)} />
            ) : null}
        </OptionStyled>
    )
}

Option.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    checked: PropTypes.bool,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    description: PropTypes.string
}

Option.defaultProps = {
    className: undefined,
    title: undefined,
    checked: false,
    loading: false,
    onChange: undefined,
    description: undefined
}

export default withErrorBoundary(Option, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileOption]: ', error, componentStack)
    }
})
