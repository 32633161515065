import { Fragment, isValidElement, useCallback, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import parseHtml from 'html-react-parser'
import { useRouter } from 'next/router'

import { getPagedBlocks } from './getPagedBlocks'

const isDev = process.env.NODE_ENV !== 'production'

// @TODO: cover other blocks if needed
const blockHasContent = block => {
    try {
        switch (block.name) {
            // @TODO: cover possible edge cases where paragraph can be considered empty
            case 'core/paragraph':
                if (
                    block.attributes.content.length === 0 ||
                    block.attributes.content.trim().match(/^(<hmn:linebreak[^<]*\/>)+$/gi) // matches content that has only linebreak(s)
                ) {
                    return false
                }
                break
            case 'core/columns':
                if (!block.innerBlocks || block.innerBlocks.length === 0) {
                    return false
                }
                break
            default:
        }
    } catch (err) {
        return true
    }
    return true
}

const blockElements = new Map([
    [
        'default',
        block => {
            if (!isDev) {
                return null
            }
            return (
                <pre
                    style={{
                        backgroundColor: '#eee',
                        padding: 5,
                        fontSize: 9,
                        border: '1px dashed #333',
                        whiteSpace: 'break-spaces'
                    }}>
                    {JSON.stringify(block, null, 2)}
                </pre>
            )
        }
    ]
])
function insertStaticElementsIntoBlockList(insertElements, pagedBlocks, isPaged) {
    if (!Array.isArray(insertElements)) {
        return pagedBlocks
    }
    const insertPositions = insertElements.filter(item => item.element).map(p => ({ ...p, inserted: false }))
    return pagedBlocks.map(block => {
        const newBlock = {
            ...block,
            content: [...(block?.content || [])]
        }
        if (isPaged) {
            insertPositions.forEach(position => {
                position.inserted = false
            }) // if blocks are paged, reset index, start inserting from scratch on each page
        }
        const blockCount = block.content.length
        insertPositions
            .map(item => ({ ...item, position: item.position >= 0 ? item.position : blockCount + item.position }))
            .forEach(item => {
                if (item.inserted) {
                    return
                }
                if (item.position > blockCount) {
                    return
                }
                newBlock.content.splice(item.position, 0, item.element)
                item.inserted = true
            })
        return newBlock
    })
}

function insertElementsIntoBlockList(insertElements, pagedBlocks, isPaged) {
    if (!Array.isArray(insertElements)) {
        return pagedBlocks
    }
    const insertPositions = insertElements
        .filter(item => item.elements) // filter out entries without elements
        .map(item => ({
            // defaults
            offset: 0,
            every: 1,
            minimumBlocksInPage: 0,
            insertAtLeast: 0,
            mustHaveBlocksAfter: false,
            count: item.elements.length,
            ...item,
            inserted: 0,
            index: 0 // this key tracks index of blocks that are counted (since it isn't counting each block)
        }))
    return pagedBlocks.map(block => {
        const newBlock = {
            ...block,
            content: []
        }
        if (isPaged) {
            insertPositions.forEach(position => {
                position.index = 0
                position.inserted = 0
            }) // if blocks are paged, reset index, start inserting from scratch on each page
        }
        const blockCount = block.content.length
        const validBlockCount = insertPositions?.map(({ minimumBlocksInPage, afterBlocks }) => {
            if (!minimumBlocksInPage) {
                return true
            }
            const blocksWithContent = block?.content?.filter(blockHasContent) || []
            if (!afterBlocks?.length) {
                return (blocksWithContent?.length || 0) >= minimumBlocksInPage
            }
            return (
                (blocksWithContent.filter(({ name }) => afterBlocks?.includes(name))?.length || 0) >=
                minimumBlocksInPage
            )
        })
        block.content.forEach((element, ind) => {
            const isLastElement = ind === blockCount - 1
            newBlock.content.push(element)
            insertPositions?.forEach((item, index) => {
                // if enough items were inserted, don't do anything
                if (
                    (isLastElement && item.mustHaveBlocksAfter) ||
                    item.inserted >= item.count ||
                    !validBlockCount[index]
                ) {
                    return
                }
                // afterBlocks specifies which block types are counted towards element insertion
                // if no blocks are specified, count each
                // else count only if block is in the list and has content
                if (
                    (!item.afterBlocks?.length || item.afterBlocks?.includes(element?.name)) &&
                    blockHasContent(element)
                ) {
                    // offset starts count later (eg insert every 2nd element after 5th element)
                    // -1 to properly count 1st item
                    const offsetIndex = item.index - item.offset
                    if (offsetIndex % item.every === 0 && offsetIndex > 0) {
                        // cycle through elements to insert
                        // (eg if count was 5 but elements.length was 3, it would insert 1,2,3,1,2)
                        const insert = item.elements[item.inserted % item.elements.length]
                        newBlock.content.push(insert)
                        item.inserted += 1
                    }
                    item.index += 1
                }
            })
        })
        insertPositions
            .filter(item => item.inserted < item.insertAtLeast)
            .forEach(item =>
                item.elements.forEach(() => {
                    if (item.inserted >= item.insertAtLeast) {
                        return
                    }
                    const insert = item.elements[item.inserted % item.elements.length]
                    newBlock.content.push(insert)
                    item.inserted += 1
                })
            )
        return newBlock
    })
}

const useBlockList = ({ docbook = '', insertElements: insertElementsAll = [], isPaged = false }) => {
    const uid = useUIDSeed()
    const blocksList = useMemo(() => {
        const list = []
        if (!docbook) {
            return []
        }
        try {
            const pagedBlocks = getPagedBlocks(docbook)

            const staticInsertElements = insertElementsAll.filter(item => item.isStatic)
            const insertElements = insertElementsAll.filter(item => !item.isStatic)

            const listWithStaticElements = insertStaticElementsIntoBlockList(staticInsertElements, pagedBlocks, isPaged)

            const listWithInsertedElements = insertElementsIntoBlockList(
                insertElements,
                listWithStaticElements,
                isPaged
            )

            // @DEBUG: uncomment to see indexes and name before each element
            // console.log(listWithInsertedElements)
            // let index1 = 0
            // return listWithInsertedElements.map(page => ({
            //     ...page,
            //     content: page.content.reduce((acc, block) => {
            //         if (blockHasContent(block)) {
            //             // console.log(block)
            //             acc.push({
            //                 name: 'core/paragraph',
            //                 clientId: 'd1bed8ea-67bd-42bb-8369-8cd2c75d868f',
            //                 innerBlocks: [],
            //                 isValid: true,
            //                 attributes: {
            //                     dropCap: false,
            //                     content: `<b style="color:cyan; background:darkgreen;"> block index: ${index1.toString()}; key or name: ${
            //                         block.name || block.key?.split('-').slice(2).join(' ') || block?.type?.name
            //                     }</b>`
            //                 }
            //             })
            //             index1 += 1
            //         }
            //         acc.push(block)
            //         return acc
            //     }, [])
            // }))

            return listWithInsertedElements
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
        return list.map((block, index) => ({
            key: uid({ block, index }),
            ...block
        }))
    }, [docbook, insertElementsAll, isPaged])
    return blocksList
}

// @TODO: write full hook documentation
/**
 * insertElements - inserts any element per specified pattern. Supports multiple patterns
 * example (
 *          inserts an ad every 2nd position with each insertion having specific adUnit and only counts columns or paragraphs,
 *          inserts SomeGraphic every 5th position, starts at 10 (every+offset), counts every block type
 *          ):
 *      [
 *           {
 *               every: 2,
 *               afterBlocks: ['core/paragraph', 'core/columns'],
 *               elements: [
 *                   <AdSlot adUnit="zena_inarticle_desktop_1" />,
 *                   <AdSlot adUnit="zena_inarticle_desktop_2" />,
 *                   <AdSlot adUnit="zena_inarticle_desktop_3"/>,
 *                   <AdSlot adUnit="zena_inarticle_desktop_4"
 *                   />
 *               ]
 *           },
 *           {
 *               every: 5,
 *               count: 20, // if you wish to go infinite, set count to something large
 *               offset: 5
 *               elements: [<SomeGraphic />]
 *           }
 *       ]
 */
const useHtmlContent = ({
    docbook = '',
    customElements = new Map(),
    currentPage = undefined,
    insertElements,
    isAmp = false
}) => {
    const { query } = useRouter()
    const uid = useUIDSeed()
    const currentPageNumber = parseInt(currentPage, 10)
    const isPaged = typeof currentPageNumber === 'number' && !Number.isNaN(currentPageNumber)
    const currentPageIndex = isPaged ? Math.max(0, currentPage - 1) : undefined

    const blockList = useBlockList({ docbook, insertElements, isPaged })
    const enabledBlockListContent = query?.disable?.includes('all-article-body')
        ? []
        : blockList?.[0]?.content?.filter(
              item => !query?.disable?.includes(item?.name?.replace('core/', '')?.replace('hmn/', ''))
          )
    const enabledBlockList = blockList
    if (enabledBlockList?.length) {
        enabledBlockList[0].content = enabledBlockListContent
    }

    const elementList = useMemo(() => new Map([...blockElements, ...customElements]), [customElements])
    const itemList = useMemo(() => {
        const list = isPaged
            ? enabledBlockList[Math.min(currentPageIndex, enabledBlockList.length - 1)]?.content
            : enabledBlockList.reduce((all, page) => [...all, ...page.content], [])
        return (list || []).map((block, index) => {
            if (isValidElement(block)) {
                return {
                    key: uid({ block, index }),
                    Element: block
                }
            }
            const elementFunction = elementList.get(block.name) || elementList.get('default')
            return {
                key: uid({ block, index }),
                Element: () => elementFunction(block, isAmp)
            }
        })
    }, [enabledBlockList, elementList, isPaged, currentPageIndex, isAmp])

    const totalPages = useMemo(
        () => (isPaged ? enabledBlockList.length : 0),
        [enabledBlockList, isPaged, currentPageIndex]
    )

    const Content = useCallback(
        () => (
            <>
                {itemList.map(({ Element, key }) => {
                    if (isValidElement(Element)) {
                        return <Fragment key={key}> {Element} </Fragment>
                    }
                    return <Element key={key} />
                })}
            </>
        ),
        [itemList]
    )
    return {
        Content,
        currentPage: isPaged ? currentPageIndex + 1 : 0,
        totalPages: isPaged ? totalPages : 0,
        blockList: enabledBlockList
    }
}

export { parseHtml, useBlockList }

export default useHtmlContent
