import { normalizeDimension } from '../../../../../../helpers/utils'

const style = ({ justifyContent, childrenSpacing }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justifyContent || 'space-between',
    alignItems: 'center',
    width: '100%',
    gridColumn: 'span 12',
    '& .buttonsRow_item': {
        '& + .buttonsRow_item': {
            marginLeft: normalizeDimension(childrenSpacing, true)
        }
    }
})

export default style
