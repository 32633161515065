import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'
import TiktokIframeContainer from './TiktokIframeContainer.component'

function TikTokItemController({ url }) {
    const [post, setPost] = useState({ htmlContent: '', author: '', title: '' })

    useEffect(() => {
        const loadEmbed = async () => {
            let htmlContent = ''
            let author = ''
            let title = ''
            try {
                const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`)
                const data = await response.json()

                htmlContent = data?.html
                author = data?.author_url
                title = data?.title

                setPost({ htmlContent, author, title })
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }

        loadEmbed()
    }, [])

    if (url && post) {
        return <TiktokIframeContainer htmlContent={`${post?.htmlContent}`} author={post?.author} title={post?.title} />
    }

    return null
}

TikTokItemController.propTypes = {
    url: PropTypes.string
}

TikTokItemController.defaultProps = {
    url: undefined
}

export default withErrorBoundary(memo(TikTokItemController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[TikTokItemController]: ', error, componentStack)
    }
})
