/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ZenaHrLogo = createSvgIcon(
    // <svg width="123" height="25" viewBox="00 0 103 25" left="-32px">
    <>
        <path
            d="M79.5087 1.4512H75.1216V24.5157H79.5087V15.1286C79.5087 12.8705 80.928 11.3222 82.8635 11.3222C84.7022 11.3222 85.7345 12.5802 85.7345 14.3544V24.5157H90.1216V13.7093C90.1216 10.0964 87.8313 7.51571 84.4119 7.51571C82.1538 7.51571 80.799 8.38668 79.6055 10.1286H79.5087V1.4512Z"
            fill="#DBDBDB"
        />
        <path
            d="M97.0515 7.96732H92.8257V24.5157H97.2128V16.4512C97.2128 12.9673 99.2773 11.4512 102.148 11.7415H102.245V7.90281C101.987 7.80603 101.697 7.77377 101.213 7.77377C99.2773 7.77377 98.1483 8.74152 97.1483 10.6125H97.0515V7.96732Z"
            fill="#DBDBDB"
        />
        <path d="M67.8384 24.5157H72.548V19.8383H67.8384V24.5157Z" fill="#E20177" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.29573 7.96736H1.06979V11.419H8.61817V11.5157L0.102051 21.1609V24.5157H14.1343V20.9996H5.39237V20.9028L13.7149 11.4835V7.96736H13.5046C12.5293 8.69749 11.0907 9.36739 9.19975 9.46194C7.39078 9.55239 6.22669 8.78595 5.29573 7.96736ZM31.0924 19.5802C30.4795 22.5157 27.8666 24.9996 23.6086 24.9996C18.1247 24.9996 15.0279 21.1609 15.0279 16.2254C15.0279 11.3222 18.3505 7.51575 23.1892 7.51575C28.5118 7.51575 31.3505 11.5803 31.3505 17.4835H19.3505C19.6731 20.0319 21.0924 21.6125 23.5763 21.6125C25.286 21.6125 26.286 20.8383 26.7699 19.5802H31.0924ZM26.7699 14.4512C26.6408 12.3545 25.2537 10.9028 23.2537 10.9028C20.9312 10.9028 19.8021 12.2899 19.415 14.4512H26.7699ZM37.6713 7.96736H33.381V24.5157H37.7681V15.1286C37.7681 12.8706 39.1874 11.3222 41.1229 11.3222C42.9616 11.3222 43.9939 12.5803 43.9939 14.3544V24.5157H48.381V13.7093C48.381 10.0964 46.0906 7.51575 42.6713 7.51575C40.4132 7.51575 38.8971 8.45123 37.7681 10.2254H37.6713V7.96736ZM65.7302 24.5157H61.5044C61.2464 24.1931 61.0851 23.419 60.9883 22.677H60.9238C60.0851 23.9351 58.8915 24.9028 56.0528 24.9028C52.6657 24.9028 50.2786 23.1286 50.2786 19.8383C50.2786 16.1932 53.2464 15.0319 56.9238 14.5157C59.6657 14.1286 60.9238 13.9028 60.9238 12.6448C60.9238 11.4512 59.9883 10.677 58.1496 10.677C56.0851 10.677 55.0851 11.419 54.956 12.9996H51.0528C51.1819 10.0964 53.3431 7.548 58.1818 7.548C63.1496 7.548 65.1496 9.77381 65.1496 13.6448V22.0641C65.1496 23.3222 65.3431 24.0641 65.7302 24.3544V24.5157ZM54.5367 19.7415C54.5367 21.2899 55.569 21.8383 57.1173 21.8383C59.6335 21.8383 61.0205 20.2899 61.0205 18.677V16.1932C60.3021 16.6123 59.2225 16.8647 58.1943 17.1051L57.956 17.1609C55.6657 17.677 54.5367 18.1932 54.5367 19.7415Z"
            fill="#E20177"
        />
        <path
            d="M7.01318 2.27833C7.3006 1.2239 9.0288 0.791848 12.1978 -0.000396729C10.0307 1.23796 10.6504 2.91634 11.0862 4.09662C11.2636 4.57715 11.4105 4.97512 11.3265 5.22721L11.3076 5.24139C10.548 4.94692 9.92835 4.41287 9.207 3.79112L9.15088 3.74276C8.54741 3.22282 7.87232 2.65123 7.01318 2.27833Z"
            fill="#B70060"
        />
        <path
            d="M5.03172 2.61331C2.44398 2.61331 0.599014 4.17075 0 4.94947C0 4.94947 1.09765 4.89146 1.79704 4.94947C5.01878 5.21667 5.57083 8.90296 9.16492 8.72325C12.0402 8.57949 13.7174 6.98611 14.1966 6.20739C13.6575 6.3871 12.9887 6.27672 12.2199 6.20739C9.09407 5.92556 8.26639 2.61331 5.03172 2.61331Z"
            fill="#B70060"
        />
    </>,
    'ZenaHrLogo'
)

export default ZenaHrLogo
