/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo, useRef, useState } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

const typekitLoadingStrategies = Object.freeze({
    BEFORE_INTERACTIVE: 'beforeInteractive',
    AFTER_INTERACTIVE: 'afterInteractive',
    LAZY_ONLOAD: 'lazyOnload',
    CSS_PRELOAD: 'cssPreload'
})

function FontsProvider({ typekitId, fonts, typekitLoadingStrategy, isAmp }) {
    const [typekitLoaded, setTypekitLoaded] = useState(false)

    const { fontCssSrcs = [], typekitIdToRender } = useMemo(
        () =>
            isAmp || typekitLoadingStrategy === typekitLoadingStrategies.CSS_PRELOAD
                ? { fontCssSrcs: [...(fonts || []), `https://use.typekit.net/${typekitId}.css`] }
                : { fontCssSrcs: fonts, typekitIdToRender: typekitId },
        [isAmp, typekitId, fonts, typekitLoadingStrategy]
    )
    const loadedAllRef = useRef(false)
    const usesNextScript = useMemo(
        () => ![typekitLoadingStrategies.CSS_PRELOAD].some(v => v === typekitLoadingStrategy),
        [typekitLoadingStrategy]
    )

    useEffect(() => {
        if (
            typekitLoadingStrategy === typekitLoadingStrategies.CSS_PRELOAD ||
            !process?.browser ||
            isAmp ||
            loadedAllRef.current ||
            !typekitLoaded
        ) {
            return
        }
        try {
            window.Typekit.load({ async: true })
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
        loadedAllRef.current = true
    }, [typekitLoadingStrategy, typekitLoaded, isAmp])

    return (
        <>
            {fontCssSrcs.map((font, index) => (
                <link key={`preload-fonts-stylesheet-${index}`} rel="preload" href={font} as="style" />
            ))}
            {fontCssSrcs.map((font, index) => (
                <link key={`fonts-stylesheet-${index}`} rel="stylesheet" href={font} />
            ))}
            {!isAmp && typekitIdToRender && usesNextScript && (
                <Script
                    strategy={typekitLoadingStrategy}
                    key={typekitIdToRender}
                    src={`https://use.typekit.net/${typekitIdToRender}.js`}
                    onLoad={() => {
                        if (!typekitLoaded) {
                            setTypekitLoaded(true)
                        }
                    }}
                    async
                />
            )}
        </>
    )
}
FontsProvider.propTypes = {
    typekitId: PropTypes.string,
    fonts: PropTypes.arrayOf(PropTypes.string),
    typekitLoadingStrategy: PropTypes.oneOf([...Object.values(typekitLoadingStrategies)]),
    isAmp: PropTypes.bool
}

FontsProvider.defaultProps = {
    typekitId: undefined,
    fonts: undefined,
    typekitLoadingStrategy: typekitLoadingStrategies.AFTER_INTERACTIVE,
    isAmp: false
}

export { typekitLoadingStrategies }
export default FontsProvider
