const defaultTransition = {
    duration: '300ms',
    timingFunction: 'ease-in-out',
    delay: '0ms'
}

export default {
    default: defaultTransition,
    button: {
        default: defaultTransition
    },
    swiper: {
        default: {
            item: defaultTransition
        }
    },
    modal: {
        default: defaultTransition
    },
    pill: {
        default: defaultTransition
    },
    tab: {
        head: {
            text: defaultTransition,
            indicator: defaultTransition
        },
        body: {
            item: defaultTransition
        }
    },
    input: {
        text: {
            default: defaultTransition
        },
        checkbox: {
            default: defaultTransition
        },
        checkboxCard: {
            default: defaultTransition
        },
        radio: {
            default: defaultTransition
        },
        switch: {
            default: defaultTransition
        }
    }
}
