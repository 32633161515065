import { createHash } from 'crypto'

const generatePPID = (): string => {
    const userAgent: string | undefined = navigator.userAgent
    const now: string = Date.now().toString()
    const rawString = `${userAgent}-${now}`
    return createHash('sha256').update(rawString).digest('hex').substring(0, 150)
}

const getCookieValue = (name: string): string | undefined =>
    document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`))
        ?.split('=')[1]

export default (): string => {
    let ppid: string | undefined = getCookieValue('PPID')

    // INFO:
    // We do not have to wait onDidomiReady because it is already loaded at this point.
    // Only create/send when a user can be identified for more than one session.
    const consentForPersonalizedAds = window?.Didomi?.getUserConsentStatusForPurpose('select_personalized_ads')

    if (consentForPersonalizedAds && !ppid) {
        ppid = generatePPID()
        const expires: string = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString() // 1 year
        document.cookie = `PPID=${ppid};expires=${expires};domain=.net.hr;path=/;Secure`
    }

    return ppid || ''
}
