import { memo, useEffect, useRef } from 'react'

const getStringHash = (stringInput?: string) => {
    let hash = 0
    let i
    let chr
    if (!stringInput || stringInput.length === 0) return hash
    for (i = 0; i < stringInput.length; i++) {
        chr = stringInput.charCodeAt(i)
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr
        // eslint-disable-next-line no-bitwise
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export const InjectHtml = memo(({ html, windowKey }: { html: string; windowKey?: string }) => {
    const divRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!divRef.current || !html) return
        const hash = getStringHash(html)
        if (windowKey && !window[windowKey]) {
            window[windowKey] = {}
        }
        const objectToSet = windowKey ? window[windowKey] : window
        if (objectToSet['inj_' + hash]) return
        objectToSet['inj_' + hash] = true
        const fragment = document.createRange().createContextualFragment(html)
        divRef.current.append(fragment)
    }, [html])
    return <div ref={divRef} />
})

export default InjectHtml
