import { useRouter } from 'next/router'
import { useMemo } from 'react'

import getCurrentPageNumber, { Options } from '../helpers/getCurrentPageNumber'

const useCurrentPageNumber = (options: Options = {}): number => {
    const router = useRouter()
    return useMemo(() => getCurrentPageNumber(router.query, undefined, options), [router, options])
}

export default useCurrentPageNumber
