import { ModalActionTypes as ActionTypes } from '../actions/modal'

const initialState = {
    isOpen: []
}

const modalsReducer = (state = initialState, { newStore, type, id } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: state.isOpen.filter(modal => modal !== id)
            }
        }

        case ActionTypes.OPEN: {
            return {
                ...state,
                isOpen: [...state.isOpen, id]
            }
        }

        case ActionTypes.TOGGLE: {
            if (state.isOpen.includes(id)) {
                return {
                    ...state,
                    isOpen: state.isOpen.filter(modal => modal !== id)
                }
            }
            return {
                ...state,
                isOpen: [...state.isOpen, id]
            }
        }

        default:
            return state
    }
}

export { modalsReducer }
