import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PersistentQueryActions } from '../actions/persistent-query'
import { AppName } from '../types/app'
import { useLocalStorage } from '.'

const stringMatchesDisabled = (val: string) => !!val?.match(/null|false|disable|undefined/gim)?.length

const checkIfValueDisabled = (value: string | string[] | undefined) =>
    typeof value !== 'undefined' &&
    (Array.isArray(value) ? value.some(stringMatchesDisabled) : stringMatchesDisabled(value))

export type UsePersistentQueryParamProps<T> = {
    appName?: AppName
    queryKey: string
    initialValue?: any
    onData?: (storageValue: any) => T
}

const persistentQueryValuesSelectorFactory = key => state => state.persistentQuery?.[key]
export const usePersistentQueryParamValue = <T>(key?: string): T => {
    const selector = useMemo(() => (key ? persistentQueryValuesSelectorFactory(key) : () => null), [key])
    return useSelector(selector)
}

/**
 * Persistent query values via LocalStorage
 * Any connected property can be stored via "url?{propertyName}={value}"
 * Only stores strings, mostly used for feature testing on production
 *
 * Any connected property can be disabled by sending null, false, undefined, or disable "url?{propertyName}=null","url?{propertyName}=false"
 * Which will erase the previously stored value
 *
 * returns [
 *   value: any,
 *   setLocalStorageValue: function,  // sets new value in LocalStorage
 *   deleteKey: function, // deletes LocalStorage key if it exists
 * ]
 */
const usePersistentQueryParam = <T = any>({
    queryKey,
    appName = AppName.ALL,
    initialValue,
    onData
}: UsePersistentQueryParamProps<T>) => {
    const router = useRouter()
    const query = router?.query || {}
    const [localStorageValue, setLocalStorageValue, deleteLocalStorageKey] = useLocalStorage<any>(
        `${appName}_${queryKey}`,
        initialValue
    )
    const dispatch = useDispatch()
    const reduxValue = usePersistentQueryParamValue<T>(queryKey)
    const setReduxValue = useCallback(
        val => {
            if (!queryKey) return
            const newValue = typeof onData === 'function' ? onData(val) : val
            if (JSON.stringify(newValue) === JSON.stringify(reduxValue)) {
                return
            }
            dispatch(PersistentQueryActions.setPersistentQuery(queryKey, newValue))
        },
        [queryKey, dispatch]
    )
    const deleteReduxValue = useCallback(() => {
        if (!queryKey) return
        dispatch(PersistentQueryActions.removePersistentQuery(queryKey))
    }, [queryKey, dispatch])

    useEffect(() => {
        setReduxValue(localStorageValue)
    }, [setReduxValue, localStorageValue])

    const deletePersistentQuery = useCallback(() => {
        deleteLocalStorageKey()
        deleteReduxValue()
    }, [deleteLocalStorageKey, deleteReduxValue])

    const setValue = useCallback(
        val => {
            setLocalStorageValue(val)
            setReduxValue(val)
        },
        [setLocalStorageValue, setReduxValue]
    )

    useEffect(() => {
        if (!queryKey) {
            return
        }
        const queryValue = query?.[queryKey]
        if (!queryValue || !queryValue.length) {
            return
        }
        if (checkIfValueDisabled(queryValue)) {
            deletePersistentQuery()
            return
        }
        setValue(queryValue)
    }, [query, queryKey, setValue])

    const value = useMemo(() => {
        if (!queryKey) {
            return undefined
        }
        const queryValue = query?.[queryKey]
        if (checkIfValueDisabled(queryValue)) return undefined
        return queryValue || reduxValue || localStorageValue
    }, [queryKey, query, localStorageValue, reduxValue])

    return [value, setValue, deletePersistentQuery]
}

export default usePersistentQueryParam

export const IsolatedUsePersistentQueryParam = <T = any>(props: UsePersistentQueryParamProps<T>) => {
    usePersistentQueryParam<T>(props)
    return null
}
