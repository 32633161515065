import { Children, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './ButtonsRow.style'

const ButtonsRowStyled = styled.div(props => ({ ...styles(props) }))

function ButtonsRow({ children, className, justifyContent, childrenSpacing, disabled, ...rest }) {
    const childElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])

    const uid = useUIDSeed()

    if (!childElements.length || disabled) {
        return null
    }

    return (
        <ButtonsRowStyled
            justifyContent={justifyContent}
            childrenSpacing={childrenSpacing}
            className={className}
            {...rest}>
            {childElements.map((child, index) => {
                const { id } = child?.props || {}
                return (
                    <div key={uid(id || index)} className="buttonsRow_item">
                        {child}
                    </div>
                )
            })}
        </ButtonsRowStyled>
    )
}

ButtonsRow.propTypes = {
    className: PropTypes.string,
    alignContentRight: PropTypes.bool,
    childrenSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    justifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'initial',
        'inherit'
    ])
}

ButtonsRow.defaultProps = {
    className: undefined,
    alignContentRight: false,
    childrenSpacing: 20,
    disabled: false,
    justifyContent: 'space-between'
}

export default ButtonsRow
