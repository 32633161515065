import { toRem, toRems } from '../../helpers/theme'

const style = ({ rounded, theme }) => {
    const roundedConfig = {
        small: toRem(4),
        medium: toRem(8),
        large: toRem(15),
        rounded: '50%'
    }

    return {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 0,
        padding: 0,
        ...(rounded && {
            borderRadius: roundedConfig[rounded]
        }),
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: 0.3,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
            // backgroundImage: @TODO: Base64 Net.hr Placeholder
        },
        img: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
            // ...(withPlaceholder && {
            //     filter: isImageLoading ? 'blur(15px)' : 'blur(0px)',
            //     opacity: isImageLoading ? 0.5 : 1,
            //     transform: isImageLoading ? 'scale(1.05)' : 'scale(1)',
            //     transition: `opacity ${transitionDuration}ms ease-in, transform ${transitionDuration}ms ease-in, filter ${
            //         transitionDuration * 2
            //     }ms ease-in`
            // })
        },
        '.image_info': {
            position: 'absolute',
            bottom: 0,
            fontSize: toRem(11),
            backgroundColor: theme.colors.background.body.inverted,
            color: theme.colors.text.white,
            padding: toRems([5, 10, 5, 10]),
            opacity: 0.7
        }
    }
}

export default style
