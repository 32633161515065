import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

// import useEffectDebugger from './debug/useEffectDebugger'
// import useMemoDebugger from './debug/useMemoDebugger'
import { useIsTransitioning } from '../context/clientNavigation'
import { omitDanasRootCategorySlugFromUrl } from '../helpers/danas'

// @NOTE: data attributes usage
// object_id: id
// section: category
// taxonomy: subcategory
// url: The 'url' parameter in data is optional, but it eliminates the potential problem if you call upScore with the next-page data before the browser navigates to the next page.
// object_type: 'article'
//              when it's an article page.
//              Do so also when the articlehas embedded videos.
//              UpScore recognise that and tracks the videos separately
//              'video'
//              when there is only(or nearly only) the video on the page.
//              When it is rather an article with embedvideo, mark it as 'article'
//              'gallery'
//              for picture gallery pages
//              'liveblog'
//              when the page holds a live-blog
//              'misc'
//              for other pages(e.g. for search pages, imprint/impressum, service etc.)

const isNumeric = n => n !== '' && n !== null && !Array.isArray(n) && !Number.isNaN(+n) && Number.isFinite(+n)

const memoMap = new Map()
const memoize =
    (fn, keyDataSelector = (...args) => args) =>
    (...args) => {
        const key = JSON.stringify(keyDataSelector(...args))
        if (memoMap.has(key)) {
            return memoMap.get(key)
        }
        const result = fn(...args)
        // console.log('useUpScore stats sending upscore', memoMap)
        memoMap.set(key, result)
        return result
    }
const upScoreStateSelecor = memoize(
    state => {
        const { upScoreReady, upScoreDomain } = state.initialLoad
        return { isReady: upScoreReady, upScoreDomain }
    },
    state => {
        const { upScoreReady, upScoreDomain } = state.initialLoad
        return { upScoreReady, upScoreDomain }
    }
)
const useUpScore = upScoreParams => {
    // @TODO: refactor all context to redux state

    const [isTransitioning] = useIsTransitioning()
    const { isReady, upScoreDomain } = useSelector(upScoreStateSelecor)
    const {
        id,
        objectType,
        category,
        subCategory,
        nextPageUrl,
        gallerySlide,
        author,
        pubdate,
        isPreview,
        isArticle,
        enabled = true
    } = upScoreParams

    const upScorePayload = useMemo(() => {
        if (category || (objectType === 'landingpage' && typeof upScoreDomain !== 'undefined')) {
            if (isNumeric(gallerySlide)) {
                return {
                    domain: upScoreDomain,
                    object_id: id,
                    section: category,
                    taxonomy: subCategory || '',
                    url: omitDanasRootCategorySlugFromUrl(nextPageUrl || ''),
                    object_type: objectType,
                    sequence: gallerySlide + 1,
                    author,
                    pubdate,
                    isPreview
                }
            }
            return {
                domain: upScoreDomain,
                object_id: id,
                section: category,
                taxonomy: subCategory || '',
                url: omitDanasRootCategorySlugFromUrl(nextPageUrl || ''),
                object_type: objectType,
                author,
                pubdate,
                isPreview
            }
        }
        return null
    }, [category, objectType, subCategory, nextPageUrl, gallerySlide, upScoreDomain, id])

    useEffect(
        () => {
            // Do nothing.
            if (
                enabled === false ||
                !isReady ||
                !upScorePayload ||
                typeof upScoreDomain === 'undefined' ||
                isTransitioning ||
                (upScorePayload.object_type === 'gallery' && // prevent triggering when transitioning between 2 galleries, wait for both id and url to be set
                    upScorePayload.object_id &&
                    upScorePayload.url &&
                    !upScorePayload.url.includes(upScorePayload.object_id))
            ) {
                // console.log('useUpScore stats not sending upscore', upScorePayload)
                return
            }

            // console.log('useUpScore stats sending upscore', upScorePayload)

            if (isArticle) {
                window.upScore({
                    config: {
                        article: '.article-body'
                    },
                    data: upScorePayload
                })
            } else {
                window.upScore({
                    data: upScorePayload
                })
            }
        },
        [isReady, isTransitioning, upScoreDomain, upScorePayload]
        // ['isReady', 'isTransitioning', 'upScoreDomain', 'upScorePayload'],
        // 'useUpScore' // remnant of useEffectDebugger
    )

    if (enabled === false) {
        return null
    }

    return upScorePayload
}

export default useUpScore
