import Link from 'next/link'
import PropTypes from 'prop-types'

// @NOTE: Sometimes Link component does not need 'a' tag in it because the component will handle the tag (see Button)

function HmnLink({ children, href, as, passHref, prefetch, scroll, noTag, title, className }) {
    return children ? (
        <Link
            href={href}
            as={as}
            passHref={noTag ? passHref : false}
            prefetch={prefetch}
            // replace={replace}
            scroll={scroll}
            legacyBehavior>
            {!noTag ? (
                <a className={className} title={title}>
                    {children}
                </a>
            ) : (
                children
            )}
        </Link>
    ) : null
}

HmnLink.propTypes = {
    href: PropTypes.string,
    as: PropTypes.string,
    passHref: PropTypes.bool,
    prefetch: PropTypes.bool,
    scroll: PropTypes.bool,
    title: PropTypes.string,
    noTag: PropTypes.bool,
    className: PropTypes.string
}

HmnLink.defaultProps = {
    href: undefined,
    as: undefined,
    passHref: false,
    prefetch: false,
    scroll: true,
    title: undefined,
    noTag: false,
    className: undefined
}

export default HmnLink
