import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        height: {
            md: toRem(theme.global.header.height.md),
            xs: toRem(theme.global.header.height.xs)
        }
    }

    return {
        '& .headerPanel': {
            width: '100%',
            position: 'fixed',
            top: 0,
            height: config.height.xs,
            background: theme.colors.background.body.secondary,
            [theme.breakpoints.up('md')]: {
                height: config.height.md
            },
            '&_inner': {
                width: '100%',
                height: '100%'
            }
        }
    }
}

const motionVariants = () => ({
    open: {
        top: '0vh',
        transition: {
            duration: 0.5,
            ease: 'easeOut'
        }
    },
    closed: {
        top: '-20vh', // @HELP can I pass some theme variables to this function? For example the "distance"
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        }
    }
})

export default style
export { motionVariants }
