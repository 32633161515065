import { useEffect, useState } from 'react'

const useUpdatedStateValue = <T>(value: T, checker: (value: any) => boolean = Boolean) => {
    const [state, setState] = useState<T>(value)
    useEffect(() => {
        if (!checker(value)) return
        setState(value)
    }, [value])
    return state
}

export default useUpdatedStateValue
