import { NavigationAccountActionTypes as ActionTypes } from '../actions/navigationAccount'

const initialState = {
    isOpen: false
}

const navigationAccountReducer = (state = initialState, { newStore, type } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE_ACCOUNT: {
            return {
                ...state,
                isOpen: false
            }
        }

        case ActionTypes.OPEN_ACCOUNT: {
            return {
                ...state,
                isOpen: true
            }
        }

        case ActionTypes.TOGGLE_ACCOUNT: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        default:
            return state
    }
}

export { navigationAccountReducer }
