import { useMutation, useQuery, useQueryClient } from 'react-query'

import useBaseApiURL from '@hmn/rtl-web-core/src/hooks/useBaseApiURL'

import useUser from './useUser'

const MutationAction = {
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe'
}

const useNewsletter = () => {
    const queryClient = useQueryClient()
    const { profile, isLoggedIn } = useUser()
    const baseApiURL = useBaseApiURL()

    const subscriptionsKey = ['newsletter/subscriptions', isLoggedIn, profile?.account?.email]

    const { data: subscriptionsData = {}, isSuccess } = useQuery(
        subscriptionsKey,
        async () => {
            const response = await fetch(`${baseApiURL}/newsletter/subscriber/${profile?.account?.email}`)

            if (!response.ok) {
                return {}
            }

            const data = await response.json()

            return data.lists?.reduce((all, list) => {
                all[list.id] = list.status === 'subscribed'

                return all
            }, {})
        },
        {
            enabled: isLoggedIn && !!profile?.account?.email,
            retry: false
        }
    )

    const { mutate: subscriptionsMutation } = useMutation(
        async ({ action, listId }) => {
            if (!isLoggedIn) {
                return { action, listId }
            }

            const data = await fetch(`${baseApiURL}/newsletter/${action}`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    email: profile?.account?.email,
                    userId: profile.id,
                    listId
                })
            }).then(response => response.json())

            // By doing this I avoid the situation where the request fails, but the UI updates as it succeeded.
            if (data.status === 'Error') {
                throw new Error(data.message)
            }

            return { action, listId }
        },
        {
            onMutate: async ({ action, listId }) => {
                await queryClient.cancelQueries(subscriptionsKey)

                const previousValue = queryClient.getQueryData(subscriptionsKey)

                queryClient.setQueryData(subscriptionsKey, {
                    ...previousValue,
                    [listId]: action === MutationAction.SUBSCRIBE
                })

                return previousValue
            },
            onError: (err, variables, previousValue) => queryClient.setQueryData(subscriptionsKey, previousValue),
            onSettled: () => {
                queryClient.invalidateQueries(subscriptionsKey)
            }
        }
    )

    return {
        data: subscriptionsData,
        update: (listId, status) =>
            subscriptionsMutation({
                action: status ? MutationAction.SUBSCRIBE : MutationAction.UNSUBSCRIBE,
                listId
            }),
        isSuccess
    }
}

export default useNewsletter
