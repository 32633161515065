const layoutDimension = {
    homepage: {
        content: 1300
    },
    article: {
        head: 1215,
        content: 950,
        related: 1215
    },
    hotOrNot: {
        content: 950,
        related: 1215
    },
    tag: {
        main: 1215
    },
    recipe: {
        content: 1150,
        related: 1215
    },
    search: {
        main: 1215 // close to 970px when scaled down
    },
    counseling: {
        head: 1200,
        main: 1200,
        content: 900,
        meta: 900,
        related: 1200
    },
    static: {
        head: 1300,
        content: 950
    },
    error: {
        head: 1050,
        main: 1350
    },
    media: {
        content: 1215,
        meta: 950,
        related: 1215,
        listalica: 1215
    },
    category: {
        main: 1215 // close to 970px when scaled down
    },
    product: {
        content: 1200,
        listing: 1400
    },
    deckoDana: {
        main: 1215 // close to 970px when scaled down
    },
    giveaway: {
        main: 900
    },
    tester: {
        head: 1000
    },
    calculator: {
        main: 1215
    }
}

export default {
    ...layoutDimension
}
