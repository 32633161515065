import { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { MenuItem, TextField } from '@mui/material'
import { formatISO, subYears } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import useUser from '../../../../hooks/useUser'
import { DatePickerField } from '../../../DatePicker'
import { Button, ButtonsRow, buttonVariants } from '..'
import styles from './UserSettings.style'

const UserSettingsStyled = styled.div(props => ({ ...styles(props) }))

const formMessage = {
    loading: 'Ažuriranje u tijeku...',
    error: 'Dogodila se pogreška, molimo pokušajte ponovno.',
    success: 'Vaši podaci su uspješno ažurirani.'
}

const formDefaultValues = {
    first_name: '',
    last_name: '',
    birthday: null,
    sex: '',
    address: '',
    postal_code: '',
    city: '',
    phone_number: ''
}

function UserSettings({ className, ...rest }) {
    const { profile, update, logOut, isValid } = useUser()
    const [formState, setFormState] = useState(null)
    const attributesSelected = useMemo(() => profile?.extended_attributes || {}, [profile])

    const initialValues = useMemo(() => {
        const values = Object.keys(formDefaultValues).reduce((all, key) => {
            all[key] = attributesSelected[key] ? attributesSelected[key] : formDefaultValues[key]
            return all
        }, {})
        return {
            ...values,
            birthday: values.birthday ? new Date(values.birthday) : null
        }
    }, [attributesSelected])

    const handleLogout = () => {
        logOut()
    }
    // Form submit
    const handleSubmit = useCallback(
        values => {
            if (Object.keys(values).some(key => values[key] !== attributesSelected[key])) {
                setFormState('loading')
                update(
                    {
                        extended_attributes: {
                            ...Object.keys(values).reduce((all, key) => {
                                all[key] = values[key] || null
                                return all
                            }, {}),
                            birthday: values.birthday ? formatISO(new Date(values.birthday)) : null
                        }
                    },
                    err => {
                        setFormState(err ? 'error' : 'success')
                    }
                )
            }
        },
        [update, attributesSelected]
    )

    return (
        <UserSettingsStyled className={className} {...rest}>
            <div className="userSettings_basic">
                {!isValid ? (
                    <div className="userSettings_requiredNotice">
                        Ne zaboravi popuniti svoje osobne podatke označene zvjezdicom. Tako možeš komentirati,
                        ocjenjivati i osvajati nagrade. Ne brini, drugi korisnici{' '}
                        <strong>neće moći vidjeti tvoje podatke, samo tvoj nadimak</strong>.
                    </div>
                ) : null}
                <div>
                    <div className="userSettings_emailLabel">Tvoja email adresa</div>
                    <div className="userSettings_email">{profile?.account?.email}</div>
                </div>
                <Button
                    className="userSettings_changePass"
                    title="Promijeni"
                    variant={buttonVariants.BETA}
                    // @TODO: Expose this to front page and revise link!
                    // eslint-disable-next-line max-len
                    href={`${process.env.NEXT_PUBLIC_HUMAN_API_TRUE_ROOT_URL}/password/change-password?_style=light&_locale=hr`}>
                    Promijeni lozinku
                </Button>
            </div>
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
                validate={values => {
                    const errors = {}
                    if (window?.localStorage?.getItem('novalidate')) {
                        return errors
                    }
                    if (!values.first_name) {
                        errors.first_name = true
                    }
                    if (!values.last_name) {
                        errors.last_name = true
                    }
                    if (!values.birthday) {
                        errors.birthday = true
                    }
                    if (!values.sex) {
                        errors.sex = true
                    }
                    if (!values.city) {
                        errors.city = true
                    }
                    return errors
                }}>
                {({ values, handleChange, handleBlur, errors }) => (
                    <Form noValidate className="userSettings_form">
                        <TextField
                            type="text"
                            name="first_name"
                            label="Ime"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            className="textField_half"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            name="last_name"
                            label="Prezime"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            className="textField_half"
                            variant="standard"
                        />
                        <Field
                            required
                            label="Datum rođenja"
                            onBlur={handleBlur}
                            name="birthday"
                            placeholder="dd/mm/yyyy"
                            className="textField_half birthday"
                            maxdate={subYears(new Date(), 13)}
                            variant="standard"
                            component={DatePickerField}
                        />
                        <TextField
                            name="sex"
                            label="Spol"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sex}
                            className="textField_half"
                            variant="standard"
                            select>
                            <MenuItem value="" disabled>
                                Odaberi
                            </MenuItem>
                            <MenuItem value="female">Ženski</MenuItem>
                            <MenuItem value="male">Muški</MenuItem>
                        </TextField>
                        <TextField
                            type="text"
                            name="address"
                            label="Ulica i broj"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            className="textField_half"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            name="postal_code"
                            label="Poštanski broj"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postal_code}
                            className="textField_half"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            name="city"
                            label="Grad"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            className="textField_half"
                            variant="standard"
                        />
                        <TextField
                            type="text"
                            name="phone_number"
                            label="Broj telefona"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone_number}
                            className="textField_half"
                            variant="standard"
                        />
                        <ButtonsRow className="userSettings_buttons">
                            <Button type="submit" title="Ažuriraj" variant={buttonVariants.ALPHA}>
                                Ažuriraj podatke
                            </Button>
                            <Button onClick={handleLogout} simple>
                                Odjavi se
                            </Button>
                        </ButtonsRow>
                        {formState ? <div className="userSettings_requiredNotice">{formMessage[formState]}</div> : null}
                        {!!Object.keys(errors).length && (
                            <div className="userSettings_requiredNotice">Obvezno polje!</div>
                        )}
                    </Form>
                )}
            </Formik>
            <div className="userSettings_footer">
                <div>Polja označena zvjezdicom (*) su obavezna</div>
                {/* <Button className="userSettings_footerButton" onClick={handleDeleteProfile} simple> */}
                {/*    Obriši RTL korisnički račun */}
                {/* </Button> */}
            </div>
        </UserSettingsStyled>
    )
}

UserSettings.propTypes = {
    className: PropTypes.string
}

UserSettings.defaultProps = {
    className: undefined
}

export default withErrorBoundary(UserSettings, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileUserSettings]: ', error, componentStack)
    }
})
