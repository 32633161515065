/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router'
import Script from 'next/script'
import PropTypes, { InferProps } from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAdsNavigationState, useCurrentEntityTypeStore } from '../../context/clientNavigation'
import getSlotDivId from '../../helpers/ads/getSlotDivId'
import pageVariants from '../../helpers/pageVariants'
import useExistingSlots from '../../hooks/ads/useExistingSlots'
import useFilledSlots from '../../hooks/ads/useFilledSlots'
import useGoogleTag from '../../hooks/ads/useGoogleTag'
import useDeviceType from '../../hooks/layout/useDeviceType'
import useEnvironment from '../../hooks/useEnvironment'
import { ProcessedAdList, ZoneName } from '../../types/ads/Ad.interface'
import { EntityType, isListingEntity, isSingleEntity } from '../../types/entity'
import { MergeTypes } from '../../types/helpers/MergeTypes'
import { useDidomiReady } from '../DidomiProvider'
import { checkAdsEnabledState, compareSlots } from './Ads.helpers'
import { IpromComponent, useIprom } from './IpromNs.provider'
import { RubiconMagniteComponent, useRubiconMagnite } from './RubiconMagnite.provider'

const typeCategoryNameMap = {
    article: 'clanak',
    gallery: 'galerija',
    video: 'video',
    'counseling-listing': 'savjetovaliste',
    counseling: 'savjetovaliste'
}

const getDefaultTargetingArguments = (appName = 'webapp', entityType: EntityType = 'default', asPath = '/') => {
    const isSingle = isSingleEntity(entityType)
    const isListing = isListingEntity(entityType)
    const pathParts = asPath.split('/').filter(Boolean)
    const categoryKey = `${appName}_category`
    const categoryValue = [
        typeCategoryNameMap[entityType] || entityType,
        ...pathParts.slice(0, isSingle ? -1 : undefined)
    ].filter((v, i, a) => a.indexOf(v) === i)
    const postType = (isSingle && ['single', entityType]) ||
        (isListing && ['listing', entityType]) || ['default', 'static']
    return {
        [categoryKey]: categoryValue,
        post_type: postType,
        app_name: appName
    }
}

const propTypes = {
    appName: PropTypes.oneOf([...Object.values(pageVariants)]),
    dfpNetworkId: PropTypes.string.isRequired,
    collapseEmptyDivs: PropTypes.bool,
    singleRequest: PropTypes.bool,
    currentAdManifest: PropTypes.shape({
        dfp: PropTypes.shape({}),
        targetingArguments: PropTypes.shape({}),
        type: PropTypes.string,
        id: PropTypes.string
    }).isRequired,
    ipromSitePath: PropTypes.arrayOf(PropTypes.string),
    rubiconMagniteScriptName: PropTypes.string,
    dependantZonesMap: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    ignoreZonesOnShallowNavigation: PropTypes.oneOfType([() => null, PropTypes.objectOf(PropTypes.object)]) // { [pageVariant]: { [zoneName]: true } }
}

type AdsProviderProps = MergeTypes<
    InferProps<typeof propTypes>,
    {
        dfpNetworkId: string
        currentAdManifest: ProcessedAdList
        appName: string | undefined
        dependantZonesMap?: Record<ZoneName, ZoneName[]>
    }
>

const AdsProvider = ({
    appName,
    dfpNetworkId,
    collapseEmptyDivs,
    singleRequest: isSingleRequest,
    dependantZonesMap = {},
    currentAdManifest,
    ipromSitePath,
    rubiconMagniteScriptName
}: AdsProviderProps) => {
    const biddingRanThisNavRef = useRef(false)

    const hasIprom = !!(ipromSitePath && ipromSitePath.length > 0)
    const hasRubicon = !!rubiconMagniteScriptName

    const navigationState = useAdsNavigationState()
    const [isMounted, setIsMounted] = useState(false)
    const [deviceType] = useDeviceType()
    const [filledSlots, setFilledSlots] = useFilledSlots()
    const didomiReady = useDidomiReady()

    const setFilledGptSlot = useCallback(({ divId, event }) => {
        setFilledSlots({
            [divId]: {
                filled: !event.isEmpty,
                size: event.size
            }
        })
    }, [])

    const dfpZones: ProcessedAdList['dfp'] = useMemo(
        () =>
            typeof currentAdManifest?.dfp === 'object' && currentAdManifest !== null && currentAdManifest?.dfp !== null
                ? Object.entries(currentAdManifest?.dfp).reduce((acc, [zoneName, slot]) => {
                      const deviceStart = deviceType === 'mobile' ? 'M -' : 'D -'
                      if (!deviceType || zoneName.startsWith(deviceStart)) {
                          acc[zoneName] = slot
                      }
                      return acc
                  }, {})
                : {},
        [currentAdManifest?.dfp, deviceType]
    )

    const router = useRouter()
    const divIdZoneNameMap = useMemo(
        () =>
            Object.entries(dfpZones).reduce((acc, [zoneName, slot]) => {
                const divId = getSlotDivId(slot)
                if (divId && zoneName) {
                    acc[divId] = zoneName
                }
                return acc
            }, {}),
        [dfpZones]
    )
    // const sendRequestEvent = useGoogleAdsEvents(appName)
    const [ipromDisplayCallback, ipromRefreshCallback] = useIprom({
        enabled: hasIprom,
        sitePath: (ipromSitePath || []).filter(Boolean) as string[]
    })
    const [rubiconDisplayCallback, rubiconRefreshCallback] = useRubiconMagnite({
        enabled: hasRubicon
    })
    const [applyChangesRef, slotsRef] = useGoogleTag({
        dfpZones,
        dfpNetworkId,
        collapseEmptyDivs: !!collapseEmptyDivs,
        singleRequest: !!isSingleRequest,
        onSlotRender: setFilledGptSlot,
        onSlotDisplay: (hasIprom && ipromDisplayCallback) || (hasRubicon && rubiconDisplayCallback) || undefined,
        onSlotRefresh: (hasIprom && ipromRefreshCallback) || (hasRubicon && rubiconRefreshCallback) || undefined
    })
    const { adsEnabled: adsEnabledEnv } = useEnvironment()
    const adsEnabled = useMemo(
        () => checkAdsEnabledState(appName, router.asPath) && adsEnabledEnv && currentAdManifest?.enabled,
        [adsEnabledEnv, router.asPath, appName, currentAdManifest?.enabled]
    )

    const zonesUsedThisNavRef = useRef({})

    // initial mount, attach onNavigation callbacks
    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true)
        }
        const onRouteChangeStart = () => {
            // clear filled slots lists in order to allow fresh slots to 'display' themselves
            setFilledSlots({}, true)
        }

        const onRouteChangeComplete = () => {
            zonesUsedThisNavRef.current = {}
            biddingRanThisNavRef.current = false
        }

        const onRouteChangeError = () => {
            onRouteChangeComplete()
        }

        router.events.on('routeChangeStart', onRouteChangeStart)
        router.events.on('routeChangeComplete', onRouteChangeComplete)
        router.events.on('routeChangeError', onRouteChangeError)
        return () => {
            // console.log('[Ads.provider]: unmounted events')
            router.events.off('routeChangeStart', onRouteChangeStart)
            router.events.off('routeChangeComplete', onRouteChangeComplete)
            router.events.off('routeChangeError', onRouteChangeError)
        }
    }, [])

    // Targeting arguments merging/fixing
    const entityType = useCurrentEntityTypeStore()
    const targetingArguments = useMemo(() => {
        if (currentAdManifest?.type === 'default') {
            return getDefaultTargetingArguments(appName, entityType, router.asPath)
        }
        return { ...currentAdManifest?.targetingArguments, app_name: appName }
    }, [currentAdManifest?.targetingArguments, appName, currentAdManifest?.type, entityType, router.asPath])

    const canRunInitialCheck = deviceType !== null && adsEnabled && !(navigationState !== 'ready' || !isMounted)

    const canRunInitialDisplay = useExistingSlots(dfpZones, canRunInitialCheck)

    const lastIterationRef = useRef(-1)
    const iterationRef = useRef(0)
    useEffect(() => {
        if (navigationState === 'transitioning') {
            iterationRef.current = 0
            lastIterationRef.current = -1
        }
    }, [navigationState])
    useEffect(() => {
        if (navigationState !== 'ready' || !canRunInitialDisplay || lastIterationRef.current === iterationRef.current) {
            return
        }
        const changes = compareSlots(dfpZones, slotsRef.current, dependantZonesMap, filledSlots, iterationRef.current)
        if (changes) {
            lastIterationRef.current = iterationRef.current
            applyChangesRef.current(changes, targetingArguments, () => {
                iterationRef.current++
            })
        }
    }, [
        canRunInitialDisplay,
        navigationState,
        targetingArguments,
        dfpZones,
        divIdZoneNameMap,
        dependantZonesMap,
        filledSlots,
        router.asPath
    ])

    // Render
    if (!adsEnabled || (!didomiReady && hasRubicon)) {
        return null
    }
    return (
        <>
            {hasRubicon && <RubiconMagniteComponent name={rubiconMagniteScriptName} />}
            {hasIprom && <IpromComponent />}
            <Script id="ad-recovery" src="https://btloader.com/tag?o=5071087362113536&upapi=true" async />
            <Script id="googletag-gpt" src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" defer />
            <Script id="googletag-cmd">
                {'var googletag = googletag || {}; googletag.cmd = googletag.cmd || [];'}
            </Script>
        </>
    )
}

AdsProvider.propTypes = propTypes

AdsProvider.defaultProps = {
    appName: undefined,
    collapseEmptyDivs: false,
    singleRequest: true,
    dependantZonesMap: undefined,
    ignoreZonesOnShallowNavigation: undefined,
    ipromSitePath: undefined,
    rubiconMagniteScriptName: undefined
}

export default AdsProvider
