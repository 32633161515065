import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Circle.style'

const CircleStyled = styled.div(props => ({ ...styles(props) }))

function Circle({ className, size, strokeWidth, variant, percentage, ...rest }) {
    const center = size / 2
    const radius = center - strokeWidth / 2

    if (!size) {
        return null
    }

    return (
        <CircleStyled
            className={className}
            circleSize={size}
            strokeWidthCircle={strokeWidth}
            percentage={percentage}
            variant={variant}
            {...rest}>
            <svg className="svgCircle" preserveAspectRatio="xMidYMid meet" viewBox={`0 0 ${size} ${size}`}>
                <circle cx={center} cy={center} r={radius} />
            </svg>
        </CircleStyled>
    )
}

const circleVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Circle.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    percentage: PropTypes.number,
    variant: PropTypes.oneOf([...Object.values(circleVariants)])
}

Circle.defaultProps = {
    className: undefined,
    size: 36,
    strokeWidth: 2,
    percentage: 100,
    variant: circleVariants.ALPHA
}

export { circleVariants }
export default Circle
