/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const CheckMark = createSvgIcon(
    <path d="M 16 0 A 16 16 0 0 0 0 16 A 16 16 0 0 0 16 32 A 16 16 0 0 0 32 16 A 16 16 0 0 0 16 0 z M 21.724609 11.056641 L 23.609375 12.943359 L 13.333984 23.21875 L 8.390625 18.275391 L 10.275391 16.390625 L 13.332031 19.449219 L 21.724609 11.056641 z " />,
    'CheckMark'
)

export default CheckMark
