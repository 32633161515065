import { toRem } from '../../../../helpers/theme'

const style = ({ circleSize, labelRightWidth, variant, viewportWidth }) => {
    const fontSize = circleSize / 2.5 // this formula fits right for rate issue but it can be changed
    const paddingRight = toRem(circleSize / 16)
    const width = viewportWidth > 340 ? toRem(circleSize + labelRightWidth) : toRem(circleSize + labelRightWidth - 10)

    const config = {
        varaint: {
            alpha: {
                label: {
                    left: '50%',
                    transform: 'translateX(-50%)'
                }
            },
            beta: {
                label: {
                    top: '50%',
                    left: circleSize,
                    transform: 'translateY(-50%)',
                    paddingLeft: viewportWidth > 370 ? toRem(16) : toRem(10)
                }
            }
        }
    }
    return {
        position: 'relative',
        width,
        minWidth: width,
        '& .overallCircle': {
            position: 'relative',
            height: circleSize,
            width: circleSize,
            borderRadius: '50%',
            '& .overallText': {
                position: 'relative',
                fontSize,
                textAlign: 'center',
                paddingRight,
                top: '50%',
                transform: 'translateY(-50%)'
            }
        },
        '& .overallLabel': {
            position: 'absolute',
            fontSize: viewportWidth > 370 ? toRem(12) : toRem(10),
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.25em',
            ...config?.varaint[variant]?.label
        }
    }
}

export default style
