const toSentenceCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
const createIsolatedHook = <HookProps,>(useHook: (props: HookProps) => any) => {
    const ComponentHook = (props: HookProps) => {
        useHook(props)
        return null
    }
    ComponentHook.displayName = 'IsolatedHook' + toSentenceCase(useHook.name)
    return ComponentHook
}

// types don't work yet
// export const IsolatedHook = <HookProps,>({
//     hook: useHook,
//     ...hookProps
// }: HookProps & { hook: (props: HookProps) => void }) => {
//     useHook(hookProps as any)
//     return null
// }

export default createIsolatedHook
