import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

// import useEffectDebugger from '../../hooks/debug/useEffectDebugger'
import { useRouteChangeStart } from '../../hooks/useRouteChange'
// import useWindowAttachedValue from '../../hooks/useWindowAttachedValue'
import { useClientNavigationStore } from './ClientNavigation.context'
import { ClientNavigationState, NavigationDirection } from './ClientNavigation.types'

const isEntityWithEmbeddedAds = (entityType: string) => ['article', 'column'].includes(entityType)
export const ClientNavigationContextConnector = () => {
    const [clientNavigation, setNavigation] = useClientNavigationStore()
    const router = useRouter()
    // console.log('clientNavigation', { ...clientNavigation })

    useEffect(() => {
        setNavigation({ initialLoad: true, clientNavigationState: ClientNavigationState.LOADING })
        const onRouteChangeStart = (...rest) => {
            // console.log('clientsidenav: onRouteChangeStart', rest)
            setNavigation({
                isTransitioning: true,
                hasNavigationStarted: true,
                inarticleAdMounted: false,
                clientNavigationState: ClientNavigationState.TRANSITIONING
                // currentEntityType: 'loading'
            })
        }
        const onRouteChangeComplete = () => {
            setNavigation({
                isTransitioning: false,
                hasNavigated: true,
                hasNavigationStarted: false,
                clientNavigationState: ClientNavigationState.READY
            })
        }

        router.events.on('routeChangeStart', onRouteChangeStart)
        router.events.on('routeChangeComplete', onRouteChangeComplete)
        router.events.on('routeChangeError', onRouteChangeComplete)

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart)
            router.events.off('routeChangeComplete', onRouteChangeComplete)
            router.events.off('routeChangeError', onRouteChangeComplete)
        }
    }, [router.events, setNavigation])

    useEffect(() => {
        const handleLoad = () => {
            // console.log('handleLoad')
            setNavigation({ domLoaded: true, clientNavigationState: ClientNavigationState.READY })
        }
        if (typeof window === 'undefined') {
            return () => {}
        }
        if (window.document.readyState === 'complete' || document.readyState === 'interactive') {
            // Fully loaded!
            handleLoad()
            return () => {}
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('load', handleLoad)
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('load', handleLoad)
            }
        }
    }, [setNavigation])

    const previousRoute = useRef(router?.asPath)
    const handleNavigationDirection = useCallback(
        route => {
            const isOldRoute =
                previousRoute.current?.split('/').filter(Boolean).length > route.split('/').filter(Boolean).length
            setNavigation({ direction: isOldRoute ? NavigationDirection.POP : NavigationDirection.PUSH })
            previousRoute.current = route

            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem('internal-route-origin', 'true')
            }
        },
        [setNavigation]
    )
    useRouteChangeStart(handleNavigationDirection)

    const timeoutRef = useRef<number>()
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = undefined
        }

        const pageIsLoading = clientNavigation.currentEntityType === 'loading'
        if (pageIsLoading) {
            setNavigation({ adsNavigationState: ClientNavigationState.RENDERING })
            return
        }
        const clientIsReady = clientNavigation.clientNavigationState === ClientNavigationState.READY

        if (!isEntityWithEmbeddedAds(clientNavigation.currentEntityType) || !clientIsReady) {
            setNavigation({ adsNavigationState: clientNavigation.clientNavigationState })
            return
        }
        if (clientNavigation.inarticleAdMounted) {
            setNavigation({ adsNavigationState: ClientNavigationState.READY })
            return
        }
        timeoutRef.current = setTimeout(
            () => setNavigation({ adsNavigationState: ClientNavigationState.READY }),
            5000
        ) as unknown as number

        setNavigation({ adsNavigationState: ClientNavigationState.RENDERING })
    }, [
        clientNavigation.clientNavigationState,
        clientNavigation.currentEntityType,
        clientNavigation.inarticleAdMounted
    ])
    // useWindowAttachedValue(clientNavigation, 'clientNavigation')
    return null
}
