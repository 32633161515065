import { toRem } from '../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        logo: {
            main: {
                width: 130,
                height: 32
            },
            small: {
                width: 65,
                height: 16
            }
        },
        rtl: {
            main: {
                width: 90,
                height: 30
            },
            small: {
                width: 60,
                height: 20
            }
        }
    }

    return {
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            marginTop: toRem(10)
        },
        background: theme.colors.footer.background,
        position: 'relative',
        zIndex: theme.zIndex.footer,
        '& .footerGraphic': {
            position: 'absolute',
            width: toRem(340),
            height: toRem(60),
            left: 0,
            top: toRem(-30),
            [theme.breakpoints.only('xs')]: {
                width: toRem(250),
                height: toRem(35),
                top: toRem(-18)
            }
        },
        '& .container': {
            position: 'relative',
            margin: 'auto',
            width: '100%',
            maxWidth: toRem(1200),
            padding: toRem(20),
            paddingTop: toRem(68),
            [theme.breakpoints.down('sm')]: {
                padding: toRem(40),
                paddingTop: toRem(46),
                maxWidth: toRem(375)
            }
        },
        '& .footerContent': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: toRem(54),
            width: '100%',
            [theme.breakpoints.down('md')]: {
                marginBottom: toRem(24)
            },
            '& .logoColumn': {
                width: toRem(config.logo.main.width),
                '& .logo svg': {
                    position: 'relative',
                    width: toRem(config.logo.main.width),
                    height: toRem(config.logo.main.height)
                },
                [theme.breakpoints.down('md')]: {
                    display: 'none'
                }
            },
            '& .menuColumn': {
                maxWidth: toRem(564)
            }
        },
        '& .footerBottom': {
            borderTop: `solid 1px ${theme.colors.footer.separator}`,
            paddingTop: toRem(28),
            '& .logo': {
                display: 'none',
                marginTop: toRem(1),
                marginBottom: toRem(-5),
                [theme.breakpoints.down('md')]: {
                    display: 'block'
                },
                '& svg': {
                    width: toRem(config.logo.small.width),
                    height: toRem(config.logo.small.height)
                }
            },
            '& .signature': {
                display: 'block',
                fontSize: toRem(14),
                lineHeight: toRem(28),
                letterSpacing: 'initial',
                fontWeight: 'normal',
                width: 'auto',
                color: theme.colors.footer.text.subtle,
                [theme.breakpoints.down('md')]: {
                    fontSize: toRem(12),
                    lineHeight: toRem(24)
                }
            },
            '& .rtl': {
                float: 'right',
                '& svg': {
                    width: toRem(config.rtl.main.width),
                    height: toRem(config.rtl.main.height),
                    [theme.breakpoints.down('md')]: {
                        width: toRem(config.rtl.small.width),
                        height: toRem(config.rtl.small.height)
                    }
                }
            }
        }
    }
}

export default style
