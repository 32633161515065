import React, { createContext, useContext } from 'react'
import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'

const LunaContext = createContext({
    themes: {},
    isNet: false
})
function Luna({ theme: themeName, children }) {
    const { themes } = useContext(LunaContext)

    if (!themeName || !themes || !themes[themeName]) {
        return children
    }
    return <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
}

Luna.propTypes = {
    theme: PropTypes.string.isRequired
}

export { LunaContext }

export default Luna
