import { useMemo, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import getConfig from 'next/config'

import detectUndefined from '../helpers/detectUndefined'
import httpClient from '../http/client'
import { handleError } from '../next/data-fetching/handle-errors'
import { appQuerySettings } from '../queries/utils'
import useBaseApiURL, { getBaseApiURL } from './useBaseApiURL'
import { createQueryClient, getQueryKey, useQueryKey } from './useData'
import useSettings from './useSettings'

const { publicRuntimeConfig = {} } = getConfig()
const { xClientName: clientNameSSR = process.env.NEXT_PUBLIC_API_CLIENT } = publicRuntimeConfig

const dateProperties = [
    'published_start',
    'published_at',
    'published_end',
    'archived_at',
    'created_at',
    'updated_at',
    'publish_requested_at',
    'launched_at'
]

const formatProperties = item => {
    if (!item || typeof item !== 'object') {
        return item
    }
    const keys = Object.keys(item)
    if (!keys.length) {
        return item
    }
    return keys.reduce((o, key) => {
        if (dateProperties.includes(key) && typeof item[key] === 'string' && item[key]) {
            o[key] = new Date(item[key])
        } else {
            o[key] = item[key]
        }
        return o
    }, {})
}

export const useListElastic = (resource, searchQuery = {}, perPage = 30, options = {}) => {
    const baseApiURL = useBaseApiURL()
    const { xClientName: clientName } = useSettings()
    const currentPageRef = useRef(options.currentPage || 1)
    const { enabled = true, initialData, disableBlock, errorHandlers = [] } = options

    // this is so that we do not have to refactor all usages across the apps
    const formattedInitialData = useMemo(() => {
        if (!initialData) {
            return undefined
        }

        if (Array.isArray(initialData.pages) && Array.isArray(initialData.pageParams)) {
            return initialData
        }

        return !!Object.keys(initialData || {})?.length || (Array.isArray(initialData) && !!initialData?.length)
            ? {
                  pages: [initialData],
                  pageParams: [undefined]
              }
            : undefined
    }, [initialData])

    const queryKey = useQueryKey({
        type: 'list',
        resource,
        params: { query: searchQuery, limit: perPage, current: currentPageRef.current }
    })

    const resourceHasUndefined = detectUndefined(resource)

    const {
        data: searchData,
        hasNextPage: canLoadMore,
        fetchNextPage: loadMore,
        refetch
    } = useInfiniteQuery(
        queryKey,
        async ({ pageParam = 0 }) => {
            if (disableBlock || resourceHasUndefined) {
                return []
            }
            const { data } = await httpClient({
                url: encodeURI(`${baseApiURL}/combined/${resource}`),
                method: options?.method || 'post',
                headers: {
                    'content-type': 'application/json',
                    'x-client-name': clientName
                },
                data: {
                    // limit: pageIndex ? limit : limit * initial,
                    limit: perPage,
                    page: pageParam + 1,
                    ...searchQuery
                }
            }).catch(handleError(errorHandlers))

            if (Array.isArray(data)) {
                // data.forEach(item => {
                //     const key = [`combined/entity/${item.id}`]
                //     queryClient.setQueryData(key, item, {
                //         ...appQuerySettings,
                //         staleTime: 0
                //     })
                // })
                return data
            }
            return []
        },
        {
            ...appQuerySettings,
            enabled,
            keepPreviousData: true,
            initialData: formattedInitialData,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            getNextPageParam: (lastGroup, _allGroups) => {
                if (!lastGroup?.length) {
                    return null
                }
                if (lastGroup.length < perPage) {
                    return null
                }
                return currentPageRef.current - 1
            }
        }
    )

    const searchResults = useMemo(
        () => (searchData?.pages || []).reduce((all, group) => [...all, ...group], []),
        [searchData?.pages]
    )

    const formatedData = useMemo(() => searchResults.map(formatProperties), [searchResults])

    return {
        data: formatedData,
        canLoadMore: typeof canLoadMore === 'undefined' ? true : canLoadMore,
        loadMore: page => {
            currentPageRef.current = page
            loadMore()
        },
        refetch
    }
}
export const getListElastic = async (
    resource,
    searchQuery = {},
    perPage = 30,
    options = {},
    injectedQueryClient = undefined
) => {
    const currentPage = options.currentPage || 1
    const baseApiURL = getBaseApiURL()
    const { disableBlock = false, enabled = true } = options

    if (disableBlock || !enabled) {
        return {
            data: null
        }
    }

    const queryClient = injectedQueryClient || createQueryClient()
    const queryKey = getQueryKey({
        type: 'list',
        resource,
        params: { query: searchQuery, limit: perPage, current: currentPage }
    })

    const resourceHasUndefined = detectUndefined(resource)

    const searchData = await queryClient.fetchInfiniteQuery(
        queryKey,
        async ({ pageParam = 0 }) => {
            if (resourceHasUndefined) {
                return []
            }

            const method = options?.method?.toLowerCase() || 'post'

            const { data } = await httpClient({
                url: encodeURI(`${baseApiURL}/combined/${resource}`),
                method,
                headers: {
                    'content-type': 'application/json',
                    'x-client-name': clientNameSSR
                },
                ...(method === 'post' && {
                    data: {
                        limit: perPage,
                        page: pageParam + 1,
                        ...searchQuery
                    }
                })
            })

            if (Array.isArray(data)) {
                // data.forEach(item => {
                //     const key = [`combined/entity/${item.id}`]
                //     queryClient.setQueryData(key, item, {
                //         ...appQuerySettings,
                //         staleTime: 0
                //     })
                // })
                return data
            }

            return []
        },
        {
            ...appQuerySettings,
            keepPreviousData: true,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            getNextPageParam: (lastGroup, _allGroups) => {
                if (!lastGroup.length) {
                    return null
                }
                if (lastGroup.length < perPage) {
                    return null
                }
                return currentPage - 1
            }
        }
    )

    const searchResults = (searchData?.pages || []).reduce((all, group) => [...all, ...group], [])

    const formatedData = searchResults.map(formatProperties)

    return {
        data: formatedData
    }
}

export const getOneElastic = async (resource, options = {}, injectedQueryClient = undefined) => {
    const { preview = false, disableBlock = false, enabled = true, resourcePrefix = 'combined' } = options
    const baseApiURL = getBaseApiURL()
    const previewQuery = preview ? '?preview=true' : ''

    if (disableBlock || !enabled) {
        return {
            data: null
        }
    }

    const queryClient = injectedQueryClient || createQueryClient()
    const queryKey = getQueryKey({ type: 'one', resource })

    const resourceHasUndefined = detectUndefined(resource)

    const searchData = await queryClient.fetchQuery(
        queryKey,
        async () => {
            if (resourceHasUndefined) {
                return []
            }

            const { data } = await httpClient({
                url: encodeURI(
                    `${baseApiURL}/${resourcePrefix?.length > 0 ? `${resourcePrefix}/` : ''}${resource}${previewQuery}`
                ),
                method: 'get',
                headers: {
                    'x-client-name': clientNameSSR
                }
            })

            return data
        },
        {
            ...appQuerySettings
        }
    )

    return {
        data: formatProperties(searchData)
    }
}
/**
 * Sets data in query cache
 * @param resource {string}
 * @param data {any}
 * @param injectedQueryClient {QueryClient}
 */
export const setOneElastic = (resource, data, injectedQueryClient) => {
    const queryClient = injectedQueryClient || createQueryClient()

    const queryKey = getQueryKey({ type: 'one', resource })
    queryClient.setQueryData(queryKey, data)
}

export const postOneElastic = async (resource, payload = {}, injectedQueryClient = undefined) => {
    const baseApiURL = getBaseApiURL()
    const resourceUri = [`combined/${resource}`]
    const queryClient = injectedQueryClient || createQueryClient()
    queryClient.cancelQueries(resourceUri)

    const resourceHasUndefined = detectUndefined(resource)

    const getResponseData = async () => {
        if (resourceHasUndefined) {
            return []
        }

        const { data } = await httpClient({
            url: encodeURI(`${baseApiURL}/${resourceUri}`),
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'x-client-name': clientNameSSR
            },
            data: payload
        })

        return data
    }

    try {
        const data = await getResponseData()
        setOneElastic(resource, data)
        return data
    } catch (e) {
        //
    }
    return null
}
