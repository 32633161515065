// @TODO: Handle add image functionality, this is unfinished
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBaseApiURL } from '@hmn/rtl-web-core/hooks'

import { ReviewerBadgeIcon } from '../../../Icon'
import { CameraIcon, Icon } from '../Icon'
import styles from './AvatarUpload.style'

const AvatarUploadStyled = styled.div(props => ({ ...styles(props) }))

function AvatarUpload({ image, onComplete, className, isTester, ...rest }) {
    const baseApiURL = useBaseApiURL()

    const onDrop = useCallback(
        acceptedFiles => {
            const doUpload = async file => {
                if (!file) {
                    return
                }
                const formData = new FormData()
                formData.append('file', file)
                const response = await fetch(`${baseApiURL}/storage/upload/avatar`, {
                    method: 'POST',
                    body: formData
                })
                if (!response.ok) {
                    return
                }
                const avatarUrl = await response.json()

                onComplete(encodeURI(avatarUrl.url))
            }
            doUpload(acceptedFiles[0])
        },
        [onComplete, baseApiURL]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        maxSize: 0.5 * 1024 * 1024,
        multiple: false,
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg']
        },
        onDropRejected: fileRejections => {
            if (fileRejections.length) {
                // eslint-disable-next-line no-alert
                alert(fileRejections[0].errors[0].message)
            }
        }
    })

    return (
        <AvatarUploadStyled className={className} {...rest}>
            <div className="avatarUpload_imageWrap">
                {image && (
                    <Image
                        src={`https:${image}`}
                        width="140"
                        height="140"
                        alt="Avatar"
                        className="avatarUpload_image"
                    />
                )}
            </div>
            <div className="avatarUpload_icon" {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon icon={CameraIcon} />
            </div>
            {isTester && (
                <div className="avatarUpload_testerica_icon" title="Testerica">
                    <Icon icon={ReviewerBadgeIcon} />
                </div>
            )}
        </AvatarUploadStyled>
    )
}

AvatarUpload.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    onComplete: PropTypes.func,
    className: PropTypes.string,
    isTester: PropTypes.bool
}

AvatarUpload.defaultProps = {
    image: undefined,
    onComplete: undefined,
    className: undefined,
    isTester: false
}

export default withErrorBoundary(AvatarUpload, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileAvatarUpload]: ', error, componentStack)
    }
})
