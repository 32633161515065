import { useCallback, useEffect, useRef } from 'react'

import { useNativeAdDispatch } from '../../../../dispatchers/ads'
import { useRouteChangeStart } from '../../../../hooks/useRouteChange'
import useWindowAttachedValue from '../../../../hooks/useWindowAttachedValue'
import { parseRevealerData } from './Revealer.helpers'
import { RevealerData } from './Revealer.interface'
// import mockDataRevealer from './Revealer.mock'

export const INJECTED_REVEALER_SCRIPTS_VARIABLE_NAME = 'inj_revel_script'
export const CLEAR_REVEALER_CALLBACK_KEY = 'clearRevealer'
const RevealerDataProvider = () => {
    const setData = useNativeAdDispatch('revealer')

    const dataEraseTimeout = useRef<number>()

    const setRevealerCallback = useCallback(
        (e: RevealerData) => {
            // console.log('event', e)
            // @TODO: implement error Revealer with message in case google admanager preview query param is set
            // if it errors on regular client, just log the error
            const parsed = parseRevealerData(e)
            // console.log('Revealer data', { sent: e, parsed })
            if (dataEraseTimeout.current) window.clearTimeout(dataEraseTimeout.current)
            setData(parsed)
        },
        [setData]
    )

    const clearRevealerCallback = useCallback(() => {
        if (typeof window !== 'undefined') {
            window[INJECTED_REVEALER_SCRIPTS_VARIABLE_NAME] = {}
        }
        setData({ open: false }, false)
        dataEraseTimeout.current = window.setTimeout(() => {
            setData(null)
        }, 2000)
    }, [setData])

    useRouteChangeStart(clearRevealerCallback)

    // useEffect(() => {
    //     setRevealerCallback(mockDataRevealer)
    // }, [setRevealerCallback])

    // important so the template can attach itself to the app
    useWindowAttachedValue(setRevealerCallback, 'setRevealer')
    // important so Revealer can close itself via close button
    useWindowAttachedValue(clearRevealerCallback, CLEAR_REVEALER_CALLBACK_KEY)
    return null
}

export default RevealerDataProvider
