/* eslint-disable camelcase */
import { memo } from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import Head from 'next/head'
import PropTypes from 'prop-types'

import ImageAuthor from '@hmn/rtl-web-core/components/Image/ImageAuthor.component'
import getVariationData from '@hmn/rtl-web-core/helpers/image-variation'
import { useImagesConfig } from '@hmn/rtl-web-core/hooks'
import { globalSettings } from '@hmn/rtl-web-core/settings'

import { ProgressiveImageStyled } from './components/ProgressiveImage/ProgressiveImage.style'
import styles from './Image.style'

const { constants } = globalSettings
const { IMAGES_TRANSITION_DURATION_MS } = constants

const imageRatioVariants = Object.freeze({
    CUSTOM_SQUARE: {
        aspect_ratio_name: 'Ratio 1:1',
        id: 'cbf7fd72-38e2-11eb-b297-0242ac15000f',
        name: '[custom] Square',
        slug: 'custom-square'
    },
    CUSTOM_4x5: {
        aspect_ratio_name: 'Ratio 4:5',
        id: 'a861ff3c-38e4-11eb-8556-0242ac15000f',
        method: 'fill',
        name: '[custom] 4:5',
        slug: 'custom-4-5'
    },
    CUSTOM_LANDSCAPE: {
        aspect_ratio_name: 'Ratio 3:2',
        id: 'db3ec0a2-38e4-11eb-949e-0242ac15000f',
        method: 'fill',
        name: '[custom] 3:2',
        slug: 'custom-3-2'
    },
    CUSTOM_PORTRAIT: {
        aspect_ratio_name: 'Ratio 2:3',
        id: '6bb44fb8-38e4-11eb-b16c-0242ac15000f',
        method: 'fill',
        name: '[custom] 2:3',
        slug: 'custom-2-3'
    },
    CUSTOM_ORIGINAL: {
        aspect_ratio_name: null,
        id: '2dd14004-38e2-11eb-a900-0242ac15000f',
        method: 'fit',
        name: '[custom] Original aspect ratio',
        slug: 'custom-original-aspect-ratio'
    }
})

const ImageStyled = styled.picture(props => ({ ...styles(props) }))

const GalleryImageStyled = styled.div(props => ({ ...styles(props) }))

function Image({
    image,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder: initialPlaceholder,
    placeholderBgColor,
    type: initialType,
    width: initialWidth,
    height: initialHeight,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    method: initialMethod,
    variation: initialVariation,
    alt,
    title,
    className,
    classNameProgressive,
    rounded,
    lazyLoad,
    ratio: initialRatio,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fullHeight,
    hideAuthor,
    updatedAt,
    preload,
    isGalleryLazyLoadImage,
    useLargestInstance,
    original,
    ...other
}) {
    const isAmp = useAmp()
    const { imagesConfig: variations = [], isLoaded } = useImagesConfig()

    const { source, placeholder, ratio, width, height, srcSetData, max_width, max_height } = getVariationData(
        image,
        initialWidth,
        initialHeight,
        initialRatio,
        initialVariation,
        variations,
        original,
        imageRatioVariants,
        isLoaded,
        isAmp,
        useLargestInstance,
        initialType,
        updatedAt
    )

    if (isGalleryLazyLoadImage) {
        return (
            <ProgressiveImageStyled
                className={classNameProgressive}
                imageHeight={height}
                imageWidth={width}
                imageMaxWidth={max_width} // @NOTE: not used at the moment
                imageMaxHeight={max_height} // @NOTE: not used at the moment
                ratio={ratio}>
                <GalleryImageStyled
                    imageWidth={width}
                    imageHeight={height}
                    className={className}
                    rounded={rounded}
                    placeholderBgColor={placeholderBgColor}
                    withPlaceholder
                    transitionDuration={IMAGES_TRANSITION_DURATION_MS}
                    {...other}>
                    {srcSetData ? (
                        <img
                            data-src={source}
                            data-srcset={`${srcSetData?.webp}, ${srcSetData?.jpeg}`}
                            alt={alt || title || 'Image'}
                            className="swiper-lazy"
                            height={height}
                            width={width}
                        />
                    ) : (
                        <img
                            data-src={source}
                            alt={alt || title || 'Image'}
                            className="swiper-lazy"
                            height={height}
                            width={width}
                        />
                    )}

                    <ImageAuthor
                        source={image?.source || image?.extended_attributes?.source}
                        hideAuthor={hideAuthor}
                        author={image?.extended_attributes?.author || image?.source}
                    />
                </GalleryImageStyled>
            </ProgressiveImageStyled>
        )
    }

    return (
        <ProgressiveImageStyled
            className={classNameProgressive}
            imageHeight={height}
            imageWidth={width}
            imageMaxWidth={max_width} // @NOTE: not used at the moment
            imageMaxHeight={max_height} // @NOTE: not used at the moment
            ratio={ratio}>
            <ImageStyled
                imageWidth={width}
                imageHeight={height}
                className={className}
                rounded={rounded}
                isImageError={!source}
                {...other}>
                <>
                    {srcSetData && (
                        <>
                            {srcSetData?.webp && (
                                <source type="image/webp" srcSet={srcSetData.webp} sizes={srcSetData?.sizes} />
                            )}
                            {srcSetData?.jpeg && (
                                <source srcSet={srcSetData.jpeg} type="image/jpeg" sizes={srcSetData?.sizes} />
                            )}
                        </>
                    )}
                    <img
                        src={placeholder || '/image-placeholder-zena.svg'}
                        alt={alt || title || 'Image'}
                        loading={lazyLoad ? 'lazy' : undefined}
                        height={`${parseInt(height, 10)}px`}
                        width={`${parseInt(width, 10)}px`}
                    />
                    {preload && (
                        <Head>
                            {srcSetData ? (
                                <link
                                    rel="preload"
                                    as="image"
                                    href={placeholder}
                                    imageSrcSet={`${srcSetData.webp}, ${srcSetData.jpeg}`}
                                    imageSizes={srcSetData.sizes}
                                />
                            ) : (
                                <link
                                    rel="preload"
                                    as="image"
                                    href={placeholder}
                                    imageSizes="(min-width: 768px) calc(700px), calc(100vw + 380px)"
                                />
                            )}
                        </Head>
                    )}
                </>
                <ImageAuthor
                    source={image?.source || image?.extended_attributes?.source}
                    hideAuthor={hideAuthor}
                    author={image?.extended_attributes?.author || image?.source}
                />
            </ImageStyled>
        </ProgressiveImageStyled>
    )
}

const imageRoundedVariants = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    ROUNDED: 'rounded'
})

const imageFillVariants = Object.freeze({
    FILL: 'fill',
    FIT: 'fit'
})

const imageTypeVariants = Object.freeze({
    JPG: 'jpg',
    WEBP: 'webp'
})

Image.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([...Object.values(imageTypeVariants)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    method: PropTypes.oneOf([...Object.values(imageFillVariants)]),
    variation: PropTypes.oneOfType([PropTypes.shape({}), () => null]),
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    classNameProgressive: PropTypes.string,
    rounded: PropTypes.oneOf([...Object.values(imageRoundedVariants)]),
    lazyLoad: PropTypes.bool,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fullHeight: PropTypes.bool,
    placeholderBgColor: PropTypes.string,
    hideAuthor: PropTypes.bool,
    updatedAt: PropTypes.string,
    preload: PropTypes.bool,
    isGalleryLazyLoadImage: PropTypes.bool,
    useLargestInstance: PropTypes.bool,
    original: PropTypes.bool
}

Image.defaultProps = {
    image: undefined,
    placeholder: undefined,
    type: undefined,
    width: 480,
    height: 480,
    method: 'fill',
    variation: imageRatioVariants.CUSTOM_LANDSCAPE,
    alt: undefined,
    title: undefined,
    className: undefined,
    classNameProgressive: undefined,
    rounded: undefined,
    lazyLoad: true,
    ratio: undefined,
    fullHeight: false,
    placeholderBgColor: undefined,
    hideAuthor: false,
    updatedAt: undefined,
    preload: undefined,
    isGalleryLazyLoadImage: false,
    useLargestInstance: undefined,
    original: false
}

const imagePropsAreEqual = (prevImage, nextImage) =>
    prevImage?.image === nextImage?.image && prevImage?.ratio === nextImage?.ratio

export { imageRoundedVariants, imageRatioVariants }

export default memo(Image, imagePropsAreEqual)
