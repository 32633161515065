/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

// viewBox="0 0 24 24"
const CloseCircle = createSvgIcon(
    <>
        <path
            d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
            stroke="#000000"
            fill="rgba(255,255,255,0.8)"
            strokeWidth="2"
        />
        <path d="M9 9L15 15M15 9L9 15" stroke="#000000" strokeWidth="2" strokeLinecap="round" />
    </>,
    'CloseCircle'
)

export default CloseCircle
