/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ArrowRight = createSvgIcon(
    <path d="M24.171 17H4v-2h20.171l-3.293-3.293 1.415-1.414L28 16l-5.707 5.707-1.415-1.414L24.171 17z" />,
    'ArrowRight'
)

export default ArrowRight
