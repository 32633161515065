// @TODO: Implement close button functionality
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { CloseIcon, Icon } from '../Icon'
import NavigationItem from './Item/Item.component'
import styles from './Navigation.style'

const NavigationStyled = styled.div(props => ({ ...styles(props) }))

function Navigation({ primaryMenu, handleCloseProfile, ...rest }) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')

    if (!primaryMenu) {
        return null
    }

    return (
        <NavigationStyled {...rest}>
            <div className="navigation_wrapper">
                {primaryMenu.map((link, index) => (
                    <NavigationItem key={uid(link.id || index)} link={link} />
                ))}
            </div>
            <button className="navigation_close" type="button" onClick={handleCloseProfile}>
                <Icon size={isDesktop ? 40 : 30} icon={CloseIcon} />
            </button>
        </NavigationStyled>
    )
}

Navigation.propTypes = {
    primaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    handleCloseProfile: () => {}
}

Navigation.defaultProps = {
    primaryMenu: undefined,
    handleCloseProfile: undefined
}

export default withErrorBoundary(Navigation, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileNavigation]: ', error, componentStack)
    }
})
