import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: toRem(50),
    background: theme.colors.profile.background.lighter,
    [theme.breakpoints.up('lg')]: {
        height: toRem(65)
    },
    '& .navigation_wrapper': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: toRems([0, 25]),
        [theme.breakpoints.up('lg')]: {
            marginLeft: '10%'
        }
    },
    '& .navigation_close': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: toRem(65),
        height: toRem(65),
        color: theme.colors.profile.navigation.text.light,
        transitionDuration: theme.transitions.default.duration,
        transitionTimingFunction: theme.transitions.default.timingFunction,
        transitionDelay: theme.transitions.default.delay,
        transitionProperty: 'color',
        background: theme.colors.profile.background.light,
        [theme.breakpoints.up('lg')]: {
            '&:hover': {
                color: theme.colors.profile.navigation.text.hover
            }
        },
        [theme.breakpoints.down('md')]: {
            width: toRem(50),
            height: toRem(50),
            position: 'absolute',
            top: 0,
            right: 0,
            color: theme.colors.profile.navigation.text.contrast,
            background: theme.colors.profile.background.tertiary
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
})

export default style
