import { useMemo } from 'react'

import type { Ad } from '../EternalAds.interface'

export const useAdElementHref = (ad: Ad, element: { clickUrl?: string; hasGlobalClickTag?: boolean }) =>
    useMemo(() => {
        const { CLICK_URL_UNESC: globalClickUrl } = ad
        const { clickUrl, hasGlobalClickTag = true } = element
        if (!clickUrl) {
            return globalClickUrl
        }
        return (
            (!hasGlobalClickTag && clickUrl) ||
            `${globalClickUrl.replace('&adurl=', '')}&adurl=${encodeURIComponent(clickUrl)}`
        )
    }, [ad, element])
