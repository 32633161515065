/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const SocialUrl = createSvgIcon(
    <path d="M 16 0 A 16 16 0 0 0 0 16 A 16 16 0 0 0 16 32 A 16 16 0 0 0 32 16 A 16 16 0 0 0 16 0 z M 19.650391 9 C 21.497791 9 22.9999 10.502209 23 12.349609 C 23 13.244309 22.650278 14.08605 22.017578 14.71875 L 19.09375 17.644531 C 18.46105 18.277331 17.619209 18.625 16.724609 18.625 C 15.830309 18.625 14.989722 18.276431 14.357422 17.644531 L 15.59375 16.40625 C 16.19875 17.01045 17.250469 17.01035 17.855469 16.40625 L 20.78125 13.480469 C 21.08335 13.177969 21.25 12.776909 21.25 12.349609 C 21.25 11.467409 20.532591 10.75 19.650391 10.75 C 19.223091 10.75 18.820078 10.91665 18.517578 11.21875 L 16.892578 12.84375 C 16.386378 12.64135 15.835436 12.53125 15.273438 12.53125 C 15.069637 12.53125 14.871275 12.562044 14.671875 12.589844 L 17.28125 9.9804688 C 17.91395 9.3477287 18.755691 9 19.650391 9 z M 15.273438 13.408203 C 16.141213 13.408091 17.010028 13.723569 17.642578 14.355469 L 16.40625 15.59375 C 15.80125 14.98965 14.747478 14.98965 14.142578 15.59375 L 11.21875 18.519531 C 10.91665 18.821931 10.75 19.223191 10.75 19.650391 C 10.75 20.532691 11.467309 21.25 12.349609 21.25 C 12.776809 21.25 13.177969 21.08335 13.480469 20.78125 L 15.089844 19.171875 C 15.600644 19.386075 16.154009 19.5 16.724609 19.5 C 16.917509 19.5 17.105069 19.470613 17.292969 19.445312 L 14.71875 22.019531 C 14.08595 22.652331 13.244309 23 12.349609 23 C 10.502209 23 9 21.497791 9 19.650391 C 9 18.755691 9.3476987 17.91405 9.9804688 17.28125 L 12.90625 14.355469 C 13.539 13.722719 14.405662 13.408316 15.273438 13.408203 z " />,
    'SocialUrl'
)

export default SocialUrl
