import { useCallback, useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'

import dataProvider from '@hmn/data-provider'

import { appQuerySettings } from '../queries/utils'
import { globalSettings } from '../settings'
import { createQueryClient, getQueryKey, useQueryKey } from './useData'

const { reactQuerySettings } = globalSettings
const createQueryRequest =
    (reqUri, reqParams) =>
    async ({ pageParam: pageOffset = 0 }) => {
        if (pageOffset === false) {
            return null
        }

        const pageStart = reqParams.pagination?.page || 1
        const pageCurrent = pageStart + pageOffset

        const { data, total } = await dataProvider.getList(reqUri, {
            ...reqParams,
            pagination: {
                ...reqParams.pagination,
                page: pageCurrent
            }
        })

        const count = data?.length || 0
        return {
            data,
            total,
            count,
            pageOffset,
            pageStart,
            pageCurrent
        }
    }

/**
 * Hook for fetching list of items for resource
 *
 * @param {string} resource
 * @param {Object} params
 * @returns {Object}
 */

// @TODO: finish and test infinite scroll with real data
const useList = (resource, params = {}, options = {}) => {
    const { enabled = true } = options
    const queryKey = useQueryKey({ type: 'list', resource, params })
    const queryRequest = useMemo(() => createQueryRequest(resource, params), [resource, params])
    const {
        data: result,
        isFetching,
        error,
        status,
        refetch,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        isSuccess
    } = useInfiniteQuery(queryKey, queryRequest, {
        getNextPageParam: (lastGroup, allGroups) => {
            if (!lastGroup.count) {
                return false
            }
            if (typeof lastGroup.total !== 'undefined') {
                const totalCount = allGroups.reduce((all, group) => all + group.count, 0)
                if (totalCount >= lastGroup.total) {
                    return false
                }
            }
            return lastGroup.pageOffset + 1
        },
        enabled,
        ...reactQuerySettings
    })

    const data = useMemo(
        () => result?.pages.reduce((all, page) => [...all, ...(page?.data || [])], []),
        [result?.pages]
    )

    const loadMore = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage()
        }
    }, [hasNextPage])

    return {
        data,
        total: result?.pages?.[0]?.total,
        status,
        error,
        loading: isFetching,
        isSuccess,
        loadMore,
        canLoadMore: !isFetchingNextPage && hasNextPage,
        refetch
    }
}

export const getList = async (resource, params = {}, injectedQueryClient = undefined, enabled = true) => {
    const queryClient = injectedQueryClient || createQueryClient()
    if (!enabled) {
        return []
    }
    const queryKey = getQueryKey({ type: 'list', resource, params })
    const queryRequest = createQueryRequest(resource, params)
    const result = await queryClient.fetchInfiniteQuery(queryKey, queryRequest, {
        ...appQuerySettings
    })

    const data = result?.pages.reduce((all, page) => [...all, ...(page?.data || [])], [])

    return {
        data
    }
}

export const setList = (resource, data, params = {}, injectedQueryClient = undefined) => {
    const queryClient = injectedQueryClient || createQueryClient()
    const queryKey = getQueryKey({ type: 'list', resource, params })
    queryClient.setQueryData(queryKey, data, {
        ...appQuerySettings
    })
}

export default useList
