/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-properties */
const { sqrt } = Math
const { sin } = Math
const { cos } = Math
const { PI } = Math
const c1 = 1.70158
const c2 = c1 * 1.525
const c3 = c1 + 1
const c4 = (2 * PI) / 3
const c5 = (2 * PI) / 4.5

const bounceOut = x => {
    const n1 = 7.5625
    const d1 = 2.75

    if (x < 1 / d1) {
        return n1 * x * x
    }
    if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75
    }
    if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375
    }
    return n1 * (x -= 2.625 / d1) * x + 0.984375
}

export const linear = {
    easeNone: x => x
}

export const quad = {
    easeIn: x => x * x,
    easeOut: x => 1 - (1 - x) * (1 - x),
    easeInOut: x => (x < 0.5 ? 2 * x * x : 1 - (-2 * x + 2) ** 2 / 2)
}

export const cubic = {
    easeIn: x => x * x * x,
    easeOut: x => 1 - (1 - x) ** 3,
    easeInOut: x => (x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2)
}

export const quart = {
    easeIn: x => x * x * x * x,
    easeOut: x => 1 - (1 - x) ** 4,
    easeInOut: x => (x < 0.5 ? 8 * x * x * x * x : 1 - (-2 * x + 2) ** 4 / 2)
}

export const quint = {
    easeIn: x => x * x * x * x * x,
    easeOut: x => 1 - (1 - x) ** 5,
    easeInOut: x => (x < 0.5 ? 16 * x * x * x * x * x : 1 - (-2 * x + 2) ** 5 / 2)
}

export const sine = {
    easeIn: x => 1 - cos((x * PI) / 2),
    easeOut: x => sin((x * PI) / 2),
    easeInOut: x => -(cos(PI * x) - 1) / 2
}

export const expo = {
    easeIn: x => (x === 0 ? 0 : 2 ** (10 * x - 10)),
    easeOut: x => (x === 1 ? 1 : 1 - 2 ** (-10 * x)),
    easeInOut: x => (x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? 2 ** (20 * x - 10) / 2 : (2 - 2 ** (-20 * x + 10)) / 2)
}

export const circ = {
    easeIn: x => 1 - sqrt(1 - x ** 2),
    easeOut: x => sqrt(1 - (x - 1) ** 2),
    easeInOut: x => (x < 0.5 ? (1 - sqrt(1 - (2 * x) ** 2)) / 2 : (sqrt(1 - (-2 * x + 2) ** 2) + 1) / 2)
}

export const back = {
    easeIn: x => c3 * x * x * x - c1 * x * x,
    easeOut: x => 1 + c3 * (x - 1) ** 3 + c1 * (x - 1) ** 2,
    easeInOut: x =>
        x < 0.5
            ? ((2 * x) ** 2 * ((c2 + 1) * 2 * x - c2)) / 2
            : ((2 * x - 2) ** 2 * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2
}

export const elastic = {
    easeIn: x => (x === 0 ? 0 : x === 1 ? 1 : -(2 ** (10 * x - 10)) * sin((x * 10 - 10.75) * c4)),
    easeOut: x => (x === 0 ? 0 : x === 1 ? 1 : 2 ** (-10 * x) * sin((x * 10 - 0.75) * c4) + 1),
    easeInOut: x =>
        x === 0
            ? 0
            : x === 1
            ? 1
            : x < 0.5
            ? -(2 ** (20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2
            : (2 ** (-20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1
}

export const bounce = {
    easeIn: x => 1 - bounceOut(1 - x),
    easeOut: bounceOut,
    easeInOut: x => (x < 0.5 ? (1 - bounceOut(1 - 2 * x)) / 2 : (1 + bounceOut(2 * x - 1)) / 2)
}
