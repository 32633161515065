import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { signIn, signOut } from 'next-auth/react'
import PropTypes from 'prop-types'

import dataProvider, { AwsS3StorageService } from '@hmn/data-provider'

import useBaseApiURL from '../../hooks/useBaseApiURL'
import UserContext from './User.context'

const humanOAuthProviderId = process.env.NEXT_PUBLIC_HUMAN_API_OAUTH_PROVIDER_ID
const humanRegisterProviderId = process.env.NEXT_PUBLIC_HUMAN_API_REGISTER_PROVIDER_ID
const publicReadOnlyTokenNetHr = process.env.NEXT_PUBLIC_HUMAN_API_READONLY_TOKEN_NET
const publicReadOnlyTokenZenaHr = process.env.NEXT_PUBLIC_HUMAN_API_READONLY_TOKEN_ZENA

const { publicRuntimeConfig = {} } = getConfig()
const { xClientName: currentClient = process.env.NEXT_PUBLIC_API_CLIENT } = publicRuntimeConfig

const MutationAction = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
}

const revalidateDelay = delay =>
    new Promise(resolve => {
        setTimeout(resolve, delay)
    })

/**
 * Try to revalidate user session multiple times using existing access token, to avoid any sync issues and accidental premature logouts.
 *
 * @param {*} { times = 5, intervalMs = 5000, callback, onError }
 * @return {*}
 */
const revalidateSession = async ({ times = 3, intervalMs = 1000, callback, onError }) => {
    if (times < 1) {
        throw new Error(
            // eslint-disable-next-line max-len
            `[zena][revalidate-session]: Bad argument, 'times' must be greater than 0, ${times} was used instead.`
        )
    }

    let attemptsCount = 0

    let result

    while (attemptsCount < times && !result?.id) {
        try {
            // eslint-disable-next-line no-await-in-loop
            result = await callback()

            if (result?.error) {
                throw new Error()
            }
        } catch (error) {
            attemptsCount += 1

            if (attemptsCount >= times) {
                onError(result)
                break
            }

            // eslint-disable-next-line no-await-in-loop
            await revalidateDelay(intervalMs)
        }
    }

    return result
}

function UserContextProvider({ children, session }) {
    const queryClient = useQueryClient()
    const baseApiURL = useBaseApiURL({ api: 'human', externalOnly: true })
    const [success, setSuccess] = useState(false)
    const [profile, setProfile] = useState()
    const { push } = useRouter()

    useEffect(() => {
        if (session && Object.keys(session || {})?.length) {
            const { accessToken } = session
            if (accessToken) {
                AwsS3StorageService.init({ fetch })

                // @WARNING: This is potentially dangerous because DataProvider is singleton.
                dataProvider.init({
                    baseUrl: baseApiURL,
                    language: 'hr',
                    token: accessToken,
                    storageService: AwsS3StorageService
                })
            } else {
                dataProvider.setToken(currentClient === 'zenahr' ? publicReadOnlyTokenZenaHr : publicReadOnlyTokenNetHr)
                signOut()
            }
        } else {
            dataProvider.setToken(currentClient === 'zenahr' ? publicReadOnlyTokenZenaHr : publicReadOnlyTokenNetHr)
        }
    }, [session])

    const queryKey = useMemo(() => ['users', 'me', session?.id], [session])

    const signOutCallback = useCallback(({ redirect = false, isErrorLogout = false } = {}) => {
        signOut({ redirect })
        setProfile()
        setSuccess(false)

        if (isErrorLogout) {
            push('/')
        } else {
            push(
                `${process.env.NEXT_PUBLIC_HUMAN_API_LOGOUT_URL}&redirect_uri=${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}`
            )
        }
    }, [])

    const {
        data: profileData,
        remove: invalidateUserData,
        isLoading
    } = useQuery(
        queryKey,
        async () =>
            revalidateSession({
                callback: async () => {
                    const { accessToken } = session || {}

                    if (!accessToken) {
                        throw new Error()
                    }

                    const response = await fetch(`${baseApiURL}/users/me`, {
                        method: 'get',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Accept-Language': ''
                        }
                    })

                    const rsp = (await response.json()) || {}

                    if (session?.id === rsp?.id) {
                        AwsS3StorageService.init({ fetch })

                        // @WARNING: This is potentially dangerous because DataProvider is singleton.
                        dataProvider.init({
                            baseUrl: baseApiURL,
                            language: 'hr',
                            token: accessToken,
                            storageService: AwsS3StorageService
                        })
                    }

                    rsp.status = response?.status

                    return rsp
                },
                onError: profileError => {
                    if (
                        (profileError?.status && (profileError?.status === 401 || profileError?.status === '401')) ||
                        profileError?.error === 'invalid_grant'
                    ) {
                        signOutCallback({ isErrorLogout: true })
                    }
                }
            }),
        {
            onSuccess: () => setSuccess(true),
            onError: () => {
                signOutCallback({ isErrorLogout: true })
            },
            enabled: process.browser,
            retry: false,
            refetchOnWindowFocus: true,
            refetchOnMount: false
        }
    )

    useEffect(() => {
        setProfile(profileData)
    }, [profileData])

    const { mutateAsync: userMutation } = useMutation(
        async ({ id, data, action }) => {
            switch (action) {
                case MutationAction.UPDATE:
                    return dataProvider.update('users', {
                        id,
                        data
                    })
                default:
                    return Promise.reject(new Error('Action has to be provided'))
            }
        },
        {
            onMutate: ({ data, action }) => {
                queryClient.cancelQueries(queryKey)

                const previousValue = queryClient.getQueryData(queryKey)

                switch (action) {
                    case MutationAction.UPDATE:
                        queryClient.setQueryData(queryKey, (cache = null) => ({
                            ...cache,
                            ...data
                        }))
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.info('Action has to be provided')
                        break
                }
                return previousValue
            },
            onError: (_, __, previousValue) => {
                queryClient.setQueryData(queryKey, previousValue)
            },
            onSettled: (data, error, variables) => {
                queryClient.invalidateQueries(queryKey)

                const { callback } = variables
                if (typeof callback === 'function') {
                    callback(error, data)
                }
            }
        }
    )

    const userValidation = user => {
        if (!user?.extended_attributes) {
            return false
        }

        const {
            extended_attributes: { first_name: name, last_name: surname, birthday, city, sex }
        } = user
        const facebookItem = user?.account?.identities?.filter(item => item.provider_name === 'facebook')
        return (
            !!(name || facebookItem?.first_name) &&
            !!(surname || facebookItem?.last_name) &&
            !!birthday &&
            !!city &&
            !!sex
        )
    }

    const value = useMemo(
        () => ({
            profile,
            avatar: profile?.public_url?.replace(/^https?:/i, ''),
            success,
            update: async (data, callback) =>
                userMutation({ id: profile.id, data, action: MutationAction.UPDATE, callback }),
            isTester: profile?.roles?.includes('ROLE_CLIENT_CUSTOM_PRODUCT_TESTER'),
            isValid: userValidation(profile),
            isLoggedIn: !!session && !!profile,
            logIn: callbackUrl => signIn(humanOAuthProviderId, { callbackUrl }),
            register: callbackUrl => signIn(humanRegisterProviderId, { callbackUrl }),
            isLoading,
            logOut: () => {
                signOutCallback()
                invalidateUserData()
            }
        }),
        [profile, success, session]
    )

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string,
        accessToken: PropTypes.string
    })
}

UserContextProvider.defaultProps = {
    session: undefined
}

export default UserContextProvider
