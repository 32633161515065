import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import ExternalAds from '@hmn/rtl-web-core/components/Ads/External/ExternalAds.component'
import { useNavigationOpenState } from '@hmn/rtl-web-core/context/navigation'
import createIsolatedHook from '@hmn/rtl-web-core/helpers/createIsolatedHook'
import { useNavigation } from '@hmn/rtl-web-core/hooks'
import useUpdatedStateValue from '@hmn/rtl-web-core/hooks/functional/useUpdatedStateValue'
import useBaseLayout, { useBaseLayoutScroll } from '@hmn/rtl-web-core/hooks/useBaseLayout'

import { Footer } from '@hmn/rtl-zena-ui/components/Footer'
import { Header } from '@hmn/rtl-zena-ui/components/Header'

import style from './BaseLayout.style'
import Content from './Content.component'

const LayoutStyled = styled.main(props => ({ ...style(props) }))

const useBaseLayoutHooks = ({ isGalleryPage }) => {
    useBaseLayout('zena', isGalleryPage)
    useBaseLayoutScroll()
}
const IsolatedUseBaseLayoutHooks = createIsolatedHook(useBaseLayoutHooks)

function BaseLayout({ children, navigationData: navigationDataIn, isGalleryPage = false }) {
    const isAmp = useAmp()
    const [isNavigationOpen] = useNavigationOpenState()
    const navigationData = useUpdatedStateValue(navigationDataIn, newData => typeof newData === 'object')

    const [primaryMenu, secondaryMenu] = useNavigation(navigationData, ['primary', 'secondary'])

    return (
        <LayoutStyled isAmp={isAmp} isNavigationOpen={isNavigationOpen}>
            <IsolatedUseBaseLayoutHooks isGalleryPage={isGalleryPage} />
            {(primaryMenu || secondaryMenu) && <Header primaryMenu={primaryMenu} secondaryMenu={secondaryMenu} />}

            <ExternalAds
                wallpaperProps={{
                    minHeight: 0
                }}
            />

            <Content>{children}</Content>

            {(primaryMenu || secondaryMenu) && <Footer primaryMenu={primaryMenu} secondaryMenu={secondaryMenu} />}
        </LayoutStyled>
    )
}

BaseLayout.propTypes = {
    navigationData: PropTypes.shape({}),
    isGalleryPage: PropTypes.bool
}

BaseLayout.defaultProps = {
    navigationData: undefined,
    isGalleryPage: false
}

export default BaseLayout
