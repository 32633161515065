import React, { memo } from 'react'
import Script from 'next/script'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary.component'

function TelegramPostController({ url }) {
    return (
        <>
            <blockquote className="telegram-post" data-telegram-post={url.split('/t.me/')[1]} data-width="100%" />
            <Script
                id="telegram-script"
                src="https://telegram.org/js/telegram-widget.js?19"
                targetSelector=".telegram-post"
            />
        </>
    )
}

TelegramPostController.propTypes = {
    url: PropTypes.string
}

TelegramPostController.defaultProps = {
    url: undefined
}

export default withErrorBoundary(memo(TelegramPostController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[TelegramPostController]: ', error, componentStack)
    }
})
