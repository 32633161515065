import { useEffect } from 'react'

const useConsentCallback = callback => {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }
        window.googlefc = window.googlefc || {}
        window.googlefc.ccpa = window.googlefc.ccpa || {}
        window.googlefc.callbackQueue = window.googlefc.callbackQueue || []
        window.googlefc.callbackQueue.push({
            // eslint-disable-next-line no-underscore-dangle
            CONSENT_DATA_READY: () => window.__tcfapi('getTCData', 0, (data, success) => callback(data, success))
        })
    }, [])
}

export default useConsentCallback
