import dataProvider from '@hmn/data-provider'

export const getList = async (resource, params) => {
    const { entity, entityId } = params

    const response = await dataProvider.getListTaxon(resource, {
        entity,
        entityId
    })

    return response.data
}

export const createOne = async (resource, params) => {
    const { entity, entityId, taxonId } = params

    const response = await dataProvider.createTaxon(resource, {
        entity,
        entityId,
        data: { taxon: taxonId }
    })

    return response.data
}

export const deleteOne = async (resource, params) => {
    const { entity, entityId, taxonId } = params

    const response = await dataProvider.deleteTaxon(resource, {
        entity,
        entityId,
        id: taxonId
    })

    return response.data
}
