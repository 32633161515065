import dayjs from 'dayjs'
/**
 * Converts valid date to hash-like string
 *
 * @param {*} date
 * @return {*}
 */
const getHashFromDate = date => {
    if (date && dayjs(date).isValid()) {
        return Number(new Date(date).getTime()).toString(36)
    }

    return date
}
export default getHashFromDate
