const UUID_REGEXP = '[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}'
const SLUG_REGEXP = '[0-9A-Z\\-]{0,}[0-9A-Z]{0,}'
const INT_REGEXP = '[1-9][0-9]*'
const PAGE_REGEXP = 'stranica-[0-9]{1,2}'
const YEAR_REGEXP = '[0-9]{3,4}'
const DATE_REGEXP = '[0-9]{1,2}'
const ARCHIVE_REGEXP = '[0-9A-Za-z\\-\\/]{1,}/arhiva/[0-9]{3,4}/[0-9]{1,2}'

// eslint-disable-next-line import/no-commonjs
module.exports = {
    UUID_REGEXP,
    SLUG_REGEXP,
    INT_REGEXP,
    PAGE_REGEXP,
    YEAR_REGEXP,
    DATE_REGEXP,
    ARCHIVE_REGEXP
}
