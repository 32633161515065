import React, { createContext, useEffect, useMemo, useState } from 'react'

import throttle from '../../helpers/throttle'

const WindowContext = createContext({
    width: 0,
    height: 0,
    position: 0,
    direction: 'down'
})

const getSize = () => {
    if (!process.browser) {
        return {
            width: 0,
            height: 0
        }
    }
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}

const getScroll = previousValue => {
    if (!process.browser) {
        return {
            position: 0,
            direction: 'down'
        }
    }
    const position = window.scrollY
    let direction = 'down'
    if (typeof previousValue?.position === 'number' && position < previousValue.position) {
        direction = 'up'
    }
    return {
        position,
        direction
    }
}

function WindowContextProvider({ children }) {
    const [size, setSize] = useState(getSize)
    const [scroll, setScroll] = useState(getScroll)

    const onResize = () => {
        setSize(getSize())
    }

    const onScroll = () => {
        setScroll(state => getScroll(state))
    }

    useEffect(() => {
        if (!process.browser) {
            return () => {}
        }
        const onResizeThrottled = throttle(onResize, 250)
        window.addEventListener('resize', onResizeThrottled)
        onResizeThrottled()
        const onScrollThrottled = throttle(onScroll, 250)
        window.addEventListener('scroll', onScrollThrottled)

        return () => {
            window.removeEventListener('resize', onResizeThrottled)
        }
    }, [])

    const value = useMemo(
        () => ({
            width: size.width,
            height: size.height,
            position: scroll.position,
            direction: scroll.direction
        }),
        [size, scroll]
    )
    return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>
}

export { WindowContextProvider }

export default WindowContext
