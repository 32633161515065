import { DidomiSDK, OnReadyFunction } from '@didomi/react'
import useTimeout from '@rooks/use-timeout'
import { ComponentProps, PropsWithChildren } from 'react'

import createFastContext from '../context/createFastContext'

type DidomiProviderProps = PropsWithChildren<ComponentProps<typeof DidomiSDK>> & { enabled: boolean }

type DidomiState = {
    ready: boolean
}

const initialState: DidomiState = {
    ready: false
}

const DidomiContext = createFastContext(initialState)

export const useDidomiReady = () => DidomiContext.useSelector((state: DidomiState) => state.ready)

const useDidomiStateDispatch = () => DidomiContext.useDispatch()

export const DidomiProvider = ({ children, enabled = true, ...didomiProps }: DidomiProviderProps) => {
    return (
        <DidomiContext.Provider value={(!enabled && { ready: true }) || undefined}>
            {enabled && <DidomiProviderInternal {...didomiProps} />}
            {children}
        </DidomiContext.Provider>
    )
}

const DidomiProviderInternal = ({ onReady, ...didomiProps }: Omit<DidomiProviderProps, 'children' | 'enabled'>) => {
    const dispatchDidomiState = useDidomiStateDispatch()
    const onReadyInternal: OnReadyFunction = didomi => {
        onReady?.(didomi)
        dispatchDidomiState({ ready: true })
    }
    // @TODO: add and connect consent later

    useTimeout(() => {
        dispatchDidomiState({ ready: true })
    }, 3000) // @NOTE: safety timeout 3 seconds

    return (
        <DidomiContext.Provider>
            <DidomiSDK {...didomiProps} onReady={onReadyInternal} />
        </DidomiContext.Provider>
    )
}
