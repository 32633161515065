import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { createWrapperHOC } from '../../helpers/components/createWrapperHOC'
import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'
import styles from './AntiClsFrame.style'

const AntiClsFrameStyled = styled.div(props => ({ ...styles(props) }))

function AntiClsFrame({
    children,
    maxHeightMd,
    maxHeightXs,
    maxWidthMd,
    maxWidthXs,
    minHeightMd,
    minHeightXs,
    marginMd,
    marginXs,
    ratio,
    width,
    displayMd,
    displayXs,
    pointerEvents,
    ...rest
}) {
    const isRatio = typeof ratio === 'number'

    if (!minHeightXs && !maxHeightXs && !minHeightMd && !maxHeightMd && typeof ratio !== 'number') {
        return children
    }

    return (
        <AntiClsFrameStyled
            className={clsx('cls_frame', isRatio && 'aspect_ratio_frame')}
            maxHeightMd={maxHeightMd}
            maxHeightXs={maxHeightXs}
            maxWidthMd={maxWidthMd}
            maxWidthXs={maxWidthXs}
            minHeightMd={minHeightMd}
            minHeightXs={minHeightXs}
            marginMd={marginMd}
            marginXs={marginXs}
            ratio={ratio}
            widthProp={width}
            displayMd={displayMd}
            displayXs={displayXs}
            pointerEventsProp={pointerEvents}
            {...rest}>
            {children}
        </AntiClsFrameStyled>
    )
}

AntiClsFrame.propTypes = {
    maxHeightMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeightXs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidthMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidthXs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minHeightMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minHeightXs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginXs: PropTypes.string,
    marginMd: PropTypes.string,
    ratio: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    displayMd: PropTypes.oneOf(['block', 'inline-block', 'inline', 'none']),
    displayXs: PropTypes.oneOf(['block', 'inline-block', 'inline', 'none']),
    pointerEvents: PropTypes.bool
}

AntiClsFrame.defaultProps = {
    maxHeightMd: undefined,
    maxHeightXs: undefined,
    maxWidthMd: undefined,
    maxWidthXs: undefined,
    minHeightMd: undefined,
    minHeightXs: undefined,
    marginXs: undefined,
    marginMd: undefined,
    ratio: undefined,
    width: '100%',
    displayMd: 'block',
    displayXs: 'block',
    pointerEvents: true
}

const AntiClsFrameWithErrorBoundary = withErrorBoundary(AntiClsFrame, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[AntiClsFrame]: ', error, componentStack)
    }
})

export default AntiClsFrameWithErrorBoundary

export const withAntiClsFrame = createWrapperHOC(AntiClsFrame, 'withAntiClsFrame')
