import { useMemo } from 'react'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import stripHtml from '@hmn/rtl-web-core/helpers/js/stripHtmlTags'
import truncate from '@hmn/rtl-web-core/helpers/js/truncateString'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import parseSeoDescription from '@hmn/rtl-web-core/helpers/seo/js/parseSeoDescription'
import { processOpenGraphImages } from '@hmn/rtl-web-core/helpers/seo/js/processOpenGraphImages'
import { useSeoImages } from '@hmn/rtl-web-core/hooks'

import {
    // dateToSeo,
    organizationStructuredData,
    sitemapToBreadcrumbs,
    StructuredData
} from '../..'

const generateWebPageStructuredData = (
    item,
    imageUrl = `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`
) => {
    const title = truncate(stripHtml(item?.title))
    const description = parseSeoDescription(item?.description)
    // @TODO find out what to do with date on static pages
    // const publishDate = dateToSeo(item?.published_at || item?.updated_at)
    // const updateDate = dateToSeo(item?.updated_at)
    const keywords = item?.breadcrumb
    return {
        name: title,
        image: imageUrl,
        '@type': 'WebPage',
        headline: truncate(title, 110, '...'),
        description,
        inLanguage: 'Croatian',
        thumbnailUrl: imageUrl,
        // dateCreated: publishDate,
        // datePublished: publishDate,
        // dateModified: updateDate,
        publisher: organizationStructuredData,
        keywords,
        about: description
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Seo({ item, images, hasAmp, shareUrl }) {
    const { imageMeta } = useSeoImages(images, {
        url: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA || ''}/images/share/default.jpg`,
        width: 1200,
        height: 630
    })
    const { seo, structuredData } = useMemo(() => {
        if (!item) {
            return {}
        }
        const title = truncate(stripHtml(item?.title))
        const description = parseSeoDescription(item?.description)

        const author = 'Žena.hr'

        // const updateDate = dateToSeo(item?.updated_at)

        const itemHref = (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) || item?.href || ''
        const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`

        const breadcrumbs = sitemapToBreadcrumbs(item?.path)

        return {
            seo: {
                title,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title,
                    description,
                    type: 'website',
                    images: processOpenGraphImages(imageMeta, title)
                    // updateTime: updateDate
                },
                twitter: {
                    creator: author
                },
                robotsProps: {
                    maxImagePreview: 'large',
                    maxVideoPreview: 10
                }
            },
            structuredData: [
                {
                    '@context': 'https://schema.org',
                    ...organizationStructuredData
                },
                breadcrumbs && {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    name: title,
                    description,
                    itemListElement: breadcrumbs
                },
                {
                    '@context': 'https://schema.org',
                    ...generateWebPageStructuredData(item, imageMeta?.[0]?.url),
                    mainEntityOfPage: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
                }
            ].filter(Boolean),
            href: `${process.env.NEXT_PUBLIC_APP_ROOT_ZENA}${itemHref}`
        }
    }, [item, imageMeta, shareUrl])
    return (
        <>
            {seo && <NextSeo {...seo} />}
            {structuredData && <StructuredData data={structuredData} />}
        </>
    )
}
Seo.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,

        href: PropTypes.string,
        path: PropTypes.arrayOf(
            PropTypes.shape({
                slug: PropTypes.string,
                title: PropTypes.string
            })
        ),
        breadcrumb: PropTypes.arrayOf(PropTypes.string)
    }),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ]),
    hasAmp: PropTypes.bool,
    shareUrl: PropTypes.string
}

Seo.defaultProps = {
    item: undefined,
    images: undefined,
    hasAmp: false,
    shareUrl: undefined
}

export { generateWebPageStructuredData }
export default withErrorBoundary(Seo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[WebPageSeo]: ', error, componentStack)
    }
})
