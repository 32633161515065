import { round } from '../math'
import isNumeric from '../utils/isNumeric'

/**
 * Convert pixels to rem using root font size
 * @param size
 * @param fontSize
 * @returns {string}
 */
const convertPxToRem = (size, fontSize = 16) => (isNumeric(size) ? `${round(size / fontSize, 5)}rem` : size)

export default convertPxToRem
