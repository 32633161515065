import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { InitialLoadActions } from '../actions/initialLoad'

const useInitialLoadDispatch = () => {
    const dispatch = useDispatch()
    const setEnvironment = useCallback(
        environment => dispatch(InitialLoadActions.setEnvironment(environment)),
        [dispatch]
    )

    return {
        setEnvironment
    }
}

export default useInitialLoadDispatch
