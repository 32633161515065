import { useCallback, useState } from 'react'
import { queryCache } from 'react-query'

import dataProvider from '@hmn/data-provider'

const useUpdateOne = (resource, id) => {
    const [isUpdated, setIsUpdated] = useState(false)
    const update = useCallback(
        async data => {
            await dataProvider.update(resource, { id, data })
            setIsUpdated(true)
            queryCache.invalidateQueries([resource, { id }])
        },
        [resource, id]
    )

    return {
        update,
        isUpdated
    }
}

export default useUpdateOne
