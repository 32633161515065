import { useEffect, useMemo, useRef } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

// expanded snippet
// (function(window, document, scriptTag, dataLayerName, id) {
//     window[dataLayerName] = window[dataLayerName] || [];
//     window[dataLayerName].push({
//         'gtm.start': new Date().getTime(),
//         event: 'gtm.js'
//     });
//     var firstScriptTag = document.getElementsByTagName(scriptTag)[0],
//         gtmScriptTag = document.createElement(scriptTag),
//         dataLayerQuery = dataLayerName != 'dataLayer' ? '&l=' + dataLayerName : '';
//     gtmScriptTag.async = true;
//     gtmScriptTag.src =
//         'https://www.googletagmanager.com/gtm.js?id=' + id + dataLayerQuery;
//     firstScriptTag.parentNode.insertBefore(gtmScriptTag, firstScriptTag);
// })(window, document, 'script', 'dataLayer', '${process.env.NEXT_PUBLIC_GTM_NET}');

function GoogleTagManager({ id, dataLayerName }) {
    const src = useMemo(
        () =>
            id &&
            `https://www.googletagmanager.com/gtm.js?id=${id}${
                dataLayerName !== 'dataLayer' ? `&l=${dataLayerName}` : ''
            }`,
        [id, dataLayerName]
    )

    // Set data layer
    const dataLayerMounted = useRef(null)
    useEffect(() => {
        const name = dataLayerName || 'dataLayer'
        if (name === dataLayerMounted.current) {
            return
        }
        if (!window[dataLayerName]) {
            window[dataLayerName] = []
        }
        dataLayerMounted.current = name
    }, [])

    // Mount basic gtm event
    const gtmMounted = useRef(false)
    useEffect(() => {
        if (gtmMounted.current) {
            return
        }
        window[dataLayerName] = window[dataLayerName] || []
        window[dataLayerName].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        })
        gtmMounted.current = true
    }, [])

    if (!id || !src) {
        return null
    }

    return <Script async src={src} />
}
GoogleTagManager.propTypes = {
    id: PropTypes.string,
    dataLayerName: PropTypes.string
}

GoogleTagManager.defaultProps = {
    id: undefined,
    dataLayerName: 'dataLayer'
}
export default GoogleTagManager
