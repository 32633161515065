const style = ({ theme, circleSize, strokeWidthCircle, percentage, variant }) => {
    const perimeter = (circleSize - strokeWidthCircle) * Math.PI
    const strokeDashoffset = `${perimeter * ((100 - percentage) / 100)}`

    const config = {
        variant: {
            alpha: {
                angle: 5,
                strokeDashArray: [47, 3, 47].map(percent => `${perimeter * (percent / 100)}`).join(' ')
            },
            beta: {
                angle: -90,
                strokeDashArray: perimeter
            }
        }
    }

    return {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        '& .svgCircle': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            '& circle': {
                stroke: theme.colors.comments.circle.stroke,
                strokeWidthCircle,
                strokeDashoffset,
                strokeDasharray: config?.variant[variant]?.strokeDashArray,
                fill: 'transparent',
                transformOrigin: '50% 50%',
                transform: `rotate(${config?.variant[variant]?.angle}deg)`
            }
        },
        '&.avatar_circle_header': {
            [theme.breakpoints.up('md')]: {
                width: '33%'
            }
        }
    }
}

export default style
