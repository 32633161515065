const constants = {
    SET_AD_MANIFEST: 'ADS/SET_AD_MANIFEST',
    DISPLAY_DFP_SLOT: 'ADS/DISPLAY_DFP_SLOT',
    CLEAR_DISPLAYED_DFP_SLOT: 'ADS/CLEAR_DISPLAYED_DFP_SLOT',
    CLEAR_DISPLAYED_DFP_SLOTS: 'ADS/CLEAR_DISPLAYED_DFP_SLOTS',
    DFP_SLOT_FILLED: 'ADS/DFP_SLOT_FILLED',
    DFP_SLOTS_FILLED: 'ADS/DFP_SLOTS_FILLED',
    CLEAR_FILLED_DFP_SLOTS: 'ADS/CLEAR_FILLED_DFP_SLOTS',
    REFRESH_DFP_SLOT: 'ADS/REFRESH_DFP_SLOT',
    INARTICLE_AD_MOUNTED: 'ADS/INARTICLE_AD_MOUNTED',
    SET_NATIVE_AD_DATA: 'ADS/SET_NATIVE_AD_DATA',
    MODIFY_NATIVE_AD_DATA: 'ADS/MODIFY_NATIVE_AD_DATA',
    SET_NATIVE_POSITION_FILLED: 'ADS/SET_NATIVE_POSITION_FILLED',
    SET_VOYO_DATA: 'ADS/SET_VOYO_DATA_LOADED',
    CLEAR_NATIVE_ADS: 'ADS/CLEAR_NATIVE_ADS',
    SET_CUSTOM_FILLED_STATE: 'ADS/SET_CUSTOM_FILLED_STATE'
}

const actions = {
    setAdManifest: manifest => ({ type: constants.SET_AD_MANIFEST, manifest }),
    displayDfpSlot: ({ adZone, targetingArguments, maxAdWidth, divId }) => ({
        type: constants.DISPLAY_DFP_SLOT,
        adZone,
        targetingArguments,
        maxAdWidth,
        divId
    }),
    setNativeAdData: ({ data, nativeType }) => ({
        type: constants.SET_NATIVE_AD_DATA,
        nativeData: data,
        nativeType
    }),
    modifyNativeAdData: ({ data, nativeType }) => ({
        type: constants.MODIFY_NATIVE_AD_DATA,
        nativeData: data,
        nativeType
    }),
    setVoyoData: voyoData => ({ type: constants.SET_VOYO_DATA, voyoData }),
    setCustomFilledState: (key, isFilled) => ({
        type: constants.SET_CUSTOM_FILLED_STATE,
        key,
        isFilled
    }),
    clearNativeAds: nativeType => ({ type: constants.CLEAR_NATIVE_ADS, nativeType }),
    clearDisplayedDfpSlot: adZone => ({ type: constants.CLEAR_DISPLAYED_DFP_SLOT, adZone }),
    clearDisplayedDfpSlots: () => ({ type: constants.CLEAR_DISPLAYED_DFP_SLOTS }),
    dfpSlotFilled: ({ divId, slotFilled }) => ({ type: constants.DFP_SLOT_FILLED, divId, slotFilled }),
    dfpSlotsFilled: divsWithStatus => ({ type: constants.DFP_SLOTS_FILLED, divsWithStatus }),
    clearFilledDfpSlots: () => ({ type: constants.CLEAR_FILLED_DFP_SLOTS }),
    refreshDfpSlot: divId => ({ type: constants.REFRESH_DFP_SLOT, divId }),
    inarticleAdMounted: () => ({ type: constants.INARTICLE_AD_MOUNTED })
}

export { constants as AdsActionTypes, actions as AdsActions }
