import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Hook to communicate among browser contexts (windows, tabs, iframes).
 * Example use case: when user presses log out in one tab, log user out from every other tab.
 *
 * Usage:
 *     1. import { useBrowserCommunication } from '../hooks'
 *     2. Pass a channel name:
 *
 *          const [communicationState, postMessage] = useBrowserCommunication('auth-channel')
 *
 *     3. `communicationState` contains `lastMessage` and `messages` which is an array of the messages that were sent from
 *        other tabs or windows to the current one.
 *     4. `postMessage` is used to send messages to other browser contexts (windows, tabs, iframes), e.g.:
 *
 *         const [isLoggedIn, setLoggedIn] = useState(true)
 *
 *         const logout = () => {
 *            setLoggedIn(false)
 *            postMessage('logout')
 *         }
 *
 *         const shouldLogout = [communicationState.lastMessage, status].includes('logout')
 *
 * @param {String} channelName
 */
const useBrowserCommunication = channelName => {
    const [state, setMessages] = useState({
        lastMessage: undefined,
        messages: []
    })

    const [supportsBroadcastAPI, setSupportsBroadcastAPI] = useState(false)

    useEffect(() => {
        setSupportsBroadcastAPI(window.BroadcastChannel)
    })

    const channel = useRef(null)

    if (channelName === 'undefined') {
        throw Error(`'channelName' parameter is required, e.g. useBrowserCommunication('some-channel')`)
    }

    if (supportsBroadcastAPI) {
        channel.current = new BroadcastChannel(channelName)
    }

    const postMessage = useCallback(
        message => {
            if (message) {
                const msg = JSON.stringify({ message, time: Date.now() })
                if (supportsBroadcastAPI && channel && channel.current) {
                    channel.current.postMessage(msg)
                } else {
                    window.localStorage.setItem(channelName, msg)
                }
            }
        },
        [channelName, supportsBroadcastAPI]
    )

    const updateState = message => {
        setMessages(prevState => ({
            lastMessage: message,
            messages: [...prevState.messages, message]
        }))
    }

    useEffect(() => {
        const updateFromLocalStorage = event => {
            try {
                const data = JSON.parse(event.newValue)
                if (data) {
                    const { message } = data
                    updateState(message)
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('[useBrowserCommunication]: Failed to parse JSON from LocalStorage.')
            }
        }

        if (supportsBroadcastAPI && channel && channel.current) {
            channel.current.onmessage = event => updateState(JSON.parse(event.data))
        } else {
            window.addEventListener('storage', updateFromLocalStorage)
        }

        return () => {
            if (channel && channel.current) {
                channel.current.close()
                channel.current = null
            } else {
                window.localStorage.removeItem(channelName)
                window.removeEventListener('storage', updateFromLocalStorage)
            }
        }
    }, [channelName, supportsBroadcastAPI])

    return [state, postMessage]
}

export default useBrowserCommunication
