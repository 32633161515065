import { useCallback } from 'react'

import { ExternalAd, MappedVia, ZoneName } from '../../types/ads/Ad.interface'
import createFastContext from '../createFastContext'

type NativeZonesStore = MappedVia<(typeof ExternalAd)[keyof typeof ExternalAd], any>
const nativeZonesInitialState: NativeZonesStore = {}
export const NativeZonesContext = createFastContext(nativeZonesInitialState)

export const useNativeZones = () => {
    const [state, setState] = NativeZonesContext.useStore()
    return [
        state,
        (nativeZones: Partial<NativeZonesStore>, overwrite = false) => {
            setState(nativeZones, overwrite)
        }
    ]
}

export const useAdZoneNative = (zoneName: ZoneName) => {
    const zoneNameSelector = useCallback((store: NativeZonesStore) => store[zoneName], [zoneName])
    const [state, setState] = NativeZonesContext.useStore(zoneNameSelector)
    const nativeAdZone = useCallback(
        (nativeSettings: any) => {
            setState({ [zoneName]: nativeSettings })
        },
        [zoneName]
    )
    return [state, nativeAdZone]
}
