import { useEffect, useRef } from 'react'

const useUpdatedRef = <T>(value: T) => {
    const ref = useRef<T>(value)
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref
}

export default useUpdatedRef
