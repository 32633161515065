/* eslint-disable camelcase, max-len */
import { useState } from 'react'
import styled from '@emotion/styled'
// @TODO: TextField from scratch without material-ui!
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../Button'
import styles from './Head.style'

const HeadStyled = styled.div(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Head({ id, username, onNameChange, label, className, ...rest }) {
    const [isEditing, setIsEditing] = useState(false)
    const [newUsername, setNewUsername] = useState('')
    const [inputError, setInputError] = useState(null)
    const labelHtmlProps = useInnerHtml(label)
    const handleOpenEditor = () => {
        setNewUsername(username || '')
        setIsEditing(true)
    }
    const handleChange = e => {
        setNewUsername(e.target.value)
        setInputError('')
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (!newUsername) {
            setInputError('Moraš ispuniti')
            return
        }
        if (typeof onNameChange === 'function') {
            onNameChange(newUsername)
        }
        setIsEditing(false)
    }

    if (isEditing) {
        return (
            <form onSubmit={handleSubmit} autoComplete="off" target="_top">
                <TextField type="text" value={newUsername} onChange={handleChange} variant="standard" />
                {inputError ? <span>{inputError}</span> : null}
                <Button type="submit" variant={buttonVariants.BETA}>
                    Spremi
                </Button>
            </form>
        )
    }
    return (
        <HeadStyled className={className} {...rest}>
            <div className="globalProfileHead">
                {label && <div className="globalProfileHead_label" {...labelHtmlProps} />}
                <div className="globalProfileHead_username">{username}</div>
                <Button type="button" onClick={handleOpenEditor} title="Promijeni" variant={buttonVariants.BETA}>
                    Promijeni
                </Button>
            </div>
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    onNameChange: PropTypes.func,
    username: PropTypes.string,
    id: PropTypes.string
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    onNameChange: undefined,
    username: undefined,
    id: undefined
}

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileHead]: ', error, componentStack)
    }
})
