import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import {
    FacebookIcon,
    Icon,
    InstagramIcon,
    MessengerIcon,
    TikTokIcon,
    TwitterIcon,
    UrlIcon,
    ViberIcon,
    WhatsAppIcon
} from '../../../Icon'
import { Link } from '../../../Link'
import styles from './Item.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

const getIconFromUrl = url => {
    if (url.includes('twitter.com')) {
        return TwitterIcon
    }
    if (url.includes('facebook.com')) {
        return FacebookIcon
    }
    if (url.includes('instagram.com')) {
        return InstagramIcon
    }
    if (url.includes('tiktok.com')) {
        return TikTokIcon
    }
    return null
}

const getIconFromName = name => {
    switch (name.toString().toLowerCase()) {
        case 'twitter':
            return TwitterIcon
        case 'facebook':
            return FacebookIcon
        case 'tiktok':
            return TikTokIcon
        case 'instagram':
            return InstagramIcon
        case 'whatsapp':
            return WhatsAppIcon
        case 'viber':
            return ViberIcon
        case 'messenger':
            return MessengerIcon
        case 'url':
            return UrlIcon
        default:
            return null
    }
}

// @TODO: handle button title, most probably needed as a prop or just do something general based on social network

function Item({ icon, href, hrefIsExternal, onClick, color, ...rest }) {
    const IconComponent = getIconFromName(icon) || getIconFromUrl(href)
    const hasOnClick = typeof onClick === 'function'

    const title = useMemo(() => {
        if (!icon) {
            return ''
        }
        if (icon === 'url') {
            return 'Kopiraj link'
        }
        return `${icon.charAt(0).toUpperCase() + icon.slice(1)}`
    }, [icon])

    if (!IconComponent || (!href && !hasOnClick)) {
        return null
    }
    return (
        <ItemStyled {...rest} colorVariant={color}>
            <ConditionalWrapper
                condition={!!href && !hasOnClick}
                Wrapper={Link}
                title={title}
                href={href}
                isExternal={hrefIsExternal}>
                <ConditionalWrapper
                    condition={hasOnClick}
                    Wrapper="button"
                    title={title}
                    aria-label={title}
                    type="button"
                    onClick={onClick}>
                    <Icon icon={IconComponent} />
                </ConditionalWrapper>
            </ConditionalWrapper>
        </ItemStyled>
    )
}

const socialItemColorVariants = Object.freeze({
    META: 'meta',
    FOOTER: 'footer'
})

Item.propTypes = {
    icon: PropTypes.oneOf(['facebook', 'instagram', 'tiktok', 'twitter', 'viber', 'whatsapp', 'url', 'messenger', '']),
    href: PropTypes.string,
    hrefIsExternal: PropTypes.bool,
    onClick: PropTypes.func,
    color: PropTypes.oneOf([...Object.values(socialItemColorVariants)])
}

Item.defaultProps = {
    icon: undefined,
    href: undefined,
    hrefIsExternal: false,
    onClick: undefined,
    color: socialItemColorVariants.META
}

export { socialItemColorVariants }
export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SocialItem]: ', error, componentStack)
    }
})
