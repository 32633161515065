import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Graphic, graphicVariants } from '../Decoration/components'
import { Icon, RtlHrLogo, ZenaHrLogo } from '../Icon'
import { Link } from '../Link'
import { Menu, menuVariants } from '../Navigation/components/Menu'
import { SocialFooterBar } from '../Social/components'
import styles from './Footer.style'

const FooterStyled = styled.footer(props => ({ ...styles(props) }))

function ZenaHrFooterLogo() {
    return (
        <Link href="/" as="/" className="logo" title="Naslovnica - Žena.hr">
            <Icon icon={ZenaHrLogo} viewBox="0 0 103 25" />
        </Link>
    )
}

function Footer({ graphicVariant, primaryMenu, secondaryMenu, ...rest }) {
    const socialLinks = [
        { href: 'https://www.facebook.com/www.zena.hr', icon: 'facebook' },
        { href: 'https://twitter.com/zenahr', icon: 'twitter' },
        { href: 'https://www.instagram.com/zenahr/', icon: 'instagram' }
        // { href: 'https://www.tiktok.com/en/', icon: 'tiktok' }
    ]

    const gtmData = {
        eventCategory: 'Navigation',
        eventLabel: 'Navigation Item',
        eventValue: 'Footer'
    }

    return (
        <FooterStyled {...rest}>
            {graphicVariant && <Graphic variant={graphicVariant} className="footerGraphic" />}
            <div className="container">
                <div className="footerContent">
                    <div className="logoColumn">
                        <ZenaHrFooterLogo />
                    </div>
                    <div className="menuColumn">
                        <Menu links={primaryMenu} variant={menuVariants.GAMMA} columns={3} gtmData={gtmData} />
                        <Menu links={secondaryMenu} variant={menuVariants.DELTA} gtmData={gtmData} />
                    </div>
                    <div className="socialColumn">
                        <SocialFooterBar links={socialLinks} />
                    </div>
                </div>
                <div className="footerBottom">
                    <Link href="/" as="/" className="rtl" title="RTL - Hrvatska">
                        <Icon icon={RtlHrLogo} viewBox="00 0 90 30" />
                    </Link>

                    <ZenaHrFooterLogo />
                    <span className="signature">{new Date().getFullYear()}. Sva prava pridržana.</span>
                </div>
            </div>
        </FooterStyled>
    )
}

Footer.propTypes = {
    graphicVariant: PropTypes.oneOf([...Object.values(graphicVariants)]),
    primaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    secondaryMenu: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Footer.defaultProps = {
    graphicVariant: graphicVariants.IOTA,
    primaryMenu: undefined,
    secondaryMenu: undefined
}

export default withErrorBoundary(Footer, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Footer]: ', error, componentStack)
    }
})
