import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { Link } from '../Link'
import styles from './Button.style'

const ButtonStyled = styled.button(props => ({ ...styles(props) }))

function Button({
    children,
    buttonRef,
    role,
    type,
    title,
    href,
    hrefAs,
    target,
    className,
    disabled,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onDragLeave,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    simple,
    width,
    widthMobile,
    loading,
    dataAttrs,
    iconComponent: Icon,
    variant,
    ...rest
}) {
    if (!children && !Icon) {
        return null
    }

    const getDataAttrs = () => {
        if (!dataAttrs) {
            return null
        }
        return Object.keys(dataAttrs).reduce((attrs, key) => {
            attrs[`data-${key}`] = dataAttrs[key]
            return attrs
        }, {})
    }

    const icon = Icon && <span className="btn_icon">{Icon}</span>
    const spinner = <span className="spinner" />

    return (
        <ConditionalWrapper condition={!!href} Wrapper={Link} href={href} as={hrefAs} title={title} passHref noTag>
            <ButtonStyled
                ref={buttonRef}
                role={role}
                as={href ? 'a' : 'button'}
                href={href}
                target={href ? target : undefined}
                rel={href && target === '_blank' ? 'noopener noreferrer' : undefined}
                type={!href ? type : undefined}
                className={clsx(
                    {
                        disabled
                    },
                    className
                )}
                title={title}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onDragLeave={onDragLeave}
                onTouchEnd={onTouchEnd}
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStart}
                simple={simple}
                buttonWidth={width}
                buttonWidthMobile={widthMobile}
                isLoading={loading}
                iconOnly={!children}
                {...getDataAttrs()}
                variant={variant}
                {...rest}>
                {children}
                {loading ? spinner : icon}
            </ButtonStyled>
        </ConditionalWrapper>
    )
}

const buttonVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Button.propTypes = {
    buttonRef: PropTypes.string,
    role: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    onDragLeave: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchStart: PropTypes.func,
    simple: PropTypes.bool,
    width: PropTypes.string,
    widthMobile: PropTypes.string,
    loading: PropTypes.bool,
    dataAttrs: PropTypes.shape({}),
    iconComponent: PropTypes.node,
    variant: PropTypes.oneOf([...Object.values(buttonVariants)])
}

Button.defaultProps = {
    buttonRef: undefined,
    role: undefined,
    type: 'button',
    className: undefined,
    disabled: false,
    title: undefined,
    href: undefined,
    hrefAs: undefined,
    target: undefined,
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    onMouseDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseUp: () => {},
    onDragLeave: () => {},
    onTouchEnd: () => {},
    onTouchMove: () => {},
    onTouchStart: () => {},
    simple: false,
    width: undefined,
    widthMobile: undefined,
    loading: false,
    dataAttrs: undefined,
    iconComponent: undefined,
    variant: buttonVariants.ALPHA
}

export { buttonVariants }

export default withErrorBoundary(Button, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Button]: ', error, componentStack)
    }
})
