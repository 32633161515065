import { ParsedUrlQuery } from 'querystring'

import { INT_REGEXP } from '../next-config/regexp'

// eslint-disable-next-line
const pageRegex = new RegExp(`^${INT_REGEXP}$`, 'gi')

export interface Options {
    isArchive?: boolean
}

const getCurrentPageNumber = (query: ParsedUrlQuery, fallbackPageNumber = 1, options: Options = {}): number => {
    const { pageNumber, stranica, slugs = [] } = query || {}
    const { isArchive } = options

    const lastSlug = slugs[slugs.length - 1]

    if (lastSlug?.match(pageRegex) && !isArchive) {
        return parseInt(lastSlug, 10)
    }

    if (stranica) {
        const parsedStranica = Array.isArray(stranica) ? stranica[0] : stranica

        return parseInt(parsedStranica, 10) || fallbackPageNumber
    }

    if (!pageNumber) return fallbackPageNumber

    const parsedPageNumber = Array.isArray(pageNumber) ? pageNumber[0] : pageNumber

    // @NOTE: paging occurs in one of two cases:
    // - either a prev-next article where pageNumber is just string number eg. "9"
    // - or a listing category type page with pageNumber as string with text, eg. "stranica-9"
    const stringNumber = /stranica/.exec(parsedPageNumber)
        ? parsedPageNumber.slice(9, parsedPageNumber.length)
        : parsedPageNumber

    return parseInt(stringNumber, 10) || fallbackPageNumber
}

export default getCurrentPageNumber
