import { toRem } from '../../../../helpers/theme'

const style = ({ variant }) => {
    const config = {
        alpha: {
            marginLeft: 0
        },
        beta: {
            marginLeft: toRem(-10)
        },
        gamma: {
            marginLeft: 0
        },
        delta: {
            marginLeft: toRem(-10)
        },
        epsilon: {
            marginLeft: toRem(-10)
        }
    }

    return {
        overflow: 'hidden',
        letterSpacing: 'initial',
        '.itemContainer': {
            marginLeft: config[variant]?.marginLeft || 0
        }
    }
}

export default style
