import { colorsDark, paletteDark } from '../globals/colors'
import { zenaDefault } from './zenaDefault'

export const zenaDark = {
    ...zenaDefault,
    colors: {
        ...paletteDark,
        ...colorsDark
    }
}
