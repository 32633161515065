import { toRem, toRems } from '../../helpers/theme'

const style = ({ theme }) => ({
    '& .headerWrapper': {
        width: '100%',
        position: 'relative',
        height: toRem(theme.global.header.height.xs),
        pointerEvents: 'none',
        background: theme.colors.background.body.secondary,
        zIndex: theme.zIndex.header,
        [theme.breakpoints.up('md')]: {
            height: toRem(theme.global.header.height.md)
        }
    },
    '& .header': {
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: theme.zIndex.header,
        textAlign: 'center',
        background: theme.colors.header.background,
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            background: 'none',
            borderBottom: 'none',
            boxSizing: 'border-box',
            height: toRem(theme.global.header.height.md)
        },
        '& > *': {
            pointerEvents: 'all'
        },
        '&_logo_label': {
            display: 'none'
        },
        '&_logo': {
            position: 'relative',
            display: 'inline-block',
            width: toRem(90),
            height: '100%',
            top: toRem(15),
            left: 'auto',
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                width: toRem(130),
                left: toRem(46),
                top: toRem(52)
            },
            '& svg': {
                width: toRem(90),
                height: toRem(22),
                [theme.breakpoints.up('md')]: {
                    width: toRem(130),
                    height: toRem(31)
                }
            }
        },
        '&_graphic': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                position: 'absolute',
                width: toRem(180),
                height: toRem(47),
                left: toRem(42),
                top: toRem(-10)
            }
        },
        '&_user': {
            position: 'absolute',
            top: 0,
            left: 0,
            [theme.breakpoints.up('md')]: {
                top: toRem(40),
                right: toRem(150),
                left: 'initial'
            }
        },
        '& .ampNavigation': {
            '&_sidebar': {
                background: theme.colors.menu.background,
                padding: toRems([15, 30, 15, 15]),
                textAlign: 'left'
            },
            '&_button': {
                position: 'absolute',
                top: toRem(10),
                right: toRem(10),
                width: toRem(30),
                height: toRem(30),
                padding: toRem(8),
                color: theme.colors.menu.text.primary,
                svg: {
                    path: {
                        fill: 'currentColor'
                    }
                }
            },
            '&_close': {
                position: 'absolute',
                display: 'flex',
                top: toRem(20),
                right: toRem(8),
                width: toRem(30),
                height: toRem(30),
                color: theme.colors.menu.text.primary,
                svg: {
                    path: {
                        fill: 'currentColor'
                    }
                }
            },
            '&_list': {
                marginTop: toRem(50)
            },
            '&_link': {
                display: 'block',
                color: theme.colors.menu.text.primary,
                fontSize: toRem(18),
                fontWeight: theme.typography.fontWeight.bold,
                lineHeight: toRem(40),
                '&:hover': {
                    color: theme.colors.menu.text.highlight,
                    boxShadow: `${toRems([0, -5, 0])} ${theme.colors.menu.text.highlight} inset`
                }
            }
        }
    }
})

export default style
