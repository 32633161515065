import { useMemo } from 'react'

import { ClosePosition, ConvergerPostion } from '../EternalAds.interface'
import { parseIntegerValue } from '../helpers/parseIntegerValue'
import { parseYesOrNo } from '../helpers/parseYesOrNo'
import { ConvergerData, ParsedConvergerDimensions, positionMap, PositionX, PositionY } from './Converger.interface'
import mockDataConverger from './Converger.mock'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseCallbacks = (callbacks?: string): ((...args: any[]) => void)[] => {
    if (!callbacks) return []
    return callbacks
        .split(',')
        .map(callb => {
            try {
                const parsedCallback = JSON.parse(callb)
                if (parsedCallback && typeof parsedCallback === 'function') {
                    return (...args) => {
                        try {
                            parsedCallback(...args)
                        } catch (e) {
                            console.error('Error in Converger callback', e)
                        }
                    }
                }
            } catch (err) {
                console.error('Error parsing Converger callback', err)
                return false
            }
            return false
        })
        .filter(s => typeof s == 'function') as ((...args: any[]) => void)[]
}

export const parsePosition = (position?: ConvergerPostion | ClosePosition): [PositionX, PositionY] => {
    if (!position) return [PositionX.CENTER, PositionY.BOTTOM]
    const [x = PositionX.CENTER, y = PositionY.BOTTOM] = positionMap[position] || []
    return [x, y]
}

export const parseConvergerData = (data?: ConvergerData) => {
    if (!data) return {}
    const {
        ad,
        clickUrlUnesc,
        clickUrl,
        height,
        openInNewTab,
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        width,
        thirdPartyTagsHtml,
        hasGlobalClickTag,
        sourceType,
        fileImage,
        fileMobileImage,
        imageBackgroundColor
    } = data

    const dimensions: ParsedConvergerDimensions = {
        height: parseIntegerValue(height),
        width: parseIntegerValue(width)
    }
    const clickOptions = {
        openInNewTab: parseYesOrNo(openInNewTab),
        hasGlobalClickTag: parseYesOrNo(hasGlobalClickTag),
        thirdPartyImpressionUrl,
        viewUrlUnesc,
        clickUrlUnesc,
        thirdPartyTagsHtml,
        clickUrl
    }
    const creative = {
        sourceType,
        fileImage,
        fileMobileImage,
        imageBackgroundColor
    }

    return {
        creative,
        dimensions,
        clickOptions,
        ad
    }
}

export type ParsedConvergerData = ReturnType<typeof parseConvergerData>

export type ParsedConvergerElement = ParsedConvergerData['creative']

export const useConvergerMockData = () =>
    useMemo(() => parseConvergerData(mockDataConverger as unknown as ConvergerData), [])
