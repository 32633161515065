import { useRef } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { createQueryClient } from '../../hooks'

const isDev = process.env.NODE_ENV !== 'production'

function QueryCacheProvider({ children }) {
    const queryClientRef = useRef()

    // To prevent accidental cache sharing between users.
    if (!queryClientRef.current) {
        queryClientRef.current = createQueryClient()
    }

    return (
        <QueryClientProvider client={queryClientRef.current}>
            {isDev && <ReactQueryDevtools position="bottom-left" />}
            {children}
        </QueryClientProvider>
    )
}

export { QueryCacheProvider }
