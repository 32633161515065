import { useCallback, useEffect, useRef } from 'react'

import { useVoyoDataDispatch } from '../dispatchers/ads'
import type { ParsedVoyoSlider } from '../helpers/middleware/parseVoyoSlider'

const getVoyoDataKey = (voyoData: { [key: string]: ParsedVoyoSlider }) => Object.keys(voyoData).sort().join(',')
export const useVoyoDataConnect = ({ voyoData }: { voyoData: { [key: string]: ParsedVoyoSlider } }) => {
    const dispatchVoyoData = useVoyoDataDispatch()
    const lastDispastchedKey = useRef('')
    // this is  to dispatch the data on ssr
    const dispatchVoyo = useCallback(
        (data: { [key: string]: ParsedVoyoSlider }) => {
            // this is important to avoid infinite loops or repeated data
            const dispatchKey = getVoyoDataKey(data)
            if (dispatchKey === lastDispastchedKey.current) return
            lastDispastchedKey.current = dispatchKey
            dispatchVoyoData(data)
        },
        [dispatchVoyoData]
    )
    useRef(dispatchVoyo(voyoData))
    useEffect(() => dispatchVoyo(voyoData), [voyoData])
}
