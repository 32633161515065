/**
 *Replace url template from API source with actualy instance variables.
 *
 * @param {*} urlTemplate
 * @param {*} replaceMap
 * @return {*}
 */
const replaceAll = (urlTemplate, replaceMap) => {
    const re = new RegExp(Object.keys(replaceMap).join('|'), 'gi')

    return urlTemplate.replace(re, matched => replaceMap[matched.toLowerCase()])
}

export default replaceAll
