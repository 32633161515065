import dynamic from 'next/dynamic'

import { AntiClsFrame } from '../../components/AntiClsFrame'
import { RenderInView } from '../../components/RenderInView'

const TwitterItemController = dynamic(() => import('./TwitterItem.controller').then(m => m.default), {
    ssr: false
})

export function LazyTwitterItem({ id, ...rest }) {
    if (!id) {
        return null
    }
    return (
        <AntiClsFrame minHeightMd="742px" minHeightSm="700px" maxWidthXs="550px" ratio={742 / 550}>
            <RenderInView viewPorts={1}>
                <TwitterItemController id={id} {...rest} />
            </RenderInView>
        </AntiClsFrame>
    )
}
LazyTwitterItem.propTypes = {
    ...TwitterItemController.propTypes
}
LazyTwitterItem.defaultProps = {
    ...TwitterItemController.defaultProps
}
