/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const CheckMarkAlt = createSvgIcon(
    <path d="M12.293 20.293L22.586 10 24 11.414 12.293 23.121l-5.707-5.707L8 16l4.293 4.293z" />,
    'CheckMarkAlt'
)

export default CheckMarkAlt
