import { useCallback, useMemo, useState } from 'react'

import { useData } from './useData'
import { postOneElastic } from './useElasticsearch'

const usePoll = ({ id }, options = {}) => {
    const { enabled = true } = options
    const [isLoading, setIsLoading] = useState(false)

    const { data: pollData } = useData({ resource: `polls/${id}`, enabled: enabled && !!id })

    const addVote = useCallback(
        async answerIndex => {
            if (!pollData?.id || Number.isNaN(answerIndex)) {
                return null
            }

            setIsLoading(true)

            const data = await postOneElastic(`polls/${id}`, {
                answerIndex
            })

            setIsLoading(false)

            return data
        },
        [pollData]
    )

    const result = useMemo(
        () => ({
            data: pollData,
            isLoading,
            addVote
        }),
        [pollData, isLoading, addVote]
    )

    return result
}

export default usePoll
