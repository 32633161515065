import { useMemo } from 'react'

import UserContext from './User.context'

function NoUserContextProvider({ children }) {
    const value = useMemo(
        () => ({
            profile: undefined,
            isLoggedIn: false,
            logIn: () => Promise.resolve(),
            register: () => Promise.resolve(),
            logOut: () => Promise.resolve()
        }),
        []
    )
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default NoUserContextProvider
