const constants = {
    CLOSE_ACCOUNT: 'NAVIGATION_ACCOUNT/CLOSE_NAVIGATION',
    OPEN_ACCOUNT: 'NAVIGATION_ACCOUNT/OPEN_NAVIGATION',
    TOGGLE_ACCOUNT: 'NAVIGATION_ACCOUNT/TOGGLE_NAVIGATION'
}

const actions = {
    openAccount: () => ({ type: constants.OPEN_ACCOUNT }),
    closeAccount: () => ({ type: constants.CLOSE_ACCOUNT }),
    toggleAccount: () => ({ type: constants.TOGGLE_ACCOUNT })
}

export { constants as NavigationAccountActionTypes, actions as NavigationAccountActions }
