import { NavigationActionTypes as ActionTypes } from '../actions/navigation'

const initialState = {
    isOpen: false,
    color: null
}

const navigationReducer = (state = initialState, { newStore, type, color } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }

        case ActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case ActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        case ActionTypes.SET_LOGO_COLOR: {
            return {
                ...state,
                color
            }
        }

        default:
            return state
    }
}

export { navigationReducer }
