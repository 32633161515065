import { CollectionActionTypes as ActionTypes } from '../actions/collections'

const defaultCollection = {
    id: 'svi-recepti',
    name: 'Svi recepti',
    items: []
}

const initialState = {
    notificationsOpen: [],
    collections: [defaultCollection],
    lastSavedItem: null, // We must use 'null' instead of 'undefined' because of JSON serialization
    lastCollectionUpdated: null // We must use 'null' instead of 'undefined' because of JSON serialization
}

const collectionsReducer = (state = initialState, { newStore, type, notification, collection } = {}) => {
    switch (type) {
        case ActionTypes.SET_STORE: {
            return {
                ...state,
                ...newStore
            }
        }
        case ActionTypes.CLOSE_NOTIFICATION: {
            // If notification id is provided, close only that notification
            if (notification && notification.id) {
                return {
                    ...state,
                    notificationsOpen: state.notificationsOpen.filter(
                        stateNotification => stateNotification.id !== notification.id
                    )
                }
            }

            // Else close all notifications
            return {
                ...state,
                notificationsOpen: []
            }
        }

        case ActionTypes.OPEN_NOTIFICATION: {
            return {
                ...state,
                notificationsOpen: [...state.notificationsOpen, notification]
            }
        }

        case ActionTypes.CREATE_COLLECTION: {
            return {
                ...state,
                collections: [...state.collections, collection],
                lastCollectionUpdated: { ...collection }
            }
        }

        case ActionTypes.DELETE_COLLECTION: {
            return {
                ...state,
                collections: state.collections.filter(stateCollection => stateCollection.id !== collection.id)
            }
        }

        case ActionTypes.UPDATE_COLLECTION: {
            return {
                ...state,
                collections: state.collections.map(stateCollection => {
                    if (stateCollection.id !== collection.id) {
                        return stateCollection
                    }

                    return {
                        ...stateCollection,
                        ...collection
                    }
                }),
                lastCollectionUpdated: { ...collection }
            }
        }

        case ActionTypes.ADD_TO_COLLECTION: {
            return {
                ...state,
                collections: state.collections.map(stateCollection => {
                    if (stateCollection.id !== collection.id) {
                        return stateCollection
                    }

                    return {
                        ...stateCollection,
                        items: stateCollection.items.includes(collection.items[0])
                            ? [...stateCollection.items]
                            : [...stateCollection.items, collection.items[0]]
                    }
                }),
                lastSavedItem: collection.items[0],
                lastCollectionUpdated: { ...collection }
            }
        }

        case ActionTypes.REMOVE_FROM_COLLECTION: {
            // If it's in only one collection and ID is passed, remove it from that collection
            if (collection && collection.id) {
                return {
                    ...state,
                    collections: state.collections.map(stateCollection => {
                        if (stateCollection.id !== collection.id) {
                            return stateCollection
                        }

                        return {
                            ...stateCollection,
                            items: stateCollection.items.filter(item => item !== collection.items[0])
                        }
                    }),
                    lastCollectionUpdated: { ...collection }
                }
            }

            return {
                ...state
            }
        }

        default:
            return state
    }
}

export { collectionsReducer }
