import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        alpha: {
            display: 'table',
            fontSize: {
                default: toRem(24),
                xs: toRem(18)
            },
            lineHeight: {
                default: toRem(52),
                xs: toRem(40)
            },
            fontWeight: 'bold',
            color: {
                default: theme.colors.menu.text.primary,
                hover: theme.colors.menu.text.highlight
            },
            underline: {
                default: `${toRems([0, -7, 0])} ${theme.colors.menu.text.highlight} inset`,
                xs: `${toRems([0, -5, 0])} ${theme.colors.menu.text.highlight} inset`
            },
            dots: false,
            textTransform: 'none'
        },
        beta: {
            display: 'inline-block',
            fontSize: {
                default: toRem(16),
                xs: toRem(18)
            },
            lineHeight: {
                default: toRem(32),
                xs: toRem(28)
            },
            fontWeight: 'normal',
            color: {
                default: theme.colors.menu.text.secondary,
                hover: theme.colors.menu.text.primary
            },
            underline: {
                default: `none`,
                xs: 'none'
            },
            dots: true
        },
        gamma: {
            display: 'table',
            fontSize: {
                default: toRem(18),
                xs: toRem(18)
            },
            lineHeight: {
                default: toRem(40),
                xs: toRem(40)
            },
            fontWeight: 'bold',
            color: {
                default: theme.colors.footer.text.primary,
                hover: theme.colors.footer.text.highlight
            },
            underline: {
                default: `${toRems([0, -5, 0])} ${theme.colors.footer.text.highlight} inset`,
                xs: `${toRems([0, -5, 0])} ${theme.colors.footer.text.highlight} inset`
            },
            dots: false
        },
        delta: {
            display: 'inline-block',
            fontSize: {
                default: toRem(14),
                xs: toRem(14)
            },
            lineHeight: {
                default: toRem(28),
                xs: toRem(28)
            },
            fontWeight: 'normal',
            color: {
                default: theme.colors.footer.text.secondary,
                hover: theme.colors.footer.text.primary
            },
            underline: {
                default: `none`,
                xs: 'none'
            },
            dots: true
        },
        epsilon: {
            display: 'inline-block',
            fontSize: {
                default: toRem(14),
                xs: toRem(14)
            },
            lineHeight: {
                default: toRem(20),
                xs: toRem(20)
            },
            fontWeight: 'bold',
            color: {
                default: theme.colors.menu.text.primary,
                hover: theme.colors.menu.text.highlight
            },
            underline: {
                default: `${toRems([0, -3, 0])} ${theme.colors.menu.text.highlight} inset`,
                xs: `${toRems([0, -3, 0])} ${theme.colors.menu.text.highlight} inset`
            },
            dots: true
        }
    }

    return {
        boxSizing: 'border-box',
        width: 'auto',
        display: config[variant].display,
        ...(config[variant].dots && {
            marginRight: toRem(10),
            '&::before': {
                content: `'\u00B7'`,
                marginRight: toRem(10)
            }
        }),
        '& .menuItem': {
            display: 'inline-block',
            color: config[variant].color.default,
            textTransform: variant === 'epsilon' ? 'uppercase' : 'none',
            fontSize: config[variant].fontSize.xs,
            lineHeight: config[variant].lineHeight.xs,
            fontWeight: config[variant].fontWeight,
            [theme.breakpoints.up('md')]: {
                fontSize: config[variant].fontSize.default,
                lineHeight: config[variant].lineHeight.default
            }
        },

        '&:hover': {
            '& > *': {
                color: config[variant].color.hover
            },
            '& .menuItem': {
                boxShadow: config[variant].underline.xs
            },
            [theme.breakpoints.up('md')]: {
                '& .menuItem': {
                    boxShadow: config[variant].underline.default
                }
            }
        }
    }
}

export default style
