import { MappedVia, SlotDivId } from '../../types/ads/Ad.interface'
import createFastContext from '../createFastContext'

export type FilledSlot = {
    filled: boolean
    size?: [number, number]
}
export type FilledSlotsStore = MappedVia<SlotDivId, FilledSlot>
const filledSlotsInitialState: FilledSlotsStore = {}
export const FilledSlotsContext = createFastContext(filledSlotsInitialState)

export const useFilledSlots = () => {
    return FilledSlotsContext.useStore()
}
