import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            flexDirection: 'column',
            marginBottom: toRem(50),
            form: {
                minWidth: toRem(325)
            },
            inputBubble: {
                fontSize: toRem(16),
                minHeight: toRem(126),
                padding: toRems([16, 8, 16, 8])
            },
            submit: {
                marginTop: toRem(20),
                flex: 1
            }
        },
        md: {
            flexDirection: 'row',
            marginBottom: toRem(58),
            form: {
                minWidth: toRem(600)
            },
            inputBubble: {
                fontSize: toRem(18),
                minHeight: toRem(101),
                padding: toRems([19.5, 11.5, 10.5, 30.5])
            },
            submit: {
                marginTop: 0,
                flex: 2
            }
        }
    }
    return {
        display: 'flex',
        flexDirection: config.sm.flexDirection,
        marginBottom: config.sm.marginBottom,
        [theme.breakpoints.up('md')]: {
            flexDirection: config.md.flexDirection,
            marginBottom: config.md.marginBottom
        },
        '& .commentsAdd': {
            width: '100%',
            minWidth: config.sm.form.minWidth,
            [theme.breakpoints.up('md')]: {
                minWidth: config.md.form.minWidth
            },
            '&_input': {
                position: 'relative',
                '&_item': {
                    border: `1px solid ${theme.colors.comments.border.default}`,
                    resize: 'none',
                    outline: 'none !important',
                    width: '100%',
                    fontWeight: theme.typography.fontWeight.medium,
                    backgroundColor: theme.colors.comments.add.background,
                    fontSize: config?.sm.inputBubble.fontSize,
                    minHeight: config?.sm.inputBubble.minHeight,
                    padding: config?.sm.inputBubble.padding,
                    lineHeight: 1.8,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config?.md.inputBubble.fontSize,
                        minHeight: config?.md.inputBubble.minHeight,
                        width: config?.md.inputBubble.width,
                        padding: config?.md.inputBubble.padding
                    }
                },
                [theme.breakpoints.up('md')]: {
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: toRem(15),
                        height: toRem(15),
                        borderTop: `1px solid ${theme.colors.comments.border.default}`,
                        borderLeft: `1px solid ${theme.colors.comments.border.default}`,
                        top: '20%',
                        left: toRem(-8),
                        background: theme.colors.comments.add.background,
                        transform: 'skewX(45deg)'
                    }
                }
            },
            '&_submit': {
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: config?.sm.submit.marginTop,
                [theme.breakpoints.up('md')]: {
                    marginTop: config?.md.submit.marginTop
                },
                '&_button': {
                    height: toRem(50),
                    alignSelf: 'center',
                    '& svg': {
                        height: toRem(18),
                        width: toRem(18)
                    }
                }
            }
        }
    }
}

export default style
