import { useMemo } from 'react'

const checkDimension = (dim, initial) => dim <= initial && dim >= (initial / 3) * 2
const useAmpAdDimensions = (
    dimensions,
    dimensionLimit = {
        max: [336, 540],
        min: [100, 50]
    }
) =>
    useMemo(() => {
        if (!dimensions || dimensions.length === 0) {
            return {}
        }

        const filteredDimensions = dimensions?.filter(
            ([width, height]) =>
                width <= dimensionLimit.max[0] &&
                width >= dimensionLimit.min[0] &&
                height <= dimensionLimit.max[1] &&
                height >= dimensionLimit.min[1]
        )

        if (!filteredDimensions || filteredDimensions.length === 0) {
            return {}
        }
        const initialDimensions = filteredDimensions?.reduce((largestDim, current) => {
            if (!largestDim || largestDim[0] < current[0] || largestDim[1] < current[1]) {
                return current
            }
            return largestDim
        }) || [300, 250]

        return {
            ampAdWidth: initialDimensions[0],
            ampAdHeight: initialDimensions[1],
            ampAdDimensions: dimensions
                ?.filter(
                    dim => checkDimension(dim[0], initialDimensions[0]) && checkDimension(dim[1], initialDimensions[1])
                )
                .map(dim => dim.join('x'))
                .join(',')
        }
    }, [dimensions])
export default useAmpAdDimensions
