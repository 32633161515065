import { useSelector } from 'react-redux'

import { ParsedVoyoSlider } from '../helpers/middleware/parseVoyoSlider'

export const nativeAdsSelector = state => state?.ads?.native
export const useNativeAdsState = (nativeType?: string) => {
    const nativeAds = useSelector(nativeAdsSelector)
    if (!nativeType) {
        return nativeAds
    }
    return nativeAds?.[nativeType]
}

export const voyoDataSelector = state => state?.ads?.voyoData
export const useVoyoData = (): { [key: string]: ParsedVoyoSlider } => useSelector(voyoDataSelector)

export const customFilledSelector = state => state?.ads?.customFilledStates
export const useCustomFilledState = (key: string) => {
    const filledSlots = useSelector(customFilledSelector)
    return filledSlots?.[key] || false
}

// const parseDeviceZoneNames = (adZoneName: string | { mobile: string; desktop: string }) => {
//     if (typeof adZoneName === 'string') {
//         return { mobileName: getAdZoneKey('M', adZoneName), desktopName: getAdZoneKey('D', adZoneName) }
//     }
//     return {
//         mobileName: getAdZoneKey('M', adZoneName?.mobile),
//         desktopName: getAdZoneKey('D', adZoneName?.desktop)
//     }
// }
