const safelyJoinSlugs = (slugs = [], joinToken = '/') => {
    // for some reason router.query.slugs and other dynamic routes
    // as single string (instead of array) even though docs say
    // it should always be array https://nextjs.org/docs/routing/dynamic-routes#catch-all-routes
    // no time to debug now so fallback like this
    const arraySlugs = !Array.isArray(slugs) ? [slugs] : slugs

    if (!slugs || !arraySlugs.length) {
        return ''
    }

    return arraySlugs
        .filter(slug => Boolean(slug) && slug !== '_category' && slug !== 'category' && slug !== 'undefined')
        .join(joinToken)
}

export default safelyJoinSlugs
