import toRem from '../../helpers/convertPxToRem'

const styles = ({
    theme,
    minHeightXs,
    minHeightMd,
    maxHeightXs,
    maxHeightMd,
    widthProp: width = 'auto',
    maxWidthMd,
    maxWidthXs,
    marginMd,
    marginXs,
    ratio,
    displayMd = 'block',
    displayXs = 'block',
    pointerEventsProp = true
}) => ({
    minHeight: toRem(minHeightXs),
    maxHeight: toRem(maxHeightXs),
    maxWidth: toRem(maxWidthXs),
    width,
    boxSizing: 'border-box',
    display: displayXs,
    position: 'relative',
    pointerEvents: pointerEventsProp ? 'auto' : 'none',
    margin: (marginXs ?? toRem(marginXs)) || 'auto',
    [theme.breakpoints.up('md')]: {
        minHeight: toRem(minHeightMd),
        maxHeight: toRem(maxHeightMd),
        maxWidth: toRem(maxWidthMd),
        display: displayMd,
        margin: marginMd ?? toRem(marginXs)
    },
    ...(typeof ratio === 'number' && {
        '&::before': {
            content: '""',
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            height: 0,
            paddingTop: `calc(${ratio} * 100%)`
        },
        '&::after': {
            content: '""',
            display: 'table',
            clear: 'both'
        }
    })
})
export default styles
