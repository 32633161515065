import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { hr } from 'date-fns/locale'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

const getHelperText = error => {
    switch (error) {
        case 'invalidDate':
            return 'Nevažeći format datuma'

        case 'maxDate':
            return 'Datum ne smije biti iza maksimalnog datuma'

        default:
            return ''
    }
}

function Field({ field, form, label, maxdate, className, ...other }) {
    const currentError = form.errors[field.name]

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hr}>
            <DatePicker
                label={label}
                className={className}
                value={field.value}
                inputFormat="dd/MM/yyyy"
                onError={error => {
                    if (error !== (currentError || '')) {
                        form.setFieldError(field.name, error)
                    }
                }}
                onChange={date => {
                    if (date !== null) {
                        form.setFieldValue(field.name, date)
                    }
                }}
                OpenPickerButtonProps={{
                    edge: 'end'
                }}
                maxDate={maxdate}
                renderInput={props => (
                    <TextField
                        error={Boolean(currentError)}
                        name={field.name}
                        helperText={getHelperText(currentError)}
                        {...other}
                        {...props}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

Field.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    }),
    form: PropTypes.objectOf(Formik),
    label: PropTypes.string,
    maxdate: PropTypes.instanceOf(Date),
    className: PropTypes.string
}

Field.defaultProps = {
    field: {
        name: undefined,
        value: null
    },
    form: undefined,
    label: null,
    maxdate: null,
    className: undefined
}

export default withErrorBoundary(Field, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Field]: ', error, componentStack)
    }
})
