import styled from '@emotion/styled'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import useUser from '../../../../hooks/useUser'
import { Circle, circleVariants } from '../../../Comments/components'
import { Icon, ReviewerIcon, UserIcon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './LoginButton.style'

const LoginButtonStyled = styled.div(props => ({ ...styles(props) }))

function LoginButton({ className }) {
    const { profile, isLoggedIn, logIn, register, avatar } = useUser()

    const handleLogin = () => {
        if (!isLoggedIn) {
            logIn()
        }
    }

    const handleRegister = () => {
        if (!isLoggedIn) {
            register()
        }
    }

    return (
        <LoginButtonStyled className={className}>
            {isLoggedIn ? (
                <Link
                    type="button"
                    title={`Korisnik ${profile?.display_name || ''}`}
                    href="/profil"
                    className="avatar_button">
                    <div className="avatar">
                        {avatar ? (
                            <Image
                                width="34"
                                height="34"
                                className="avatar_image"
                                src={avatar}
                                alt={profile?.display_name || ''}
                            />
                        ) : (
                            <Icon icon={ReviewerIcon} size={26} className="avatar_icon" />
                        )}
                        <Circle
                            size={36}
                            variant={circleVariants.ALPHA}
                            className="avatar_circle avatar_circle_header"
                        />
                    </div>
                    <span href="/profil" className="title loggedIn" title={`Korisnik ${profile?.display_name || ''}`}>
                        MOJ PROFIL
                    </span>
                </Link>
            ) : (
                <>
                    <button onClick={handleLogin} className="loginButton" title="Prijava" type="button">
                        <Icon icon={UserIcon} className="userIcon" />
                        <span className="title loggedOut">Prijava</span>
                    </button>
                    <div className="line" />
                    <button onClick={handleRegister} className="registerButton" title="Registracija" type="button">
                        <span className="title loggedOut">Registracija</span>
                    </button>
                </>
            )}
        </LoginButtonStyled>
    )
}

LoginButton.propTypes = {
    className: PropTypes.string
}

LoginButton.defaultProps = {
    className: undefined
}

export default withErrorBoundary(LoginButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[LoginButton]: ', error, componentStack)
    }
})
