import { useMemo, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { taxonomy } from '../queries/requests'

const MutationAction = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
}
/**
 * `useTaxonomy` returns a list of taxons on a specified taxonomy.
 *
 * Provides `data` and `total` values.
 *
 * As well as request information (`status`, `error`, ...).
 *
 * `action` key contains taxon `create`, `update` and `delete` methods.
 *
 * @param {string} resource
 * @param {Object} [options={}]
 * @param {boolean} [options.enabled=true]
 * @param {*[]} [options.initialData=[]]
 * @param {boolean} [options.refetchOnWindowFocus=false]
 * @param {boolean} [options.enabledForAnonymous=true]
 * @param {boolean} [options.refetchOnLogin=false]
 * @example
 * const { data } = useTaxonomy(
 *      'favorited_categorized',
 *      {
 *          // if `true`, data will be loaded
 *          // if `false`, cache or `initialData` will be used
 *          enabled: true,
 *          // `array` of entities
 *          initialData: [],
 *          // if `true`, `initialData` will be replaced with fresh
 *          // if `false` `initialData` will be used
 *          // if data should or should not be refetched when tabbing
 *          refetchOnWindowFocus: false,
 *          // set to `false` if data requires user token
 *          // set to `true` if data should load for everyone
 *          enabledForAnonymous: true,
 *          // set to `false` if data is equal for everyone
 *          // set to `true` if dada is unique per user
 *          // ignored if `enabledForAnonymous` is set to `false`
 *          refetchOnLogin: true
 *      }
 * )
 */
const useTaxonomy = (resource, options = {}) => {
    const queryClient = useQueryClient()
    const { isLoggedIn, data: { id: loggedInUserId } = {} } = useSelector(state => state.user)

    const {
        enabled = true,
        initialData = [],
        refetchOnWindowFocus = false,
        enabledForAnonymous = true,
        refetchOnLogin = false
    } = options

    const { current: resourceUri } = useRef(`taxonomies/${resource}/taxons`)

    const queryEnabled = enabledForAnonymous || isLoggedIn ? enabled : false

    const queryKey = useMemo(() => {
        if ((enabledForAnonymous && !refetchOnLogin) || !isLoggedIn) {
            return [resourceUri]
        }
        return [resourceUri, loggedInUserId]
    }, [resourceUri, isLoggedIn, loggedInUserId, enabledForAnonymous, refetchOnLogin])

    const { current: queryInitialData } = useRef({
        data: initialData,
        total: initialData?.length || 0
    })

    const {
        data: queryResult,
        isFetching,
        error,
        status,
        refetch
    } = useQuery(queryKey, () => taxonomy.getList(resourceUri), {
        enabled: queryEnabled,
        initialData: queryInitialData,
        refetchOnWindowFocus,
        refetchOnMount: false,
        retry: false
    })

    const { mutate: queryMutation } = useMutation(
        async ({ taxon, action }) => {
            const { id, ...data } = taxon
            switch (action) {
                case MutationAction.CREATE:
                    return taxonomy.createOne(resourceUri, {
                        data
                    })
                case MutationAction.UPDATE:
                    return taxonomy.updateOne(resourceUri, {
                        id,
                        data
                    })
                case MutationAction.DELETE:
                    return taxonomy.deleteOne(resourceUri, {
                        id
                    })
                default:
                    return Promise.reject(new Error('Action has to be provided'))
            }
        },
        {
            onMutate: ({ taxon, action, cacheAction }) => {
                queryClient.cancelQueries(queryKey)

                const previousValue = queryClient.getQueryData(queryKey)

                switch (action) {
                    case MutationAction.CREATE:
                        queryClient.setQueryData(queryKey, (cache = { data: [], total: 0 }) => ({
                            data: [
                                ...cache.data,
                                {
                                    id: `new-${Date.now()}`,
                                    isDummy: true,
                                    ...taxon
                                }
                            ],
                            total: cache.total + 1
                        }))
                        break
                    case MutationAction.UPDATE:
                        queryClient.setQueryData(queryKey, (cache = { data: [], total: 0 }) => ({
                            data: cache.data.map(item => {
                                if (item.id === taxon.id) {
                                    return {
                                        ...item,
                                        ...taxon
                                    }
                                }
                                return item
                            }),
                            total: cache.total
                        }))
                        break
                    case MutationAction.DELETE:
                        queryClient.setQueryData(queryKey, (cache = { data: [], total: 0 }) => ({
                            data: cache.data.filter(item => (item.taxon?.id || item.id) !== taxon.id),
                            total: cache.total ? cache.total - 1 : 0
                        }))
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.warn('Action has to be provided')
                        break
                }

                if (cacheAction && typeof cacheAction === 'function') {
                    queryClient.setQueryData(queryKey, cache => cacheAction(cache) || cache)
                }

                return previousValue
            },
            onError: (err, variables, previousValue) => {
                queryClient.setQueryData(queryKey, previousValue)
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey)
            }
        }
    )

    return {
        key: queryKey,
        data: queryResult?.data || queryInitialData.data,
        total: queryResult?.data ? queryResult?.total : queryInitialData.total,
        loading: isFetching,
        status,
        error,
        refetch,
        action: {
            create: async (taxon, { cacheAction } = {}) =>
                queryMutation({ taxon, action: MutationAction.CREATE, cacheAction }),
            update: async (taxon, { cacheAction } = {}) =>
                queryMutation({ taxon, action: MutationAction.UPDATE, cacheAction }),
            delete: async (taxon, { cacheAction } = {}) =>
                queryMutation({ taxon, action: MutationAction.DELETE, cacheAction }),
            invalidate: () => {
                queryClient.invalidateQueries(queryKey)
            }
        }
    }
}

export default useTaxonomy
