import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: toRems([30, 0]),
    '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.colors.profile.border.default}`
    },
    '& .globalProfileOption': {
        width: '90%'
    },
    '& .globalProfileOption_title': {
        marginBottom: toRem(8),
        color: theme.colors.profile.text.default,
        fontFamily: theme.typography.fontFamily.alternative,
        fontWeight: theme.typography.fontWeight.bold,
        fontSize: toRem(18),
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(20)
        }
    },
    '& .globalProfileOption_description': {
        color: theme.colors.profile.text.alternative,
        fontSize: toRem(14),
        lineHeight: 1.4,
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(16)
        }
    }
})

export default style
