import { useMemo } from 'react'
import xss, { getDefaultWhiteList } from 'xss'

const options = {
    whiteList: getDefaultWhiteList(),
    stripIgnoreTag: true
}

const filterXss = new xss.FilterXSS(options)

export const sanitizeHtmlContent = html => filterXss.process(html)
const useInnerHtml = html => {
    const htmlProps = useMemo(
        () => ({
            dangerouslySetInnerHTML: {
                __html: sanitizeHtmlContent(html)
            }
        }),
        [html]
    )

    return htmlProps
}

export default useInnerHtml
