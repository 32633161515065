import createFastContext from './createFastContext'

// @TODO: implement this one
type NavigationStore = {
    isOpen: boolean
    color: string | null
    searchIsOpen: boolean
    filtersIsOpen: boolean
}

const navigationInitialState: NavigationStore = {
    isOpen: false,
    color: null,
    searchIsOpen: false,
    filtersIsOpen: false
}

export const NavigationContext = createFastContext(navigationInitialState)

export const useNavigationStore = () => NavigationContext.useStore()

const navigationOpenSelector = (store: NavigationStore) => store.isOpen
const navigationOpenProcessor = (isOpen: boolean) => ({ isOpen })
export const useNavigationOpenState = () => NavigationContext.useStore(navigationOpenSelector, navigationOpenProcessor)

const searchIsOpenSelector = (store: NavigationStore) => store.searchIsOpen
const searchIsOpenProcessor = (searchIsOpen: boolean) => ({ searchIsOpen })
export const useSearchIsOpenState = () => NavigationContext.useStore(searchIsOpenSelector, searchIsOpenProcessor)
