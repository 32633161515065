import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const { panel } = theme.global.navigation
    const config = {
        maxWidth: {
            default: toRem(panel.maxWidth.default),
            md: toRem(panel.maxWidth.md),
            xs: toRem(panel.maxWidth.xs)
        }
    }
    return {
        '& .navigationPanel': {
            height: 'auto',
            position: 'fixed',
            right: 0,
            background: theme.colors.menu.background,
            maxWidth: config.maxWidth.default,
            [theme.breakpoints.only('md')]: {
                maxWidth: config.maxWidth.md
            },
            [theme.breakpoints.down('md')]: {
                maxWidth: config.maxWidth.xs
            }
        }
    }
}

const motionVariants = {
    open: {
        // right: '0vw',
        visibility: 'visible',
        x: '0%',
        transition: {
            duration: 0.5,
            ease: 'easeOut'
        }
    },
    closed: {
        // right: '-110vw',
        x: `calc(100% + ${toRem(24)})`,
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        },
        transitionEnd: {
            visibility: 'hidden'
        }
    }
}

export default style
export { motionVariants }
