import { useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

export const useRouteChangeComplete = (callback, runImmediately = false) => {
    const router = useRouter()

    useEffect(() => {
        if (!callback || typeof callback !== 'function') {
            return () => {}
        }
        const handleRouteChangeComplete = (...rest) => {
            callback?.(...rest)
        }
        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
        }
    }, [callback])
    // run on initial load once
    const isMountedRef = useRef(false)
    useEffect(() => {
        if (isMountedRef.current || !runImmediately) {
            return
        }
        isMountedRef.current = true
        callback()
    }, [callback, runImmediately])
}
export const useRouteChangeStart = (callback, runImmediately = false) => {
    const router = useRouter()

    useEffect(() => {
        if (!callback || typeof callback !== 'function') {
            return () => {}
        }
        const handleRouteChangeStart = (...rest) => {
            callback?.(...rest)
        }
        router.events.on('routeChangeStart', handleRouteChangeStart)
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
        }
    }, [callback])
    // run on initial load once
    const isMountedRef = useRef(false)
    useEffect(() => {
        if (isMountedRef.current || !runImmediately) {
            return
        }
        isMountedRef.current = true
        callback()
    }, [callback, runImmediately])
}

export const useRouteChange = ({ onComplete, onStart, onError }) => {
    const router = useRouter()

    const onStartCallback = useMemo(() => (typeof onStart === 'function' ? onStart : () => null), [onStart])
    const onCompleteCallback = useMemo(() => (typeof onComplete === 'function' ? onComplete : () => null), [onComplete])
    const onErrorCallback = useMemo(() => (typeof onError === 'function' ? onError : () => null), [onError])

    useEffect(() => {
        router.events.on('routeChangeStart', onStartCallback)
        router.events.on('routeChangeComplete', onCompleteCallback)
        router.events.on('routeChangeError', onErrorCallback)
        return () => {
            router.events.off('routeChangeStart', onStartCallback)
            router.events.off('routeChangeComplete', onCompleteCallback)
            router.events.off('routeChangeError', onErrorCallback)
        }
    }, [onStartCallback, onCompleteCallback, onErrorCallback, router.events])
}
export default useRouteChange
