import { configureStore } from '@reduxjs/toolkit'

import { adsReducer } from '../reducers/ads'
import { collectionsReducer } from '../reducers/collections'
import { drawersReducer } from '../reducers/drawer'
import { filtersReducer } from '../reducers/filters'
import { initialLoadReducer } from '../reducers/initialLoad'
import { modalsReducer } from '../reducers/modal'
import { navigationReducer } from '../reducers/navigation'
import { navigationAccountReducer } from '../reducers/navigationAccount'
import { persistentQueryReducer } from '../reducers/persistent-query'
import { searchReducer } from '../reducers/search'
import { settingsReducer } from '../reducers/settings'
import { userReducer } from '../reducers/user'
import { videoJsReducer } from '../reducers/videojs'

const reducer = {
    ads: adsReducer,
    collections: collectionsReducer,
    drawers: drawersReducer,
    filters: filtersReducer,
    initialLoad: initialLoadReducer,
    modals: modalsReducer,
    navigation: navigationReducer,
    navigationAccount: navigationAccountReducer,
    persistentQuery: persistentQueryReducer,
    search: searchReducer,
    settings: settingsReducer,
    user: userReducer,
    videoJs: videoJsReducer
}

export const store = configureStore({
    reducer
})
