// @TODO: Move everything theme related configs from globals folder

import { breakpoints as globalBreakpoints, createMediaQueries } from '../../helpers/theme'
import {
    colors as globalColors,
    global,
    normalize,
    shadows as globalShadows,
    transitions as globalTransitions,
    typography as globalTypography,
    utilities,
    zIndex as globalZIndex
} from '../globals'
import { cardStyleConfig as cards } from './cards'
import { layoutDimension, layoutTheme } from './layout'

// Define theme overrides here
export const rtl = {
    normalize,
    global,
    breakpoints: {
        ...createMediaQueries(globalBreakpoints)
    },
    colors: {
        ...globalColors
    },
    typography: {
        ...globalTypography
    },
    zIndex: {
        ...globalZIndex
    },
    transitions: {
        ...globalTransitions
    },
    shadows: {
        ...globalShadows
    },
    layoutTheme,
    layoutDimension,
    utilities,
    cards
}
