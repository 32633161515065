import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    padding: toRems([0, 20]),
    display: 'flex',
    alignItems: 'center',
    minHeight: toRem(50),
    background: theme.colors.profile.background.default,
    '& .rtlLogo': {
        height: toRem(22)
    }
})

export default style
