/* eslint-disable @typescript-eslint/naming-convention */
import PropTypes from 'prop-types'
import React, { Component, forwardRef, isValidElement, PropsWithChildren } from 'react'

const propTypes = {
    /**
     * This is a component you want rendered in the event of an error.
     * As props it will be passed the error, componentStack, and resetErrorBoundary (which will reset the error boundary's state when called,
     * useful for a "try again" button when used in combination with the onReset prop).
     * This is required if no fallback or fallbackRender prop is provided.
     */
    FallbackComponent: PropTypes.elementType,
    /**
     * This is a render-prop based API that allows you to inline your error fallback UI into the component that's using the ErrorBoundary.
     * This is useful if you need access to something that's in the scope of the component you're using.
     * It will be called with an object that has error, componentStack, and resetErrorBoundary:
     *
     *  <ErrorBoundary
     *      fallbackRender={({ error, resetErrorBoundary })} => (
     *          <div>
     *              <h1>{error.message}</h1>
     *              <button onClick={() => {
     *                  resetComponentState()
     *                  resetErrorBoundary()
     *              }}>
     *                  Try again
     *              </button>
     *          </div>
     *      )
     *  >
     *      <SomeComponentThatMayThrowError />
     *  </ErrorBoundary>
     *
     */
    fallbackRender: PropTypes.func,
    /**
     * To keep it consistent with the React.Suspense component, we also support a simple fallback prop which you can use for a generic fallback.
     * This will not be passed any props so you can't show the user anything actually useful though, so it's not really recommended.
     */
    fallback: PropTypes.elementType,
    /**
     * This will be called when there's been an error that the ErrorBoundary has handled.
     * It will be called with two arguments: error, componentStack.
     */
    onError: PropTypes.func,
    /**
     * This will be called immediately before the ErrorBoundary resets it's internal state (which will result in rendering the children again).
     * You should use this to ensure that re-rendering the children will not result in a repeat of the same error happening again.
     *
     * onReset will be called with whatever resetErrorBoundary is called with
     */
    onReset: PropTypes.func,
    /**
     * Sometimes an error happens as a result of local state to the component that's rendering the error.
     * If this is the case, then you can pass resetKeys which is an array of values.
     *
     * If the ErrorBoundary is in an error state, then it will check these values each render and if they change from one render to the next,
     * then it will reset automatically (triggering a re-render of the children).
     */
    resetKeys: PropTypes.arrayOf(PropTypes.any),
    /**
     * This is called when the resetKeys are changed (triggering a reset of the ErrorBoundary). It's called with the prevResetKeys and the resetKeys.
     */
    onResetKeysChange: PropTypes.func
}

type ErrorBoundaryProps = PropsWithChildren<PropTypes.InferProps<typeof propTypes>>

const changedArray = <ArrayType extends unknown[]>(a: ArrayType, b: ArrayType) =>
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))

type ErrorBoundaryState = {
    error: Error | null
    info: {
        componentStack: string
        [key: string]: any
    } | null
}
const initialState: ErrorBoundaryState = { error: null, info: null }

class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>> {
    override state = initialState

    override componentDidUpdate(prevProps) {
        const { error } = this.state
        const { resetKeys, onResetKeysChange } = this.props
        if (error !== null && changedArray(prevProps?.resetKeys, resetKeys)) {
            if (onResetKeysChange) {
                onResetKeysChange(prevProps?.resetKeys, resetKeys)
            }
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(initialState)
        }
    }

    override componentDidCatch(error, info) {
        console.log('ErrorBoundary caught componentDidCatch', error, info?.componentStack)
        const { onError } = this.props
        if (onError) {
            onError(error, info?.componentStack)
        }

        this.setState({ error, info })
    }

    resetErrorBoundary = (...args) => {
        const { onReset } = this.props
        if (onReset) {
            onReset(...args)
        }

        this.setState(initialState)
    }

    override render() {
        const { error, info } = this.state
        const { fallbackRender, FallbackComponent, fallback, children } = this.props

        if (error !== null) {
            const props = {
                componentStack: info?.componentStack,
                error,
                resetErrorBoundary: this.resetErrorBoundary
            }

            if (isValidElement(fallback)) {
                return fallback
            }

            if (typeof fallbackRender === 'function') {
                return fallbackRender(props)
            }

            if (typeof FallbackComponent === 'function') {
                return <FallbackComponent {...props} />
            }

            throw new Error('ErrorBoundary requires either a fallback, fallbackRender, or FallbackComponent prop')
        }

        return children
    }

    static propTypes = propTypes

    static defaultProps = {
        FallbackComponent: undefined,
        fallback: undefined,
        fallbackRender: undefined,
        // eslint-disable-next-line no-console
        onError: (error, componentStack) => console.error('[ErrorBoundary]: ', error, componentStack),
        onReset: () => {},
        onResetKeysChange: () => {},
        resetKeys: []
    }
}

const withErrorBoundary = <ComponentType extends React.FC<any>>(ChildComponent: ComponentType, errorBoundaryProps) => {
    const Wrapped = forwardRef((props, ref) => (
        <ErrorBoundary {...errorBoundaryProps}>
            {/* @ts-expect-error: fix up types */}
            <ChildComponent {...props} ref={ref} />
        </ErrorBoundary>
    ))

    // Format for display in DevTools
    const name = ChildComponent.displayName || ChildComponent.name || 'Unknown'
    Wrapped.displayName = `withErrorBoundary(${name})`
    Wrapped.propTypes = ChildComponent.propTypes
    Wrapped.defaultProps = ChildComponent.defaultProps

    return Wrapped as unknown as ComponentType
}

export { ErrorBoundary, withErrorBoundary }
