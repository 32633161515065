// @TODO: Expose links to Sidebar component and connect them with real links
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import styles from './FooterLinks.style'
import FooterLinksItem from './Item/Item.component'

const FooterLinksStyled = styled.div(props => ({ ...styles(props) }))

function FooterLinks({ footerLinks, ...rest }) {
    const uid = useUIDSeed()
    if (!footerLinks) {
        return null
    }
    return (
        <FooterLinksStyled {...rest}>
            {footerLinks.map((link, index) => (
                <FooterLinksItem key={uid(link.id || index)} link={link} />
            ))}
        </FooterLinksStyled>
    )
}

FooterLinks.propTypes = {
    footerLinks: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

FooterLinks.defaultProps = {
    footerLinks: undefined
}

export default withErrorBoundary(FooterLinks, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GlobalProfileFooterLinks]: ', error, componentStack)
    }
})
