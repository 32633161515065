/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import Script, { ScriptProps } from 'next/script'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'
import { useUIDSeed } from 'react-uid'

import googletagSlotsToIds from '../../helpers/ads/googletagSlotsToIds'
import { GoogleTagSlot, NativeGoogleTagIdCallback, NativeGoogleTagSlotCallback } from '../../types/ads/Ad.interface'

// const PREBID_TIMEOUT = 3000
const FAILSAFE_TIMEOUT = 3500
const PREBID_VARIABLE_NAME = 'pbjs'

declare global {
    interface Window {
        [PREBID_VARIABLE_NAME]?: {
            que: any[]
            rp?: {
                requestBids: (config: {
                    callback: (backSlots: GoogleTagSlot[]) => void
                    gptSlotObjects: GoogleTagSlot[]
                }) => void
            }
            setConfig?: (config: { consentManagement: any }) => void
        }
        __tcfapi?: (...args: any[]) => void
    }
}

const useRefWithDependency = value => {
    const refVal = useRef(value)
    useEffect(() => {
        refVal.current = value
    }, [value])

    return refVal
}

const failsafeHandler = (callback: (slots: GoogleTagSlot[]) => void, initialSlots: GoogleTagSlot[]) => {
    let adserverRequestSent = false
    return bidsBackSlots => {
        if (adserverRequestSent) return
        adserverRequestSent = true
        callback(bidsBackSlots || initialSlots)
    }
}

const useRubiconMagnite = ({ enabled = false }: { enabled?: boolean }) => {
    const isClient = typeof window !== 'undefined'

    const isEnabled = enabled && isClient

    const globalsMountedRef = useRef(false)
    // set up needed globals, runs only once
    useEffect(() => {
        if (!isEnabled || globalsMountedRef.current) {
            return
        }
        globalsMountedRef.current = true
        const consentManagement: any = {
            // gpp: {
            //     cmpApi: 'iab',
            //     timeout: 10000
            // },
            usp: {
                cmpApi: 'iab',
                timeout: 10000
            },
            gdpr:
                (typeof window.__tcfapi === 'function' && {
                    cmpApi: 'iab',
                    timeout: 10000,
                    defaultGdprScope: true
                }) ||
                undefined
        }

        // define global PBJS and GPT libraries
        window.pbjs = window.pbjs || {
            que: [() => window.pbjs?.setConfig?.({ consentManagement })]
        }

        window.googletag = window.googletag || {
            cmd: []
        }
    }, [isEnabled])

    // const timeoutRefs = useRef({})
    const enabledRef = useRefWithDependency(isEnabled)

    const displayAdUnits = useCallback(
        (googletagSlots: GoogleTagSlot[], nativeDisplay: NativeGoogleTagSlotCallback) => {
            const prebid = window[PREBID_VARIABLE_NAME]
            if (!enabledRef.current || googletagSlots.length === 0) {
                return
            }
            if (!prebid || !prebid.rp) {
                nativeDisplay(googletagSlots)
                return
            }
            const sendAdServerRequest = failsafeHandler(slotsToRefresh => {
                nativeDisplay(slotsToRefresh)
            }, googletagSlots)
            prebid.que.push(() => {
                if (!prebid.rp) {
                    sendAdServerRequest(googletagSlots)
                    return
                }
                prebid.rp.requestBids({
                    callback: sendAdServerRequest,
                    gptSlotObjects: googletagSlots
                })
            })
            setTimeout(sendAdServerRequest, FAILSAFE_TIMEOUT)
        },
        []
    )
    const rubiconDisplayCallback = useCallback(
        (googletagSlots: GoogleTagSlot[], nativeDisplay: NativeGoogleTagIdCallback) =>
            displayAdUnits(googletagSlots, (gptSlots: GoogleTagSlot[]) => nativeDisplay(googletagSlotsToIds(gptSlots))),
        [displayAdUnits]
    )

    const rubiconRefreshCallback = useCallback(
        (googletagSlots: GoogleTagSlot[], nativeRefresh: NativeGoogleTagIdCallback) =>
            displayAdUnits(googletagSlots, (gptSlots: GoogleTagSlot[]) => nativeRefresh(googletagSlotsToIds(gptSlots))),
        []
    )

    return [rubiconDisplayCallback, rubiconRefreshCallback]
}

const RubiconMagniteComponent = ({
    name,
    strategy,
    async,
    defer
}: {
    name: string
    strategy: ScriptProps['strategy']
    async: boolean
    defer: boolean
}) => {
    const uid = useUIDSeed()
    return (
        <Script
            strategy={strategy}
            id={`rubicon_magnite_${name.replace(/[ -/]/gim, '_')}`}
            key={uid(name)}
            defer={defer}
            async={async}
            src={`https://micro.rubiconproject.com/prebid/${name}.js`}
        />
    )
}

RubiconMagniteComponent.propTypes = {
    name: PropTypes.string,
    strategy: PropTypes.string,
    async: PropTypes.bool,
    defer: PropTypes.bool
}

RubiconMagniteComponent.defaultProps = {
    name: '1001_my_entire_website',
    strategy: 'afterInteractive',
    async: true,
    defer: true
}

export { RubiconMagniteComponent, useRubiconMagnite }
