// @TODO: Deliberately NOT DRY so we can fine tune it later if required and then migrate to utilities
import { useMemo } from 'react'

// It's not possible to chose prefix or suffix equal to this since we use it for pluralization
const delimiter = '||||'

// We must escape the prefix and the suffix because we're not allowed to use special regex characters like {} or []
const escape = token => token.replace(/[.*+?^${}()|[\]\\]/g, '$&')

// Construct custom regex token if passed as option
const constructRegexToken = options => {
    const prefix = (options && options.prefix) || '%{'
    const suffix = (options && options.suffix) || '}'

    if (prefix === delimiter || suffix === delimiter) {
        throw new RangeError(`'${delimiter} is reserved.`)
    }

    return new RegExp(`${escape(prefix)}(.*?)${escape(suffix)}`, 'g')
}

// 0 (number/string) - unknown
// 1 (number/string), m, male;
// 2 (number/string), f, female;
// 3 (number/string), p, plural
const getSexTypeIndex = sex => {
    if (!Number.isNaN(parseInt(sex, 10))) {
        const sexId = parseInt(sex, 10)
        // update this when we figure out what 0 means, for now return female
        return sexId > 0 ? sexId - 1 : 1
    }

    let index

    switch (sex) {
        case 'male':
        case 'm':
            index = 0
            break
        case 'female':
        case 'f':
            index = 1
            break
        case 'plural':
        case 'p':
            index = 2
            break
        default:
            // Use female as default since most registered users are female
            index = 1
    }

    return index
}

const transformPhrase = (phrase, substitutions = '', { locale, regexToken } = {}) => {
    // We got nothing to interpolate or nothing to substitue, just return our inputs
    if (!phrase.length) {
        return phrase
    }

    if (typeof phrase !== 'string') {
        throw new Error(`'${phrase}' cannot be interpolated on because it's not of String type`)
    }

    const defaultRegexToken = /%\{(.*?)\}/g
    const interpolationRegex = regexToken ? constructRegexToken(regexToken) : defaultRegexToken

    let result = phrase

    const options = typeof substitutions === 'string' ? { gender: substitutions } : substitutions

    if (options.gender !== null && result) {
        const texts = result.split(delimiter)
        result = (texts[getSexTypeIndex(options.gender || options, locale)] || texts[0]).trim()
    }

    result = result.replace(interpolationRegex, (expression, argument) => {
        if (options[argument] === 'undefined' || options[argument] === null) {
            return expression
        }
        return argument.replace(argument, options[argument])
    })

    return result
}

const useSexalize = (phrase, options) => {
    const sexalizedPhrase = useMemo(() => transformPhrase(phrase, options), [phrase, options])

    return sexalizedPhrase
}

export default useSexalize
