import { keyframes } from '@emotion/react'

import { toRem, toRems } from '../../helpers/theme'
/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, buttonWidth, buttonWidthMobile, iconOnly, isLoading, variant, simple }) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background
    // for development errors

    const spinnerAnimation = () => {
        const spinner = keyframes({
            from: {
                transform: 'rotate(0)'
            },
            to: {
                transform: 'rotate(360deg)'
            }
        })
        return {
            animationName: spinner,
            animationDuration: '2s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite'
        }
    }

    const config = {
        xs: {
            minHeight: toRem(46),
            padding: toRems([5, 20])
        },
        md: {
            minHeight: toRem(50),
            padding: toRems([7, 20])
        },
        icon: {
            fontSize: toRem(32),
            hSpacing: toRem(6)
        },
        fontSize: toRem(12),
        borderWidth: toRem(2),
        lineHeight: 1.4,
        letterSpacing: toRem(3),
        variant: {
            alpha: {
                borderStyle: 'solid',
                borderWidth: toRem(2)
            }
        },
        reset: {
            dimensions: {
                minWidth: 0,
                minHeight: 0,
                padding: 0
            },
            border: {
                border: 'none',
                borderRadius: 0
            }
        }
    }

    return {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        minWidth: buttonWidthMobile || buttonWidth || 0,
        minHeight: config.xs?.minHeight,
        margin: 0,
        padding: config.xs?.padding,
        outline: 0,
        backgroundColor: theme.colors.button[variant]?.background?.default,
        color: theme.colors.button[variant]?.text?.default,
        borderColor: theme.colors.button[variant]?.border?.default,
        borderStyle: config?.variant[variant]?.borderStyle,
        borderWidth: config?.variant[variant]?.borderWidth,
        fontWeight: theme.typography.fontWeight.bold,
        fontSize: config.fontSize,
        lineHeight: config.lineHeight,
        letterSpacing: config.letterSpacing,
        textTransform: 'uppercase',
        cursor: 'pointer',
        transitionDuration: theme.transitions.button.default.duration,
        transitionTimingFunction: theme.transitions.button.default.timingFunction,
        transitionDelay: theme.transitions.button.default.delay,
        transitionProperty: 'color, background-color, border-color',
        userSelect: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...(simple && {
            verticalAlign: 'baseline',
            ...config?.reset?.dimensions,
            ...config?.reset?.border,
            backgroundColor: 'transparent'
        }),
        [theme.breakpoints.up('md')]: {
            padding: config.md?.padding,
            minWidth: buttonWidth || 0,
            minHeight: config.md?.minHeight,
            ...(simple && {
                ...config?.reset?.dimensions
            })
        },
        ...(isLoading && {
            pointerEvents: 'none',
            backgroundColor: theme.colors.button[variant]?.background?.disabled || devWarnBg,
            color: theme.colors.button[variant]?.text?.disabled || devWarnTxt
        }),
        '& .spinner': {
            marginLeft: config.icon?.hSpacing,
            width: toRem(15),
            height: toRem(15),
            border: `2px solid ${theme.colors.button[variant]?.text?.disabled}`,
            borderRadius: '50%',
            borderTop: `2px solid ${theme.colors.button[variant]?.background?.default}`,
            ...spinnerAnimation()
        },
        '&:focus': {
            borderColor: theme.colors.button[variant]?.border?.focus,
            backgroundColor: theme.colors.button[variant]?.background?.focus || devWarnBg,
            color: theme.colors.button[variant]?.text?.focus || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.focus || devWarnTxt
            })
        },
        '&:hover': {
            borderColor: theme.colors.button[variant]?.border?.hover,
            backgroundColor: theme.colors.button[variant]?.background?.hover || devWarnBg,
            color: theme.colors.button[variant]?.text?.hover || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.hover || devWarnTxt,
                textDecoration: 'underline'
            })
        },
        '&:active': {
            borderColor: theme.colors.button[variant]?.border?.active,
            backgroundColor: theme.colors.button[variant]?.background?.active || devWarnBg,
            color: theme.colors.button[variant]?.text?.active || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.active || devWarnTxt
            })
        },
        '& .btn_icon': {
            display: 'inherit',
            fontSize: config.icon?.fontSize,
            marginLeft: iconOnly ? 0 : config.icon?.hSpacing,
            '& > span': {
                fontSize: 'inherit'
            }
        },
        '&.disabled, [disabled], [disabled="disabled"]': {
            backgroundColor: theme.colors.button[variant]?.background?.disabled || devWarnBg,
            color: theme.colors.button[variant]?.text?.disabled || devWarnTxt,
            pointerEvents: 'none',
            cursor: 'default',
            ...(!isLoading && {
                opacity: 0.6
            }),
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.disabled || devWarnTxt
            })
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        },

        '&.consent_btn': {
            margin: '20px 0'
        }
    }
}

export default style
