const currentAppEnvironment = process.env.NEXT_PUBLIC_APP_CONFIG_ENV

const isLocalhost = currentAppEnvironment === 'development'
const isDev = currentAppEnvironment === 'development'
const isProduction = currentAppEnvironment === 'production'

const parseClientEnvironment = query => {
    const { disableExternal = '', disable3rdParty = '', disableAds = '' } = query || {}

    return {
        adsEnabled: isDev || (!disableAds && !disableExternal),
        thirdPartyEnabled: !isDev || (!disable3rdParty && !disableExternal),
        isProduction,
        isDev,
        isLocalhost
    }
}

export default parseClientEnvironment
