import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant, isNavigationOpen, fixed }) => {
    const { navigation, header } = theme.global

    const config = {
        iconSize: {
            default: isNavigationOpen ? toRem(50) : toRem(29),
            xs: isNavigationOpen ? toRem(50) : toRem(29)
        },
        alpha: {
            size: {
                default: toRem(navigation.panel.header.height.default),
                xs: toRem(navigation.panel.header.height.xs)
            },
            background: {
                default: theme.colors.menu.button.background.default,
                hover: theme.colors.menu.button.background.hover
            },
            color: {
                default: theme.colors.menu.button.icon.default,
                hover: theme.colors.menu.button.icon.hover
            }
        },
        beta: {
            size: {
                default: toRem(header.height.md),
                xs: toRem(header.height.xs)
            },
            background: {
                default: theme.colors.header.button.background.default,
                hover: theme.colors.header.button.background.hover
            },
            color: {
                default: theme.colors.header.button.icon.default,
                hover: theme.colors.header.button.icon.hover
            }
        }
    }

    return {
        position: fixed ? 'fixed' : 'relative',
        ...(fixed && {
            top: 0,
            right: 0,
            left: 'auto'
        }),
        display: 'block',
        button: {
            left: 'auto',
            background: config[variant].background.default,
            color: config[variant].color.default,
            width: config[variant].size.xs,
            height: config[variant].size.xs,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'background, color',
            [theme.breakpoints.up('md')]: {
                width: config[variant].size.default,
                height: config[variant].size.default
            },
            span: {
                fontSize: config.iconSize.xs,
                [theme.breakpoints.up('md')]: {
                    fontSize: config.iconSize.default
                }
            },
            '&:hover': {
                background: config[variant].background.hover,
                color: config[variant].color.hover
            },
            svg: {
                path: {
                    fill: 'currentColor'
                }
            }
        }
    }
}

export default style
