/* eslint-disable @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../../helpers/components/createStyledComponent'
// import { toRem } from '../../../../../helpers/theme'

export type StyleProps = { theme?: any; height?: number; left?: boolean; width?: number; pageWidth?: number }
const styles = ({
    // theme,
    left = false,
    height = 600,
    width = 120,
    pageWidth = 960
}: StyleProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height,
    maxWidth: pageWidth,
    overflow: 'visible',
    '.WallpaperSide': {
        position: 'relative',
        height: '100%',
        margin: '0 auto',
        overflow: 'visible',
        '&_container': {
            pointerEvents: 'all',
            position: 'absolute',
            top: 0,
            [left ? 'left' : 'right']: -width + 'px',
            [left ? 'right' : 'left']: 'auto',
            width,
            height
        }
    }
})

const Styled = createStyledComponent({ tag: 'div', styles, blockProps: ['left', 'width', 'height', 'pageWidth'] })

export default Styled
