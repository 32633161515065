// @NOTE: be careful what you do here

import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, columns, spacing }) => {
    const itemMargin = spacing
    return {
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        margin: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        '& .columnsItem': {
            position: 'relative',
            flexBasis: '100%',
            marginTop: toRem(itemMargin),
            padding: 0,
            '&:nth-of-type(-n+1)': {
                marginTop: 0
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: `calc((100% / 2) - ${toRem(itemMargin / 2)})`,
                ...(columns === 1 && {
                    maxWidth: 'none'
                }),
                '&:nth-of-type(-n+2)': {
                    ...(columns !== 1 && {
                        marginTop: 0
                    })
                },
                '&:not(:nth-of-type(2n+0))': {
                    marginRight: toRem(itemMargin),
                    ...(columns === 1 && {
                        marginRight: 0
                    })
                },
                '&:nth-of-type(2n+0)': {
                    marginRight: 0
                }
            },
            [theme.breakpoints.up('lg')]: {
                ...(columns === 3 && {
                    maxWidth: `calc((100% / 3) - ${toRem(itemMargin / 1.5)})`,
                    '&:nth-of-type(-n+3)': {
                        marginTop: 0
                    },
                    '&:not(:nth-of-type(3n+0))': {
                        marginRight: toRem(itemMargin)
                    },
                    '&:nth-of-type(3n+0)': {
                        marginRight: 0
                    }
                }),
                ...(columns === 4 && {
                    maxWidth: `calc((100% / 4) - ${toRem(itemMargin / 1.33)})`,
                    '&:nth-of-type(-n+4)': {
                        marginTop: 0
                    },
                    '&:not(:nth-of-type(4n+0))': {
                        marginRight: toRem(itemMargin)
                    },
                    '&:nth-of-type(4n+0)': {
                        marginRight: 0
                    }
                })
            }
        }
    }
}

export default style
