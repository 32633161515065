import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    width: '100%',
    '.prevNext': {
        position: 'relative',
        width: '100%',
        paddingTop: toRem(15),
        paddingBottom: toRem(20),
        background: theme.colors.prevNext?.panel?.background || theme.colors.devWarning,
        '&_wrapper': {
            width: '100%',
            background: theme.colors.prevNext?.panel?.background || theme.colors.devWarning
        },
        '&_graphic': {
            width: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'scale(-1, 1)',
            height: toRem(12),
            top: toRem(-6)
        },
        '&_count': {
            flex: '0 0 auto',
            fontSize: toRem(12),
            lineHeight: toRem(32),
            color: theme.colors.grey500,
            letterSpacing: '0.2em',
            fontWeight: theme.typography.fontWeight.bold
        },
        '&_button': {
            flex: '1 1 50%',
            '&_wrapper': {
                margin: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                maxWidth: toRem(1215)
            }
        }
    }
})

export default style
