import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { ClientNavigationState, useClientNavigationState } from '../../context/clientNavigation'
import createIsolatedHook from '../../helpers/createIsolatedHook'
import safelyJoinSlugs from '../../helpers/safelyJoinSlugs'
import { useGemius, useGTM, useInterval, useUpScore } from '..'
import useCurrentPageNumber from '../useCurrentPageNumber'
import { useRouteChangeComplete } from '../useRouteChange'

// @NOTE: tested only for regular category and layout builder atm, probably will need a minor refactor for other types of pages
const useCategoryUpscore = ({
    nodeId,
    homepageId,
    appRoot,
    node,
    slugs,
    parentNode,
    isZena = false,
    isArchive = false
}) => {
    if (!homepageId || !appRoot) {
        throw new Error(
            `neccessary environment attribute ${!appRoot ? 'appRoot ' : ''}${!appRoot && !homepageId ? 'and ' : ''}${
                !homepageId ? 'homepageId ' : ''
            }${!appRoot && !homepageId ? 'are' : 'is'} missing`
        )
    }
    const router = useRouter()
    const { pageNumber, year, month } = router.query

    const upScoreParams = useMemo(() => {
        if (nodeId === homepageId) {
            return ['landingpage', '', '', '']
        }

        let pageUrl = pageNumber
            ? `${appRoot}/${safelyJoinSlugs(slugs)}/${
                  isArchive ? `/arhiva/${year}/${month}` : 'najnovije'
              }?stranica=${pageNumber}`
            : ''

        if (isZena) {
            pageUrl = `${appRoot}/${safelyJoinSlugs(slugs)}/${pageNumber}`
        }

        if (node?.extended_attributes?.show || parentNode?.extended_attributes?.show) {
            pageUrl = `${appRoot}/${router?.asPath}`
        }
        return [isArchive ? 'misc' : 'landingpage', slugs?.[0], slugs?.[1] || '', pageUrl]
    }, [nodeId, slugs, pageNumber, node, parentNode, router, slugs, year, month, isArchive])

    useUpScore({
        id: '',
        objectType: upScoreParams?.[0],
        category: upScoreParams?.[1],
        subCategory: upScoreParams?.[2],
        nextPageUrl: upScoreParams?.[3]
    })
}

const getGemiusDiff = (isShow, listingPage, id, router) => {
    if (isShow) {
        return router?.asPath
    }
    return listingPage === 1 ? id : listingPage
}

// @NOTE: tested only for regular category and layout builder atm, probably will need a minor refactor for other types of pages
const useCategoryGemius = ({ gemiusId, nodeId, node, parentNode }) => {
    const router = useRouter()
    const listingPage = useCurrentPageNumber()
    const id = nodeId || node?.id
    useGemius(
        gemiusId,
        getGemiusDiff(node?.extended_attributes?.show || parentNode?.extended_attributes?.show, listingPage, id, router)
    )
}

const getPageType = (node, homepageId) => {
    if (node?.id === homepageId) {
        return 'homepage'
    }
    if (node?.tree_level === '0') {
        return `Section`
    }
    return `Subsection`
}

// @NOTE: tested only for regular category and layout builder atm, probably will need a minor refactor for other types of pages
// generic enough to be used for net regular category too
const useCategoryGtm = ({ node, homepageId, isListing, nodeId, parentNode, appRoot, isArchive = false }) => {
    if (!homepageId) {
        throw new Error(`neccessary environment attribute homepageId is missing`)
    }
    const router = useRouter()
    const id = nodeId || node?.id
    const { sendDataToGTM } = useGTM()

    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => {
        setIsBrowser(true)
    }, [])

    const gtmDataVirtualInfo = useMemo(
        () => ({
            event: 'VirtualPageView',
            virtualPageURL: `${node?.sitemap?.href === '/naslovnica' ? '/' : node?.sitemap?.href}`,
            virtualPageTitle: `${node?.title === 'Naslovnica' ? `Naslovnica` : node?.title}`
        }),
        [node]
    )
    const gtmDataGeneralInfo = useMemo(
        () => ({
            event: 'pageView',
            userType: 'not logged in',
            pageType: isArchive ? 'archive pages' : getPageType(node, homepageId),
            contentID: id,
            contentTitle: node?.title,
            contentAuthor: '',
            contentCreator: ''
        }),
        [node, isArchive]
    )
    const sendPageViewData = useCallback(() => {
        const gtmDataCategoryInfo = {
            contentType: ''
        }
        sendDataToGTM(gtmDataCategoryInfo)
        sendDataToGTM(gtmDataGeneralInfo)
        sendDataToGTM(gtmDataVirtualInfo)
    }, [appRoot, gtmDataGeneralInfo, gtmDataVirtualInfo, isListing, sendDataToGTM])
    // useRouteChangeComplete(sendPageViewData, true)
    const navigationState = useClientNavigationState()
    const sentThisNavRef = useRef(false)
    const resetSentThisNavRef = useCallback(() => {
        sentThisNavRef.current = false
    }, [])
    useRouteChangeComplete(resetSentThisNavRef)
    useEffect(() => {
        // console.log('navigationState', navigationState)
        if (navigationState !== ClientNavigationState.READY || sentThisNavRef.current) {
            return
        }
        sentThisNavRef.current = true
        sendPageViewData()
    }, [navigationState, sendPageViewData])

    // This was causing double VirtualPageViews on rtl and danas, doublecheck net & zena and delete
    // useEffect(() => {
    //     if (
    //         isListing &&
    //         !(node?.extended_attributes?.show || parentNode?.extended_attributes?.show) &&
    //         articles?.length &&
    //         appRoot === process.env.NEXT_PUBLIC_APP_ROOT_RTL
    //     ) {
    //         sendDataToGTM({
    //             event: 'VirtualPageView',
    //             virtualPageURL: `${node?.sitemap?.href === '/naslovnica' ? '/' : node?.sitemap?.href}`,
    //             virtualPageTitle: `${node?.title === 'Naslovnica' ? `Naslovnica` : node?.title}`
    //         })
    //     }
    // }, [
    //     listingPage,
    //     articles?.length,
    //     isListing,
    //     node?.extended_attributes?.show,
    //     parentNode?.extended_attributes?.show
    // ])

    useEffect(() => {
        if (node?.extended_attributes?.show || parentNode?.extended_attributes?.show) {
            sendDataToGTM({
                event: 'VirtualPageView',
                virtualPageURL: router?.asPath,
                virtualPageTitle: node?.title
            })
        }
    }, [node?.extended_attributes?.show, parentNode?.extended_attributes?.show, router?.asPath])

    // @NOTE reloads site after 20 minutes for gtm
    useInterval(
        () => {
            if (isBrowser) {
                window?.location?.reload()
            }
        },
        {
            delay: 1200000
        }
    )
}

// @NOTE: tested only for regular category and layout builder atm, probably will need a minor refactor for other types of pages
const useCategoryThirdParties = ({
    gemiusId,
    appRoot,
    homepageId,
    nodeId,
    node,
    slugs,
    parentNode,
    isListing,
    isZena = false,
    isArchive = false
}) => {
    if (!homepageId || !appRoot) {
        throw new Error(
            `neccessary environment attribute ${!appRoot ? 'appRoot ' : ''}${!appRoot && !homepageId ? 'and ' : ''}${
                !homepageId ? 'homepageId ' : ''
            }${!appRoot && !homepageId ? 'are' : 'is'} missing`
        )
    }
    useCategoryUpscore({ nodeId, homepageId, appRoot, slugs, node, parentNode, isZena, isArchive })
    useCategoryGemius({ gemiusId, nodeId, node, parentNode })
    useCategoryGtm({ node, homepageId, isListing, nodeId, parentNode, appRoot, isArchive })
}

export const IsolatedUseCategoryThirdParties = createIsolatedHook(useCategoryThirdParties)

export default useCategoryThirdParties
