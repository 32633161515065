import styled from '@emotion/styled'

import { toRem, toRems } from '../../helpers/theme'

export const Table = styled.div(({ theme }) => ({
    overflowX: 'scroll',
    marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
    }
}))

export const Paragraph = styled.p(({ theme, align }) => ({
    textAlign: align,
    color: theme.colors.text.default
}))

const GenericHeading = styled.h1(({ theme, textAlign, as }) => {
    const config = theme.global.htmlContent[as] || theme.global.htmlContent.title

    return {
        marginBottom: toRem(config.marginBottom || 0),
        textAlign
    }
})

export const H1 = GenericHeading
export const H2 = GenericHeading.withComponent('h2')
export const H3 = GenericHeading.withComponent('h3')
export const H4 = GenericHeading.withComponent('h4')
export const H5 = GenericHeading.withComponent('h5')
export const H6 = GenericHeading.withComponent('h6')

export const Ol = styled.ol()
export const Ul = styled.ul()

export const Quote = styled.ul(({ theme, width, align }) => ({
    width: width || '100%',

    marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin),
        float: align
    }
}))

export const Row = styled.div(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        gridColumnGap: toRem(25)
    },
    marginBottom: toRem(50)
}))

export const Column = styled.div(({ theme, setWidth }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: setWidth
    }
}))

export const EmbedVideo = styled.div(({ theme }) => {
    const videoPadding = () => ({
        height: 0,
        paddingTop: 'calc(100% * 9 / 16)'
    })
    return {
        ...videoPadding(),
        position: 'relative',
        width: '100%',
        marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
        },
        iframe: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
        }
    }
})

export const EmbedSocial = styled.div(({ theme }) => ({
    marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
    },
    '.twitter-tweet': {
        margin: '0 auto'
    },
    '.fb-post': {
        [theme.breakpoints.down('md')]: {
            span: {
                maxWidth: `${toRem(330)}`,
                iframe: {
                    maxWidth: `${toRem(330)}`
                }
            }
        }
    }
}))

export const EmbedPoll = styled.div(() => ({
    marginBottom: '1em'
}))

export const EmbedForm = styled.div(({ align = 'right' }) => ({
    float: align || 'none',
    width: align ? '50%' : '100%',
    paddingTop: 'calc(100% * 9 / 16)',
    marginBottom: '1em'
}))

export const EmbedArticle = styled.div(({ theme, align }) => ({
    width: '100%',
    marginBottom: toRem(theme.global.htmlContent.article.xs.marginBottom),
    ...(align === 'center' && {
        padding: toRems([20, 0])
    }),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.article.md.marginBottom),
        minWidth: toRem(200),
        float: align === 'center' ? undefined : align,
        margin: align === 'center' ? 'auto' : undefined,
        ...(align === 'center' && {
            padding: toRems([50, 0])
        })
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: toRem(theme.global.htmlContent.xs.marginBottom),
        marginLeft: toRem(theme.global.htmlContent.article.marginLeft), // `calc(50% - 50vw)`,
        marginRight: toRem(theme.global.htmlContent.article.marginRight) // `calc(50% - 50vw)`
    },
    'a, a:visited, a:hover': {
        textDecoration: 'none'
    }
}))

export const EmbedImage = styled.div(({ theme }) => ({
    marginBottom: toRem(theme.global.htmlContent.image.xs.marginBottom),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.image.md.marginBottom)
    },
    ...(theme.global.htmlContent.image.hasEmbedImage && {
        '& .embedImage_image': {
            margin: toRems([0, 0, 6, 0])
        },
        '.embedImage_author': {
            padding: toRems([0, 0, 0, 18]),
            position: 'relative',
            fontSize: toRem(14),
            color: theme.colors.text.pale,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(16)
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
                marginRight: toRem(15),
                width: toRem(12),
                height: 1,
                background: theme.colors.text.pale
            }
        }
    })
}))

export const EmbedSoundCloud = styled.div(({ theme }) => ({
    height: toRem(180),
    position: 'relative',
    width: '100%',
    marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
    [theme.breakpoints.up('md')]: {
        marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
    },
    iframe: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }
}))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EmbedIFrame = styled.div(({ theme, parsedHeight, desktopHeight, mobileHeight }) => ({
    '.embed_iframe_container': {
        position: 'relative',
        width: '100%',
        paddingBottom: `${(mobileHeight / 345) * 100}%`,

        [theme.breakpoints.up('md')]: {
            paddingBottom: `${(desktopHeight / 700) * 100}%`
        },

        // INFO: leave this example for further usage if needed
        // '@container ifrContainer (min-width: 550px)': {
        //     paddingBottom: `${(desktopHeight / 700) * 100}%`
        // },

        '> iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }
    }
}))
