import { useMemo } from 'react'

import { findFirstTitle } from '../../helpers/blockList'

const usePrevNextItems = ({ article = {}, currentPage = 1, totalPages = 1, blockList = [] } = {}) =>
    useMemo(
        () => ({
            prev: {
                title: currentPage <= 2 ? article?.title : findFirstTitle(blockList[currentPage - 2]),
                href:
                    currentPage <= 1 || !article?.sitemap?.href
                        ? undefined
                        : `${article.sitemap.href}/${currentPage - 1}`
            },
            next: {
                title:
                    !currentPage || currentPage >= totalPages ? 'Naslovnicu' : findFirstTitle(blockList[currentPage]),
                href:
                    !currentPage || currentPage >= totalPages || !article?.sitemap?.href
                        ? '/'
                        : `${article.sitemap.href}/${currentPage + 1}`
            }
        }),
        [article, currentPage, totalPages, blockList]
    )
export default usePrevNextItems
