/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Five = createSvgIcon(
    <path
        d="M10 20a10 10 0 01-2.5-.3.8.8 0 11.4-1.5A8.5 8.5 0 0018 7.7a.8.8 0 111.5-.5A10 10 0 0110 20zm-8.8-7c.5-.1.7-.5.6-1a8.5 8.5 0 019.7-10.3.8.8 0 10.2-1.5A10 10 0 003 2.9a10 10 0 00-2.6 9.5.8.8 0 001 .6zm12.7-.2c0 .1-1.5 2.3-4 2.3-2.4 0-3.8-2.2-3.9-2.3a.8.8 0 111.3-.8s1 1.5 2.7 1.5c1.6 0 2.6-1.5 2.6-1.5a.8.8 0 111.3.8zM18 0a2 2 0 00-1.2.4A2 2 0 0013.7 2c0 1.2.7 2.4 2 3.4a2 2 0 002.3 0c1.3-1 2-2.2 2-3.4a2 2 0 00-2-2zm-1 4.1c0 .1-.3.1-.4 0C16 3.7 15.3 3 15.2 2c0-.3.2-.4.4-.4.3 0 .5.1.5.4a.8.8 0 101.5 0c0-.3.2-.4.4-.4.3 0 .4.1.4.4 0 1-.7 1.7-1.3 2.1zM4.5 14.2a2 2 0 00-1.2.4A2 2 0 000 16.2c0 1.3.7 2.4 2 3.4a2 2 0 002.3 0c1.3-1 2-2.1 2-3.4a2 2 0 00-2-2zm-1 4.2h-.5c-.6-.5-1.3-1.3-1.3-2.2 0-.2.1-.4.4-.4.2 0 .4.2.4.4a.8.8 0 001.5 0c0-.2.2-.4.5-.4.2 0 .4.2.4.4 0 1-.8 1.7-1.4 2.2zM8.2 7h-1c-.9 0-1.7.4-2.4 1a.8.8 0 101 1.2c.5-.4.9-.6 1.3-.6h1.1a.8.8 0 100-1.6zm7 1c-.8-.7-1.6-1-2.4-1h-1a.8.8 0 000 1.6h1c.5 0 1 .2 1.5.6a.8.8 0 001-.2c.3-.3.3-.8 0-1z"
        fill="#000"
    />,
    'Five'
)

export default Five
