export const defaults = {
    fontFamily: {
        default: 'Switzer, ArialToSwitzer',
        alternative: 'DM Serif Display Regular, TimesNewRomanToDMSerif'
    },
    fontDisplay: 'swap',
    fontSize: 16,
    fontSizes: {
        // [type]: [xs, sm, md, lg, xl, xxl]
        body: [18, 18, 22, 22, 22, 22],
        p: [18, 18, 22, 22, 22, 22],
        ul: [18, 18, 22, 22, 22, 22],
        ol: [18, 18, 22, 22, 22, 22],
        dl: [18, 18, 22, 22, 22, 22],
        table: [18, 18, 22, 22, 22, 22],
        h1: [28, 28, 64, 64, 64, 64],
        h2: [22, 22, 42, 42, 42, 42],
        h3: [24, 24, 28, 28, 28, 28],
        h4: [18, 18, 22, 22, 22, 22],
        h5: [18, 18, 22, 22, 22, 22],
        h6: [18, 18, 22, 22, 22, 22],
        small: [16, 16, 18, 18, 18, 18],
        blockquote: [16, 16, 26, 26, 26, 26]
    },
    fontStyle: {
        normal: 'normal',
        italic: 'italic'
    },
    fontVariant: {
        normal: 'normal'
    },
    lineHeight: 1.6,
    fontWeight: {
        extraLight: 200,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700
    }
}

const lineHeights = {
    body: 1.8,
    heading: {
        h1: 1.4,
        h2: 1.2,
        h3: 1.2,
        h4: 1.2,
        h5: 1.2,
        h6: 1.2
    },
    paragraph: 1.8,
    button: 2,
    // input: 1.6,
    blockquote: 1.6,
    list: 1.4
}

const rendering = {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'greyscale',
    textRendering: 'optimizeLegibility'
}

const fontSettings = {
    html: {
        ...rendering,
        fontFamily: `${defaults.fontFamily.default}, sans-serif`
    },
    body: {
        lineHeight: lineHeights.body,
        letterSpacing: 0
    },
    h1: {
        fontFamily: `${defaults.fontFamily.alternative}, serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.heading.h1
    },
    h2: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h2
    },
    h3: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h3
    },
    h4: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h4
    },
    h5: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h5
    },
    h6: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.bold,
        lineHeight: lineHeights.heading.h6
    },
    p: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.medium,
        lineHeight: lineHeights.paragraph
    },
    ul: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.medium,
        lineHeight: lineHeights.list
    },
    ol: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.medium,
        lineHeight: lineHeights.list
    },
    small: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.medium
    },
    button: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.button
    },
    // input: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    // textarea: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    // select: {
    //     fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    //     fontWeight: defaults.fontWeight.regular,
    //     lineHeight: lineHeights.input
    // },
    blockquote: {
        fontFamily: `${defaults.fontFamily.alternative}, serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.blockquote,
        fontStyle: defaults.fontStyle.italic
    }
}

export default {
    fontSettings,
    fontFamily: `${defaults.fontFamily.default}, sans-serif`,
    fontFamilyAlt: `${defaults.fontFamily.alternative}, serif`,
    font: {
        default: defaults.fontFamily.default,
        display: defaults.fontDisplay,
        weight: defaults.fontWeight,
        style: defaults.fontStyle,
        variant: defaults.fontVariant
    },
    fontSizes: defaults.fontSizes,
    fontWeight: defaults.fontWeight,
    rootFontSize: defaults.fontSize
}
