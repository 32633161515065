import { ElementType, forwardRef, memo } from 'react'

import { SvgIcon } from '../components/SvgIcon'

const createSvgIcon = (path: ElementType, displayName?: string, defaultViewBox?: string) => {
    const Component = memo(
        forwardRef<SVGElement, any>((props, ref) => (
            <SvgIcon ref={ref} viewBox={defaultViewBox} {...props}>
                {path}
            </SvgIcon>
        ))
    )

    if (displayName) Component.displayName = `${displayName}Icon`
    // Component.lookupName = `${displayName.toLowerCase()}`

    return Component
}

export default createSvgIcon
