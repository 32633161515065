import React from 'react'
import PropTypes from 'prop-types'

import { EmbedSocial } from '../../components/HtmlContent/HtmlContent.style'
import { LazyTwitterItem } from './LazyTwitterItem.component'

const tweetRegex = /https?:\/\/(twitter\.com|x\.com)\/[0-9a-zA-Z_]{1,20}\/status\/([0-9]*)/
export function CommonTwitterEmbed({ url }) {
    const id = url?.match(tweetRegex)?.[2]

    if (!id) {
        return null
    }

    return (
        <EmbedSocial>
            <LazyTwitterItem id={id} />
        </EmbedSocial>
    )
}

CommonTwitterEmbed.propTypes = {
    url: PropTypes.string
}

CommonTwitterEmbed.defaultProps = {
    url: undefined
}
